import React, { useEffect } from 'react';
import injectSheet from 'react-jss';
import { connect } from 'react-redux';
import { compose } from 'recompose';
import 'appCore/libs/dmak.min';
import renderHTML from 'react-render-html';
import { SoundController, Block } from 'components';
import { constants, translate } from 'utils';
import { images, theme } from 'appCore';
import { selectors } from 'containers/LessonBySkill/duck';
import styles from './styles';

const mapStateToProps = state => selectors(state);

const WordPopupContent = ({
  classes,
  word,
  kanjiItem,
  kanji,
  currentLang,
  getKanjiDetail,
  removeDataKanji
}) => {
  const redrawKanji = () => {
    const drawElement = document.getElementById('draw');
    if (drawElement) drawElement.innerHTML = '';
    // eslint-disable-next-line no-undef
    const dmak = new Dmak(kanjiItem || (word.vocabulary ? word.vocabulary : word.kanji), {
      element: 'draw',
      uri: constants.KANJI_API,
      step: 0.01,
      height: 200,
      width: 200,
      stroke: {
        order: {
          visible: true
        },
        attr: {
          active: theme.colors.primary
        }
      }
    });
    return dmak;
  };

  const reload = () => redrawKanji();

  useEffect(() => {
    redrawKanji();
    if (getKanjiDetail || word.kanji) getKanjiDetail(kanjiItem || word.kanji);
    return () => {
      if (getKanjiDetail || word.kanji) removeDataKanji();
    };
  }, []);

  return (
    <div className={classes.wrapper}>
      <div id="draw" />
      <span className={classes.spelling}>{word.spelling}</span>
      {Object.entries(kanji).length !== 0 && (
        <>
          <Block className={classes.blockKanji}>
            <div className={classes.blockWordKanji}>
              <span className={classes.wordKanjiFirst}>Từ</span>
              <span className={classes.wordKanji}>: {kanji.kanji}</span>
            </div>
            <div className={classes.blockWordKanji}>
              <span className={classes.wordKanjiFirst}>Hán việt</span>
              <span className={classes.wordKanji}>
                : {translate(kanji, currentLang).chinese_vietnamese_word}
                {/* {kanji.translations && kanji.translations[currentLang]
                  ? kanji.translations[currentLang].chinese_vietnamese_word
                  : kanji.translations && kanji.translations.vi.chinese_vietnamese_word} */}
              </span>
            </div>
            <div className={classes.blockWordKanji}>
              <span className={classes.wordKanjiFirst}>Ý nghĩa</span>
              <span className={classes.wordKanji}>
                : {translate(kanji, currentLang).meaning}
                {/* {kanji.translations && kanji.translations[currentLang]
                  ? kanji.translations[currentLang].meaning
                  : kanji.translations && kanji.translations.vi.meaning} */}
              </span>
            </div>
          </Block>
          {kanji.translations &&
            translate(kanji, currentLang) &&
            translate(kanji, currentLang).example1 && (
              <Block className={classes.blockKanji}>
                <div className={classes.blockWordKanjiExample}>
                  <span className={classes.wordKanjiFirstExample}>Ví dụ 1</span>
                  <span className={classes.wordKanjiExample}>
                    {renderHTML(translate(kanji, currentLang).example1)}
                    {/* {kanji.translations && kanji.translations[currentLang]
                    ? renderHTML(kanji.translations[currentLang].example1)
                    : renderHTML(kanji.translations && kanji.translations.vi.example1)} */}
                  </span>
                </div>
              </Block>
            )}
          {kanji.translations &&
            translate(kanji, currentLang) &&
            translate(kanji, currentLang).example2 && (
              <Block className={classes.blockKanji}>
                <div className={classes.blockWordKanjiExample}>
                  <span className={classes.wordKanjiFirstExample}>Ví dụ 2</span>
                  <span className={classes.wordKanjiExample}>
                    {renderHTML(translate(kanji, currentLang).example2)}
                    {/* {kanji.translations && kanji.translations[currentLang]
                    ? renderHTML(kanji.translations[currentLang].example2)
                    : renderHTML(kanji.translations && kanji.translations.vi.example2)} */}
                  </span>
                </div>
              </Block>
            )}
        </>
      )}
      <div className={classes.bottom}>
        <div className={classes.button} onClick={reload}>
          <img className={classes.reload} src={images.reload} alt="reload" />
        </div>
        {kanjiItem && <SoundController src={kanji.audio} />}
        {!kanjiItem && <SoundController src={word.audio} />}
      </div>
    </div>
  );
};

export default compose(
  connect(mapStateToProps),
  injectSheet(styles)
)(WordPopupContent);
