/*
 * @Author: loc.do@bstarsolutions.com
 * @Date: 2019-08-02 16:16:37
 * @Last Modified by: loc.do@bstarsolutions.com
 * @Last Modified time: 2019-08-13 18:42:15
 */
import React from 'react';
import { put, takeLatest } from 'redux-saga/effects';
import { modalActions } from 'containers/ModalManagement/duck';
import { ModalContact } from 'components';
import { EnterpriseAPI } from 'services';

import * as types from './types';

function* submitRegisterEnterpriseSaga(action) {
  const { typeForm, name, email, phone, course, numOfEmployee } = action.payload;
  try {
    yield put({ type: types.POST_FORM_REGISTER_ENTERPRISE_FETCHING });
    const rawData = yield EnterpriseAPI.submitRegisterEnterprise(
      typeForm,
      name,
      email,
      phone,
      course,
      numOfEmployee
    );

    if (rawData.errors && String(rawData.errors.name) === 'validation.required') {
      const children = <ModalContact emptyName />;
      yield put(modalActions.showModal({ children }));
    } else if (rawData.errors && String(rawData.errors.email) === 'validation.email') {
      const children = <ModalContact emptyEmail />;
      yield put(modalActions.showModal({ children }));
    } else if (rawData.errors && String(rawData.errors.phone) === 'validation.required') {
      const children = <ModalContact emptyPhone />;
      yield put(modalActions.showModal({ children }));
    } else if (rawData.errors && String(rawData.errors.phone) === 'validation.min.numeric') {
      const children = <ModalContact phoneIsZero />;
      yield put(modalActions.showModal({ children }));
    } else if (rawData.errors && String(rawData.errors.course_id) === 'validation.numeric') {
      const children = <ModalContact emptyCourse />;
      yield put(modalActions.showModal({ children }));
    } else if (rawData.errors && String(rawData.errors.num_of_employee) === 'validation.numeric') {
      const children = <ModalContact emptyNumOfEmployee />;
      yield put(modalActions.showModal({ children }));
    } else if (rawData.data && rawData.data.code === 200) {
      const children = <ModalContact registerSuccess />;
      yield put(modalActions.showModal({ children }));
    }

    yield put({
      type: types.POST_FORM_REGISTER_ENTERPRISE_SUCCESS,
      payload: {
        status: false,
        resultRegisterEnterprise: rawData.data.data
      }
    });
  } catch (e) {
    yield put({
      type: types.POST_FORM_REGISTER_ENTERPRISE_FAIL,
      payload: {
        resultRegisterEnterprise: {}
      }
    });
  }
}

function* getListCourseEnterpriseSaga() {
  try {
    yield put({ type: types.GET_LIST_COURSE_ENTERPRISE_FETCHING });
    const rawData = yield EnterpriseAPI.getListCourseEnterprise();
    yield put({
      type: types.GET_LIST_COURSE_ENTERPRISE_SUCCESS,
      payload: {
        listCourseEnterprise: rawData.data.data
      }
    });
  } catch (e) {
    yield put({
      type: types.GET_LIST_COURSE_ENTERPRISE_FAIL
    });
  }
}

export const submitRegisterEnterpriseInSaga = takeLatest(
  types.POST_FORM_REGISTER_ENTERPRISE_INIT,
  submitRegisterEnterpriseSaga
);
export const getListCourseEnterpriseInSaga = takeLatest(
  types.GET_LIST_COURSE_ENTERPRISE_INIT,
  getListCourseEnterpriseSaga
);

export default this;
