export default this;
export { default as FirstInAll } from './FirstInAllComponent';
export { default as Breadcrumb } from './Breadcrumb';
export { default as Block } from './Block';
export { default as Button } from './Button';
export { default as LinkContact } from './LinkContact';
export { default as Divider } from './Divider';
export { default as Slide } from './Slide';
export { default as Introduction } from './Introduction';
export { default as TextInput } from './TextInput';
export { default as Modal } from './Modal';
export { default as ModalWrapper } from './Modal';
export { default as Dot } from './Dot';
export { default as IntroductionCourse } from './IntroductionCourse';
export { default as DurationCourse } from './DurationCourse';
export { default as Tab } from './Tab';
export { default as LeftMenu } from './LeftMenu';
export { default as WordBox } from './WordBox';
export { default as WordPopupContent } from './WordPopupContent';
export { default as Sell } from './Sell';
export { default as ModalContent } from './ModalContent';
export { default as Selector } from './Selector';
export { default as PopupMenu } from './PopupMenu';
export { default as Collapse } from './Collapse';
export { default as Tooltip } from './Tooltip';
export { default as ModalResult } from './ModalResult';
export { default as Audio } from './Audio';
export { default as Video } from './Video';
export { default as SoundController } from './SoundController';
export { default as RibbonTopLeft } from './RibbonTopLeft';
export { default as InformationPractice } from './InformationPractice';
export { default as BlockCoin } from './BlockCoin';
export { default as InputSelect } from './InputSelect';
export { default as CourseInShop } from './CourseInShop';
export { default as RadioButton } from './RadioButton';
export { default as ExamInShop } from './ExamInShop';
export { default as LoadingComponent } from './LoadingComponent';
export { default as LazyImage } from './LazyImage';
export { default as CountTime } from './CountTime';
export { default as BlockExpand } from './BlockExpand';
export { default as ModalConfirm } from './ModalConfirm';
export { default as BlockTitleBorder } from './BlockTitleBorder';
export { default as ModalUpdateInformation } from './ModalUpdateInformation';
export { default as ModalCheckLogin } from './ModalCheckLogin';
export { default as YourBlock } from './YourBlock';
export { default as YourBlockCourse } from './YourBlockCourse';
export { default as SocialLogin } from './SocialLogin';
export { default as SwitchButton } from './SwitchButton';
export { default as Pagination } from './Pagination';
export { default as ModalArticle } from './ModalArticle';
export { default as ModalResultDetailExam } from './ModalResultDetailExam';
export { default as PageAnnoumentMobile } from './PageAnnoumentMobile';
export { default as ModalContact } from './ModalContact';
export { default as Slider2 } from './Slider2';
export { default as MetaTag } from './MetaTag';
