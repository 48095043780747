/*
 * @Author: loc.do@bstarsolutions.com
 * @Date: 2019-05-10 10:36:21
 * @Last Modified by: loc.do@bstarsolutions.com
 * @Last Modified time: 2019-05-17 17:14:17
 */
export const COURSE_INIT = 'COURSE_INIT';
export const COURSE_FETCHING = 'COURSE_FETCHING';
export const COURSE_SUCCESS = 'COURSE_SUCCESS';
export const COURSE_FAIL = 'COURSE_FAIL';
export const COURSE_COMBO = 'COURSE_COMBO';
export const COURSE_PACKAGE_PRIMARY = 'COURSE_PACKAGE_PRIMARY';
