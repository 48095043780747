/*
 * @Author: loc.do@bstarsolutions.com
 * @Date: 2019-05-15 10:27:37
 * @Last Modified by: loc.do@bstarsolutions.com
 * @Last Modified time: 2019-08-02 15:50:15
 */

import React from 'react';
import injectSheet from 'react-jss';
import { Link } from 'react-router-dom';
import { withRouter } from 'react-router';
import { translator } from 'utils';
import renderHTML from 'react-render-html';
import { Button, Block, Video } from 'components';
import styles from './styles';

function IntroductionCourse(props) {
  const {
    classes,
    description,
    title,
    idLesson,
    linkYoutube,
    isBought,
    onClickShow,
    labelDefaultButton,
    strings
  } = props;

  const lang = strings.introCourse;

  const url =
    linkYoutube &&
    (linkYoutube.includes('https://youtu.be/')
      ? linkYoutube.replace('https://youtu.be/', 'https://www.youtube.com/embed/')
      : linkYoutube.replace('https://www.youtube.com/watch?v=', 'https://www.youtube.com/embed/'));

  return (
    <div className={`${classes.container} mt-3 mt-lg-1`}>
      {url ? (
        <div className="introductionCourse-wp">
          <div className="wp-video-from-youtube">
            <iframe
              className={classes.video}
              src={`${url}?autoplay=1`}
              frameBorder="0"
              title="Rainichi Youtube"
              allow="accelerometer; autoplay; encrypted-media; gyroscope; picture-in-picture"
              allowFullScreen
            ></iframe>
          </div>
        </div>
      ) : (
        <Video className={classes.video} controls autoPlay src={linkYoutube} width="100%" />
      )}
      <div className={`${classes.boxBtnContainer} boxBtnContainer`}>
        {isBought === true ? (
          <Link onClick={() => onClickShow()} to={`/khoa-hoc/bai-hoc/${idLesson}`}>
            <Button className={classes.btn} label={labelDefaultButton} />
          </Link>
        ) : (
          <div className={classes.boxBtn}>
            <Link onClick={() => onClickShow()} to={`/khoa-hoc/bai-hoc/${idLesson}`}>
              <Button typeButton="third" className={classes.btn} label={lang.btnTrial} />
            </Link>
            <Link to={`/cua-hang/khoa-hoc/${idLesson}`}>
              <Button className={classes.btn} label={lang.btnBuy} />
            </Link>
          </div>
        )}
      </div>
      <Block className={classes.blockDescription}>
        <span className={classes.introTitle}>{title}</span>
        <div className={classes.descriptionCourse}>
          {renderHTML(description === null ? '' : description)}
        </div>
      </Block>
    </div>
  );
}

IntroductionCourse.defaultProps = {
  title: `Khóa học`,
  description: `Mô tả về khóa học`,
  linkYoutube: 'https://www.youtube.com/embed/axa7OWiReg4',
  isBought: false,
  labelDefaultButton: 'Học ngay',
  onClickShow: () => {}
};

export default withRouter(translator(injectSheet(styles)(IntroductionCourse)));
