/*
 * @Author: loc.do@bstarsolutions.com
 * @Date: 2019-05-29 16:53:30
 * @Last Modified by: loc.do@bstarsolutions.com
 * @Last Modified time: 2019-09-05 14:02:40
 */
import { scaleStyle } from 'utils';
import { theme } from 'appCore';

export default {
  container: {
    margin: `0 auto`,
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    width: '100%',
    paddingLeft: 30,
    paddingRight: 30,
    ...scaleStyle({
      maxWidth: 1440
    })
  },
  title: {
    color: theme.colors.primary,
    fontWeight: `bold`,
    lineHeight: `41px`,
    textTransform: `uppercase`,
    ...scaleStyle({
      fontSize: 30,
      marginBottom: 23
    })
  },
  border: {
    height: 6,
    backgroundColor: theme.colors.primary,
    ...scaleStyle({
      marginBottom: 50,
      width: 60
    })
  },
  scoreModal: {
    marginLeft: 10,
    color: theme.colors.primary
  },
  descriptionModal: {
    ...scaleStyle({
      marginBottom: 20
    })
  },
  imgResult: {
    ...scaleStyle({
      marginBottom: 20,
      width: 120
    })
  },
  wrapNotPractice: {
    display: 'flex',
    width: `100%`,
    justifyContent: 'center',
    alignItems: 'center',
    flexDirection: 'column'
  },
  marginBotomImg: {
    marginBottom: 20
  },
  victory: {
    marginBottom: 20
  },
  wrapImage: {
    maxWidth: 350,
    bottom: 0
  },
  imgLevel: {
    width: '100%',
    height: '100%',
    objectFit: 'cover',
    position: 'relative',
    top: 10
  }
};
