import React from 'react';
import { put, takeLatest } from 'redux-saga/effects';
import { ArticleAPI } from 'services';
import { modalActions } from 'containers/ModalManagement/duck';
import { actionArticles } from 'containers/Article/duck';
import { ModalArticle, ModalContact } from 'components';
import * as types from './types';

function* getArticlesAPI(actions) {
  const {
    payload: { slug, currentPage }
  } = actions;
  try {
    yield put({ type: types.ARTICLE_FETCHING });
    const rawData = yield ArticleAPI.getArticles(slug, currentPage);
    if (rawData.data.data.category) {
      yield put({
        type: types.ARTICLE_SUCCESS,
        payload: {
          status: rawData.data.success,
          articles: rawData.data.data.articles,
          category: rawData.data.data.category,
          slider: rawData.data.data.slider,
          acticleType: rawData.data.data.articleType,
          pagination: rawData.data.meta.pagination
        }
      });
    } else {
      yield put({
        type: types.ARTICLE_SUCCESS,
        payload: {
          status: false,
          articles: {},
          category: {},
          slider: {}
        }
      });
    }
  } catch (e) {
    yield put({
      type: types.ARTICLE_FAILURE
    });
  }
}

function* getArticleDetails(actions) {
  const {
    payload: { type, id }
  } = actions;
  try {
    yield put({ type: types.ARTICLE_DETAILS_FETCHING });
    const rawData = yield ArticleAPI.getArticleDetails(type, id);
    yield put({
      type: types.ARTICLE_DETAILS_SUCCESS,
      payload: {
        status: rawData.data.success,
        details: rawData.data.data
      }
    });
  } catch (e) {
    yield put({ type: types.ARTICLE_DETAILS_FAILURE });
  }
}

function* applyToJob(actions) {
  const {
    payload: { idJob, formData }
  } = actions;
  try {
    yield put({ type: types.ARTICLE_DETAILS_FETCHING });
    const rawData = yield ArticleAPI.applyToJob(idJob, formData);
    if (rawData.data && rawData.data.message === 'Apply successfully') {
      const children = <ModalArticle success />;
      yield put(modalActions.showModal({ children }));
      yield put(actionArticles.getArticleDetails('jobs', idJob));
      yield put(actionArticles.isHideApplyPage());
    }

    if (rawData && rawData.message === 'Fail to apply job. Current user applied this job') {
      const children = <ModalArticle cvIsAppplied />;
      yield put(modalActions.showModal({ children }));
      yield put(actionArticles.getArticleDetails('jobs', idJob));
      yield put(actionArticles.isHideApplyPage());
    }

    if (rawData.errors && String(rawData.errors.cv) === 'validation.mimes') {
      const children = <ModalArticle fileEmpty />;
      yield put(modalActions.showModal({ children }));
    }

    if (rawData.errors && String(rawData.errors.cv) === 'validation.max.file') {
      const children = <ModalArticle maxFile />;
      yield put(modalActions.showModal({ children }));
    }

    if (rawData.errors && String(rawData.errors.cv) === 'validation.required') {
      const children = <ModalArticle cv />;
      yield put(modalActions.showModal({ children }));
    }
    if (rawData.errors && String(rawData.errors.cv) === 'validation.file') {
      const children = <ModalArticle cv />;
      yield put(modalActions.showModal({ children }));
    }

    if (rawData.errors && String(rawData.errors.email) === 'validation.required') {
      const children = <ModalArticle email />;
      yield put(modalActions.showModal({ children }));
    }

    if (rawData.errors && String(rawData.errors.email) === 'validation.email') {
      const children = <ModalArticle emailValid />;
      yield put(modalActions.showModal({ children }));
    }

    if (rawData.errors && String(rawData.errors.name) === 'validation.required') {
      const children = <ModalArticle name />;
      yield put(modalActions.showModal({ children }));
    }
    yield put({
      type: types.ARTICLE_DETAILS_SUCCESS,
      payload: {
        status: rawData.data.success,
        resultApplyToJob: rawData.data.data
      }
    });
  } catch (e) {
    yield put({ type: types.ARTICLE_DETAILS_FAILURE });
  }
}

function* postSendFormContactSaga(action) {
  const { typeForm, name, email, phone, address, content } = action.payload;
  try {
    yield put({ type: types.POST_SEND_FORM_CONTACT_FETCHING });
    const rawData = yield ArticleAPI.postSendFormContact(
      typeForm,
      name,
      phone,
      email,
      address,
      content
    );

    if (rawData.errors && String(rawData.errors.name) === 'validation.required') {
      const children = <ModalContact emptyName />;
      yield put(modalActions.showModal({ children }));
    } else if (rawData.errors && String(rawData.errors.email) === 'validation.email') {
      const children = <ModalContact emptyEmail />;
      yield put(modalActions.showModal({ children }));
    } else if (rawData.errors && String(rawData.errors.phone) === 'validation.required') {
      const children = <ModalContact emptyPhone />;
      yield put(modalActions.showModal({ children }));
    } else if (rawData.errors && String(rawData.errors.course_id) === 'validation.numeric') {
      const children = <ModalContact emptyCourse />;
      yield put(modalActions.showModal({ children }));
    } else if (rawData.errors && String(rawData.errors.num_of_employee) === 'validation.numeric') {
      const children = <ModalContact emptyNumOfEmployee />;
      yield put(modalActions.showModal({ children }));
    } else if (rawData.errors && String(rawData.errors.address) === 'validation.string') {
      const children = <ModalContact emptyAddress />;
      yield put(modalActions.showModal({ children }));
    } else if (rawData.errors && String(rawData.errors.address) === 'validation.string') {
      const children = <ModalContact emptyAddress />;
      yield put(modalActions.showModal({ children }));
    } else if (rawData.data && rawData.data.code === 200) {
      const children = <ModalContact sendSuccess />;
      yield put(modalActions.showModal({ children }));
    }

    yield put({
      type: types.POST_SEND_FORM_CONTACT_SUCCESS,
      payload: {
        status: false,
        resultRegisterContact: rawData.data.data
      }
    });
  } catch (e) {
    yield put({
      type: types.POST_SEND_FORM_CONTACT_FAIL
    });
  }
}

export const getArticlesSaga = takeLatest(types.ARTICLE_INIT, getArticlesAPI);
export const getArticleDetailsSaga = takeLatest(types.ARTICLE_DETAILS_INIT, getArticleDetails);
export const applyToJobSaga = takeLatest(types.APPLY_JOB_INIT, applyToJob);
export const postSendFormContactInSaga = takeLatest(
  types.POST_SEND_FORM_CONTACT_INIT,
  postSendFormContactSaga
);
export default this;
