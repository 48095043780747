/*
 * @Author: loc.do@bstarsolutions.com
 * @Date: 2019-07-26 10:47:35
 * @Last Modified by: loc.do@bstarsolutions.com
 * @Last Modified time: 2019-08-14 10:28:47
 */
import { scaleStyle } from 'utils';
import { theme } from 'appCore';

export default {
  blockSectionResult: {
    display: 'flex',
    flexDirection: 'column',
    width: '100%'
  },
  detailResult: {
    display: 'flex',
    flexDirection: 'column',
    flex: 3,
    textAlign: 'center',
    ...scaleStyle({
      marginBottom: 20
    })
  },
  wrapBlockTestSection: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'center',
    height: '100%',
    width: `100%`
  },
  contest: {
    marginRight: 10,
    height: '100%',
    color: theme.colors.primary,
    textTransform: 'uppercase',
    fontWeight: 'bold',
    ...scaleStyle({
      fontSize: 22
    })
  },
  wrapBlockSection: {
    display: 'flex',
    height: '100%'
  },
  blockSectionContest: {
    display: 'flex',
    flexDirection: 'column',
    width: '100%',
    ...scaleStyle({
      marginTop: 20
    })
  },
  detailContestSection: {
    width: '100%',
    display: 'flex',
    justifyContent: 'center',
    marginBottom: 10,
    ...scaleStyle({
      minWidth: 300
    })
  },
  firstContest: {
    display: 'flex',
    width: `100%`,
    justifyContent: 'flex-start',
    color: theme.colors.black,
    ...scaleStyle({
      fontSize: 20
    })
  },
  lastContest: {
    display: 'flex',
    width: `100%`,
    justifyContent: 'flex-start',
    color: theme.colors.black,
    fontWeight: 'bold',
    ...scaleStyle({
      fontSize: 20
    })
  },
  partContest: {
    display: 'flex'
  },
  blockSummaryContest: {
    display: 'flex',
    flexDirection: 'column',
    ...scaleStyle({
      fontSize: 20,
      marginTop: 20
    })
  },
  wrapResultContest: {
    width: '100%',
    display: 'flex'
  },
  resultTotalContest: {
    display: 'flex',
    width: `100%`
  },
  resultTotalContestLast: {
    display: 'flex',
    justifyContent: 'flex-start',
    width: '100%',
    color: theme.colors.black,
    fontWeight: 'bold',
    textAlign: 'left',
    ...scaleStyle({
      marginLeft: 20
    })
  },
  titleResultTotalContest: {
    marginRight: 10,
    height: '100%',
    color: theme.colors.primary,
    fontWeight: 'bold',
    ...scaleStyle({
      fontSize: 22
    })
  },
  wrapTotalResultExam: {
    backgroundColor: theme.colors.gray87,
    ...scaleStyle({
      padding: 20,
      borderRadius: 30,
      marginBottom: 20
    })
  }
};
