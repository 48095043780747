import * as types from './types';

export const getArticles = (slug, currentPage) => ({
  type: types.ARTICLE_INIT,
  payload: { slug, currentPage }
});

export const getArticleDetails = (type, id) => ({
  type: types.ARTICLE_DETAILS_INIT,
  payload: { type, id }
});

export const applyJob = (idJob, formData) => ({
  type: types.APPLY_JOB_INIT,
  payload: {
    idJob,
    formData
  }
});

export const isShowApplyPage = () => ({
  type: types.IS_SHOW_APPLY_PAGE
});

export const isHideApplyPage = () => ({
  type: types.IS_HIDE_APPLY_PAGE
});

export const postSendFormContact = (typeForm, name, phone, email, address, content) => ({
  type: types.POST_SEND_FORM_CONTACT_INIT,
  payload: {
    typeForm,
    name,
    phone,
    email,
    address,
    content
  }
});
