import { combineReducers } from 'redux';

import app from 'containers/App/duck';
import header from 'containers/Header/duck';
import modals from 'containers/ModalManagement/duck';
import login from 'containers/Login/duck';
import courses from 'containers/Courses/duck';
import courseChild from 'containers/Courses/CourseChild/duck';
import lessonInCourse from 'containers/Courses/CourseChild/LessonInCourse/duck';
import lessonBySkill from 'containers/LessonBySkill/duck';
import exams from 'containers/Exams/duck';
import examChilds from 'containers/Exams/ExamChild/duck';
import exercise from 'containers/Exercise/duck';
import signUp from 'containers/SignUp/duck';
import forgotPassword from 'containers/ForgotPassword/duck';
import practice from 'containers/Practice/duck';
import modalVocabularyDetail from 'containers/ModalVocabularyDetail/duck';
import shop from 'containers/Shop/duck';
import profile from 'containers/Profile/duck';
import articles from 'containers/Article/duck';
import home from 'containers/Home/duck';
import enterprise from 'containers/Enterprise/duck';

export default function createReducer() {
  return combineReducers({
    app,
    header,
    login,
    courses,
    signUp,
    forgotPassword,
    modals,
    courseChild,
    lessonInCourse,
    lessonBySkill,
    practice,
    modalVocabularyDetail,
    exams,
    examChilds,
    shop,
    exercise,
    profile,
    articles,
    home,
    enterprise
  });
}
