/*
 * @Author: loc.do@bstarsolutions.com
 * @Date: 2019-05-15 10:41:49
 * @Last Modified by: loc.do@bstarsolutions.com
 * @Last Modified time: 2019-07-30 11:17:05
 */

import { theme } from 'appCore';
import { scaleStyle } from 'utils';

export default {
  container: {
    width: '100%'
    // display: 'flex',
    // paddingLeft: 30,
    // paddingRight: 30,
    // flexDirection: 'column',
    // margin: `0px auto`,
    // alignItems: 'center',
    // ...scaleStyle({
    //   maxWidth: 1440.3
    // })
  },
  boxBtn: {
    display: 'flex',
    flexDirection: 'row'
  },
  boxBtnContainer: {
    marginTop: 77,
    marginBottom: 61,
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'center'
  },
  btn: {
    width: 180,
    display: 'flex',
    justifyContent: 'center',
    marginRight: 20
  },
  blockYoutube: {
    ...scaleStyle({
      maxWidth: 1440.3
    })
  },
  introTitle: {
    ...scaleStyle({
      maxWidth: 1265,
      fontSize: 35,
      lineHeight: `50px`,
      marginBottom: 60
    }),
    color: theme.colors.primary
  },
  descriptionCourse: {
    lineHeight: `35px`,
    marginBottom: 28,
    ...scaleStyle({
      fontSize: 20
    })
  },
  blockDescription: {
    marginBottom: 60,
    padding: `43px 50px 28px 50px`,
    display: 'block'
  },
  video: {
    // height: 400,
    ...scaleStyle({
      maxWidth: 1000,
      margin: '0 auto',
      width: `100%`,
      borderRadius: 40
    }),
    '& > button': {
      marginBottom: 20
    }
  }
};
