/*
 * @Author: loc.do@bstarsolutions.com
 * @Date: 2019-06-10 17:02:34
 * @Last Modified by: loc.do@bstarsolutions.com
 * @Last Modified time: 2019-10-22 15:34:47
 */

import React, { useState, useEffect } from 'react';
import injectSheet from 'react-jss';
import { Block, RadioButton, Button, ModalContent } from 'components';
import { withRouter } from 'react-router';
import { translator } from 'utils';
import { images } from 'appCore';
import Payment from 'containers/Shop/Payment';
import styles from './styles';
import { constants } from '../../utils';

const CourseInShop = props => {
  const {
    classes,
    strings,
    currentLang,
    listSelectCourseInShop,
    showPopup,
    buyCourseInShop,
    showBtnClose,
    matchName,
    isShowPaymentPage,
    onClose,
    payTransferCoinOffline,
    hideBtnClose,
    fetchPayment,
    checkBoughtClass,
    courseEnterprise,
    hidePaymentPage,
    listConfiguration,
    fetchPaymentBuyCourse,
    tokenSelector,
    history
  } = props;

  const lang = strings.blockBuyCourse;

  const [idSelect, setIdSelect] = useState(0);

  const onChangeRadio = (courses, idCourse) => {
    setIdSelect(courses && idCourse);
  };

  const buyCourse = (id, buy, re, dura) => {
    buyCourseInShop(id, buy, re, dura);
  };

  const onBuyClassExist = courseEP => {
    if (tokenSelector) {
      checkBoughtClass(courseEP);
    } else {
      history.push(`/login`);
    }
  };

  const handleBuy = (course, courseP, numOfEmployeeP) => {
    buyCourse(
      course,
      courseP.buying_credits,
      courseP.reward_credits,
      courseP.price_types.duration,
      numOfEmployeeP
    );
    setIdSelect(0);
  };

  const onBuyCourse = (duration, courseName, coursesId, coursePrice, numOfEmployeeCourse) => {
    if (tokenSelector) {
      showBtnClose();
      if (idSelect !== 0) {
        setIdSelect(0);
      }
      const children = (
        <ModalContent
          isButton={duration !== 999999}
          btnLabelModal={lang.btnConfirm}
          onClickDefault={() => handleBuy(coursesId, coursePrice, numOfEmployeeCourse)}
          title={`${lang.title} ${courseName}`}
        >
          {duration !== null ? (
            <>
              {' '}
              {duration !== 999999 ? (
                <>
                  <span>
                    {lang.confirm}
                    {duration === 999999 ? ` ${lang.onTime} ` : ` ${duration} ${lang.day}`}.
                  </span>
                  <span>{lang.confirmMore}</span>
                </>
              ) : (
                <>
                  <span>{lang.durationForever}</span>
                </>
              )}
            </>
          ) : (
            <>
              <span>{lang.confirmSure}</span>
            </>
          )}
        </ModalContent>
      );
      showPopup({ children });
    } else {
      history.push(`/login`);
    }
  };

  useEffect(() => {}, [idSelect]);

  return (
    <div className={classes.container}>
      {isShowPaymentPage === true ? (
        <Payment
          fetchPaymentBuyCourse={fetchPaymentBuyCourse}
          listConfiguration={listConfiguration}
          payTransferCoinOffline={payTransferCoinOffline}
          onClose={onClose}
          hideBtnClose={hideBtnClose}
          showPopup={showPopup}
          currentLang={currentLang}
          fetchPayment={fetchPayment}
          courseEnterprise={courseEnterprise}
          idSelect={idSelect}
          hidePaymentPage={hidePaymentPage}
        />
      ) : (
        <>
          {listSelectCourseInShop.map((courseInShop, i) => {
            return (
              <Block key={i} className={`${classes.block} itemShop`}>
                <Block className={classes.blockChild}>
                  <div className={`${classes.blockOne} blockOne`}>
                    <img
                      className={`${classes.imgAvatar} imgThumb`}
                      src={courseInShop.avatar}
                      alt="Course"
                    />
                    <span className={`${classes.name} name`}>
                      {courseInShop.translations[currentLang]
                        ? courseInShop.translations[currentLang].name
                        : courseInShop.translations.vi.name}
                    </span>
                  </div>
                  <div className={classes.wrapPricesCourse}>
                    {courseInShop.prices &&
                      courseInShop.prices.map((price, j) => {
                        return (
                          <div key={j} className={classes.price}>
                            <RadioButton
                              checked={j === 0}
                              index={courseInShop.id}
                              value={price.id}
                              onClick={() => onChangeRadio(courseInShop.id, j)}
                              name={
                                price.price_types.translations[currentLang]
                                  ? price.price_types.translations[currentLang].name
                                  : price.price_types.translations.vi.name
                              }
                            />
                            <img className={classes.coin} src={images.coin} alt="Coin" />
                            {price.discount_credits !== null && (
                              <span className={classes.credit}>
                                {price.discount_credits ? price.discount_credits : 0}{' '}
                                {matchName === 'lop-hoc' ? '₫' : lang.coin}
                              </span>
                            )}
                            {price.discount_credits !== null ? (
                              <span className={classes.reward}>
                                {price.buying_credits ? price.buying_credits : 0}{' '}
                                {matchName === 'lop-hoc' ? '₫' : lang.coin}
                              </span>
                            ) : (
                              <span className={classes.credit}>
                                {price.buying_credits
                                  ? constants.numberWithCommas(price.buying_credits)
                                  : 0}{' '}
                                {matchName === 'lop-hoc' ? '₫' : lang.coin}
                              </span>
                            )}
                          </div>
                        );
                      })}
                  </div>
                </Block>
                <div className={classes.wrapBtnBuy}>
                  <Button
                    onClick={
                      matchName === 'lop-hoc'
                        ? () => onBuyClassExist(courseInShop)
                        : () =>
                            onBuyCourse(
                              courseInShop.duration,
                              courseInShop.translations[currentLang]
                                ? courseInShop.translations[currentLang].name
                                : courseInShop.translations.vi.name,
                              courseInShop.id,
                              courseInShop.prices[idSelect],
                              courseInShop.num_of_employee
                            )
                    }
                    className={classes.btnBuy}
                    label={lang.btnBuy}
                  />
                </div>
              </Block>
            );
          })}
        </>
      )}
    </div>
  );
};

export default withRouter(translator(injectSheet(styles)(CourseInShop)));
