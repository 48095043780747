/*
 * @Author: loc.do@bstarsolutions.com
 * @Date: 2019-06-26 15:33:59
 * @Last Modified by: loc.do@bstarsolutions.com
 * @Last Modified time: 2019-09-19 15:25:58
 */
import { theme } from 'appCore';
import { scaleStyle } from 'utils';

export default {
  container: {
    ...scaleStyle({
      marginBottom: 10
    })
  },
  titleExam: {
    fontWeight: 'bold',
    color: theme.colors.primary,
    display: 'flex',
    width: '100%',
    ...scaleStyle({
      fontSize: 20,
      paddingLeft: 100,
      marginTop: 36
    })
  },
  containerInfoExam: {
    display: 'flex',
    width: '100%',
    ...scaleStyle({
      marginBottom: 26,
      marginTop: 26
    })
  },
  rightInforExam: {
    display: 'flex',
    flexDirection: 'column',
    width: '100%',
    ...scaleStyle({
      paddingLeft: 50
    })
  },
  name: {
    ...scaleStyle({
      fontSize: 20,
      lineHeight: `35px`,
      width: 160
    })
  },
  nameMyExam: {
    textAlign: 'center',
    ...scaleStyle({
      fontSize: 25,
      lineHeight: `35px`,
      width: 200,
      marginTop: 30,
      marginBottom: 30
    })
  },
  boxExam: {
    display: 'flex',
    width: `100%`
  },
  result: {
    fontWeight: 'bold',
    color: theme.colors.primary,
    ...scaleStyle({
      lineHeight: `35px`,
      fontSize: 20
    })
  },
  boxBtn: {
    display: 'flex',
    width: '100%',
    height: '100%',
    justifyContent: 'flex-end',
    ...scaleStyle({
      paddingRight: 21.3
    })
  },
  btn: {
    ...scaleStyle({
      marginRight: 20
    })
  },
  wrapQuestionsMyExam: {
    display: 'flex',
    alignItems: 'flex-start',
    flexDirection: 'column',
    backgroundColor: theme.colors.gray87,
    width: `100%`,
    ...scaleStyle({
      marginBottom: 20,
      borderRadius: 30,
      padding: 20
    })
  },
  orderListQuestion: {
    backgroundColor: theme.colors.primary,
    borderRadius: 999,
    display: 'flex',
    color: theme.colors.white,
    fontWeight: 'bold',
    justifyContent: 'center',
    alignItems: 'center',
    ...scaleStyle({
      width: 30,
      height: 30,
      fontSize: 14
    })
  },
  wrapListAnswer: {
    display: 'flex',
    flexDirection: 'column',
    width: '100%',
    justifyContent: 'center',
    alignItems: 'center',
    ...scaleStyle({
      maxWidth: 980
    })
  },
  answerNotWrongAndRight: {
    color: theme.colors.black,
    backgroundColor: '#dedede'
  },
  answerRight: {
    backgroundColor: theme.colors.green,
    color: theme.colors.white
  },
  answerWrong: {
    backgroundColor: theme.colors.red,
    color: theme.colors.white
  },
  wrapTitleTopMyExam: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center'
  },
  nameAnswerMyExam: {
    marginLeft: 20
  },
  [`@media screen and (max-width: ${theme.screens.maxWidth800}px)`]: {
    containerInfoExam: {
      display: 'flex',
      flexDirection: 'column',
      justifyContent: 'center',
      alignItems: 'center'
    },
    boxBtn: {
      display: 'flex',
      justifyContent: 'center',
      marginTop: 10
    },
    wrapQuestionsMyExam: {
      width: 240
    },
    container: {
      width: '100%'
    }
  }
};
