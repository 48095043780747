import React, { useEffect } from 'react';
import injectSheet from 'react-jss';
import { Block } from 'components';
import { images } from 'appCore';
import styles from './styles';

const Modal = ({
  classes,
  children,
  title,
  onClose,
  message,
  isShowBtnClose,
  noNeedPreventEvent
}) => {
  const preventEvent = e => {
    e.preventDefault();
    return false;
  };

  const addListenerKeyPress = () => {
    document.addEventListener('keydown', preventEvent);
  };

  const removeListenerKeyPress = () => {
    document.removeEventListener('keydown', preventEvent);
  };

  const handleClose = () => {
    removeListenerKeyPress();
    onClose();
  };

  useEffect(() => {
    if (!noNeedPreventEvent) addListenerKeyPress();
    return () => {
      if (!noNeedPreventEvent) removeListenerKeyPress();
    };
  }, []);

  return (
    <Block className={classes.wrapper}>
      <div className={classes.content}>
        {title && (
          <div className={classes.title}>
            <span>{title}</span>
            <div className={classes.divider} />
          </div>
        )}
        <div className={classes.children}>
          {children}
          {message}
        </div>
      </div>
      {!isShowBtnClose && (
        <img onClick={handleClose} className={classes.close} src={images.close} alt="close" />
      )}
    </Block>
  );
};

Modal.defaultProps = {
  isShowBtnClose: true
};

export default injectSheet(styles)(Modal);
