/*
 * @Author: thang.le@bstarsolutions.com
 * @Date: 2019-10-31
 * @Last Modified by: thang.le@bstarsolutions.com
 * @Last Modified time: 2019-10-31
 */

import React from 'react';
import MetaTags from 'react-meta-tags';

function MetaTag(props) {
  const { title, image } = props;

  return (
    <MetaTags>
      <title>{title || 'Rainichi'}</title>
      <meta name="description" content={title || 'Website dành cho người học Tiếng Nhật'} />
      <meta name="author" content="VietLabo" />
      <meta
        name="keywords"
        content="rainichi, Rainichi, japan, online course, japanese online course"
      />
      <meta name="subject" content="Rainichi" />
      <meta name="copyright" content="VietLabo" />
      <meta name="language" content="vi" />
      <meta name="robots" content="index,follow" />
      <meta name="Classification" content="Business" />
      <meta name="author" content="name, info@rainichi.com" />
      <meta name="reply-to" content="info@rainichi.com" />
      <meta name="identifier-URL" content="https://rainichi.vn" />
      <meta name="directory" content="submission" />
      <meta name="coverage" content="Worldwide" />
      <meta name="distribution" content="Global" />
      <meta name="rating" content="General" />
      <meta property="og:title" content={title || 'Rainichi'} />
      <meta property="og:image" content={image || ''} />
      <meta property="og:image:width" content="200" />
      <meta property="og:image:height" content="200" />
      <meta property="og:image:alt" content="Rainichi" />
      <meta property="og:site_name" content="Rainichi" />
      <meta property="og:description" content={title || 'Rainichi'} />
      <meta property="fb:app_id" content="2458511104207144" />
    </MetaTags>
  );
}

export default MetaTag;
