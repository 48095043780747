/*
 * @Author: loc.do@bstarsolutions.com
 * @Date: 2019-06-25 10:01:23
 * @Last Modified by: loc.do@bstarsolutions.com
 * @Last Modified time: 2019-07-19 10:30:19
 */

import React from 'react';
import injectSheet from 'react-jss';
import { Block } from 'components';

import styles from './styles';

const BlockTitleBorder = props => {
  const { classes, title, children } = props;

  return (
    <Block className={classes.myInfo}>
      <span className={classes.titleInfo}>{`${title}`.toLocaleUpperCase()}</span>
      <div className={classes.border} />
      {children}
    </Block>
  );
};

BlockTitleBorder.defaultProps = {
  title: 'Title Default'
};

export default injectSheet(styles)(BlockTitleBorder);
