import * as types from './types';

export const fetchExercise = examId => ({
  type: types.EXERCISE_INIT,
  examId
});

export const fetchPracticeExercise = skillId => ({
  type: types.PRACTICE_EXERCISE_INIT,
  skillId
});

export const changeActiveAnswerPractice = (id, type, lessonCurrentId) => ({
  type: types.CHANGE_ACTIVE_EXERCISE_INIT,
  payload: { id, type, lessonCurrentId }
});

export const submitPracticeExercise = (sectionId, dataSubmit, hasPractice) => ({
  type: types.SUBMIT_PRACTIC_EXERCISE_INIT,
  payload: { sectionId, dataSubmit, hasPractice }
});

export const remakeDataPracticeExercise = () => ({
  type: types.REMAKE_DATA_PRACTICE
});

export const showPagePracticeExercise = () => ({
  type: types.SHOW_PAGE_PRACTICE_EXERCISE
});

export const hidePagePracticeExercise = () => ({
  type: types.HIDE_PAGE_PRACTICE_EXERCISE
});

export const saveIdExercise = idExercise => ({
  type: types.SAVE_ID_EXERCISE,
  payload: {
    idExercise
  }
});

export const submitNewTestPractice = exerciseId => ({
  type: types.SUBMIT_NEW_TEST_PRACTICE_INIT,
  payload: {
    exerciseId
  }
});

export const removeResultExercise = () => ({
  type: types.REMOVE_RESULT_EXERCISE
});

export const hasTime = () => ({
  type: types.HAS_TIME_EXAM
});

export const hasNotTime = () => ({
  type: types.HAS_NOT_TIME_EXAM
});

export const changeIsFetchingFalse = () => ({
  type: types.CHANGE_IS_FETCHING_FALSE
});

export const changeIsFetchingTrue = () => ({
  type: types.CHANGE_IS_FETCHING_TRUE
});

export const isButtonTotal = isTotal => ({
  type: types.IS_BUTTON_TOTAL,
  payload: {
    isTotal
  }
});

export default this;
