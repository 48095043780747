import * as types from './types';

export const submitLogin = ({ email, password }) => ({
  type: types.LOGIN_SUBMIT,
  payload: { email, password }
});

export const submitLoginSocial = (provider, token) => ({
  type: types.LOGIN_SOCIAL_SUBMIT,
  payload: { provider, token }
});

export const autoLogin = token => ({
  type: types.LOGIN_SUBMIT,
  payload: { token }
});

export const removeError = () => ({
  type: types.LOGIN_ERROR_EMPTY
});

export const logout = () => ({
  type: types.LOGOUT
});

export default this;
