/*
 * @Author: loc.do@bstarsolutions.com
 * @Date: 2019-05-10 11:04:10
 * @Last Modified by: loc.do@bstarsolutions.com
 * @Last Modified time: 2019-08-22 18:51:44
 */

import { createStructuredSelector } from 'reselect';

const lesson = state => state.lessonBySkill.lesson;
const isChoice = state => state.lessonBySkill.isChoice;
const score = state => state.lessonBySkill.totalScore;
const totalQuestion = state =>
  state.lessonBySkill.metaLesson && state.lessonBySkill.metaLesson.total_question;
const result = state => state.practice;
const isFetching = state => state.lessonBySkill.isFetching;
const courseChild = state => state.courseChild.courseDetail;

export default createStructuredSelector({
  lesson,
  isChoice,
  score,
  totalQuestion,
  result,
  isFetching,
  courseChild
});
