/*
 * @Author: loc.do@bstarsolutions.com
 * @Date: 2019-08-02 16:05:31
 * @Last Modified by: loc.do@bstarsolutions.com
 * @Last Modified time: 2019-09-24 10:09:15
 */

export const POST_FORM_REGISTER_ENTERPRISE_INIT = 'POST_FORM_REGISTER_ENTERPRISE_INIT';
export const POST_FORM_REGISTER_ENTERPRISE_FETCHING = 'POST_FORM_REGISTER_ENTERPRISE_FETCHING';
export const POST_FORM_REGISTER_ENTERPRISE_SUCCESS = 'POST_FORM_REGISTER_ENTERPRISE_SUCCESS';
export const POST_FORM_REGISTER_ENTERPRISE_FAIL = 'POST_FORM_REGISTER_ENTERPRISE_FAIL';

export const GET_LIST_COURSE_ENTERPRISE_INIT = 'GET_LIST_COURSE_ENTERPRISE_INIT';
export const GET_LIST_COURSE_ENTERPRISE_FETCHING = 'GET_LIST_COURSE_ENTERPRISE_FETCHING';
export const GET_LIST_COURSE_ENTERPRISE_SUCCESS = 'GET_LIST_COURSE_ENTERPRISE_SUCCESS';
export const GET_LIST_COURSE_ENTERPRISE_FAIL = 'GET_LIST_COURSE_ENTERPRISE_FAIL';
