import loadingComponent from './loading-component.svg';
import favicon from './favicon.ico';
import logo from './logo.svg';
import email from './email.svg';
import openBook from './open-book.svg';
import phoneCall from './phone-call.svg';
import rightArrow from './keyboard-right-arrow.svg';
import rightArrowPrimary from './rightArrowPrimary.svg';
import logoVN from './vietnam.svg';
import bottomArrow from './arrow-bottom.svg';
import logoEN from './united-kingdom.svg';
import banner from './banner.png';
import bannerOne from './banner-1.jpg';
import reading from './reading.svg';
import appStore from './apple.svg';
import googlePlay from './google-play.svg';
import checked from './checked.svg';
import classroom from './presentation.svg';
import skillCommon from './thought.svg';
import studySpeak from './voice-command.svg';
import translate from './translate.svg';
import exam from './review.svg';
import work from './suitcase.svg';
import cap from './cap.svg';
import sumo from './sumo.svg';
import lock from './lock.svg';
import emailIcon from './emailIcon.svg';
import question from './question.svg';
import socialGroup from './socialGroup.jpg';
import close from './close.svg';
import padlock from './padlock.png';
import star from './star.svg';
import star1 from './star1.svg';
import reload from './reload.svg';
import volume from './volume.svg';
import down from './down.svg';
import plus from './plus.svg';
import sub from './sub.svg';
import userIcon from './userIcon.svg';
import passcodeIcon from './passcodeIcon.svg';
import phoneIcon from './phoneIcon.svg';
import microphone from './microphone.svg';
import next from './next.svg';
import prev from './prev.svg';
import emailGray from './emailGray.svg';
import addressGray from './addressGray.svg';
import hotlineGray from './hotlineGray.svg';
import hint from './hint.svg';
import prevPractice from './back-practice.svg';
import nextPractice from './forward-practice.svg';
import tickCircle from './tick-circle.svg';
import sumoFail from './sumo/sumo-fail.png';
import sumoSuccess from './sumo/sumo-success.png';
import sumo1 from './sumo/sumo-1.png';
import sumo2 from './sumo/sumo-2.png';
import sumo3 from './sumo/sumo-3.png';
import sumo4 from './sumo/sumo-4.png';
import sumo5 from './sumo/sumo-5.png';
import sumo6 from './sumo/sumo-6.png';
import sumo7 from './sumo/sumo-7.png';
import sumo8 from './sumo/sumo-8.png';
import voluming from './voluming.svg';
import disableMic from './disable-mic.svg';
import muteVolume from './mute.svg';
import bgCoin from './bg-coin.svg';
import playButton from './play-button.svg';
import pauseButton from './pause-button.png';
import porterVideo from './poster-video.png';
import preSelect from './pre-select.svg';
import coin from './coin.png';
import myCoin from './my-coin.svg';
import emailProfile from './email-profile.svg';
import phoneSymbol from './phone-symbol.svg';
import accountWithUser from './account-with-user.svg';
import iconMessenger from './icon-messenger.svg';
import googleLogin from './googleLogin.jpg';
import password from './password.svg';
import goTop from './gotop.svg';
import volumeActive from './volume-active.svg';
import volumeDisable from './volume-disable.svg';
import microphoneActive from './microphone-active.svg';
import microphoneDisable from './microphone-disable.svg';
import menuButton from './menu-bullet.svg';
import pagiPrev from './pagi-prev.svg';
import pagiNext from './pagi-next.svg';
import pagiCircle from './pagi-circle.svg';
import pagiCircleHover from './pagi-circle-hover.svg';
import pagiPrevHover from './pagi-prev-hover.svg';
import pagiNextHover from './pagi-next-hover.svg';
import facebook from './facebook-logo.svg';
import googlePlus from './google-plus.svg';
import twitter from './twitter.svg';
import closeMenu from './close-menu.svg';
import plusExpand from './plus-expand.svg';
import divExpand from './div-expand.svg';
import bgCenter from './bg-center.jpg';
import flash from './flash.svg';
import happy from './happy.svg';
import mortarBoard from './mortarboard.svg';
import park from './park.svg';
import clock from './clock.svg';
import placeHolder from './placeholder.svg';
import bgLecturers from './bg-lecturers.jpg';
import cancelMenuBullet from './cancel-menu-bullet.svg';
import boCongThuong from './bocongthuong.png';
import desktop from './desktop.png';
import google from './google.png';
import apple from './apple.png';
import bgPlayButton from './bg-play-button.svg';
import iconPlayButton from './icon-play-button.svg';
import bgEnterpriseStrength from './bg-enterprise-strength.png';
import invisible from './invisible.svg';
import eye from './eye.svg';
import n5 from './level/n5.png';
import n4 from './level/n4.png';
import n3 from './level/n3.png';
import n2 from './level/n2.png';
import n1 from './level/n1.png';
import calendarJob from './calendar-job.png';
import eyeJob from './eye-job.png';
import facebookJob from './facebook-job.png';
import googlePlusJob from './google-plus-job.png';
import twitterJob from './twitter-job.png';
import backApplyJob from './back-apply-job.svg';
import strengthImg from './strength-image.png';

export default {
  favicon,
  classroom,
  skillCommon,
  studySpeak,
  translate,
  exam,
  work,
  cap,
  logo,
  email,
  openBook,
  phoneCall,
  rightArrow,
  rightArrowPrimary,
  logoVN,
  bottomArrow,
  logoEN,
  banner,
  reading,
  bannerOne,
  appStore,
  googlePlay,
  checked,
  sumo,
  lock,
  emailIcon,
  question,
  socialGroup,
  close,
  padlock,
  star,
  star1,
  reload,
  volume,
  down,
  plus,
  sub,
  userIcon,
  passcodeIcon,
  phoneIcon,
  microphone,
  next,
  prev,
  emailGray,
  addressGray,
  hotlineGray,
  hint,
  prevPractice,
  nextPractice,
  tickCircle,
  sumoFail,
  sumoSuccess,
  voluming,
  disableMic,
  muteVolume,
  bgCoin,
  playButton,
  pauseButton,
  porterVideo,
  preSelect,
  coin,
  loadingComponent,
  myCoin,
  emailProfile,
  phoneSymbol,
  accountWithUser,
  sumo1,
  sumo2,
  sumo3,
  sumo4,
  sumo5,
  sumo6,
  sumo7,
  sumo8,
  iconMessenger,
  password,
  googleLogin,
  goTop,
  volumeActive,
  volumeDisable,
  microphoneActive,
  microphoneDisable,
  menuButton,
  pagiPrev,
  pagiNext,
  pagiCircle,
  pagiCircleHover,
  pagiPrevHover,
  pagiNextHover,
  facebook,
  googlePlus,
  twitter,
  closeMenu,
  plusExpand,
  divExpand,
  bgCenter,
  flash,
  happy,
  mortarBoard,
  park,
  placeHolder,
  clock,
  bgLecturers,
  cancelMenuBullet,
  boCongThuong,
  desktop,
  google,
  apple,
  bgPlayButton,
  iconPlayButton,
  bgEnterpriseStrength,
  invisible,
  eye,
  n5,
  n4,
  n3,
  n2,
  n1,
  calendarJob,
  eyeJob,
  facebookJob,
  googlePlusJob,
  twitterJob,
  backApplyJob,
  strengthImg
};
