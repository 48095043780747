import React, { useRef, useState, useEffect } from 'react';
import injectSheet from 'react-jss';
import Slider from 'rc-slider';
import c from 'classnames';
import { images, theme } from 'appCore';
import { formatTime } from 'utils';
import 'rc-slider/assets/index.css';
import styles from './styles';

const Video = props => {
  const { classes, src, className, onTimeUpdate, getRef } = props;
  const videoRef = useRef(null);
  const [videoInfo, setInfoVideo] = useState({
    duration: 0,
    currentTime: 0
  });

  const isPlaying = () => videoRef.current && !videoRef.current.paused;

  const play = () => {
    videoRef.current.play();
  };

  const pause = () => {
    videoRef.current.pause();
  };

  const togglePlayvideo = () => (isPlaying() ? pause() : play());

  const handleLoadMetaData = () => {
    setInfoVideo({ ...videoInfo, duration: videoRef.current.duration });
  };

  const _onTimeUpdate = () => {
    setInfoVideo({ ...videoInfo, currentTime: videoRef.current.currentTime });
    if (onTimeUpdate) {
      onTimeUpdate();
    }
  };

  const onChange = value => {
    setInfoVideo({ ...videoInfo, currentTime: value });
    videoRef.current.currentTime = value;
  };

  useEffect(() => {
    if (getRef) {
      getRef(videoRef);
    }
  }, []);
  return (
    <div className={c(className, classes.videoWrapper)}>
      <video
        ref={videoRef}
        className={classes.video}
        poster={images.porterVideo}
        src={src || 'https://www.html5rocks.com/en/tutorials/video/basics/devstories.mp4'}
        onLoadedMetadata={handleLoadMetaData}
        onTimeUpdate={_onTimeUpdate}
        preload="metadata"
      />
      <div className={classes.control}>
        <Slider
          max={Math.floor(videoInfo.duration)}
          value={videoInfo.currentTime}
          onChange={onChange}
          trackStyle={[{ background: theme.colors.primary }]}
          handleStyle={[
            {
              border: 'none',
              background: theme.colors.primary
            }
          ]}
        />
        <div className={classes.bottom}>
          <img
            className={classes.playBtn}
            src={!isPlaying() ? images.playButton : images.pauseButton}
            alt="play"
            onClick={togglePlayvideo}
          />
          <span className={classes.time}>{`${formatTime(
            videoInfo.duration - videoInfo.currentTime
          )}`}</span>
        </div>
      </div>
    </div>
  );
};

export default injectSheet(styles)(Video);
