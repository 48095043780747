/*
 * @Author: loc.do@bstarsolutions.com
 * @Date: 2019-06-18 11:03:41
 * @Last Modified by: loc.do@bstarsolutions.com
 * @Last Modified time: 2019-08-06 17:46:39
 */

import { theme } from 'appCore';
import { scaleStyle } from 'utils';

export default {
  nameExercise: {
    objectFit: 'contain',
    ...scaleStyle({
      fontSize: 20,
      lineHeight: `20px`,
      marginBottom: 10,
      marginTop: 10
    })
  },
  countTime: {
    objectFit: 'contain',
    textAlign: 'center',
    ...scaleStyle({
      fontSize: 30,
      lineHeight: `60px`,
      marginBottom: 20
    })
  },
  time: {
    objectFit: 'contain',
    textAlign: 'center',
    color: theme.colors.primary,
    ...scaleStyle({
      fontSize: 70,
      lineHeight: `50px`,
      marginBottom: 30
    })
  },
  notTime: {
    objectFit: 'contain',
    textAlign: 'center',
    color: theme.colors.primary,
    ...scaleStyle({
      fontSize: 70,
      lineHeight: `40px`
    })
  },
  btnSubmit: {
    ...scaleStyle({
      marginBottom: 20
    })
  },
  sectionPractice: {
    color: theme.colors.primary,
    textAlign: 'center',
    textTransform: 'uppercase',
    ...scaleStyle({
      fontSize: 30,
      lineHeight: `40px`
    })
  },
  countTimeContainer: {
    padding: 10
  }
};
