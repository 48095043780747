/*
 * @Author: loc.do@bstarsolutions.com
 * @Date: 2019-05-15 17:17:36
 * @Last Modified by: loc.do@bstarsolutions.com
 * @Last Modified time: 2019-05-15 17:23:31
 */

import React from 'react';
import injectSheet from 'react-jss';
import { translator } from 'utils';
import styles from './styles';

function DurationCourse(props) {
  const { classes, duration, day, strings } = props;
  const lang = strings.blockBuyCourse;
  return (
    <div>
      {duration && (
        <span className={classes.durationCourse}>{`${
          duration === 999999 ? lang.onTime : `${duration} ${day}`
        } `}</span>
      )}
    </div>
  );
}

DurationCourse.defaultProps = {
  duration: 0,
  day: 'day'
};

export default translator(injectSheet(styles)(DurationCourse));
