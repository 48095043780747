/*
 * @Author: loc.do@bstarsolutions.com
 * @Date: 2019-05-22 14:16:48
 * @Last Modified by: loc.do@bstarsolutions.com
 * @Last Modified time: 2019-06-11 17:47:16
 */

import { theme } from 'appCore';

const widthLargeKey = `@media (min-width: ${theme.screens.maxWidth}px)
  and (max-width: ${theme.screens.maxWidthLargeMedium}px)`;

const widthSmallKey = `@media  (max-width: ${theme.screens.maxWidth}px)`;

const convertNumber = (px, number) => {
  let numberString;
  if (typeof px === 'string' && px.indexOf('px') !== -1) {
    const numberStringFirst = px.indexOf(',');
    const numberStringFinally = numberStringFirst !== -1 ? px.replace(',', '') : px;
    numberString = numberStringFinally.slice(0, px.indexOf('px')) * number;
    return `${numberString}px`;
  }
  return px * number;
};

const createLarge = (key, origin) => ({
  [key]: convertNumber(origin, 0.7)
});

const createSmall = (key, origin) => ({
  [key]: convertNumber(origin, 0.65)
});

const scale = keys => origins => {
  const orginArr = keys.map((k, i) => ({
    [k]: origins[i],
    [widthLargeKey]: {
      ...Object.assign({}, ...keys.map((_k, _i) => createLarge(_k, origins[_i])))
    },
    [widthSmallKey]: {
      ...Object.assign({}, ...keys.map((_k, _i) => createSmall(_k, origins[_i])))
    }
  }));
  return {
    ...Object.assign({}, ...orginArr)
  };
};

export const scaleStyle = objectStyle => {
  const keys = Object.keys(objectStyle);
  const values = keys.map(k => objectStyle[k]);
  return scale(keys)(values);
};

export default scale;
