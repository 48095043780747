import { createStructuredSelector } from 'reselect';

const dataArticles = state => state.articles;
const currentLang = state => state.app.currentLang;
const isFetching = state => state.articles.isFetching;
const detailsPost = state => state.articles.details;
const isShowApplyPageData = state => state.articles.isShowApplyPageData;
const isShowMenu = state => state.header.isShowMenu;
export default createStructuredSelector({
  dataArticles,
  currentLang,
  isFetching,
  detailsPost,
  isShowApplyPageData,
  isShowMenu
});
