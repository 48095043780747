/*
 * @Author: loc.do@bstarsolutions.com
 * @Date: 2019-05-06 10:19:37
 * @Last Modified by: loc.do@bstarsolutions.com
 * @Last Modified time: 2019-05-21 11:33:43
 */

import { theme } from 'appCore';

export default {
  linkContact: {
    textDecoration: 'none',
    color: theme.colors.primary,
    ...theme.components.linkContact,
    minHeight: 24,
    display: 'inline-flex',
    alignItems: 'center',
    '&::before': {
      content: 'a'
    }
  }
};
