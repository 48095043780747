/*
 * @Author: loc.do@bstarsolutions.com
 * @Date: 2019-05-08 17:07:51
 * @Last Modified by: loc.do@bstarsolutions.com
 * @Last Modified time: 2019-05-15 14:52:12
 */

import { theme } from 'appCore';

export default {
  slideBannerContainer: {
    backgroundSize: `cover`,
    width: `100%`,
    display: 'flex',
    alignItems: 'center',
    flexDirection: 'column',
    backgroundColor: `${theme.colors.black}66`,
    backgroundBlendMode: `multiply`
  }
};
