import axiosInstance from './interceptor';

const Auth = {
  login: ({ email, password }) =>
    axiosInstance
      .post(`/auth/login`, { email, password })
      .catch(e =>
        e.response && e.response.status === 401 && e.response.data
          ? e.response.data
          : e.response.data
      ),
  loginSocial: (provider, token) =>
    axiosInstance
      .post(`/auth/social-login-mobile/${provider}`, { token })
      .then(res => res)
      .catch(e =>
        e.response && e.response.status === 401 && e.response.data
          ? e.response.data
          : e.response.data
      ),
  autoLogin: token => axiosInstance.post(`/auth/login/refresh`, { refresh_token: `${token}` }),
  signUp: ({ email }) =>
    axiosInstance
      .post(`/auth/signup`, { email })
      .then(res => res)
      .catch(e =>
        e.response && (e.response.status === 401 || e.response.status === 422) && e.response.data
          ? e.response.data
          : new Error(e)
      ),
  signUpActive: ({ name, email, activationToken, password, phone }) =>
    axiosInstance
      .post(`/auth/signup/activate`, {
        _method: 'PATCH',
        name,
        email,
        activation_token: activationToken,
        password,
        phone
      })
      .catch(e =>
        e.response && (e.response.status === 404 || e.response.status === 422) && e.response.data
          ? e.response.data
          : new Error(e)
      ),

  forgotPassword: ({ email }) =>
    axiosInstance
      .post(`/auth/password/create-reset`, { email })
      .then(res => res)
      .catch(e =>
        e.response && (e.response.status === 404 || e.response.status === 422) && e.response
          ? {
              status: e.response.status,
              data: e.response.data
            }
          : new Error(e)
      )
};

export default Auth;
