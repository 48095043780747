import * as types from './types';

export const fetchExamChild = (idExam, level, typeExam) => ({
  type: types.EXAM_CHILD_INIT,
  payload: { idExam, level, typeExam }
});

export const fetchExamDetails = id => ({
  type: types.EXAM_DETAILS_INIT,
  id
});

export default this;
