/* eslint-disable no-console */
/* eslint-disable no-param-reassign */
import axios from 'axios';
import { constants } from 'utils';
import { put } from 'redux-saga/effects';
import { logout } from 'containers/Login/duck/actions';
import StorageFactory from './storageFactory';

const storage = StorageFactory.getStorage();
const token = storage.getItem('access_token');
const instance = axios.create({
  baseURL: constants.API,
  headers: {
    'Content-Type': 'application/json',
    Authorization: `Bearer ${token}`
  }
});

// Add a response interceptor

instance.interceptors.response.use(
  response => {
    return response;
  },
  error => {
    const originalRequest = error.config;
    if (error.response.status === 401 && !originalRequest._retry) {
      originalRequest._retry = true;
      const refreshToken = storage.getItem('refresh_token');
      const bgOverflow = document.getElementById('bgOverflow');
      if (bgOverflow) {
        bgOverflow.style.display = 'flex';
        if (document.getElementById('loading')) {
          document.getElementById('loading').style.opacity = '0';
        }
      }
      return (
        axios
          .post(`${constants.API}/auth/login/refresh`, {
            refresh_token: `${refreshToken}`
          })
          // eslint-disable-next-line consistent-return
          .then(res => {
            if (res.status === 200) {
              storage.setItem('access_token', res.data.data.access_token);
              storage.setItem('refresh_token', res.data.data.refresh_token);
              axios.defaults.headers.Authorization = `Bearer ${res.data.data.access_token}`;
              originalRequest.headers.Authorization = `Bearer ${res.data.data.access_token}`;
              if (bgOverflow) {
                bgOverflow.style.display = 'none';
              }
              return axios.request(originalRequest);
            }
          })
          // eslint-disable-next-line consistent-return
          .catch(err => {
            if (bgOverflow) {
              bgOverflow.style.display = 'none';
            }
            if (err.response.status === 401) {
              put(logout());
              originalRequest.url = originalRequest.url.replace(
                constants.API,
                `${constants.API}/pure-route`
              );
              delete originalRequest.headers.Authorization;
              storage.removeItem('access_token');
              storage.removeItem('refresh_token');
              if (document.getElementById('logout')) {
                document.getElementById('logout').click();
              }
              return axios.request(originalRequest);
            }
          })
      );
    }
    return Promise.reject(error);
  }
);
export default instance;
