/*
 * @Author: loc.do@bstarsolutions.com
 * @Date: 2019-05-13 17:00:44
 * @Last Modified by: loc.do@bstarsolutions.com
 * @Last Modified time: 2019-05-13 17:44:22
 */

import { theme } from 'appCore';

export default {
  dotContainer: {
    display: 'flex',
    marginTop: 45,
    justifyContent: 'center',
    marginBottom: 44
  },
  dot: {
    width: 10,
    height: 10,
    borderRadius: 999,
    backgroundColor: theme.colors.grayb2,
    marginRight: 10
  }
};
