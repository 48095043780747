/*
 * @Author: loc.do@bstarsolutions.com
 * @Date: 2019-05-29 16:51:25
 * @Last Modified by: loc.do@bstarsolutions.com
 * @Last Modified time: 2019-09-05 15:33:50
 */
import { connect } from 'react-redux';
import React from 'react';
import { compose } from 'recompose';
import injectSheet from 'react-jss';
import { Link, withRouter } from 'react-router-dom';
import { Button, ModalResultDetailExam } from 'components';
import { translator } from 'utils';
import * as actions from 'containers/Exercise/duck/actions';
import { images } from 'appCore';
import styles from './styles';

function ModalResult(props) {
  const {
    classes,
    result,
    total,
    score,
    lessionId,
    onClose,
    dispatch,
    strings,
    isPrimary,
    courseId,
    isExerciseResult,
    sectionId,
    currentLang,
    isPrimaryExam,
    history,
    hasPractice,
    courseName,
    typeCourse,
    isCombo
  } = props;

  const lang = strings.submitPractice;

  const hideModal = () => {
    dispatch(onClose);
  };

  const onSubmit = () => {
    dispatch(actions.changeIsFetchingFalse());
    history.push(`/de-thi/bai-thi/${sectionId}`);
  };

  const onOpenDetailResult = () => {
    history.push(`/de-thi/bai-thi/${sectionId}/1`);
  };

  const resultImageSumo = (resultSumo, isComplete) => {
    if (resultSumo === 'PASSED' && isComplete === true) {
      return <img className={classes.imgResult} src={images.sumoSuccess} alt="Sumo" />;
    }
    if (resultSumo === 'FAILED' && isComplete === false) {
      return <img className={classes.imgResult} src={images.sumo1} alt="Sumo" />;
    }
    if (resultSumo === 'PASSED' && isComplete === false) {
      return <img className={classes.imgResult} src={images.sumo1} alt="Sumo" />;
    }
    return <img className={classes.imgResult} src={images.sumoFail} alt="Sumo" />;
  };

  const resultBtn = (resultSumo, isComplete) => {
    if (resultSumo === 'PASSED' && isComplete === true) {
      return (
        <div onClick={isPrimaryExam ? onSubmit : onOpenDetailResult}>
          <Button onClick={hideModal} label={isPrimaryExam ? 'OK' : lang.seeResults} />
        </div>
      );
    }
    if (resultSumo === 'FAILED' && isComplete === false) {
      return (
        <Link onClick={onSubmit} to={`/de-thi/bai-thi/${sectionId}/1`}>
          <Button onClick={hideModal} label="OK" />
        </Link>
      );
    }
    if (resultSumo === 'PASSED' && isComplete === false) {
      return (
        <Link onClick={onSubmit} to={`/de-thi/bai-thi/${sectionId}/1`}>
          <Button onClick={hideModal} label="OK" />
        </Link>
      );
    }
    return (
      <div onClick={isPrimaryExam ? onSubmit : onOpenDetailResult}>
        <Button onClick={hideModal} label={isPrimaryExam ? 'OK' : lang.seeResults} />
      </div>
    );
  };

  const renderImgLevel = () => {
    if (typeCourse === 5) {
      return <img className={classes.imgLevel} src={images.n5} alt="level" />;
    }
    if (typeCourse === 4) {
      return <img className={classes.imgLevel} src={images.n4} alt="level" />;
    }
    if (typeCourse === 3) {
      return <img className={classes.imgLevel} src={images.n3} alt="level" />;
    }
    if (typeCourse === 2) {
      return <img className={classes.imgLevel} src={images.n2} alt="level" />;
    }
    return <img className={classes.imgLevel} src={images.n1} alt="level" />;
  };

  return (
    <div className={classes.container}>
      {hasPractice ? (
        <>
          <span className={classes.title}>{lang.titleResult}</span>
          <div className={classes.border} />
          <span className={classes.descriptionModal}>
            {lang.contentResult}
            <span className={classes.scoreModal}>
              {Number.isInteger(score)
                ? score
                : score.toFixed(2).slice(score.toFixed(2).length - 1) === '0'
                ? Number(score.toFixed(2).slice(0, score.toFixed(2).length - 1))
                : score.toFixed(2)}
              / {total}
            </span>
          </span>
          {isExerciseResult ? (
            <>
              {isPrimaryExam && (
                <ModalResultDetailExam
                  onClose={onClose}
                  isPrimaryExam={isPrimaryExam}
                  sectionId={sectionId}
                  resultData={result.data.data}
                  currentLang={currentLang}
                  isComplete={result.data.data.is_completed}
                />
              )}
              {resultImageSumo(result.data.data.result, result.data.data.is_completed)}
              {resultBtn(result.data.data.result, result.data.data.is_completed)}
            </>
          ) : (
            <>
              {result.data.data.has_done === true && isCombo && (
                <span className={classes.victory}>
                  {lang.victory} {`${courseName}`}
                </span>
              )}
              {result.data.data.status === true ? (
                <img className={classes.imgResult} src={images.sumoSuccess} alt="Sumo" />
              ) : (
                <img className={classes.imgResult} src={images.sumoFail} alt="Sumo" />
              )}
              {isPrimary ? (
                <Link to={`/khoa-hoc/bai-hoc/${courseId}`}>
                  <Button
                    onClick={hideModal}
                    label={result.data.data.status === true ? lang.passResult : lang.failResult}
                  />
                </Link>
              ) : (
                <Link to={`/khoa-hoc/loai-bai-hoc/${lessionId}`}>
                  <Button
                    onClick={hideModal}
                    label={result.data.data.status === true ? lang.passResult : lang.failResult}
                  />
                </Link>
              )}
            </>
          )}
        </>
      ) : (
        <div className={classes.wrapNotPractice}>
          <span className={classes.title}>{lang.titleNotPractice}</span>
          <div className={classes.border} />
          <span>{lang.nextPractice}</span>
        </div>
      )}
      {result.data.data.has_done === true && isCombo && (
        <>
          <div className={classes.marginBotomImg} />
          <div className={classes.wrapImage}>{renderImgLevel(courseName)}</div>
        </>
      )}
    </div>
  );
}

export default compose(
  connect(),
  injectSheet(styles),
  translator
)(withRouter(ModalResult));
