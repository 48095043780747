import * as types from './types';

const INITIAL_STATE = {
  currentLang: '',
  languages: [],
  breadcrumb: [],
  tabs: [],
  leftMenu: [],
  listMenu: [],
  isFetching: false,
  listConfiguration: {}
};

export default (state = INITIAL_STATE, action) => {
  switch (action.type) {
    case types.SUPPORT_LANGUAGE_SUCCESS:
      return {
        ...state,
        languages: action.payload.languages,
        currentLang: action.payload.currentLang
      };
    case types.LANGUAGE_CHANGE:
      return {
        ...state,
        currentLang: action.payload
      };
    case types.BREADCRUMB_CHANGE:
      return {
        ...state,
        breadcrumb: action.payload
      };
    case types.TABS_CHANGE:
      return {
        ...state,
        tabs: action.payload
      };
    case types.LEFTMENU_CHANGE:
      return {
        ...state,
        leftMenu: action.payload
      };
    case types.GET_LIST_MENU_FETCHING: {
      return {
        ...state,
        isFetching: true
      };
    }
    case types.GET_LIST_MENU_SUCCESS: {
      return {
        ...state,
        listMenu: action.payload.listMenu,
        isFetching: false
      };
    }
    case types.GET_LIST_MENU_FAIL: {
      return {
        ...state,
        listMenu: [],
        isFetching: false
      };
    }
    case types.GET_LIST_CONFIGURATION_FETCHING: {
      return {
        ...state,
        isFetching: true
      };
    }
    case types.GET_LIST_CONFIGURATION_SUCCESS: {
      return {
        ...state,
        listConfiguration: action.payload.listConfiguration,
        isFetching: false
      };
    }
    case types.GET_LIST_CONFIGURATION_FAIL: {
      return {
        ...state,
        listConfiguration: {},
        isFetching: false
      };
    }
    default:
      return state;
  }
};
