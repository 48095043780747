/*
 * @Author: loc.do@bstarsolutions.com
 * @Date: 2019-05-29 09:56:03
 * @Last Modified by: loc.do@bstarsolutions.com
 * @Last Modified time: 2019-06-14 09:12:05
 */

import { constants } from 'utils';
import axiosInstance from './interceptor';

const Practices = {
  submitPractices: (lessonId, score, totalQuestion, courseId) =>
    axiosInstance.post(`${constants.API}/exercise/submit`, {
      lesson_id: lessonId,
      score,
      total_question: totalQuestion,
      course_id: courseId
    })
};

export default Practices;
