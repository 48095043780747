/*
 * @Author: loc.do@bstarsolutions.com
 * @Date: 2019-06-25 10:03:13
 * @Last Modified by: loc.do@bstarsolutions.com
 * @Last Modified time: 2019-06-25 13:19:09
 */
import { scaleStyle } from 'utils';
import { theme } from 'appCore';

export default {
  myInfo: {
    width: `100%`,
    display: 'flex',
    ...scaleStyle({
      marginBottom: 10
    })
  },
  titleInfo: {
    fontWeight: 'bold',
    color: theme.colors.primary,
    ...scaleStyle({
      lineHeight: `80px`,
      fontSize: 20
    })
  },
  border: {
    objectFit: 'contain',
    backgroundColor: theme.colors.primary,
    ...scaleStyle({
      width: 111,
      height: 3,
      marginBottom: 39
    })
  }
};
