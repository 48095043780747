/*
 * @Author: loc.do@bstarsolutions.com
 * @Date: 2019-05-16 10:27:28
 * @Last Modified by: loc.do@bstarsolutions.com
 * @Last Modified time: 2019-07-04 15:59:27
 */

import React from 'react';
import injectSheet from 'react-jss';
import { Block } from 'components';
import { translate } from 'utils';
import { theme, images } from 'appCore';

import styles from './styles';

function Sell(props) {
  const { classes, lesson, bought, onClick, currentLang } = props;

  const lessonStatus = {
    LOCK: 0,
    IN_PROGRESS: 1,
    DONE: 2
  };

  function checkSell() {
    if (!bought) {
      if (lesson.has_trial) {
        return true;
      }
      return false;
    }
    if (lesson.status === lessonStatus.LOCK) {
      return false;
    }
    return true;
  }

  function getStar(number) {
    const limit = 6;
    let i = 0;
    let j = 1;
    const stars = [];
    const notstar = limit - number;
    for (i; i < number; i += 1) {
      stars.push(<img key={i} src={images.star} className={classes.star} alt="Star" />);
    }
    for (j = 1; j < notstar; j += 1) {
      stars.push(<img key={j + 5} src={images.star1} className={classes.star} alt="Star" />);
    }
    return stars;
  }

  return (
    <div onClick={onClick} className={classes.boxChildCourse}>
      <Block className={`${classes.childCourse} childCourse-lesson`}>
        {checkSell()
          ? ''
          : !bought && (
              <img className={classes.imgPadlock} src={images.padlock} alt={images.banner} />
            )}
        <div
          style={
            checkSell()
              ? {
                  backgroundImage: `url(${lesson.image})`
                }
              : {
                  backgroundColor: `${theme.colors.black}cc`,
                  backgroundBlendMode: `multiply`,
                  backgroundImage: `url(${lesson.image})`
                }
          }
          className={`${classes.imgChildCourse} thumbImg-lesson`}
        >
          {!checkSell() && !!window.StyleMedia && (
            <div
              style={{
                position: 'absolute',
                height: '100%',
                width: '100%',
                opacity: 0.8,
                backgroundColor: theme.colors.black,
                backgroundBlendMode: 'unset'
              }}
            />
          )}
        </div>
      </Block>
      {lesson.stars !== 99 && (
        <div className={`${classes.boxStar} boxStar`}>{getStar(lesson.stars)}</div>
      )}
      <span className={`${classes.name} sell-name--isMod`}>
        {translate(lesson, currentLang).name}
      </span>
    </div>
  );
}

export default injectSheet(styles)(Sell);
