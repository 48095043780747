/*
 * @Author: loc.do@bstarsolutions.com
 * @Date: 2019-05-08 11:08:08
 * @Last Modified by: loc.do@bstarsolutions.com
 * @Last Modified time: 2019-07-19 13:34:47
 */

import { theme } from 'appCore';
import { scaleStyle } from 'utils';

export default {
  block: {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    alignItems: 'center',
    backgroundColor: theme.colors.white,
    overflow: 'hidden',
    ...scaleStyle({
      borderRadius: 30
    })
  },
  borderColorDefault: {
    border: `1px solid ${theme.colors.gray87}`
  },
  borderColorPrimary: {
    border: `1px solid ${theme.colors.primary}`
  },
  borderColorSecondary: {
    border: `1px solid ${theme.colors.secondary}`
  },
  row: {
    flexDirection: 'row'
  }
};
