import { theme } from 'appCore';
import { scaleStyle } from 'utils';

export default {
  block: {
    ...scaleStyle({
      padding: 30,
      minWidth: 250
    }),
    display: 'flex',
    alignItems: 'flex-start'
  },
  item: {
    transition: 'all 0.5s',
    width: '100%',
    ...scaleStyle({
      height: 81,
      fontSize: 20
    }),
    display: 'flex',
    alignItems: 'center',
    borderBottom: 'solid 1px gray',
    textTransform: 'capitalize',
    color: theme.colors.black,
    '&:hover': {
      fontWeight: 'bold'
    }
  },
  active: {
    fontWeight: 'bold',
    color: theme.colors.primary
  },
  last: {
    borderBottom: 'none'
  },
  icon: {
    ...scaleStyle({
      marginRight: 22
    })
  },
  [`@media screen and (max-width: ${theme.screens.maxWidth800}px)`]: {
    active: {
      display: 'none'
    }
  }
};
