import React from 'react';
import PropTypes from 'prop-types';
import c from 'classnames';
import injectSheet from 'react-jss';
import { Link } from 'react-router-dom';
import styles from './styles';

function Tab(props) {
  const { active, classes, tabs } = props;
  const tabItem = (label, index, to, action) => (
    <Link
      key={index}
      className={c(classes.tabItem, { [classes.active]: active === index })}
      to={to || '#'}
      onClick={action}
    >
      {label}
    </Link>
  );

  return (
    <>
      <div className={classes.tabs}>
        {tabs.map((t, i) => (
          <div key={i} className={classes.itemWrapper}>
            <div className={classes.divider} />
            {tabItem(t.label, i, t.to, t.action)}
          </div>
        ))}
        <div className={classes.divider} />
      </div>
    </>
  );
}

Tab.propTypes = {
  tabs: PropTypes.arrayOf(Object),
  active: PropTypes.number
};

Tab.defaultProps = {
  tabs: [{ label: 'tabA', content: 'aaa' }, { label: 'tabB', content: {} }],
  active: 0
};

export default injectSheet(styles)(Tab);
