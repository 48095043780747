import { put, takeLatest, select, takeMaybe } from 'redux-saga/effects';
import { constants } from 'utils';
import { CoursesAPI, BaseAPIs, VocabularyAPIs } from 'services';
import { ModalVocabularyDetailActions } from 'containers/ModalVocabularyDetail/duck';
import { appActions } from 'containers/App/duck';
import WebVVT from './node-webvtt';

import {
  LESSON_BY_SKILL_INIT,
  LESSON_BY_SKILL_FETCHING,
  LESSON_BY_SKILL_SUCCESS,
  LESSON_BY_SKILL_FAIL,
  REMAKE_LEFT_MENU,
  SUBTITLE_INIT,
  SUBTITLE_FETCHING,
  SUBTITLE_FAIL,
  SUBTITLE_SUCCESS,
  SPEECH_TO_TEXT_FETCHING,
  SPEECH_TO_TEXT_SUCCESS,
  SPEECH_TO_TEXT_FAIL,
  SPEECH_TO_TEXT_INIT,
  REMOVE_RESULT_SPEECH_TO_TEXT,
  GET_KANJI_DETAIL_INIT,
  GET_KANJI_DETAIL_SUCCESS,
  GET_KANJI_DETAIL_FETCHING,
  GET_KANJI_DETAIL_FAIL
} from './types';

import { LESSON_IN_COURSE_SUCCESS } from '../../Courses/CourseChild/LessonInCourse/duck/types';

const currentLangSelector = state => state.app.currentLang;
const { SKILL_TYPE } = constants;
const LESSON_BY_SKILL_API = {
  [SKILL_TYPE.VOCABULARY]: CoursesAPI.getVocabInLesson,
  [SKILL_TYPE.GRAMMAR]: CoursesAPI.geGrammarInLesson,
  [SKILL_TYPE.KANJI]: CoursesAPI.getKanjiInLesson,
  [SKILL_TYPE.READING]: CoursesAPI.geReadingInLesson,
  [SKILL_TYPE.LISTENING]: CoursesAPI.getListeningInLesson,
  [SKILL_TYPE.CONVERSATION]: CoursesAPI.getConversationInLesson,
  [SKILL_TYPE.SONG]: CoursesAPI.getListeningInLesson,
  [SKILL_TYPE.TEST]: CoursesAPI.getExerciseInLesson,
  [SKILL_TYPE.PRACTICE]: CoursesAPI.getExerciseInLesson
};

function* getLessonBySkill(action) {
  const {
    payload: { lessionId, skillType }
  } = action;
  try {
    yield put({ type: LESSON_BY_SKILL_FETCHING });
    const rawData = yield LESSON_BY_SKILL_API[skillType](lessionId);
    yield put({
      type: LESSON_BY_SKILL_SUCCESS,
      payload: {
        status: rawData.data.success,
        lesson: rawData.data.data,
        metaLesson: rawData.data.meta,
        totalScore: 0
      }
    });
  } catch (e) {
    yield put({ type: LESSON_BY_SKILL_FAIL });
  }
}

function* remakeLeftMenu(action) {
  const currentLang = yield select(currentLangSelector);
  const {
    payload: { courseId }
  } = action;
  const rawData = yield CoursesAPI.getLessonInCourse(courseId);
  const lessons = rawData.data.data;
  yield put({
    type: LESSON_IN_COURSE_SUCCESS,
    payload: {
      status: rawData.data.success,
      lessonInCourse: lessons,
      lessonInCourseId: courseId
    }
  });
  if (!rawData.success) {
    const newLeftMenu =
      lessons &&
      lessons.map(l => {
        const lang =
          l.translations && l.translations[currentLang]
            ? l.translations[currentLang]
            : l.translations.vi;
        const isConcept = l.type === constants.BASIC_TYPE.CONCEPT;
        return {
          label: lang ? lang.name : '',
          translations: l.translations,
          to: isConcept
            ? `/khoa-hoc/dang-hoc/${l.id}/${l.type}/concept`
            : `/khoa-hoc/dang-hoc/${l.id}/${l.type}/${l.chapters[0].type}`,
          concept: isConcept && lang ? lang.description : '',
          id: l.id
        };
      });
    yield put(appActions.changeLeftMenu(newLeftMenu));
  }
}

function* getSubtitle(action) {
  try {
    yield put({ type: SUBTITLE_FETCHING });
    const rawData = yield BaseAPIs.getByUrl(action.payload);
    const { cues } = WebVVT.parse(rawData.data);
    if (cues) {
      const filtered = cues.flatMap(cue => (cue.end && [cue]) || []);
      yield put({
        type: SUBTITLE_SUCCESS,
        payload: filtered
      });
    } else {
      yield put({ type: SUBTITLE_FAIL });
    }
  } catch (e) {
    yield put({ type: SUBTITLE_FAIL });
  }
}

function* postSpeechToText(action) {
  const {
    payload: { base64, sampleRateHertz, audioChannelCount }
  } = action;
  try {
    yield put({
      type: SPEECH_TO_TEXT_FETCHING,
      payload: {
        statusSpeechToText: 'running',
        resultSpeechToText: ''
      }
    });
    const rawData = yield VocabularyAPIs.submitRecord(base64, sampleRateHertz, audioChannelCount);

    const results = rawData.data.results.map(res => {
      return res.alternatives.map(alt => {
        return alt.transcript;
      });
    });

    yield put({
      type: SPEECH_TO_TEXT_SUCCESS,
      payload: {
        statusSpeechToText: 'success',
        resultSpeechToText: results.toString()
      }
    });
  } catch (e) {
    yield put({
      type: SPEECH_TO_TEXT_FAIL,
      payload: {
        statusSpeechToText: 'fail',
        resultSpeechToText: ''
      }
    });
  }
  yield put(ModalVocabularyDetailActions.enableVolume());
}

function* removeResultSpeechToText() {
  yield put({
    type: REMOVE_RESULT_SPEECH_TO_TEXT
  });
}

function* removeResultSpeechToTextPractice() {
  yield put({
    type: REMOVE_RESULT_SPEECH_TO_TEXT
  });
}

function* getKanjiDetail(action) {
  const { kanji } = action.payload;
  yield put({
    type: GET_KANJI_DETAIL_FETCHING
  });

  const rawData = yield VocabularyAPIs.getKanjiDetail(kanji);

  try {
    yield put({
      type: GET_KANJI_DETAIL_SUCCESS,
      payload: {
        kanji: rawData.data.data
      }
    });
  } catch (e) {
    yield put({
      type: GET_KANJI_DETAIL_FAIL
    });
  }
}

export const getLessonBySkillSaga = takeLatest(LESSON_BY_SKILL_INIT, getLessonBySkill);
export const remakeLeftMenuSaga = takeLatest(REMAKE_LEFT_MENU, remakeLeftMenu);
export const getSubtileSaga = takeLatest(SUBTITLE_INIT, getSubtitle);
export const postSpeechToTextSaga = takeLatest(SPEECH_TO_TEXT_INIT, postSpeechToText);
export const removeResultSpeechToTextSaga = takeMaybe(removeResultSpeechToText);
export const removeResultSpeechToTextSagaPractice = takeMaybe(removeResultSpeechToTextPractice);
export const getKanjiDetailSaga = takeLatest(GET_KANJI_DETAIL_INIT, getKanjiDetail);
export default this;
