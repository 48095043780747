/*
 * @Author: loc.do@bstarsolutions.com
 * @Date: 2019-08-02 16:06:54
 * @Last Modified by: loc.do@bstarsolutions.com
 * @Last Modified time: 2019-08-26 10:38:22
 */
import * as types from './types';

export const getListSlide = slugPage => ({
  type: types.GET_LIST_SLIDE_INIT,
  payload: {
    slugPage
  }
});

export const getListGallery = slugPage => ({
  type: types.GET_LIST_GALLERY_INIT,
  payload: {
    slugPage
  }
});

export const getListLecturers = () => ({
  type: types.GET_LIST_LECTURERS_INIT
});

export const registerBuyCourseInHome = (typeForm, name, phone, email, courseId, content) => ({
  type: types.REGISTER_BUY_COURSE_IN_HOME_INIT,
  payload: {
    typeForm,
    name,
    phone,
    email,
    courseId,
    content
  }
});

export const getListCourseInHome = () => ({
  type: types.GET_LIST_COURSE_IN_HOME_INIT
});

export const getListAllDataLandingPage = pageId => ({
  type: types.GET_LIST_ALL_DATA_LANDING_PAGE_INIT,
  payload: {
    pageId
  }
});

export default this;
