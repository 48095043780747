import React from 'react';
import ReactDOM from 'react-dom';
import { Provider } from 'react-redux';
import { CookiesProvider } from 'react-cookie';
import { persistStore } from 'redux-persist';
import { PersistGate } from 'redux-persist/integration/react';
import loadable from '@loadable/component';
import { LoadingComponent } from 'components';
import { configureStore } from './store';
import './index.css';
import * as serviceWorker from './serviceWorker';

const App = loadable(() => import('./containers/App'), {
  fallback: <LoadingComponent />
});

const initialState = {};
const store = configureStore(initialState);
const persistor = persistStore(store);

ReactDOM.render(
  <Provider store={store}>
    <PersistGate loading={null} persistor={persistor}>
      <CookiesProvider>
        <App />
      </CookiesProvider>
    </PersistGate>
  </Provider>,
  document.getElementById('root')
);
serviceWorker.unregister();
