import React from 'react';
import PropTypes from 'prop-types';
import c from 'classnames';
import injectSheet from 'react-jss';
// import { Link } from 'react-router-dom';
import styles from './styles';

const WordBox = props => {
  const { onClick, active, classes, word, currentLang } = props;
  return (
    <div className={c(classes.wrapper, { [classes.active]: active })} onClick={() => onClick(word)}>
      <span className={classes.vocabulary}>{word.vocabulary ? word.vocabulary : word.kanji}</span>
      <span className={classes.spelling}>
        {word.spelling
          ? word.spelling
          : word.translations && word.translations[currentLang]
          ? word.translations[currentLang].meaning
          : word.meaning}
      </span>
    </div>
  );
};

WordBox.propTypes = {
  word: PropTypes.objectOf(Object),
  onClick: PropTypes.func
};

WordBox.defaultProps = {
  word: {
    audio: `http://stg.api.rainichi.com/storage/files/vocabulary/audios/
      fe92b5d90dd0d7d2f7bd25710210a65d.mp3`,
    audio_example_1: null,
    audio_example_2: null,
    hiragana: null,
    id: 120,
    image: null,
    is_active: 1,
    kanji: null,
    katakana: null,
    spelling: 'a',
    svg: 'http://api.rainichi.com/kanji/03042.svg',
    translations: {
      vi: { chinese_vietnamese_word: null, meaning: 'a', example1: null, example2: null }
    },
    type: 5,
    vocabulary: 'あ'
  },
  onClick: () => {}
};

export default injectSheet(styles)(WordBox);
