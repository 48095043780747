import React from 'react';
import PropTypes from 'prop-types';
import injectSheet from 'react-jss';
import c from 'classnames';
import { Link } from 'react-router-dom';
import { images } from 'appCore';
import styles from './styles';

const Breadcrumb = ({ classes, list, language, className }) => {
  const active = list.length - 1;

  return (
    <div className={c(classes.breadcrumb, className)}>
      {list.map((e, i) => (
        <span key={i}>
          {i > 0 && <img className={classes.rightArrow} src={images.rightArrow} alt="right" />}
          <Link
            to={e.to ? e.to : ''}
            className={c(classes.link, { [classes.active]: i === active })}
          >
            {language && e.translations && e.translations[language]
              ? e.translations[language].name
              : e.label}
          </Link>
        </span>
      ))}
    </div>
  );
};

Breadcrumb.propsTypes = {
  list: PropTypes.array
};

Breadcrumb.defaultProps = {
  list: [
    { label: 'Rainichi', to: '/' },
    { label: 'Khóa học', to: '/course' },
    { label: 'Khóa học gói combo', to: '/course' }
  ]
};

export default injectSheet(styles)(Breadcrumb);
