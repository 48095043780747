import React, { useRef, useState, useEffect } from 'react';
import injectSheet from 'react-jss';
import Slider from 'rc-slider';
import c from 'classnames';
import { images, theme } from 'appCore';
import 'rc-slider/assets/index.css';
import styles from './styles';

const Audio = props => {
  const { classes, src, className } = props;
  const audioRef = useRef(null);
  const [audioInfo, setInfoAudio] = useState({
    duration: 0,
    currentTime: 0
  });

  const isPlaying = () => audioRef.current && !audioRef.current.paused;

  const play = () => {
    audioRef.current.play();
  };

  const pause = () => {
    audioRef.current.pause();
  };

  const togglePlayAudio = () => (isPlaying() ? pause() : play());

  const handleLoadMetaData = () => {
    setInfoAudio({ ...audioInfo, duration: audioRef.current.duration });
  };

  const onTimeUpdate = () => {
    setInfoAudio({ ...audioInfo, currentTime: audioRef.current.currentTime });
  };

  const onChange = value => {
    setInfoAudio({ ...audioInfo, currentTime: value });
    audioRef.current.currentTime = value;
  };

  useEffect(() => {}, []);
  return (
    <div className={c(className, classes.audioWrapper)}>
      <audio
        ref={audioRef}
        className={classes.audio}
        src={src || 'https://www.computerhope.com/jargon/m/example.mp3'}
        onLoadedMetadata={handleLoadMetaData}
        onTimeUpdate={onTimeUpdate}
        preload="metadata"
      />
      <div className={classes.control}>
        <Slider
          max={Math.floor(audioInfo.duration)}
          value={audioInfo.currentTime}
          onChange={onChange}
          trackStyle={[{ background: theme.colors.primary }]}
          handleStyle={[
            {
              border: 'none',
              background: theme.colors.primary
            }
          ]}
        />
        <img
          className={classes.playBtn}
          src={!isPlaying() ? images.playButton : images.pauseButton}
          alt="play"
          onClick={togglePlayAudio}
        />
      </div>
    </div>
  );
};

export default injectSheet(styles)(Audio);
