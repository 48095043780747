/*
 * @Author: loc.do@bstarsolutions.com
 * @Date: 2019-05-24 13:21:23
 * @Last Modified by: loc.do@bstarsolutions.com
 * @Last Modified time: 2019-09-18 14:15:26
 */

import { constants } from 'utils';
import * as axiosCustom from 'axios';
import axios from './interceptor';

const Vocabularies = {
  getVocabularies: idVocabulary => axios.get(`${constants.API}/vocabulary/${idVocabulary}`),
  // eslint-disable-next-line no-unused-vars
  submitRecord: (base64Record, sampleRateHertz, audioChannelCount) =>
    axiosCustom.post(
      `${constants.API_SPEECH_TO_TEXT}?key=${constants.KEY_SPEECH_TO_TEXT}`,
      {
        config: {
          encoding: 'LINEAR16',
          sampleRateHertz,
          languageCode: 'ja-JP',
          audioChannelCount
        },
        audio: {
          content: base64Record
        }
      },
      {
        headers: {
          'Content-Type': 'application/json'
        }
      }
    ),
  getKanjiDetail: kanji =>
    axios
      .get(`${constants.API}/kanji/text/${kanji}`)
      .then(res => res)
      .catch(e => e)
};

export default Vocabularies;
