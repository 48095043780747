import * as types from './types';

const INITIAL_STATE = {
  isOpen: false,
  modalProps: {},
  isShowBtnClose: false
};

export default (state = INITIAL_STATE, action) => {
  switch (action.type) {
    case types.ALL_MODALS_HIDE:
      return INITIAL_STATE;
    case types.MODAL_SHOW:
      return {
        ...state,
        isOpen: true,
        modalProps: action.payload
      };
    case types.MODAL_HIDE:
      return {
        ...state,
        isOpen: action.payload.isOpen,
        modalProps: action.payload.modalProps
      };
    case types.HIDE_BTN_CLOSE_IN_MODAL:
      return {
        ...state,
        isShowBtnClose: true
      };
    case types.SHOW_BTN_CLOSE_IN_MODAL:
      return {
        ...state,
        isShowBtnClose: false
      };
    default:
      return state;
  }
};
