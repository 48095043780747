import { createStructuredSelector } from 'reselect';

const listSlide = state => state.home.listSlide;
const isFetching = state => state.home.isFetching;
const currentLang = state => state.app.currentLang;
const listGallery = state => state.home.listGallery;
const isShowMenu = state => state.header.isShowMenu;
const listConfiguration = state => state.app.listConfiguration;
const listCourseInHome = state => state.home.listCourseInHome;
const listAllDataLandingPage = state => state.home.listAllDataLandingPage;
const tokenSelector = state => (state.login.user ? state.login.user.access_token : '');

export default createStructuredSelector({
  listSlide,
  isFetching,
  currentLang,
  listGallery,
  isShowMenu,
  listConfiguration,
  listCourseInHome,
  listAllDataLandingPage,
  tokenSelector
});
