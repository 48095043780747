/*
 * @Author: loc.do@bstarsolutions.com
 * @Date: 2019-06-07 14:11:03
 * @Last Modified by: loc.do@bstarsolutions.com
 * @Last Modified time: 2019-10-23 10:03:22
 */
import { theme, images } from 'appCore';
import { scaleStyle } from 'utils';

export default {
  block: {
    display: 'flex',
    objectFit: 'contain',
    justifyContent: 'center',
    opacity: 0.9,
    backgroundColor: theme.colors.gray253,
    ...scaleStyle({
      // marginBottom: 24,
      // marginRight: 22,
      // width: 330,
      // height: 429,
      borderRadius: 40
    })
  },
  top: {
    // flex: 5,
    display: 'flex',
    flexDirection: 'column',
    width: '100%'
  },
  img: {
    objectFit: 'contain',
    alignSelf: 'center',
    display: 'flex',
    ...scaleStyle({
      width: 110.3,
      height: 76,
      marginTop: 40.2
    })
  },
  coin: {
    objectFit: 'contain',
    fontWeight: 'bold',
    textAlign: 'center',
    color: theme.colors.price,
    ...scaleStyle({
      fontSize: 40,
      lineHeight: `40px`,
      marginTop: 28.8
    })
  },
  bottom: {
    // flex: 5,
    display: 'flex',
    flexDirection: 'column',
    width: '100%',
    backgroundImage: `url(${images.bgCoin})`,
    backgroundSize: 'cover',
    paddingBottom: '15px',
    marginTop: '20px'
  },
  priceOnSale: {
    objectFit: 'contain',
    fontWeight: 'bold',
    textAlign: 'center',
    color: '#4e4e4e',
    ...scaleStyle({
      fontSize: 30,
      marginTop: 24,
      lineHeight: `40px`
    })
  },
  price: {
    textAlign: 'center',
    textDecoration: 'line-through',
    color: theme.colors.primary,
    ...scaleStyle({
      fontSize: 20,
      lineHeight: `40px`
    })
  },
  btn: {
    display: 'flex',
    alignSelf: 'center',
    justifyContent: 'center',
    ...scaleStyle({
      marginTop: 25,
      minWidth: 170
    })
  },
  [`@media screen and (max-width: ${theme.screens.maxWidth800}px)`]: {
    btn: {
      borderRadius: 10,
      // marginBottom: 20,
      width: 140
    },
    coin: {
      // marginBottom: 18
    }
  }
};
