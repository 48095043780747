import { theme } from 'appCore';
import { scaleStyle } from 'utils';

export default {
  textinput: {
    display: 'flex',
    position: 'relative',
    margin: 10,
    width: `98%`,
    ...scaleStyle({
      height: 60
    })
  },
  error: {
    '&:after': {
      content: '"*"',
      position: 'absolute',
      display: 'flex',
      top: 'calc(50% - 8px)',
      fontWeight: 'bold',
      color: theme.colors.error,
      ...scaleStyle({
        fontSize: 15,
        right: 15
      })
    }
  },
  left: {
    position: 'absolute',
    zIndex: 2,
    left: 0,
    top: 0,
    bottom: 0,
    display: 'flex',
    ...scaleStyle({
      width: 60
    })
  },
  icon: {
    margin: 'auto',
    ...scaleStyle({
      width: 30,
      height: 30
    })
  },
  right: {
    width: `100%`,
    position: 'relative',
    display: 'flex',
    justifyContent: 'center',
    ...scaleStyle({
      height: 60
    })
  },
  input: {
    flex: 1,
    width: 'calc(100% - 60px)',
    height: 'calc(100% - 2px)',
    border: `solid 1px ${theme.colors.gray87}`,
    fontFamily: 'Open Sans',
    ...scaleStyle({
      fontSize: 20,
      borderRadius: 10,
      paddingLeft: 60
    }),
    '&:focus': {
      outline: 'unset',
      border: `solid 1px ${theme.colors.primary}`
    }
  },
  noIcon: {
    padding: '0 10px',
    width: 'auto'
  },
  errorInput: {
    border: `solid 1px ${theme.colors.error}`
  },
  [`@media screen and (max-width: ${theme.screens.maxWidth800}px)`]: {
    input: {
      fontSize: 12,
      fontWeight: 'bold',
      borderRadius: 10,
      paddingLeft: 30
    }
  }
};
