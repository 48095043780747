import { createStore, applyMiddleware } from 'redux';
import createSagaMiddleware from 'redux-saga';
import { persistReducer } from 'redux-persist';
import storage from 'redux-persist/lib/storage';
import { composeWithDevTools } from 'redux-devtools-extension';

import createReducer from './reducers';
import rootSaga from './saga';

const persistConfig = {
  key: 'root',
  storage,
  whitelist: [
    'login',
    'app',
    'header',
    'courseChild',
    'lessonInCourse',
    'courses',
    'exams',
    'exercise',
    'articles'
  ],
  blacklist: ['modal']
};

const rootReducer = createReducer();
const persistedReducer = persistReducer(persistConfig, rootReducer);
const sagaMiddleware = createSagaMiddleware();

const checkEnv = () => {
  if (process.env.NODE_ENV !== 'production') {
    return composeWithDevTools(applyMiddleware(sagaMiddleware));
  }
  return applyMiddleware(sagaMiddleware);
};

export default function configureStore(preloadedState = {}) {
  const store = createStore(persistedReducer, preloadedState, checkEnv());

  sagaMiddleware.run(rootSaga);

  return store;
}
