import * as types from './types';

export const fetchLessonBySkill = (lessionId, skillType) => ({
  type: types.LESSON_BY_SKILL_INIT,
  payload: { lessionId, skillType }
});

export const remakeLeftMenu = courseId => ({
  type: types.REMAKE_LEFT_MENU,
  payload: { courseId }
});

export const getSubtitle = url => ({
  type: types.SUBTITLE_INIT,
  payload: url
});

export const submitSpeechToText = (base64, sampleRateHertz, audioChannelCount) => ({
  type: types.SPEECH_TO_TEXT_INIT,
  payload: { base64, sampleRateHertz, audioChannelCount }
});

export const removeResultSpeechToText = () => ({
  type: types.REMOVE_RESULT_SPEECH_TO_TEXT
});

export const removeResultSpeechToTextPractice = () => ({
  type: types.REMOVE_RESULT_SPEECH_TO_TEXT_PRACTICE
});

export const getKanjiDetail = kanji => ({
  type: types.GET_KANJI_DETAIL_INIT,
  payload: {
    kanji
  }
});

export const removeDataKanji = () => ({
  type: types.REMOVE_DATA_KANJI
});

export default this;
