/*
 * @Author: loc.do@bstarsolutions.com
 * @Date: 2019-05-10 11:04:10
 * @Last Modified by: loc.do@bstarsolutions.com
 * @Last Modified time: 2019-07-26 15:03:03
 */

import { createStructuredSelector } from 'reselect';

const listLessonInCourse = state => state.lessonInCourse;
const breadcrumb = state => state.app.breadcrumb;
const currentLang = state => state.app.currentLang;
const courseDetail = state => state.courseChild;
const isCombo = state => state.courses.isCombo;
const isPrimary = state => state.courses.isPrimary;
const isFetching = state => state.lessonInCourse.isFetching;
const listCourseChild = state => state.courseChild;

export default createStructuredSelector({
  listLessonInCourse,
  courseDetail,
  breadcrumb,
  currentLang,
  isCombo,
  isPrimary,
  isFetching,
  listCourseChild
});
