export const LESSON_BY_SKILL_INIT = 'LESSON_BY_SKILL_INIT';
export const LESSON_BY_SKILL_FETCHING = 'LESSON_BY_SKILL_FETCHING';
export const LESSON_BY_SKILL_SUCCESS = 'LESSON_BY_SKILL_SUCCESS';
export const LESSON_BY_SKILL_FAIL = 'LESSON_BY_SKILL_FAIL';

export const WORD_POPUP_SHOW = 'WORD_POPUP_SHOW';
export const REMAKE_LEFT_MENU = 'REMAKE_LEFT_MENU';

export const SUBTITLE_INIT = 'SUBTITLE_INIT';
export const SUBTITLE_FETCHING = 'SUBTITLE_FETCHING';
export const SUBTITLE_SUCCESS = 'SUBTITLE_SUCCESS';
export const SUBTITLE_FAIL = 'SUBTITLE_FAIL';

export const SPEECH_TO_TEXT_INIT = 'SPEECH_TO_TEXT_INIT';
export const SPEECH_TO_TEXT_FETCHING = 'SPEECH_TO_TEXT_FETCHING';
export const SPEECH_TO_TEXT_SUCCESS = 'SPEECH_TO_TEXT_SUCCESS';
export const SPEECH_TO_TEXT_FAIL = 'SPEECH_TO_TEXTL_FAIL';

export const REMOVE_RESULT_SPEECH_TO_TEXT = 'REMOVE_RESULT_SPEECH_TO_TEXT';

export const REMOVE_RESULT_SPEECH_TO_TEXT_PRACTICE = 'REMOVE_RESULT_SPEECH_TO_TEXT_PRACTICE';

export const GET_KANJI_DETAIL_INIT = 'GET_KANJI_DETAIL_INIT';
export const GET_KANJI_DETAIL_SUCCESS = 'GET_KANJI_DETAIL_SUCCESS';
export const GET_KANJI_DETAIL_FETCHING = 'GET_KANJI_DETAIL_FETCHING';
export const GET_KANJI_DETAIL_FAIL = 'GET_KANJI_DETAIL_FAIL';

export const REMOVE_DATA_KANJI = 'REMOVE_DATA_KANJI';
