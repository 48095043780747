/*
 * @Author: loc.do@bstarsolutions.com
 * @Date: 2019-06-25 10:54:22
 * @Last Modified by: loc.do@bstarsolutions.com
 * @Last Modified time: 2019-08-09 14:24:14
 */
import React from 'react';
import { put, takeLatest, takeMaybe } from 'redux-saga/effects';
import { modalActions } from 'containers/ModalManagement/duck';
import { ModalUpdateInformation } from 'components';
import { ProfileAPI } from 'services';
import { removeAvatarDefault, removePositionAvatarDefault } from './actions';

import * as types from './types';

function* getInformationUserInSaga() {
  try {
    yield put({ type: types.GET_INFOMATION_USER_FETCHING });
    const rawData = yield ProfileAPI.getInformationUserAPI();
    yield put({
      type: types.GET_INFOMATION_USER_SUCCESS,
      payload: {
        status: rawData.data.success,
        myInformation: rawData.data.data
      }
    });
  } catch (e) {
    yield put({ type: types.GET_INFOMATION_USER_FAIL });
  }
}

function* updateInformationUser(action) {
  const { formData } = action.payload;
  try {
    yield put({ type: types.UPDATE_INFOMATION_USER_FETCHING });
    const rawData = yield ProfileAPI.updateInformationUserAPI(formData);
    if (rawData.data && rawData.data.code === 200) {
      const children = <ModalUpdateInformation success />;
      yield put(modalActions.showModal({ children }));
    } else if (rawData.errors && String(rawData.errors.avatar) === 'validation.mimes') {
      const children = <ModalUpdateInformation failImage />;
      yield put(modalActions.showModal({ children }));
    } else if (rawData.errors && String(rawData.errors.name) === 'validation.string') {
      const children = <ModalUpdateInformation failEmptyName />;
      yield put(modalActions.showModal({ children }));
    } else {
      const children = <ModalUpdateInformation fail />;
      yield put(modalActions.showModal({ children }));
    }
    yield put({
      type: types.UPDATE_INFOMATION_USER_SUCCESS,
      payload: {
        status: rawData.data.success,
        myInformation: rawData.data.data
      }
    });
    window.scrollTo({ top: 0, left: 0, behavior: 'smooth' });
    yield put(removeAvatarDefault());
    yield put(removePositionAvatarDefault());
  } catch (e) {
    yield put({ type: types.UPDATE_INFOMATION_USER_FAIL });
    yield put(removePositionAvatarDefault());
  }
}

function* getAvatarDefault() {
  try {
    yield put({ type: types.GET_AVATAR_DEFAULT_FETCHING });
    const rawData = yield ProfileAPI.getAvatarDefaultAPI();
    yield put({
      type: types.GET_AVATAR_DEFAULT_SUCCESS,
      payload: {
        status: rawData.data.success,
        avatarDefault: rawData.data.data
      }
    });
  } catch (e) {
    yield put({ type: types.GET_AVATAR_DEFAULT_FAIL });
  }
}

function* getMyExams() {
  try {
    yield put({ type: types.GET_MY_EXAM_FETCHING });
    const rawData = yield ProfileAPI.getMyExamsAPI();
    yield put({
      type: types.GET_MY_EXAM_SUCCESS,
      payload: {
        status: rawData.data.success,
        myExams: rawData.data.data
      }
    });
  } catch (e) {
    yield put({ type: types.GET_MY_EXAM_FAIL });
  }
}

function* getMyExamDetail(action) {
  const { idExamSection } = action.payload;
  try {
    yield put({ type: types.GET_MY_EXAM_DETAIL_FETCHING });
    const rawData = yield ProfileAPI.getMyExamDetailAPI(idExamSection);
    yield put({
      type: types.GET_MY_EXAM_DETAIL_SUCCESS,
      payload: {
        status: rawData.data.success,
        myExamDetail: rawData.data.data
      }
    });
  } catch (e) {
    yield put({ type: types.GET_MY_EXAM_DETAIL_FAIL });
  }
}

function* showDetailMyExam() {
  yield put({ type: types.SHOW_DETAIL_MY_EXAM });
}

function* hideDetailMyExam() {
  yield put({ type: types.HIDE_DETAIL_MY_EXAM });
}

function* showDetailMyExamSection() {
  yield put({ type: types.SHOW_DETAIL_MY_EXAM_SECTION });
}

function* hideDetailMyExamSection() {
  yield put({ type: types.HIDE_DETAIL_MY_EXAM_SECTION });
}

export const getInformationUserSaga = takeLatest(
  types.GET_INFOMATION_USER_INIT,
  getInformationUserInSaga
);

export const updateInformationUserSaga = takeLatest(
  types.UPDATE_INFOMATION_USER_INIT,
  updateInformationUser
);

function* getMyCourses() {
  try {
    yield put({ type: types.GET_MY_COURSE_FETCHING });
    const rawData = yield ProfileAPI.getMyCoursesAPI();
    yield put({
      type: types.GET_MY_COURSE_SUCCESS,
      payload: {
        status: rawData.data.success,
        myCourses: rawData.data.data
      }
    });
  } catch (e) {
    yield put({ type: types.GET_MY_COURSE_FAIL });
  }
}

function* showDetailMyCourseDetail() {
  yield put({ type: types.SHOW_DETAIL_MY_COURSE_DETAIL });
}

function* hideDetailMyCourseDetail() {
  yield put({ type: types.HIDE_DETAIL_MY_COURSE_DETAIL });
}

function* getMyCourseDetail(action) {
  const { idCourse } = action.payload;
  try {
    yield put({ type: types.GET_MY_COURSE_DETAIL_FETCHING });
    const rawData = yield ProfileAPI.getMyCourseDetailAPI(idCourse);
    yield put({
      type: types.GET_MY_COURSE_DETAIL_SUCCESS,
      payload: {
        status: rawData.data.success,
        myCourseDetail: rawData.data.data
      }
    });
  } catch (e) {
    yield put({ type: types.GET_MY_COURSE_DETAIL_FAIL });
  }
}

function* getMyCoins() {
  try {
    yield put({ type: types.GET_MY_COIN_FETCHING });
    const rawData = yield ProfileAPI.getMyCoinsAPI();
    yield put({
      type: types.GET_MY_COIN_SUCCESS,
      payload: {
        status: rawData.data.success,
        myCoins: rawData.data.data
      }
    });
  } catch (e) {
    yield put({ type: types.GET_MY_COIN_FAIL });
  }
}

function* getMethodCredits() {
  try {
    yield put({ type: types.GET_MY_METHOD_CREDIT_FETCHING });
    const rawData = yield ProfileAPI.getMethodCreditsAPI();
    yield put({
      type: types.GET_MY_METHOD_CREDIT_SUCCESS,
      payload: {
        status: rawData.data.success,
        medthodCreditArr: rawData.data.data
      }
    });
  } catch (e) {
    yield put({ type: types.GET_MY_METHOD_CREDIT_FAIL });
  }
}

function* updatePasswordUser(action) {
  const { formData } = action.payload;
  try {
    yield put({ type: types.UPDATE_NEW_PASSWORD_FETCHING });
    const rawData = yield ProfileAPI.updatePasswordUserAPI(formData);

    if (rawData.data.code === 200) {
      const children = <ModalUpdateInformation success />;
      yield put(modalActions.showModal({ children }));
    } else {
      const children = <ModalUpdateInformation fail />;
      yield put(modalActions.showModal({ children }));
    }
    yield put({
      type: types.UPDATE_NEW_PASSWORD_SUCCESS,
      payload: {
        status: rawData.data.success,
        resultUpdatePassword: rawData.data
      }
    });
  } catch (e) {
    if (e.message === 'Request failed with status code 400') {
      const children = <ModalUpdateInformation passwordFail />;
      yield put(modalActions.showModal({ children }));
    }
    if (e.message === 'Request failed with status code 422') {
      const children = <ModalUpdateInformation passwordSameFail />;
      yield put(modalActions.showModal({ children }));
    }
    yield put({ type: types.UPDATE_NEW_PASSWORD_FAIL });
  }
}

function* saveMyCourseId(action) {
  const { courseId, courseName } = action.payload;
  yield put({
    type: types.SAVE_MY_COURSE_ID,
    payload: {
      courseId,
      courseName
    }
  });
}

function* saveAvatarDefault(action) {
  const { myAvatarDefault } = action.payload;
  yield put({
    type: types.SAVE_AVATAR_DEFAULT,
    payload: {
      myAvatarDefault
    }
  });
}

function* removeAvatarDefaultWhenSuccess() {
  yield put({
    type: types.REMOVE_AVATAR_DEFAULT
  });
}

function* savePositionAvatarDefault(action) {
  const { myPositionAvatarDefault } = action.payload;
  yield put({
    type: types.SAVE_POSITION_AVATAR_DEFAULT,
    payload: {
      myPositionAvatarDefault
    }
  });
}

function* removePositionAvatarDefaultWhenSuccess() {
  yield put({
    type: types.REMOVE_POSITION_AVATAR_DEFAULT
  });
}

export const getAvatarDefaultSaga = takeLatest(types.GET_AVATAR_DEFAULT_INIT, getAvatarDefault);
export const getMyExamsSaga = takeLatest(types.GET_MY_EXAM_INIT, getMyExams);
export const getMyExamDetailSaga = takeLatest(types.GET_MY_EXAM_DETAIL_INIT, getMyExamDetail);
export const showDetailMyExamSaga = takeMaybe(showDetailMyExam);
export const hideDetailMyExamSaga = takeMaybe(hideDetailMyExam);
export const showDetailMyExamSectionSaga = takeMaybe(showDetailMyExamSection);
export const hideDetailMyExamSectionSaga = takeMaybe(hideDetailMyExamSection);
export const getMyCoursesSaga = takeLatest(types.GET_MY_COURSE_INIT, getMyCourses);
export const showDetailMyCourseDetailSaga = takeMaybe(showDetailMyCourseDetail);
export const hideDetailMyCourseDetailSaga = takeMaybe(hideDetailMyCourseDetail);
export const getMyCourseDetailSaga = takeLatest(types.GET_MY_COURSE_DETAIL_INIT, getMyCourseDetail);
export const getMyCoinsSaga = takeLatest(types.GET_MY_COIN_INIT, getMyCoins);
export const getMethodCreditSaga = takeLatest(types.GET_MEMTHOD_CREDIT_INIT, getMethodCredits);

export const updatePasswordUserSaga = takeLatest(
  types.UPDATE_NEW_PASSWORD_INIT,
  updatePasswordUser
);
export const saveMyCourseIdSaga = takeMaybe(saveMyCourseId);
export const saveAvatarDefaultSaga = takeMaybe(saveAvatarDefault);
export const removeAvatarDefaultDefault = takeMaybe(removeAvatarDefaultWhenSuccess);
export const savePositionAvatarDefaultSaga = takeMaybe(savePositionAvatarDefault);
export const removePositionAvatarDefaultWhenSuccessSaga = takeMaybe(
  removePositionAvatarDefaultWhenSuccess
);
export default this;
