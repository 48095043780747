import { scaleStyle } from 'utils';
import { theme } from 'appCore';

export default {
  videoWrapper: {
    position: 'relative',
    minHeight: 80
  },
  video: {
    width: '100%',
    borderRadius: 20,
    overflow: 'hidden',
    objectFit: 'cover'
  },
  control: {
    position: 'absolute',
    display: 'flex',
    flexDirection: 'column',
    ...scaleStyle({
      left: 45,
      right: 45,
      height: 50
    }),
    bottom: 36
  },
  bottom: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',
    marginTop: 4
  },
  playBtn: {
    ...scaleStyle({
      height: 40,
      width: 40,
      marginTop: 10
    }),
    marginLeft: -7,
    cursor: 'pointer'
  },
  time: {
    color: theme.colors.white
  }
};
