import { theme } from 'appCore';
import { scaleStyle } from 'utils';

export default {
  tabs: {
    height: 55,
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    borderBottom: `solid ${theme.colors.lightgray} 1px`,
    marginBottom: 30
  },
  itemWrapper: {
    display: 'flex',
    alignItems: 'center'
  },
  tabItem: {
    color: theme.colors.black,
    textTransform: 'uppercase',
    fontWeight: 'bold',
    borderBottom: `solid transparent`,
    '&:hover': {
      color: theme.colors.primary,
      borderBottom: 'solid'
    },
    ...scaleStyle({
      lineHeight: '55px',
      marginRight: 30,
      marginLeft: 30,
      fontSize: 20
    })
  },
  active: {
    color: theme.colors.primary,
    borderBottom: 'solid'
  },
  divider: {
    width: 1.5,
    background: 'gray',
    height: 16
  },
  content: {
    marginTop: 20
  }
};
