/*
 * @Author: loc.do@bstarsolutions.com
 * @Date: 2019-05-24 13:21:23
 * @Last Modified by: loc.do@bstarsolutions.com
 * @Last Modified time: 2019-10-22 15:47:01
 */

import { constants } from 'utils';
import axios from './interceptor';

const Stores = {
  getListCoin: () => axios.get(`${constants.API}/credit?per_page=100`),
  getListCoinNotAuthen: () => axios.get(`${constants.API}/pure-route/credit?per_page=100`),
  getListCourse: type => axios.get(`${constants.API}/course-type?category=${type}`),
  getListCourseNotAuthen: type =>
    axios.get(`${constants.API}/pure-route/course-type?category=${type}`),

  getListSkill: () => axios.get(`${constants.API}/level`),
  getListSkillNotAuthen: () => axios.get(`${constants.API}/pure-route/level`),
  getListSelectCourse: (params, courseType, arrayLevel, typeUser) =>
    axios.get(
      `${
        constants.API
      }/course/admin?is_shop=${params}&course_type=${courseType}&levels=${arrayLevel &&
        `[${arrayLevel}]`} &customer_type_id=${typeUser}&per_page=100`
    ),
  getListSelectCourseNotAuthen: (params, courseType, arrayLevel, typeUser) =>
    axios.get(
      `${
        constants.API
      }/pure-route/course/admin?is_shop=${params}&course_type=${courseType}&levels=${arrayLevel &&
        `[${arrayLevel}]`} &customer_type_id=${typeUser}&per_page=100`
    ),
  filterCourseInShop: (params, courseId, typeCourse) =>
    axios.get(
      `${constants.API}/course/admin?is_shop=${params}&course_id=${courseId}
      &customer_type_id=${typeCourse}&per_page=100`
    ),
  filterCourseInShopNotAuthen: (params, courseId, typeCourse) =>
    axios.get(
      `${constants.API}/pure-route/course/admin?is_shop=${params}&course_id=${courseId}
      &customer_type_id=${typeCourse}&per_page=100`
    ),
  filterExamInShop: examId => axios.get(`${constants.API}/test?test_id=${examId}&per_page=100`),
  filterExamInShopNotAuthen: examId =>
    axios.get(`${constants.API}/pure-route/test?test_id=${examId}&per_page=100`),
  getListSelectExam: (arrayLevel, type, courseType) =>
    axios.get(
      `${constants.API}/test?levels=${`[${arrayLevel &&
        arrayLevel}]`}&type=${type}&course_type_id=${courseType}&customer_type_id=1,2&per_page=100`
    ),
  getListSelectExamNotAuthen: (arrayLevel, type, courseType) =>
    axios.get(
      `${constants.API}/pure-route/test?levels=${`[${arrayLevel &&
        arrayLevel}]`}&type=${type}&course_type_id=${courseType}&customer_type_id=1,2&per_page=100`
    ),
  getPaymentVnPay: (
    vnpBankCode,
    amount,
    orderInfo,
    courseId,
    isEnterprise,
    duration,
    numOfEmployee
  ) =>
    axios.post(`${constants.API}/payment-vnpay`, {
      vnp_BankCode: vnpBankCode,
      vnp_Amount: amount,
      vnp_OrderInfo: orderInfo,
      course_id: courseId,
      is_enterprise: isEnterprise,
      duration,
      num_of_employee: numOfEmployee
    }),
  payTransferCoinOffline: (
    paymentType,
    amount,
    orderInfo,
    courseId,
    isEnterprise,
    duration,
    numOfEmployee
  ) =>
    axios.post(`${constants.API}/buying/offline`, {
      payment_type: paymentType,
      vpc_OrderInfo: orderInfo,
      vpc_Amount: amount,
      course_id: courseId,
      is_enterprise: isEnterprise,
      duration,
      num_of_employee: numOfEmployee
    }),
  buyCourseInShop: (couseId, credit, reward, duration) =>
    axios.post(`${constants.API}/buying/course`, {
      course_id: couseId,
      buying_credits: credit,
      reward_credits: reward,
      duration
    }),
  buyExamInShop: (testId, credit, reward, duration) =>
    axios.post(`${constants.API}/buying/test`, {
      test_id: testId,
      buying_credits: credit,
      reward_credits: reward,
      duration
    }),
  buyClassInShop: (couseId, numberOfEmployee, credit, reward, duration) =>
    axios
      .post(`${constants.API}/classroom/default`, {
        course_id: couseId,
        num_of_employee: numberOfEmployee,
        buying_credits: credit,
        reward_credits: reward,
        duration
      })
      .then(res => res)
      .catch(e =>
        e.response && e.response.status === 401 && e.response.data
          ? e.response.data
          : e.response.data
      ),
  buyClassInShopExist: (typeForm, courseId, numOfEmpoyee, email, phone, name) =>
    axios
      .post(`${constants.API}/contact`, {
        type: typeForm,
        course_id: courseId,
        num_of_employee: numOfEmpoyee,
        email,
        phone,
        name
      })
      .then(res => res)
      .catch(e =>
        e.response && (e.response.status === 401 || e.response.status === 422) && e.response.data
          ? e.response.data
          : new Error(e)
      ),
  checkBoughtClass: () =>
    axios
      .get(`${constants.API}/classroom/checked`)
      .then(res => res)
      .catch(e =>
        e.response && e.response.status === 401 && e.response.data
          ? e.response.data
          : e.response.data
      ),
  getPaymentVnPayBuyCourse: (vnpBankCode, amount, orderInfo, courseId) =>
    axios.post(`${constants.API}/payment-by-cash`, {
      vnp_BankCode: vnpBankCode,
      vnp_Amount: amount,
      vnp_OrderInfo: orderInfo,
      course_id: courseId
    })
};

export default Stores;
