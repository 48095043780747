/*
 * @Author: Do Loc - doxuanloc2016@gmail.com
 * @Date: 2019-06-22 19:24:46
 * @Last Modified by: loc.do@bstarsolutions.com
 * @Last Modified time: 2019-08-26 15:45:54
 */
import { connect } from 'react-redux';
import React, { useEffect } from 'react';
import injectSheet from 'react-jss';
import { translator } from 'utils';
import { compose } from 'recompose';
import { ModalContent, Button } from 'components';
import { storeActions } from 'containers/Shop/duck';
import { modalActions } from 'containers/ModalManagement/duck';
import styles from './styles';

const ModalContact = props => {
  const {
    strings,
    emptyName,
    emptyEmail,
    emptyPhone,
    emptyCourse,
    emptyNumOfEmployee,
    registerSuccess,
    emptyAddress,
    emptyContent,
    sendSuccess,
    phoneIsZero,
    userExist,
    userJoined,
    courseEnterprise,
    dispatch,
    classes
  } = props;

  useEffect(() => {
    dispatch(modalActions.showBtnClose());
  }, []);

  const lang = strings.modalContact;

  const onShowPayment = () => {
    dispatch(storeActions.showPaymentPage(courseEnterprise));
    dispatch(modalActions.hideModal());
  };

  const onCloseModal = () => {
    dispatch(modalActions.hideModal());
  };
  return (
    <ModalContent isButton={false} title={lang.title}>
      {emptyName && <span>{lang.emptyName}</span>}
      {emptyEmail && <span>{lang.emptyEmail}</span>}
      {emptyPhone && <span>{lang.emptyPhone}</span>}
      {emptyCourse && <span>{lang.emptyCourse}</span>}
      {emptyNumOfEmployee && <span>{lang.emptyNumOfEmployee}</span>}
      {registerSuccess && <span>{lang.registerSuccess}</span>}
      {emptyAddress && <span>{lang.emptyAddress}</span>}
      {emptyContent && <span>{lang.emptyContent}</span>}
      {sendSuccess && <span>{lang.sendSuccess}</span>}
      {phoneIsZero && <span>{lang.phoneIsZero}</span>}
      {userExist && (
        <div className={classes.wrapUserExist}>
          <span>{lang.userExist}</span>
          <div className={classes.wrapBtn}>
            <Button className={classes.btnOK} onClick={onShowPayment} label={lang.accept} />
            <Button className={classes.btnOK} onClick={onCloseModal} label={lang.notAccept} />
          </div>
        </div>
      )}
      {userJoined && <span>{lang.userJoined}</span>}
    </ModalContent>
  );
};

ModalContact.defaultProps = {
  emptyName: false,
  emptyEmail: false,
  emptyPhone: false,
  emptyCourse: false,
  emptyNumOfEmployee: false,
  registerSuccess: false,
  emptyAddress: false,
  emptyContent: false,
  sendSuccess: false,
  phoneIsZero: false,
  userExist: false,
  userJoined: false
};

export default compose(
  connect(),
  injectSheet(styles),
  translator
)(ModalContact);
