/*
 * @Author: loc.do@bstarsolutions.com
 * @Date: 2019-05-10 10:42:17
 * @Last Modified by: loc.do@bstarsolutions.com
 * @Last Modified time: 2019-07-04 16:05:10
 */

import * as types from './types';

const INITIAL_STATE = {
  status: false,
  courses: {},
  isCombo: false,
  isPrimary: false,
  isFetching: false
};

export default (state = INITIAL_STATE, action) => {
  switch (action.type) {
    case types.COURSE_FETCHING:
      return {
        ...state,
        isFetching: true
      };
    case types.COURSE_SUCCESS:
      return {
        ...state,
        status: action.payload.status,
        courses: action.payload.courses,
        isFetching: false
      };
    case types.COURSE_FAIL:
      return {
        ...state,
        isFetching: true
      };
    case types.COURSE_COMBO:
      return {
        ...state,
        isCombo: action.payload
      };
    case types.COURSE_PACKAGE_PRIMARY:
      return {
        ...state,
        isPrimary: action.payload
      };
    default:
      return state;
  }
};
