/*
 * @Author: loc.do@bstarsolutions.com
 * @Date: 2019-06-07 11:52:49
 * @Last Modified by: loc.do@bstarsolutions.com
 * @Last Modified time: 2019-10-23 10:25:30
 */
import { theme } from 'appCore';
import { scaleStyle } from 'utils';

export default {
  container: {
    margin: '0 auto',
    flexDirection: 'column',
    display: 'flex',
    ...scaleStyle({
      // maxWidth: 1440
    })
  },
  containerShowMenu: {
    margin: '0 auto',
    flexDirection: 'column',
    display: 'flex',
    marginTop: 250,
    transition: '1s',
    ...scaleStyle({
      maxWidth: 1440
    })
  },
  breadcrumb: {
    '& > span:first-child': {
      paddingLeft: 30
    },
    '& > span:last-child': {
      paddingRight: 30
    }
  },
  content: {
    display: 'flex'
  },
  right: {
    flex: 1,
    display: 'flex',
    maxWidth: '100%',
    flexWrap: 'wrap',
    ...scaleStyle({
      paddingLeft: 20,
      paddingRight: 20
    })
  },
  block: {
    marginRight: 0,
    '&:nth-child(even)': {
      border: `1px solid ${theme.colors.secondary}`
    },
    '&:nth-child(odd)': {
      border: `1px solid ${theme.colors.primary}`
    }
  },
  containerBuyCourse: {
    display: 'flex',
    width: '100%',
    flexDirection: 'column'
  },
  listSelect: {
    display: 'flex',
    flexDirection: 'row',
    width: `100%`,
    flexWrap: 'wrap',
    '& > div': {
      marginLeft: 0,
      marginRight: 0,
      '& > img': {
        ...scaleStyle({
          top: 28,
          right: 15
        })
      }
    }
  },
  listCourseSkill: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    width: `100%`,
    ...scaleStyle({
      marginTop: 30
    })
  },
  blockBuyCourse: {
    width: `100%`
  },
  containerBuyExam: {
    display: 'flex',
    width: '100%',
    flexDirection: 'column'
  },
  contentPayment: {
    width: '100%',
    display: 'flex',
    flexDirection: 'column'
  },
  courseTitle: {
    textAlign: 'center',
    color: theme.colors.primary
  },
  titlePayment: {
    color: theme.colors.primary,
    textAlign: 'center',
    ...scaleStyle({
      fontSize: 35,
      marginTop: 6
    })
  },
  list: {
    flex: 1,
    width: '100%',
    flexDirection: 'column',
    justifyContent: 'center',
    ...scaleStyle({
      marginTop: 35,
      marginBottom: 35
    })
  },
  description: {
    display: 'none',
    flexDirection: 'column',
    '& > p ': {
      fontWeight: 'bold',
      fontSize: '25px'
    },
    '& > span ': {
      fontWeight: 'normal'
    },
    ...scaleStyle({
      marginTop: 20
    })
  },
  bankName: {
    fontWeight: 'bold',
    ...scaleStyle({
      lineHeight: `60px`,
      fontSize: 26
    })
  },
  info: {
    ...scaleStyle({
      fontSize: 25,
      lineHeight: `50px`,
      marginBottom: 10
    })
  },
  btnTransfer: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center'
  },
  hideExpand: {
    display: 'none',
    curpor: 'pointer'
  },
  showExpand: {
    display: 'flex',
    curpor: 'pointer'
  },
  containerBuyCoin: {
    display: 'flex',
    flexWrap: 'wrap',
    alignItems: 'center',
    width: `100%`,
    justifyContent: 'space-around'
  },
  renderNameMobile: {
    display: 'none'
  },
  containerMyClass: {
    display: 'flex',
    width: `100%`,
    flexDirection: 'column'
  },
  blockBuyCourseToOrder: {
    display: 'flex',
    width: `100%`,
    marginBottom: 10,
    backgroundColor: theme.colors.gray69
  },
  blockBuyCourseToOrderBottom: {
    display: 'flex',
    width: `100%`,
    marginBottom: 10,
    border: 'none'
  },
  nameBuyCourseToOrder: {
    textAlign: 'left',
    color: theme.colors.primary,
    display: 'flex',
    justifyContent: 'flex-start',
    width: `100%`,
    ...scaleStyle({
      fontSize: 35,
      lineHeight: `40px`,
      marginTop: 30,
      marginLeft: 30
    })
  },
  wrapSelectBuyCourseToOrder: {
    display: 'flex',
    width: `100%`
  },
  selectBuyCourseToOrder: {
    marginLeft: 20,
    marginRight: 20,
    marginTop: 20,
    marginBottom: 20,
    display: 'flex',
    flex: 1
  },
  wrapBtnBuyCourseToOrder: {
    width: `100%`,
    display: 'flex',
    justifyContent: 'flex-end',
    marginRight: 30,
    marginBottom: 20
  },
  selectMuti: {
    marginRight: 35,
    marginLeft: 35,
    width: '45%',
    marginTop: 10,
    '& > div': {
      '& > div': {
        '& > div': {
          '&:first-child': {
            borderRadius: `10px !important`,
            padding: 10,
            border: `1px solid ${theme.colors.gray87} !important`,
            fontSize: 20,
            position: 'relative !important',
            marginTop: '10px !important',
            height: '60px !important',
            '& > span': {
              top: `10px !important`,
              '& > span': {
                color: `${theme.colors.gray56} !important`,
                bottom: 10
              }
            }
          },
          '&:last-child': {
            borderRadius: `10px !important`,
            marginBottom: 20,
            padding: 10,
            border: `1px solid ${theme.colors.gray87} !important`,
            fontSize: 20,
            position: 'relative !important',
            marginTop: '10px !important',
            '& > span': {
              '& > span': {
                color: `${theme.colors.gray56} !important`
              }
            }
          }
        }
      }
    }
  },
  [`@media screen and (max-width: 1367px)`]: {
    selectMuti: {
      marginRight: 35,
      marginLeft: 35,
      width: '45%',
      marginTop: 10,
      '& > div': {
        '& > div': {
          '& > div': {
            '&:first-child': {
              borderRadius: `10px !important`,
              padding: 10,
              border: `1px solid ${theme.colors.gray87} !important`,
              fontSize: 12,
              position: 'relative !important',
              marginTop: '10px !important',
              height: '20px !important',
              '& > span': {
                top: `10px !important`,
                '& > span': {
                  color: `${theme.colors.gray56} !important`,
                  bottom: 10,
                  position: 'relative'
                }
              }
            },
            '&:last-child': {
              borderRadius: `10px !important`,
              marginBottom: 20,
              padding: 10,
              border: `1px solid ${theme.colors.gray87} !important`,
              fontSize: 12,
              position: 'relative !important',
              marginTop: '10px !important',
              '& > span': {
                '& > span': {
                  color: `${theme.colors.gray56} !important`
                }
              }
            }
          }
        }
      }
    }
  },
  [`@media screen and (max-width: ${theme.screens.maxWidth800}px)`]: {
    content: {
      flexDirection: 'column'
    },
    block: {
      // width: 167.7
    },
    blockLeftMenu: {
      border: 'none',
      padding: 0,
      position: 'relative',
      '& > a': {
        fontSize: 14,
        fontWeight: 'bold',
        borderTop: '1px solid #ccc',
        borderBottom: 0,
        marginTop: 10,
        '&:last-child': {
          borderBottom: '1px solid #ccc',
          marginBottom: 10
        },
        '& > img': {
          position: 'absolute',
          right: 10
        },
        '& > span': {
          paddingLeft: 30
        }
      }
    },
    renderNameMobile: {
      fontSize: 18,
      fontWeight: 'bold',
      lineHeight: `19px`,
      textAlign: 'center',
      color: theme.colors.primary,
      display: 'flex',
      justifyContent: 'center',
      marginBottom: 20,
      marginTop: 10
    },
    wrapSelectBuyCourseToOrder: {
      flexDirection: 'column',
      '& div': {
        '& input': {
          paddingLeft: 10
        }
      }
    },
    selectBuyCourseToOrder: {
      width: `95%`,
      margin: '0 auto'
    },
    nameBuyCourseToOrder: {
      marginBottom: 10
    },
    wrapBtnBuyCourseToOrder: {
      marginRight: 0,
      '& button': {
        width: '95%',
        margin: '0 auto',
        borderRadius: 10
      }
    },
    btnRequest: {
      width: `100%`,
      display: 'flex',
      justifyContent: 'center',
      alignItems: 'center',
      height: 50,
      fontSize: 14
    },
    blockBuyCourseToOrder: {
      border: 'none'
    },
    selectMuti: {
      width: '95%',
      margin: '0 auto',
      '& > div': {
        '& > div': {
          '& > div': {
            '&:first-child': {
              borderRadius: `10px !important`,
              padding: 10,
              border: `1px solid ${theme.colors.gray87} !important`,
              fontSize: 12,
              fontWeight: `bold`,
              position: 'relative !important',
              marginTop: '10px !important',
              height: '20px !important',
              '& > span': {
                top: `10px !important`,
                '& > span': {
                  color: `${theme.colors.gray56} !important`,
                  bottom: 10,
                  position: 'relative'
                }
              }
            },
            '&:last-child': {
              borderRadius: `10px !important`,
              padding: 10,
              fontWeight: `bold`,
              border: `1px solid ${theme.colors.gray87} !important`,
              fontSize: 12,
              position: 'relative !important',
              marginTop: '10px !important',
              '& > span': {
                '& > span': {
                  color: `${theme.colors.gray56} !important`
                }
              }
            }
          }
        }
      }
    },
    listSelect: {
      width: '100%',
      display: 'flex',
      flexDirection: 'column'
    },
    right: {
      paddingLeft: 10,
      paddingRight: 10
    },
    titlePayment: {
      textAlign: 'left',
      fontWeight: 'bold',
      fontSize: 15
    }
  }
};
