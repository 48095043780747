/*
 * @Author: loc.do@bstarsolutions.com
 * @Date: 2019-05-15 17:21:27
 * @Last Modified by: loc.do@bstarsolutions.com
 * @Last Modified time: 2019-05-21 15:49:53
 */

import { theme } from 'appCore';

export default {
  durationCourse: {
    position: `absolute`,
    backgroundColor: theme.colors.primary,
    textAlign: `center`,
    color: theme.colors.white,
    ...theme.components.durationCourse
  }
};
