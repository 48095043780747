export { default as constants } from './constants';
export { default as translator, localization } from './translator';
export { default as scale, scaleStyle } from './scale';
export { default as history } from './history';
export { default as translate } from './translate';
export { default as translateKey } from './translateKey';
export const formatTime = seconds => {
  const _seconds = Math.round(seconds % 60);
  const _minus = Math.floor(seconds / 60);
  return `${_minus}:${_seconds > 9 ? '' : 0}${_seconds}`;
};
