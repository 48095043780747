import * as types from './types';

export const selectMenuItem = active => ({
  type: types.MENU_ITEM_ACTIVE,
  payload: active
});

export const isShowListMenuInResponsive = () => ({
  type: types.IS_SHOW_LIST_MENU_IN_RESPONSIVE
});

export const isHideListMenuInResponsive = () => ({
  type: types.IS_HIDE_LIST_MENU_IN_RESPONSIVE
});

export default this;
