import { createStructuredSelector } from 'reselect';

const status = state => state.login.status;
const isFetching = state => state.login.isFetching;
const error = state => state.login.error;
const currentLang = state => state.app.currentLang;
const isShowMenu = state => state.header.isShowMenu;
export default createStructuredSelector({
  status,
  isFetching,
  error,
  currentLang,
  isShowMenu
});
