import * as types from './types';

const INITIAL_STATE = {
  status: false,
  statusExercise: false,
  exercise: {},
  practiceExercise: [],
  statusSubmit: false,
  resultExercise: [],
  totalScore: 0,
  totalQuestion: 0,
  totalScoreApi: 0,
  isShowPagePracticeExercise: false,
  isFetching: false,
  idExercise: 0,
  resultDataSubmitNewTest: {},
  questions: {},
  hasTime: false,
  isFetchingPractice: false,
  isReloadPageWhenPracticeExam: false,
  isPrimary: false,
  isTotal: false,
  answers: {}
};

export default (state = INITIAL_STATE, action) => {
  switch (action.type) {
    case types.EXERCISE_FETCHING:
      return {
        ...state,
        isFetching: true,
        isShowPagePracticeExercise: false,
        totalScore: 0,
        resultExercise: []
      };
    case types.EXERCISE_SUCCESS:
      return {
        ...state,
        status: action.payload.status,
        exercise: action.payload.exercise,
        isShowPagePracticeExercise: false,
        totalScore: 0,
        isFetching: false,
        isTotal: false
      };
    case types.EXERCISE_FAIL:
      return {
        ...state,
        isFetching: false,
        isShowPagePracticeExercise: false,
        totalScore: 0
      };
    case types.PRACTICE_EXERCISE_FETCHING:
      return {
        ...state,
        totalScore: 0,
        isFetching: true,
        isFetchingPractice: false
      };
    case types.PRACTICE_EXERCISE_SUCCESS:
      return {
        ...state,
        statusExercise: action.payload.status,
        practiceExercise: action.payload.practiceExercise,
        totalQuestion: action.payload.totalQuestion,
        totalScoreApi: action.payload.totalScoreApi,
        totalScore: 0,
        isFetching: false,
        questions: {},
        isFetchingPractice: true
      };
    case types.PRACTICE_EXERCISE_FAIL:
      return {
        ...state,
        totalScore: 0,
        isFetching: false,
        isFetchingPractice: true
      };
    case types.CHANGE_ACTIVE_ANSWER_EXERCISE_SUCCESS:
      return {
        ...state,
        statusExercise: action.payload.statusExercise,
        practiceExercise: action.payload.practiceExercise,
        totalScore: action.payload.totalScore,
        questions: action.payload.questions,
        answers: action.payload.answers
      };
    case types.SUBMIT_PRACTICE_EXERCISE_FETCHING:
      return {
        ...state,
        statusSubmit: action.payload.statusSubmit
      };
    case types.SUBMIT_PRACTICE_EXERCISE_SUCCESS:
      return {
        ...state,
        statusExercise: action.payload.status,
        resultExercise: action.payload.resultExercise,
        statusSubmit: action.payload.statusSubmit,
        exercise: action.payload.exercise
      };
    case types.HIDE_PAGE_PRACTICE_EXERCISE:
      return {
        ...state,
        isShowPagePracticeExercise: false
      };
    case types.SHOW_PAGE_PRACTICE_EXERCISE:
      return {
        ...state,
        isShowPagePracticeExercise: true
      };
    case types.REMAKE_DATA_PRACTICE:
      return {
        ...state,
        practiceExercise: {}
      };
    case types.SAVE_ID_EXERCISE:
      return {
        ...state,
        idExercise: action.payload.idExercise
      };
    case types.SUBMIT_NEW_TEST_PRACTICE_SUCCESS:
      return {
        ...state,
        isFetching: false,
        resultDataSubmitNewTest: action.payload.resultDataSubmitNewTest
      };
    case types.SUBMIT_NEW_TEST_PRACTICE_FETCHING:
      return {
        ...state,
        isFetching: true
      };
    case types.SUBMIT_NEW_TEST_PRACTICE_FAIL:
      return {
        ...state,
        isFetching: true
      };
    case types.REMOVE_RESULT_EXERCISE: {
      return {
        ...state,
        resultExercise: []
      };
    }
    case types.HAS_TIME_EXAM: {
      return {
        ...state,
        hasTime: true
      };
    }
    case types.HAS_NOT_TIME_EXAM: {
      return {
        ...state,
        hasTime: false
      };
    }
    case types.CHANGE_IS_FETCHING_FALSE: {
      return {
        ...state,
        isReloadPageWhenPracticeExam: false
      };
    }
    case types.CHANGE_IS_FETCHING_TRUE: {
      return {
        ...state,
        isReloadPageWhenPracticeExam: true
      };
    }
    case types.IS_BUTTON_TOTAL: {
      return {
        ...state,
        isTotal: action.payload.isTotal
      };
    }
    default:
      return state;
  }
};
