/*
 * @Author: loc.do@bstarsolutions.com
 * @Date: 2019-06-06 15:35:08
 * @Last Modified by: loc.do@bstarsolutions.com
 * @Last Modified time: 2019-06-06 15:35:29
 */

import reducers from './reducers';
import * as ModalVocabularyDetailActions from './actions';
import * as types from './types';

export { default as selectors } from './selectors';

export { ModalVocabularyDetailActions, types };

export default reducers;
