/*
 * @Author: loc.do@bstarsolutions.com
 * @Date: 2019-05-08 16:55:30
 * @Last Modified by: loc.do@bstarsolutions.com
 * @Last Modified time: 2019-05-15 14:52:13
 */
import React, { useEffect } from 'react';
import injectSheet from 'react-jss';
import c from 'classnames';

import { images } from 'appCore';
import styles from './styles';

function Slide(props) {
  const { classes, imageBanner, children, idSlide, className } = props;

  let slideIndex = 1;

  const slidingBanner = n => {
    const x = document.querySelectorAll(`#${idSlide}`);

    let i = 0;

    if (n > x.length) {
      slideIndex = 1;
    }
    if (n < 1) {
      slideIndex = x.length;
    }
    for (i; i < x.length; i += 1) {
      x[i].style.display = 'none';
    }
    if (x !== undefined && x.length !== 0) {
      x[slideIndex - 1].style.display = 'flex';
    }
  };

  const autoSlide = n => {
    slidingBanner((slideIndex += n));
    setInterval(() => {
      slidingBanner((slideIndex += n));
    }, 5000);
  };

  useEffect(() => {
    autoSlide(1);
  });

  return (
    <div
      id={idSlide}
      style={{ backgroundImage: `url(${imageBanner})` }}
      className={c(classes.slideBannerContainer, className)}
    >
      <div className={classes.loading} />
      {children}
    </div>
  );
}

Slide.defaultProps = {
  imageBanner: images.banner
};

export default injectSheet(styles)(Slide);
