import { createStructuredSelector } from 'reselect';

const email = state => state.signUp.email;
const isFetching = state => state.signUp.isFetching;
const already = state => state.signUp.already;
const user = state => state.login.user;
const currentLang = state => state.app.currentLang;
const isShowMenu = state => state.header.isShowMenu;
export default createStructuredSelector({
  user,
  email,
  isFetching,
  already,
  currentLang,
  isShowMenu
});
