import { theme } from 'appCore';

export default {
  block: {
    padding: '0 44px 0 48px'
  },
  top: {
    width: '100%',
    height: 100,
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center'
  },
  title: {
    fontSize: theme.fonts.font20,
    fontWeight: 'bold',
    color: theme.colors.primary
  },
  icon: {
    width: 29,
    height: 29
  },
  sub: {
    animation: 'sub .5s linear'
  },
  plus: {
    animation: 'plus .5s linear'
  },
  bottom: {
    width: '100%',
    fontSize: theme.fonts.font20,
    lineHeight: '35px',
    overflow: 'hidden',
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'flex-start'
  },
  content: {
    transition: 'all 1s',
    flex: 0,
    maxHeight: 0
  },
  open: {
    flex: 1,
    maxHeight: 'unset',
    marginBottom: 28
  },
  '@keyframes sub': {
    '0%': {
      transform: 'rotate(90deg)'
    },
    '100%': {
      transform: 'rotate(0deg)'
    }
  },
  '@keyframes plus': {
    '0%': {
      transform: 'rotate(-90deg)'
    },
    '100%': {
      transform: 'rotate(0deg)'
    }
  }
};
