import React from 'react';
import injectSheet from 'react-jss';
import { constants, translator } from 'utils';
import { images } from 'appCore';
import GoogleLogin from 'react-google-login';
import FacebookLogin from 'react-facebook-login';
import { Divider } from 'components';
import styles from './styles';

const SocialLogin = props => {
  const { classes, strings, loginSocial } = props;
  const lang = strings.login;

  const responseGoogle = response => {
    const provider = constants.GOOGLE_AUTH_KEYS.PROVIDER;
    if (response && response.getAuthResponse().access_token) {
      const token = response.getAuthResponse().access_token;
      loginSocial(provider, token);
    }
  };

  const responseFacebook = response => {
    const provider = constants.FACEBOOK_AUTH_KEYS.PROVIDER;
    if (response.email) {
      const token = response.accessToken;
      loginSocial(provider, token);
    }
  };

  return (
    <div>
      <Divider className={classes.divider} />
      <div className={classes.loginNowWithWrapper}>
        <div className={classes.loginNowWith}>{lang.loginNowWIth}</div>
        <div className={classes.socialGroup}>
          <GoogleLogin
            clientId={constants.GOOGLE_AUTH_KEYS.APP_ID}
            onSuccess={responseGoogle}
            onFailure={responseGoogle}
            className={classes.btnSocial}
            cookiePolicy="single_host_origin"
            icon={false}
            buttonText=""
          >
            <img className={classes.imgGoogle} src={images.googleLogin} alt="Google Login" />
          </GoogleLogin>
          <FacebookLogin
            appId={constants.FACEBOOK_AUTH_KEYS.APP_ID}
            autoLoad
            fields="name,email,picture"
            callback={responseFacebook}
            reauthenticate
            textButton=""
            size="medium"
            icon="fa fa-facebook"
            cssClass={`${classes.btnSocial} ${classes.btnSocialFacebok}`}
          />
        </div>
      </div>
    </div>
  );
};

export default injectSheet(styles)(translator(SocialLogin));
