/*
 * @Author: loc.do@bstarsolutions.com
 * @Date: 2019-05-10 10:45:42
 * @Last Modified by: loc.do@bstarsolutions.com
 * @Last Modified time: 2019-10-22 11:30:08
 */

import { put, takeLatest, select } from 'redux-saga/effects';

import { CoursesAPI } from 'services';

import { COURSE_FETCHING, COURSE_SUCCESS, COURSE_FAIL, COURSE_INIT } from './types';

const tokenSelector = state => (state.login.user ? state.login.user.access_token : '');

function* getCoursesAPI() {
  const token = yield select(tokenSelector);
  try {
    yield put({ type: COURSE_FETCHING });
    let rawData;
    if (token) {
      rawData = yield CoursesAPI.getCourses();
    } else {
      rawData = yield CoursesAPI.getCoursesNotAuthen();
    }

    yield put({
      type: COURSE_SUCCESS,
      payload: {
        status: rawData.data.success,
        courses: rawData.data.data
      }
    });
  } catch (e) {
    yield put({ type: COURSE_FAIL });
  }
}

export const getCourses = takeLatest(COURSE_INIT, getCoursesAPI);

export default this;
