/*
 * @Author: loc.do@bstarsolutions.com
 * @Date: 2019-05-16 16:16:04
 * @Last Modified by: loc.do@bstarsolutions.com
 * @Last Modified time: 2019-08-13 11:00:42
 */

import React from 'react';

import { Button } from 'components';
import injectSheet from 'react-jss';
import cla from 'classnames';

import styles from './styles';

function ModalContent(props) {
  const {
    classes,
    title,
    description,
    isButton,
    btnLabelModal,
    children,
    isButtonExtend,
    btnLabelModalExtend,
    classDescription,
    classBorder,
    onClickDefault,
    onClickExtend,
    classButtonDefault,
    isLoading
  } = props;

  return (
    <div className={classes.container}>
      <span className={classes.title}>{title}</span>
      <div className={cla(classBorder, classes.border)} />
      {description && (
        <span className={cla(classDescription, classes.description)}> {description}</span>
      )}
      {children && children}
      <div className={classes.btnGroup}>
        {isButton && (
          <Button
            className={cla(classButtonDefault)}
            onClick={onClickDefault}
            isLoading={isLoading}
            label={btnLabelModal}
          />
        )}
        {isButtonExtend && <Button onClick={onClickExtend} label={btnLabelModalExtend} />}
      </div>
    </div>
  );
}

ModalContent.defaultProps = {
  title: `Title`,
  isButton: true,
  btnLabelModal: 'Mua ngay'
};

export default injectSheet(styles)(ModalContent);
