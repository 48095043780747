import { put, takeLatest, select, takeMaybe } from 'redux-saga/effects';
import { ExamsAPI } from 'services';
import * as types from './type';

const userToken = state => (state.login.user ? state.login.user.access_token : '');

function* getExamesAPI() {
  const token = yield select(userToken);
  try {
    yield put({ type: types.EXAM_FETCHING });
    let rawData;
    if (token) {
      rawData = yield ExamsAPI.getExams();
    } else {
      rawData = yield ExamsAPI.getExamsNotAuthen();
    }
    yield put({
      type: types.EXAM_SUCCESS,
      payload: {
        status: rawData.data.success,
        exams: rawData.data.data
      }
    });
  } catch (e) {
    yield put({ type: types.EXAM_FAIL });
  }
}

function* getLevelsAPI() {
  try {
    yield put({ type: types.LEVEL_FETCHING });
    const rawData = yield ExamsAPI.getLevelsNotAuthen();
    yield put({
      type: types.LEVEL_SUCCESS,
      payload: {
        status: rawData.data.success,
        levels: rawData.data.data
      }
    });
  } catch (e) {
    yield put({ type: types.LEVEL_FAIL });
  }
}

function* selectValueExam(action) {
  const value = action.payload;
  try {
    yield put({
      type: types.SELECT_VALUE_EXAM,
      valueExam: value
    });
  } catch (e) {
    yield put({
      type: types.SELECT_VALUE_EXAM_FAIL
    });
  }
}

export const getExamsSaga = takeLatest(types.EXAM_INIT, getExamesAPI);
export const getLevelSaga = takeLatest(types.LEVEL_INIT, getLevelsAPI);
export const selectValueExamSaga = takeMaybe(selectValueExam);
export default this;
