import React from 'react';
import PropTypes from 'prop-types';
import c from 'classnames';
import injectSheet from 'react-jss';
import { Link } from 'react-router-dom';
import { Block } from 'components';
import { images } from 'appCore';
import styles from './styles';

const LeftMenu = ({ classes, list, active, onClickItem, language, className }) => {
  return (
    <Block className={c(classes.block, className)}>
      {list.map((e, i) => (
        <Link
          key={i}
          className={c(
            classes.item,
            { [classes.active]: active === i },
            { [classes.last]: list.length - 1 === i }
          )}
          to={e.to}
          onClick={onClickItem ? () => onClickItem(e) : null}
        >
          <img
            className={classes.icon}
            src={active === i ? images.rightArrowPrimary : images.rightArrow}
            alt="arrow"
          />
          <span>
            {language && e.translations && e.translations[language]
              ? e.translations[language].name
              : e.label}
          </span>
        </Link>
      ))}
    </Block>
  );
};

LeftMenu.propTypes = {
  list: PropTypes.arrayOf(Object)
};

LeftMenu.defaultProps = {
  list: [{ label: 'item1', to: 'aaa' }, { label: 'item2', to: '' }]
};

export default injectSheet(styles)(LeftMenu);
