/*
 * @Author: loc.do@bstarsolutions.com
 * @Date: 2019-08-06 09:29:27
 * @Last Modified by: loc.do@bstarsolutions.com
 * @Last Modified time: 2019-09-09 16:47:13
 */

import { theme } from 'appCore';
import { scaleStyle } from 'utils';

export default {
  button: {
    position: 'relative',
    display: 'flex',
    background: theme.colors.primary,
    color: theme.colors.white,
    fontWeight: 'bold',
    textTransform: 'uppercase',
    alignItems: 'center',
    outline: 'none',
    cursor: 'pointer',
    fontFamily: 'Open Sans',
    transition: 'all 0.5s',
    border: `1px solid ${theme.colors.white}`,
    whiteSpace: 'nowrap',
    '&:hover': {
      background: theme.colors.white,
      color: theme.colors.black,
      border: `1px solid ${theme.colors.lightgray}`
    },
    '&:active': {
      background: theme.colors.black,
      color: theme.colors.white
    },
    '& > span': {
      width: '100%'
    },
    padding: '0 25px',
    ...scaleStyle({
      minHeight: 60,
      borderRadius: 70,
      fontSize: 20,
      lineHeight: '60px'
    })
  },
  big: {
    background: theme.colors.backgroundGray,
    color: theme.colors.middlegray,
    justifyContent: 'center',
    border: 'solid 1px lightgray',
    ...scaleStyle({
      minHeight: 72.5,
      fontSize: 14,
      width: 217
    })
  },
  small: {
    textTransform: 'unset',
    fontWeight: 'unset',
    ...scaleStyle({
      minHeight: 40,
      fontSize: 15,
      lineHeight: '40px'
    })
  },
  secondary: {
    background: theme.colors.secondary,
    color: theme.colors.black
  },
  third: {
    background: theme.colors.gray,
    color: theme.colors.white
  },
  fourth: {
    background: theme.colors.white,
    color: theme.colors.gray,
    border: `1px solid ${theme.colors.gray}`
  },
  facebook: {
    background: theme.colors.facebook,
    color: theme.colors.white,
    border: `1px solid ${theme.colors.facebook}`,
    '&:hover': {
      background: '#234484',
      color: theme.colors.white
    }
  },
  googlePlus: {
    background: theme.colors.googlePlus,
    color: theme.colors.white,
    border: `1px solid ${theme.colors.googlePlus}`,
    '&:hover': {
      background: '#c12f1f',
      color: theme.colors.white
    }
  },
  twitter: {
    background: theme.colors.twitter,
    color: theme.colors.white,
    border: `1px solid ${theme.colors.twitter}`,
    '&:hover': {
      background: '#348dd1',
      color: theme.colors.white
    }
  },
  icon: {
    ...scaleStyle({
      width: 32
      // height: 32
    })
  },
  label: {
    marginLeft: 10
  },
  loading: {
    paddingRight: 40,
    opacity: 0.5,
    pointerEvents: 'none',
    '&:before': {
      content: '""',
      position: 'absolute',
      right: 10,
      top: '50%',
      marginTop: '-10px',
      width: 12,
      height: 12,
      border: '4px solid',
      borderLeftColor: 'transparent',
      borderRadius: '50%',
      animation: 'rotate360 .5s infinite linear'
    }
  },

  '@keyframes rotate360': {
    '100%': {
      transform: 'rotate(360deg)'
    }
  },
  disable: {
    cursor: 'default',
    background: theme.colors.gray,
    pointerEvents: 'none'
  },
  hidden: {
    cursor: 'default',
    opacity: 0,
    pointerEvents: 'none'
  },
  [`@media screen and (max-width: ${theme.screens.maxWidth800}px)`]: {}
};
