/*
 * @Author: loc.do@bstarsolutions.com
 * @Date: 2019-05-03 11:25:25
 * @Last Modified by: loc.do@bstarsolutions.com
 * @Last Modified time: 2019-06-28 10:40:19
 */

import React from 'react';
import injectSheet from 'react-jss';
import c from 'classnames';
import PropTypes from 'prop-types';

// Import Styles
import styles from './styles';

function LinkContact(props) {
  const { classes, nameContact, href, children, className } = props;

  return (
    <a className={c(className, classes.linkContact)} href={href}>
      {children}
      <span>{nameContact}</span>
    </a>
  );
}

LinkContact.propTypes = {
  href: PropTypes.string.isRequired,
  nameContact: PropTypes.string.isRequired
};

export default injectSheet(styles)(LinkContact);
