import * as types from './types';

const INITIAL_STATE = {
  error: '',
  isFetching: false,
  status: false
};

export default (state = INITIAL_STATE, action) => {
  switch (action.type) {
    case types.FORGOT_PASSWORD_SUCCESS:
      return {
        ...state,
        status: action.payload.status,
        isFetching: false
      };
    case types.FORGOT_PASSWORD_FETCHING:
      return {
        ...state,
        isFetching: true,
        error: ''
      };
    case types.FORGOT_PASSWORD_FAILURE:
      return {
        ...state,
        error: action.payload,
        isFetching: false
      };
    default:
      return state;
  }
};
