/*
 * @Author: loc.do@bstarsolutions.com
 * @Date: 2019-08-02 16:06:54
 * @Last Modified by: Do Loc - doxuanloc2016@gmail.com
 * @Last Modified time: 2019-08-12 06:52:18
 */
import * as types from './types';

export const submitRegisterEnterprise = (typeForm, name, email, phone, course, numOfEmployee) => ({
  type: types.POST_FORM_REGISTER_ENTERPRISE_INIT,
  payload: {
    typeForm,
    name,
    email,
    phone,
    course,
    numOfEmployee
  }
});

export const getListCourseEnterprise = () => ({
  type: types.GET_LIST_COURSE_ENTERPRISE_INIT
});

export default this;
