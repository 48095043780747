/*
 * @Author: loc.do@bstarsolutions.com
 * @Date: 2019-05-03 09:32:20
 * @Last Modified by: loc.do@bstarsolutions.com
 * @Last Modified time: 2019-08-29 16:28:18
 */
import React from 'react';
import PropTypes from 'prop-types';
import c from 'classnames';
import injectSheet from 'react-jss';
import styles from './styles';

function TextInput(props) {
  const {
    className,
    classes,
    value,
    icon,
    type,
    onChange,
    placeholder,
    error,
    onKeyPress,
    onKeyDown,
    classNameInput
  } = props;

  return (
    <div className={c(classes.textinput, className, { [classes.error]: error })}>
      {icon && (
        <div className={classes.left}>
          <img className={classes.icon} src={icon} alt="icon" />
        </div>
      )}
      <div className={classes.right}>
        <input
          onKeyPress={onKeyPress}
          onKeyDown={onKeyDown}
          className={c(
            classes.input,
            classNameInput,
            { [classes.noIcon]: !icon },
            { [classes.errorInput]: error }
          )}
          type={type}
          onChange={onChange}
          value={value || ''}
          placeholder={placeholder}
          autoComplete="new-password"
        />
      </div>
    </div>
  );
}

TextInput.propTypes = {
  type: PropTypes.string,
  icon: PropTypes.string,
  placeholder: PropTypes.string,
  onChange: PropTypes.func
};

TextInput.defaultProps = {
  type: 'text',
  icon: '',
  placeholder: '',
  onChange: () => {}
};

export default injectSheet(styles)(TextInput);
