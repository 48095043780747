/*
 * @Author: loc.do@bstarsolutions.com
 * @Date: 2019-06-25 10:48:54
 * @Last Modified by: loc.do@bstarsolutions.com
 * @Last Modified time: 2019-07-08 10:35:24
 */

import * as types from './types';

const INITIAL_STATE = {
  status: false,
  myInformation: [],
  isFetching: false,
  avatarDefault: [],
  myExams: [],
  myExamDetail: [],
  isShowDetailMyExam: false,
  isShowDetailMyExamSection: false,
  myCourses: [],
  isShowMyCourseDetail: false,
  myCourseDetail: [],
  myCoins: [],
  resultUpdatePassword: {},
  courseId: 0,
  courseName: '',
  myAvatarDefault: '',
  myPositionAvatarDefault: -1,
  medthodCreditArr: []
};

export default (state = INITIAL_STATE, action) => {
  switch (action.type) {
    case types.GET_INFOMATION_USER_SUCCESS:
      return {
        ...state,
        status: action.payload.status,
        myInformation: action.payload.myInformation,
        isFetching: false
      };
    case types.UPDATE_INFOMATION_USER_FETCHING:
      return {
        ...state,
        isFetching: true
      };
    case types.UPDATE_INFOMATION_USER_SUCCESS:
      return {
        ...state,
        status: true,
        isFetching: false,
        myInformation: action.payload.myInformation
      };
    case types.UPDATE_INFOMATION_USER_FAIL:
      return {
        ...state,
        status: true,
        isFetching: false
      };
    case types.GET_AVATAR_DEFAULT_SUCCESS:
      return {
        ...state,
        status: true,
        isFetching: false,
        avatarDefault: action.payload.avatarDefault
      };
    case types.GET_AVATAR_DEFAULT_FETCHING:
      return {
        ...state,
        isFetching: true
      };
    case types.GET_AVATAR_DEFAULT_FAIL:
      return {
        ...state,
        isFetching: true
      };
    case types.GET_MY_EXAM_SUCCESS:
      return {
        ...state,
        status: true,
        isFetching: false,
        myExams: action.payload.myExams
      };
    case types.GET_MY_EXAM_FETCHING:
      return {
        ...state,
        status: false,
        isFetching: true
      };
    case types.GET_MY_EXAM_FAIL:
      return {
        ...state,
        status: false,
        isFetching: true
      };
    case types.SHOW_DETAIL_MY_EXAM:
      return {
        ...state,
        isShowDetailMyExam: true
      };
    case types.HIDE_DETAIL_MY_EXAM:
      return {
        ...state,
        isShowDetailMyExam: false
      };
    case types.GET_MY_EXAM_DETAIL_SUCCESS:
      return {
        ...state,
        status: true,
        isFetching: false,
        myExamDetail: action.payload.myExamDetail
      };
    case types.GET_MY_EXAM_DETAIL_FETCHING:
      return {
        ...state,
        status: false,
        isFetching: true
      };
    case types.GET_MY_EXAM_DETAIL_FAIL:
      return {
        ...state,
        status: false,
        isFetching: true
      };
    case types.SHOW_DETAIL_MY_EXAM_SECTION:
      return {
        ...state,
        isShowDetailMyExamSection: true
      };
    case types.HIDE_DETAIL_MY_EXAM_SECTION:
      return {
        ...state,
        isShowDetailMyExamSection: false
      };
    case types.GET_MY_COURSE_SUCCESS:
      return {
        ...state,
        status: true,
        isFetching: false,
        myCourses: action.payload.myCourses
      };
    case types.GET_MY_COURSE_FETCHING:
      return {
        ...state,
        status: false,
        isFetching: true
      };
    case types.GET_MY_COURSE_FAIL:
      return {
        ...state,
        status: false,
        isFetching: true
      };
    case types.SHOW_DETAIL_MY_COURSE_DETAIL:
      return {
        ...state,
        isShowMyCourseDetail: true
      };
    case types.HIDE_DETAIL_MY_COURSE_DETAIL:
      return {
        ...state,
        isShowMyCourseDetail: false
      };
    case types.GET_MY_COURSE_DETAIL_SUCCESS:
      return {
        ...state,
        status: true,
        isFetching: false,
        myCourseDetail: action.payload.myCourseDetail
      };
    case types.GET_MY_COURSE_DETAIL_FAIL:
      return {
        ...state,
        status: false,
        isFetching: true
      };
    case types.GET_MY_COURSE_DETAIL_FETCHING:
      return {
        ...state,
        status: false,
        isFetching: true
      };
    case types.GET_MY_COIN_SUCCESS:
      return {
        ...state,
        status: true,
        isFetching: false,
        myCoins: action.payload.myCoins
      };
    case types.GET_MY_COIN_FETCHING:
      return {
        ...state,
        status: false,
        isFetching: true
      };
    case types.GET_MY_COIN_FAIL:
      return {
        ...state,
        status: false,
        isFetching: true
      };
    case types.GET_MY_METHOD_CREDIT_SUCCESS:
      return {
        ...state,
        status: true,
        isFetching: false,
        medthodCreditArr: action.payload.medthodCreditArr
      };
    case types.GET_MY_METHOD_CREDIT_FETCHING:
      return {
        ...state,
        status: false,
        isFetching: true
      };
    case types.GET_MY_METHOD_CREDIT_FAIL:
      return {
        ...state,
        status: false,
        isFetching: true
      };
    case types.UPDATE_NEW_PASSWORD_SUCCESS:
      return {
        ...state,
        status: true,
        isFetching: false,
        resultUpdatePassword: action.payload.resultUpdatePassword
      };
    case types.UPDATE_NEW_PASSWORD_FETCHING:
      return {
        ...state,
        status: false,
        isFetching: true
      };
    case types.UPDATE_NEW_PASSWORD_FAIL:
      return {
        ...state,
        status: false,
        isFetching: true
      };
    case types.SAVE_MY_COURSE_ID:
      return {
        ...state,
        courseId: action.payload.courseId,
        courseName: action.payload.courseName
      };
    case types.SAVE_AVATAR_DEFAULT:
      return {
        ...state,
        myAvatarDefault: action.payload.myAvatarDefault
      };
    case types.REMOVE_AVATAR_DEFAULT:
      return {
        ...state,
        myAvatarDefault: ''
      };
    case types.SAVE_POSITION_AVATAR_DEFAULT:
      return {
        ...state,
        myPositionAvatarDefault: action.payload.myPositionAvatarDefault
      };
    case types.REMOVE_POSITION_AVATAR_DEFAULT:
      return {
        ...state,
        myPositionAvatarDefault: -1
      };
    default:
      return state;
  }
};
