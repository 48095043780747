/*
 * @Author: loc.do@bstarsolutions.com
 * @Date: 2019-06-25 10:39:35
 * @Last Modified by: loc.do@bstarsolutions.com
 * @Last Modified time: 2019-08-09 14:07:42
 */

import { constants } from 'utils';
import axiosInstance from './interceptor';

const Profile = {
  getInformationUserAPI: () => axiosInstance.get(`${constants.API}/auth/user`),
  updateInformationUserAPI: formData =>
    axiosInstance
      .post(`${constants.API}/auth/user`, formData)
      .then(res => res)
      .catch(e =>
        e.response && (e.response.status === 401 || e.response.status === 422) && e.response.data
          ? e.response.data
          : new Error(e)
      ),
  getAvatarDefaultAPI: () => axiosInstance.get(`${constants.API}/user/default-avatar`),
  getMyExamsAPI: () => axiosInstance.get(`${constants.API}/user/my-tests`),
  getMyExamDetailAPI: idExamSection =>
    axiosInstance.get(`${constants.API}/user/my-test-detail/${idExamSection}`),
  getMyCoursesAPI: () => axiosInstance.get(`${constants.API}/user/my-courses`),
  getMyCourseDetailAPI: idCourse =>
    axiosInstance.get(`${constants.API}/user/my-course-detail/${idCourse}`),
  getMyCoinsAPI: () => axiosInstance.get(`${constants.API}/order/user-transaction`),
  updatePasswordUserAPI: formData =>
    axiosInstance.post(`${constants.API}/auth/update-password`, formData),
  getMethodCreditsAPI: () => axiosInstance.get(`${constants.API}/reward-rule`)
};

export default Profile;
