/*
 * @Author: Do Loc - doxuanloc2016@gmail.com
 * @Date: 2019-06-15 21:07:06
 * @Last Modified by: Do Loc - doxuanloc2016@gmail.com
 * @Last Modified time: 2019-06-16 08:37:25
 */

export default {
  img: {
    background: '#F1F1FA',
    width: 400,
    height: 300,
    display: 'block',
    margin: '10px auto',
    border: 0
  }
};
