import { theme } from 'appCore';
import { scaleStyle } from 'utils';

export default {
  divider: {
    height: 2,
    ...scaleStyle({
      marginTop: 58
    })
  },
  loginNowWithWrapper: {
    display: 'flex',
    flexDirection: 'column'
  },
  loginNowWith: {
    fontWeight: 'bold',
    textAlign: 'center',
    color: theme.colors.primary,
    textTransform: 'capitalize',
    ...scaleStyle({
      fontSize: 20,
      marginBottom: 40,
      marginTop: 40
    })
  },
  socialGroup: {
    margin: 'auto',
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'center',
    width: '100%',
    ...scaleStyle({
      marginBottom: 50.5,
      height: 100
    })
  },
  btnSocial: {
    flexDirection: 'column !important',
    justifyContent: 'center !important',
    display: 'flex !important',
    alignItems: 'center !important',
    borderRadius: '50px !important',
    border: '1px solid #f37021 !important',
    backgroundColor: 'rgb(255, 255, 255)',
    ...scaleStyle({
      marginRight: 20,
      width: 60,
      height: 60
    }),
    boxShadow: 'none',
    outline: 'none',
    cursor: 'pointer',
    '&:hover': {
      opcity: '0,5'
    }
  },

  imgGoogle: {
    alignContent: 'center',
    ...scaleStyle({
      maxWidth: 'none!important',
      height: 40
    }),
    paddingTop: `3px`
  },
  btnSocialFacebok: {
    marginLeft: 20,
    '& > i': {
      ...scaleStyle({
        fontSize: 40
      }),
      fontWeight: 'bold',
      color: 'rgb(59, 88, 156)'
    }
  },

  btnSocialLogin: {}
};
