import { all } from 'redux-saga/effects';
import {
  getLanguageSaga,
  getListMenuInSaga,
  getListConfigurationInSaga
} from 'containers/App/duck/saga';
import { getCourses } from 'containers/Courses/duck/saga';
import { getCourseChild, getCourseDetail } from 'containers/Courses/CourseChild/duck/saga';
import { getLessonInCourse } from 'containers/Courses/CourseChild/LessonInCourse/duck/saga';
import {
  getLessonBySkillSaga,
  remakeLeftMenuSaga,
  getSubtileSaga,
  postSpeechToTextSaga,
  removeResultSpeechToTextSaga,
  getKanjiDetailSaga
} from 'containers/LessonBySkill/duck/saga';
import { getExamsSaga, getLevelSaga, selectValueExamSaga } from 'containers/Exams/duck/saga';
import { getExamChildsSaga, getExamDetailsSaga } from 'containers/Exams/ExamChild/duck/saga';
import {
  getExerciseSaga,
  getPracticeExerciseSaga,
  changeActiveAnswerPracticesSaga,
  postSubmitPracticeExerciseSaga,
  showPagePracticeExerciseSaga,
  hidePagePracticeExerciseSaga,
  saveIdExerciseSaga,
  submitNewTestPracticeSaga,
  isButtonTotalInSagaReducer
} from 'containers/Exercise/duck/saga';
import { loginSaga, autoLoginSaga, loginSocialSaga } from 'containers/Login/duck/saga';
import { signUpSaga, signUpActiveSaga } from 'containers/SignUp/duck/saga';
import { forgotPasswordSaga } from 'containers/ForgotPassword/duck/saga';
import { onChangeActiveAnswer, onSubmitPractice } from 'containers/Practice/duck/saga';
import { hideModalSage } from 'containers/ModalManagement/duck/saga';
import {
  disableMicSaga,
  enableMicSaga,
  disableVolumeSaga,
  enableVolumeSaga
} from 'containers/ModalVocabularyDetail/duck/saga';
import {
  getListCoinSaga,
  getCourseInShopSaga,
  getSkillInShopSaga,
  getSelectCourseInShopSaga,
  getSelectExamInShopSaga,
  filterCourseInShopSaga,
  getPaymentInfoSaga,
  showPaymentPageSaga,
  hidePaymentPageSaga,
  payTransferCoinOfflineSaga,
  filterExamInShopSaga,
  buyCourseInShopSaga,
  buyExamInShopSaga,
  buyClassInShopSaga,
  buyClassInShopExistInSaga,
  checkBoughtClassInSaga,
  getPaymentVNPayBuyCourseInSaga
} from 'containers/Shop/duck/saga';

import {
  getInformationUserSaga,
  updateInformationUserSaga,
  getAvatarDefaultSaga,
  getMyExamsSaga,
  showDetailMyExamSaga,
  hideDetailMyExamSaga,
  getMyExamDetailSaga,
  showDetailMyExamSectionSaga,
  hideDetailMyExamSectionSaga,
  getMyCoursesSaga,
  showDetailMyCourseDetailSaga,
  hideDetailMyCourseDetailSaga,
  getMyCourseDetailSaga,
  getMyCoinsSaga,
  getMethodCreditSaga,
  updatePasswordUserSaga,
  saveMyCourseIdSaga,
  saveAvatarDefaultSaga,
  removeAvatarDefaultDefault,
  savePositionAvatarDefaultSaga,
  removePositionAvatarDefaultWhenSuccessSaga
} from 'containers/Profile/duck/saga';

import {
  getArticlesSaga,
  getArticleDetailsSaga,
  applyToJobSaga,
  postSendFormContactInSaga
} from 'containers/Article/duck/saga';

import {
  getListSlideInSaga,
  getListGalleryInSaga,
  getListLecturersInSaga,
  getListCourseInHomeInSaga,
  registerBuyCourseInHomeInSaga,
  getListAllDataLandingPageInSaga
} from 'containers/Home/duck/saga';

import {
  submitRegisterEnterpriseInSaga,
  getListCourseEnterpriseInSaga
} from 'containers/Enterprise/duck/saga';

export default function* rootSaga() {
  yield all([
    loginSaga,
    getCourses,
    autoLoginSaga,
    loginSocialSaga,
    signUpSaga,
    signUpActiveSaga,
    forgotPasswordSaga,
    getCourseChild,
    getLessonInCourse,
    getCourseDetail,
    getLessonBySkillSaga,
    remakeLeftMenuSaga,
    getSubtileSaga,
    getLanguageSaga,
    onChangeActiveAnswer,
    onSubmitPractice,
    postSpeechToTextSaga,
    removeResultSpeechToTextSaga,
    hideModalSage,
    disableMicSaga,
    enableMicSaga,
    disableVolumeSaga,
    enableVolumeSaga,
    getListCoinSaga,
    getExamsSaga,
    getExamChildsSaga,
    getExamDetailsSaga,
    getLevelSaga,
    getExerciseSaga,
    getCourseInShopSaga,
    getSkillInShopSaga,
    getSelectCourseInShopSaga,
    getSelectExamInShopSaga,
    selectValueExamSaga,
    filterCourseInShopSaga,
    getPracticeExerciseSaga,
    changeActiveAnswerPracticesSaga,
    postSubmitPracticeExerciseSaga,
    showPagePracticeExerciseSaga,
    hidePagePracticeExerciseSaga,
    getPaymentInfoSaga,
    showPaymentPageSaga,
    hidePaymentPageSaga,
    payTransferCoinOfflineSaga,
    filterExamInShopSaga,
    buyCourseInShopSaga,
    buyExamInShopSaga,
    getInformationUserSaga,
    updateInformationUserSaga,
    getAvatarDefaultSaga,
    getMyExamsSaga,
    showDetailMyExamSaga,
    hideDetailMyExamSaga,
    getMyExamDetailSaga,
    showDetailMyExamSectionSaga,
    hideDetailMyExamSectionSaga,
    getMyCoursesSaga,
    showDetailMyCourseDetailSaga,
    hideDetailMyCourseDetailSaga,
    getMyCourseDetailSaga,
    getMyCoinsSaga,
    getMethodCreditSaga,
    updatePasswordUserSaga,
    saveMyCourseIdSaga,
    getKanjiDetailSaga,
    getArticlesSaga,
    getArticleDetailsSaga,
    saveAvatarDefaultSaga,
    removeAvatarDefaultDefault,
    savePositionAvatarDefaultSaga,
    removePositionAvatarDefaultWhenSuccessSaga,
    saveIdExerciseSaga,
    submitNewTestPracticeSaga,
    applyToJobSaga,
    isButtonTotalInSagaReducer,
    getListMenuInSaga,
    getListSlideInSaga,
    getListGalleryInSaga,
    buyClassInShopSaga,
    getListLecturersInSaga,
    submitRegisterEnterpriseInSaga,
    getListCourseEnterpriseInSaga,
    postSendFormContactInSaga,
    getListConfigurationInSaga,
    getListCourseInHomeInSaga,
    registerBuyCourseInHomeInSaga,
    buyClassInShopExistInSaga,
    getListAllDataLandingPageInSaga,
    checkBoughtClassInSaga,
    getPaymentVNPayBuyCourseInSaga
  ]);
}
