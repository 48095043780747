/*
 * @Author: loc.do@bstarsolutions.com
 * @Date: 2019-07-19 15:55:09
 * @Last Modified by: loc.do@bstarsolutions.com
 * @Last Modified time: 2019-07-22 10:17:20
 */

import React from 'react';
import ReactPaginate from 'react-paginate';

const Pagination = props => {
  const { totalPage, currentPage, onPageChange } = props;

  return (
    <ReactPaginate
      className="pagination"
      pageClassName="page-item"
      previousLinkClassName="page-link"
      nextLinkClassName="page-link"
      pageLinkClassName="page-link"
      activeLinkClassName="page-link-active"
      onPageChange={onPageChange}
      pageRangeDisplayed={5}
      pageCount={totalPage}
      previousLabel="<"
      nextLabel=">"
      previousClassName="page-previous"
      forcePage={currentPage - 1}
      disabledLinkClassName="page-link-disabled"
    />
  );
};

Pagination.defaultProps = {
  totalPage: 2,
  currentPage: 1
};

export default Pagination;
