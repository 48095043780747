const primary = '#f37021';
const secondary = '#ffde2f';
const colors = {
  white: '#FFF',
  red: 'red',
  black: '#000000',
  blackBlue: '#414042',
  primary,
  secondary,
  defaultButton: primary,
  orangeButton: primary,
  price: '#0062ff',
  link: '#0062ff',
  blue: '#0062ff',
  facebook: '#385795',
  googlePlus: '#d74837',
  twitter: '#53a8e9',
  green: '#067e59',
  gray: '#4e4e4e',
  grayb2: '#b2b2b2',
  gray87: '#dedede',
  gray172: '#acacac',
  grayLight: '#e8e8e8',
  grayBlue: '#ededed',
  grayDarkComment: '#a5a5a5',
  grayDark: '#5d5d5d',
  gray253: '#fdfdfd',
  gray203: '#cbcbcb',
  gray56: '#707070',
  lightgray: '#bababa',
  gray69: '#f4f4f4',
  middlegray: '#7F7F7F',
  middleGrayLight: '#f7f7f7',
  colorJob: '#808080',
  charcoalGrey: '#414042',
  backgroundGray: 'linear-gradient(to bottom, #fefefe, #eaeaea)',
  highlight: 'rgba(255, 222, 47, 0.6)',
  backgroundFooter: '#FAFAFA',
  divider: '#DDDDDD',
  backgroundModal: 'rgba(0, 0, 0, 0.81)',
  error: 'red'
};

const fonts = {
  default: `Open Sans, sans-serif`,
  saira: `Saira, sans-serif;`,
  montserrat: `'Montserrat', sans-serif;`
};

const screens = {
  maxWidthTablet: 1024,
  maxWidth: 1366,
  maxWidthMediumSmall: 1400,
  maxWidthMedium: 1440,
  maxWidthLargeMedium: 1600,
  maxWidthLarge: 1680,
  maxWidth900: 900,
  maxWidth800: 800,
  maxWidth635: 635
};

const font14 = {
  fontSize: 14,
  [`@media (min-width: ${screens.maxWidth}px) and (max-width: ${screens.maxWidthLargeMedium}px)`]: {
    fontSize: 9.8
  },
  [`@media  (max-width: ${screens.maxWidth}px)`]: {
    fontSize: 8.4
  }
};

const font15 = {
  fontSize: 15,
  [`@media (min-width: ${screens.maxWidth}px) and (max-width: ${screens.maxWidthLargeMedium}px)`]: {
    fontSize: 10.5
  },
  [`@media  (max-width: ${screens.maxWidth}px)`]: {
    fontSize: 9
  }
};

const font16 = {
  fontSize: 16,
  [`@media (min-width: ${screens.maxWidth}px) and (max-width: ${screens.maxWidthLargeMedium}px)`]: {
    fontSize: 11.2
  },
  [`@media  (max-width: ${screens.maxWidth}px)`]: {
    fontSize: 9.6
  }
};

const font20 = {
  fontSize: 20,
  [`@media (min-width: ${screens.maxWidth}px) and (max-width: ${screens.maxWidthLargeMedium}px)`]: {
    fontSize: 14
  },
  [`@media  (max-width: ${screens.maxWidth}px)`]: {
    fontSize: 12
  }
};

const font24 = {
  fontSize: 24,
  [`@media (min-width: ${screens.maxWidth}px) and (max-width: ${screens.maxWidthLargeMedium}px)`]: {
    fontSize: 14
  },
  [`@media  (max-width: ${screens.maxWidth}px)`]: {
    fontSize: 12
  }
};

const font25 = {
  fontSize: 25,
  [`@media (min-width: ${screens.maxWidth}px) and (max-width: ${screens.maxWidthLargeMedium}px)`]: {
    fontSize: 17.5
  },
  [`@media  (max-width: ${screens.maxWidth}px)`]: {
    fontSize: 15
  }
};

const font30 = {
  fontSize: 30,
  [`@media (min-width: ${screens.maxWidth}px) and (max-width: ${screens.maxWidthLargeMedium}px)`]: {
    fontSize: 21
  },
  [`@media  (max-width: ${screens.maxWidth}px)`]: {
    fontSize: 18
  }
};

const font40 = {
  fontSize: 40,
  [`@media (min-width: ${screens.maxWidth}px) and (max-width: ${screens.maxWidthLargeMedium}px)`]: {
    fontSize: 28
  },
  [`@media  (max-width: ${screens.maxWidth}px)`]: {
    fontSize: 24
  }
};

const font42 = {
  fontSize: 42,
  [`@media (min-width: ${screens.maxWidth}px) and (max-width: ${screens.maxWidthLargeMedium}px)`]: {
    fontSize: 16.8
  },
  [`@media  (max-width: ${screens.maxWidth}px)`]: {
    fontSize: 14.4
  }
};

const font65 = {
  fontSize: 65,
  [`@media (min-width: ${screens.maxWidth}px) and (max-width: ${screens.maxWidthLargeMedium}px)`]: {
    fontSize: 45.5
  },
  [`@media  (max-width: ${screens.maxWidth}px)`]: {
    fontSize: 39
  }
};

const imgStrength = {
  width: 863.5,
  height: 845,
  [`@media (min-width: ${screens.maxWidth}px) and (max-width: ${screens.maxWidthLargeMedium}px)`]: {
    width: 604.45,
    height: 591.5
  },
  [`@media  (max-width: ${screens.maxWidth}px)`]: {
    width: 518.1,
    height: 507
  }
};

const width217 = {
  width: 217,
  [`@media (min-width: ${screens.maxWidth}px) and (max-width: ${screens.maxWidthLargeMedium}px)`]: {
    width: 151.9
  },
  [`@media  (max-width: ${screens.maxWidth}px)`]: {
    width: 130.2
  }
};

const height65 = {
  height: 65,
  [`@media (min-width: ${screens.maxWidth}px) and (max-width: ${screens.maxWidthLargeMedium}px)`]: {
    height: 45.5
  },
  [`@media  (max-width: ${screens.maxWidth}px)`]: {
    height: 39
  }
};

const linkContact = {
  fontSize: 18,
  marginRight: 30.5,
  [`@media (min-width: ${screens.maxWidth}px) and (max-width: ${screens.maxWidthLargeMedium}px)`]: {
    fontSize: 12.6,
    marginRight: 21.35
  },
  [`@media  (max-width: ${screens.maxWidth}px)`]: {
    fontSize: 10.8,
    marginRight: 18.3
  }
};

const itemMenu = {
  fontSize: 20,
  lineHeight: `60px`,
  marginLeft: 35,
  [`@media (min-width: ${screens.maxWidth}px) and (max-width: ${screens.maxWidthLargeMedium}px)`]: {
    fontSize: 14,
    lineHeight: `42px`,
    marginLeft: 24.5
  },
  [`@media  (max-width: ${screens.maxWidth}px)`]: {
    fontSize: 12,
    lineHeight: `36px`,
    marginLeft: 21
  }
};

const imageCentre = {
  width: 474.8,
  height: 382.4,
  [`@media (min-width: ${screens.maxWidth}px) and (max-width: ${screens.maxWidthLargeMedium}px)`]: {
    width: 332.36,
    height: 267.68
  },
  [`@media  (max-width: ${screens.maxWidth}px)`]: {
    width: 332.36,
    height: 267.68
  }
};

const durationCourse = {
  right: 40,
  top: 20,
  width: 100,
  padding: 10,
  borderRadius: 10,
  fontSize: 20,
  [`@media (min-width: ${screens.maxWidth}px) and (max-width: ${screens.maxWidthLargeMedium}px)`]: {
    right: 28,
    top: 14,
    width: 70,
    padding: 7,
    borderRadius: 7,
    fontSize: 14
  },
  [`@media  (max-width: ${screens.maxWidth}px)`]: {
    right: 24,
    top: 12,
    width: 60,
    padding: 6,
    borderRadius: 6,
    fontSize: 12
  }
};

const itemPopup = {
  maxWidth: 340,
  minWidth: 150,
  lineHeight: `40px`,
  fontSize: 18,
  [`@media (min-width: ${screens.maxWidth}px) and (max-width: ${screens.maxWidthLargeMedium}px)`]: {
    maxWidth: 238,
    minWidth: 105,
    lineHeight: `28px`,
    fontSize: 12.6
  },
  [`@media  (max-width: ${screens.maxWidth}px)`]: {
    maxWidth: 204,
    minWidth: 90,
    lineHeight: `24px`,
    fontSize: 10.8
  }
};

const socialGroup = {
  width: 158.8,
  height: 60,
  marginBottom: 50.5,
  [`@media (min-width: ${screens.maxWidth}px) and (max-width: ${screens.maxWidthLargeMedium}px)`]: {
    width: 111.16,
    height: 42,
    marginBottom: 35.35
  },
  [`@media  (max-width: ${screens.maxWidth}px)`]: {
    width: 95.28,
    height: 36,
    marginBottom: 30.3
  }
};

const wrapper = {
  height: 178,
  maxWidth: 211,
  borderRadius: 40,
  [`@media (min-width: ${screens.maxWidth}px) and (max-width: ${screens.maxWidthLargeMedium}px)`]: {
    height: 124.6,
    maxWidth: 147.7,
    borderRadius: 28
  },
  [`@media  (max-width: ${screens.maxWidth}px)`]: {
    height: 106.8,
    maxWidth: 126.6,
    borderRadius: 24
  }
};

const vocabulary = {
  fontSize: 60,
  lineHeight: '60px',
  [`@media (min-width: ${screens.maxWidth}px) and (max-width: ${screens.maxWidthLargeMedium}px)`]: {
    fontSize: 42,
    lineHeight: '42px'
  },
  [`@media  (max-width: ${screens.maxWidth}px)`]: {
    fontSize: 36,
    lineHeight: '36px'
  }
};

const app = {
  height: 130,
  [`@media (min-width: ${screens.maxWidth}px) and (max-width: ${screens.maxWidthLargeMedium}px)`]: {
    height: 91
  },
  [`@media  (max-width: ${screens.maxWidth}px)`]: {
    height: 78
  }
};

export default {
  colors,
  fonts,
  screens,
  fontSizes: {
    font65,
    font42,
    font40,
    font30,
    font25,
    font24,
    font20,
    font16,
    font15,
    font14
  },
  imgs: {
    imgStrength,
    imageCentre,
    socialGroup
  },
  widths: {
    width217
  },
  heights: {
    height65
  },
  components: {
    linkContact,
    itemMenu,
    durationCourse,
    itemPopup,
    wrapper,
    vocabulary,
    app
  }
};
