/*
 * @Author: loc.do@bstarsolutions.com
 * @Date: 2019-06-26 11:39:03
 * @Last Modified by: loc.do@bstarsolutions.com
 * @Last Modified time: 2019-07-24 18:23:37
 */
import React from 'react';
import { put, takeEvery } from 'redux-saga/effects';
import { authAPIs } from 'services';
import axiosInstance from 'services/interceptor';
import { ModalCheckLogin } from 'components';
import { modalActions } from 'containers/ModalManagement/duck';
import { profileActions } from 'containers/Profile/duck';
import StorageFactory from 'services/storageFactory';
import * as types from './types';

const storage = StorageFactory.getStorage();

// console.log('tag', axiosInstance.defaults);
function* submitLogin(action) {
  const { payload } = action;
  try {
    yield put({ type: types.LOGIN_FETCHING });
    const rawData = yield authAPIs.login(payload);
    if (rawData.data && rawData.data.code === 200) {
      yield put({
        type: types.LOGIN_SUCCESS,
        payload: {
          status: rawData.data.success,
          user: rawData.data.data
        }
      });
      storage.setItem('access_token', rawData.data.data.access_token);
      storage.setItem('refresh_token', rawData.data.data.refresh_token);
      axiosInstance.defaults.headers.Authorization = `Bearer ${rawData.data.data.access_token}`;
    }
    if (rawData.data && rawData.data.code === 10) {
      const children = <ModalCheckLogin checkFailUserExist />;
      yield put(modalActions.showModal({ children }));
      yield put({
        type: types.LOGIN_FAILURE,
        payload: {
          status: false
        }
      });
    }
    if (rawData && rawData.code === 10) {
      const children = <ModalCheckLogin checkFailUserExist />;
      yield put(modalActions.showModal({ children }));
      yield put({
        type: types.LOGIN_FAILURE,
        payload: {
          status: false
        }
      });
    }
    if (rawData.errors && String(rawData.errors.password) === 'validation.required') {
      const children = <ModalCheckLogin checkPasswordNotEnter />;
      yield put(modalActions.showModal({ children }));
      yield put({
        type: types.LOGIN_FAILURE,
        payload: {
          status: false
        }
      });
    }
    if (rawData.errors && String(rawData.errors.email) === 'validation.required') {
      const children = <ModalCheckLogin checkEmailNotEnter />;
      yield put(modalActions.showModal({ children }));
      yield put({
        type: types.LOGIN_FAILURE,
        payload: {
          status: false
        }
      });
    }
    if (rawData.errors && String(rawData.errors.email) === 'validation.email') {
      const children = <ModalCheckLogin checkEmail />;
      yield put(modalActions.showModal({ children }));
      yield put({
        type: types.LOGIN_FAILURE,
        payload: {
          status: false
        }
      });
    }
  } catch (error) {
    yield put({
      type: types.LOGIN_FAILURE,
      payload: {
        status: false
      }
    });
  }
}

function* autoLogin(action) {
  const { payload } = action;
  try {
    yield put({ type: types.AUTO_LOGIN_FETCHING });
    const rawData = yield authAPIs.autoLogin(payload.token);
    if (rawData && rawData.data && rawData.data.data) {
      yield put({
        type: types.AUTO_LOGIN_SUCCESS,
        payload: {
          status: rawData.data.success,
          data: rawData.data.data,
          access_token_new: rawData.data.data.access_token
        }
      });
      storage.setItem('access_token', rawData.data.data.access_token);
      storage.setItem('refresh_token', rawData.data.data.refresh_token);
    }
  } catch (e) {
    yield put({
      type: types.AUTO_LOGIN_FAILURE,
      payload: {
        status: false,
        user: null
      }
    });
  }
}

function* submitLoginSocial(action) {
  const {
    payload: { provider, token }
  } = action;
  try {
    yield put({ type: types.LOGIN_SOCIAL_FETCHING });
    const rawData = yield authAPIs.loginSocial(provider, token);
    if (rawData.data && rawData.data.code === 200) {
      yield put({
        type: types.LOGIN_SOCIAL_SUCCESS,
        payload: {
          status: rawData.data.success,
          user: rawData.data.data
        }
      });
      storage.setItem('access_token', rawData.data.data.access_token);
      storage.setItem('refresh_token', rawData.data.data.refresh_token);
      axiosInstance.defaults.headers.Authorization = `Bearer ${rawData.data.data.access_token}`;
      yield put(profileActions.getInformationUser());
    }
  } catch (e) {
    yield put({
      type: types.LOGIN_SOCIAL_FAILURE,
      payload: {
        status: false
      }
    });
  }
}

export const autoLoginSaga = takeEvery(types.LOGIN_SUBMIT, submitLogin);
export const loginSocialSaga = takeEvery(types.LOGIN_SOCIAL_SUBMIT, submitLoginSocial);
export const loginSaga = takeEvery(types.AUTO_LOGIN_SUBMIT, autoLogin);

export default this;
