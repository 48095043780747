/*
 * @Author: loc.do@bstarsolutions.com
 * @Date: 2019-05-14 14:36:31
 * @Last Modified by: loc.do@bstarsolutions.com
 * @Last Modified time: 2019-10-22 11:42:25
 */

import * as types from './types';

const INITIAL_STATE = {
  status: false,
  isLoading: false,
  courseChild: [],
  statusFetchCourse: false,
  courseDetail: [],
  isFetching: false
};

export default (state = INITIAL_STATE, action) => {
  switch (action.type) {
    case types.COURSE_CHILD_FETCHING:
      return {
        ...state,
        isFetching: true
      };
    case types.COURSE_CHILD_SUCCESS:
      return {
        ...state,
        status: action.payload.status,
        courseChild: action.payload.courseChild,
        isFetching: false
      };
    case types.COURSE_CHILD_FAIL:
      return {
        ...state,
        isFetching: false
      };
    case types.COURSE_DETAIL_SUCCESS:
      return {
        ...state,
        statusFetchCourse: action.payload.statusFetchCourse,
        courseDetail: action.payload.courseDetail
      };
    default:
      return state;
  }
};
