/* eslint-disable no-return-assign */
/*
 * @Author: Do Loc - doxuanloc2016@gmail.com
 * @Date: 2019-06-15 20:53:44
 * @Last Modified by: Do Loc - doxuanloc2016@gmail.com
 * @Last Modified time: 2019-06-16 08:49:27
 */

import React, { useEffect } from 'react';
import injectSheet from 'react-jss';
import styles from './styles';

const LazyImage = props => {
  const { ironImageHd, classes, srcLoaded, srcPreload } = props;

  useEffect(() => {
    // eslint-disable-next-line no-undef
    const hdLoaderImg = new Image();

    hdLoaderImg.src = srcLoaded;

    hdLoaderImg.onload = () => {
      ironImageHd.setAttribute('style', `background-image: url('${srcLoaded}')`);
      ironImageHd.classList.add('iron-image-fade-in');
    };
  }, []);

  return (
    <div className={classes.ironImageContainer}>
      <div className={classes.ironImageLoaded} />
      <div
        className={classes.ironImagePreload}
        style={{ backgroundImage: `url('${srcPreload}')` }}
      />
    </div>
  );
};

export default injectSheet(styles)(LazyImage);
