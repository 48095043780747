/*
 * @Author: loc.do@bstarsolutions.com
 * @Date: 2019-05-03 09:32:20
 * @Last Modified by: Do Loc - doxuanloc2016@gmail.comail.com
 * @Last Modified time: 2019-08-12 22:49:32
 */
import React from 'react';
import PropTypes from 'prop-types';
import c from 'classnames';
import injectSheet from 'react-jss';
import { Link } from 'react-router-dom';
import styles from './styles';

function Button(props) {
  const {
    disable,
    hidden,
    onClick,
    label,
    typeButton,
    style,
    classes,
    icon,
    size,
    to,
    className,
    isLoading,
    onKeyDown,
    disabled,
    type,
    classIcon,
    dataLayout,
    dataHref
  } = props;
  const linkWrapper = component => <Link to={to}>{component}</Link>;
  const button = (
    <button
      disabled={disabled}
      type={type}
      onClick={onClick}
      data-href={dataHref}
      datalayout={dataLayout}
      onKeyDown={onKeyDown}
      className={c(
        className,
        classes.button,
        { [classes.big]: size === 'big' },
        { [classes.small]: size === 'small' },
        { [classes.secondary]: typeButton === 'secondary' },
        { [classes.third]: typeButton === 'third' },
        { [classes.fourth]: typeButton === 'fourth' },
        { [classes.loading]: isLoading },
        { [classes.disable]: disable },
        { [classes.hidden]: hidden },
        { [classes.facebook]: typeButton === 'facebook' },
        { [classes.googlePlus]: typeButton === 'googlePlus' },
        { [classes.twitter]: typeButton === 'twitter' }
      )}
      style={style}
    >
      {icon && <img className={c(classes.icon, classIcon)} src={icon} alt="icon" />}
      {label && <span className={icon ? classes.label : classes.labelNotIcon}>{label}</span>}
    </button>
  );

  return to ? linkWrapper(button) : button;
}

Button.propTypes = {
  onClick: PropTypes.func,
  label: PropTypes.string,
  typeButton: PropTypes.string,
  size: PropTypes.string,
  to: PropTypes.string,
  isLoading: PropTypes.bool,
  type: PropTypes.string
};

Button.defaultProps = {
  onClick: () => {},
  label: 'Button',
  typeButton: 'primary',
  size: 'normal',
  to: '',
  isLoading: false,
  disabled: false,
  type: 'button'
};

export default injectSheet(styles)(Button);
