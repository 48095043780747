/*
 * @Author: loc.do@bstarsolutions.com
 * @Date: 2019-05-10 10:45:42
 * @Last Modified by: Do Loc - doxuanloc2016@gmail.comail.com
 * @Last Modified time: 2019-10-17 22:13:57
 */

import { put, takeLatest, select } from 'redux-saga/effects';
import { appActions } from 'containers/App/duck';
import { constants } from 'utils';
import { CoursesAPI } from 'services';

import {
  LESSON_IN_COURSE_INIT,
  LESSON_IN_COURSE_FETCHING,
  LESSON_IN_COURSE_SUCCESS,
  LESSON_IN_COURSE_FAIL
} from './types';

const currentLangSelector = state => state.app.currentLang;

function* getLessonInCourseAPI(action) {
  const currentLang = yield select(currentLangSelector);
  try {
    yield put({
      type: LESSON_IN_COURSE_FETCHING
    });
    const rawData = yield CoursesAPI.getLessonInCourse(action.id);
    const lessons = rawData.data.data;
    yield put({
      type: LESSON_IN_COURSE_SUCCESS,
      payload: {
        status: rawData.data.success,
        lessonInCourse: lessons,
        lessonInCourseId: action.id
      }
    });

    const newLeftMenu = lessons.map(l => {
      const lang = l.translations[currentLang] ? l.translations[currentLang] : l.translations.vi;
      const isConcept = l.type === constants.BASIC_TYPE.CONCEPT;
      return {
        label: lang.name || '',
        translations: l.translations,
        to: isConcept
          ? `/khoa-hoc/dang-hoc/${l.id}/${l.type}/concept`
          : `/khoa-hoc/dang-hoc/${l.id}/${l.type}/${l.chapters[0].type}`,
        concept: isConcept ? lang.description : '',
        id: l.id
      };
    });
    yield put(appActions.changeLeftMenu(newLeftMenu));
  } catch (e) {
    yield put({
      type: LESSON_IN_COURSE_FAIL,
      payload: {
        status: false,
        lessonInCourse: []
      }
    });
  }
}

export const getLessonInCourse = takeLatest(LESSON_IN_COURSE_INIT, getLessonInCourseAPI);

export default this;
