/*
 * @Author: thach.nguyen@bstarsolutions.com
 * @Date: 2019-07-04
 * @Last Modified by: loc.do@bstarsolutions.com
 * @Last Modified time: 2019-08-12 13:32:29
 */
export const ARTICLE_INIT = 'ARTICLE_INIT';
export const ARTICLE_FETCHING = 'ARTICLE_FETCHING';
export const ARTICLE_SUCCESS = 'ARTICLE_SUCCESS';
export const ARTICLE_FAILURE = 'ARTICLE_FAILURE';
export const ARTICLE_TYPE = 'ARTICLE_TYPE';

export const ARTICLE_DETAILS_INIT = 'ARTICLE_DETAILS_INIT';
export const ARTICLE_DETAILS_FETCHING = 'ARTICLE_DETAILS_FETCHING';
export const ARTICLE_DETAILS_SUCCESS = 'ARTICLE_DETAILS_SUCCESS';
export const ARTICLE_DETAILS_FAILURE = 'ARTICLE_DETAILS_FAILURE';

export const APPLY_JOB_INIT = 'APPLY_JOB_INIT';
export const APPLY_JOB_FETCHING = 'APPLY_JOB_FETCHING';
export const APPLY_JOB_SUCCESS = 'APPLY_JOB_SUCCESS';
export const APPLY_JOB_FAIL = 'APPLY_JOB_FAIL';

export const IS_SHOW_APPLY_PAGE = 'IS_SHOW_APPLY_PAGE';
export const IS_HIDE_APPLY_PAGE = 'IS_HIDE_APPLY_PAGE';

export const POST_SEND_FORM_CONTACT_INIT = 'POST_SEND_FORM_CONTACT_INIT';
export const POST_SEND_FORM_CONTACT_FETCHING = 'POST_SEND_FORM_CONTACT_FETCHING';
export const POST_SEND_FORM_CONTACT_SUCCESS = 'POST_SEND_FORM_CONTACT_SUCCESS';
export const POST_SEND_FORM_CONTACT_FAIL = 'POST_SEND_FORM_CONTACT_FAIL';
