/*
 * @Author: loc.do@bstarsolutions.com
 * @Date: 2019-05-16 16:20:27
 * @Last Modified by: loc.do@bstarsolutions.com
 * @Last Modified time: 2019-08-14 10:29:14
 */

import { theme } from 'appCore';
import { scaleStyle } from 'utils';

export default {
  container: {
    margin: `0 auto`,
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    ...scaleStyle({
      maxWidth: 1440
    })
  },
  title: {
    color: theme.colors.primary,
    fontWeight: `bold`,
    lineHeight: `41px`,
    marginBottom: 23,
    textTransform: `uppercase`,
    ...scaleStyle({
      fontSize: 30
    })
  },
  border: {
    height: 6,
    backgroundColor: theme.colors.primary,
    ...scaleStyle({
      marginBottom: 50,
      width: 60
    })
  },
  description: {
    textAlign: 'center',
    ...scaleStyle({
      maxWidth: 580,
      fontSize: 20,
      lineHeight: `35px`,
      marginBottom: 50
    })
  },
  btnGroup: {
    marginTop: 15,
    display: 'inline-flex',
    textAlign: 'center',
    '& > button': {
      ...scaleStyle({
        marginRight: 10
      })
    }
  }
};
