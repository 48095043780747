/*
 * @Author: loc.do@bstarsolutions.com
 * @Date: 2019-06-12 11:01:24
 * @Last Modified by: loc.do@bstarsolutions.com
 * @Last Modified time: 2019-10-22 15:23:59
 */

import React from 'react';
import injectSheet from 'react-jss';
import { Block, Button, ModalContent } from 'components';
import { withRouter } from 'react-router';
import { translator, translate } from 'utils';
import { images } from 'appCore';
import styles from './styles';

const data = [
  {
    id: 72,
    name: 'Đề thi 2 Tổng hợp',
    level_id: 5,
    level: 'N5',
    is_active: true,
    is_combine: null,
    type: 1,
    in_course: null,
    test_fail: [
      {
        id: 65,
        test_id: 72,
        chapter_id: '11',
        fail_score: 0
      }
    ],
    test_section: [
      {
        id: 78,
        time: 2,
        chapter_id: '11',
        questions: 5,
        image: 'https://stg.api.rainichi.com/storage/skill/ngu-phap.png',
        translations: {
          vi: {
            name: 'Ngữ pháp'
          },
          en: {
            name: 'Grammar'
          }
        },
        has_children: false
      },
      {
        id: 79,
        time: 2,
        chapter_id: '10',
        questions: 10,
        image: 'https://stg.api.rainichi.com/storage/skill/tu-vung-tong-hop.png',
        translations: {
          vi: {
            name: 'Từ vựng tổng hợp'
          },
          en: {
            name: 'Vocabulary summary'
          }
        },
        has_children: false
      },
      {
        id: 80,
        time: 2,
        chapter_id: '12',
        questions: 10,
        image: 'https://stg.api.rainichi.com/storage/skill/doc-hieu.png',
        translations: {
          vi: {
            name: 'Đọc hiểu'
          },
          en: {
            name: 'Reading'
          }
        },
        has_children: false
      },
      {
        id: 83,
        time: 2,
        chapter_id: '13',
        questions: 5,
        image: 'https://stg.api.rainichi.com/storage/skill/luyen-nghe.png',
        translations: {
          vi: {
            name: 'Nghe'
          },
          en: {
            name: 'Listening'
          }
        },
        has_children: false
      }
    ],
    questions: null,
    minimum_score: 1,
    price: 0,
    bought: true,
    reward: 0,
    image:
      // eslint-disable-next-line max-len
      'https://stg.api.rainichi.com/storage/files/test/images/12c58b9955028cafba6a02a3473e620a4EIMhl.jpg',
    video: null,
    translations: {
      vi: {
        name: 'Đề thi 2 Tổng hợp',
        description: null
      }
    },
    created_at: '20-05-2019',
    created_by: 'Thoa Lữ Lữ'
  }
];

const ExamInShop = props => {
  const {
    classes,
    listSelectExamInShop,
    strings,
    buyExamInShop,
    showPopup,
    isFetchingLoadingSelect,
    showBtnClose,
    tokenSelector,
    history,
    currentLang
  } = props;

  const lang = strings.blockBuyExam;

  const handleBuy = (idExam, buying, reward, duration) => {
    buyExamInShop(idExam, buying, reward, duration);
  };

  const onBuyCourse = (examName, examId, examPrice, examReward, duration) => {
    showBtnClose();
    if (tokenSelector) {
      const children = (
        <ModalContent
          btnLabelModal={lang.btnConfirm}
          onClickDefault={() => handleBuy(examId, examPrice, examReward, duration)}
          title={`${lang.title}: ${examName}`}
        >
          <span className={classes.confirm}>{lang.confirm}</span>
        </ModalContent>
      );
      showPopup({ children });
    } else {
      history.push(`/login`);
    }
  };

  return (
    <div className={`${classes.container} row wrapExamShop--isMod `}>
      {listSelectExamInShop &&
        listSelectExamInShop.map(list => {
          return (
            <div className="col-6 col-md-4 mb-3 mb-lg-4">
              <Block key={list.id} className={classes.block}>
                {list.image ? (
                  <div className={classes.wpimg}>
                    <img className={classes.img} src={list.image} alt="Exam Shop" />
                  </div>
                ) : (
                  <div className={classes.wpimg}>
                    <img className={classes.img} src={images.sumo} alt="Exam Shop" />
                  </div>
                )}
                <span className={classes.name}>{translate(list, currentLang).name}</span>
                <div className={`${classes.info} txt-price`}>
                  <img className={classes.imgCoin} src={images.coin} alt="Coin" />
                  <span className={classes.price}>
                    {list.price === 0 ? lang.free : `${list.price} ${lang.coin}`}
                  </span>
                </div>
                <Button
                  onClick={() =>
                    onBuyCourse(
                      translate(list, currentLang).name,
                      list.id,
                      list.price,
                      list.reward,
                      0
                    )
                  }
                  className={classes.btn}
                  isLoading={isFetchingLoadingSelect}
                  label={lang.btnBuy}
                />
              </Block>
            </div>
          );
        })}
    </div>
  );
};

ExamInShop.defaultProps = {
  listData: data
};

export default withRouter(translator(injectSheet(styles)(ExamInShop)));
