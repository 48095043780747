/* eslint-disable jsx-a11y/anchor-has-content */
/*
 * @Author: Do Loc - doxuanloc2016@gmail.com
 * @Date: 2019-06-22 19:24:46
 * @Last Modified by: loc.do@bstarsolutions.com
 * @Last Modified time: 2019-08-29 16:08:51
 */

import React, { useEffect } from 'react';
import injectSheet from 'react-jss';
import { translator } from 'utils';
import { compose } from 'recompose';
import { ModalContent } from 'components';
import { connect } from 'react-redux';
import * as modalActions from 'containers/ModalManagement/duck/actions';
import * as storeActions from 'containers/Shop/duck/actions';
import { withRouter } from 'react-router';
import styles from './styles';

const ModalConfirm = props => {
  const {
    strings,
    success,
    fail,
    notCoin,
    successExam,
    title,
    failExam,
    notCoinExam,
    alreadyExam,
    dispatch,
    successClass,
    classes,
    dataClass,
    createdClass,
    history,
    courseId,
    examId
  } = props;

  const lang = strings.blockBuyCourse;
  const langExam = strings.blockBuyExam;

  const onClose = () => {
    dispatch(modalActions.hideAll());
    history.push(`/khoa-hoc/mon-hoc/1/${courseId}`);
  };

  const onCloseAndExam = () => {
    dispatch(modalActions.hideAll());
    history.push(`/de-thi/bai-thi/${examId}`);
  };

  const onMoveBuyCoin = () => {
    dispatch(modalActions.hideAll());
    history.push(`/cua-hang`);
    window.scrollTo({ top: 0, left: 0, behavior: 'smooth' });
  };

  const onCloseAndBuy = () => {
    dispatch(modalActions.hideAll());
  };

  useEffect(() => {
    dispatch(modalActions.hideBtnClose());
    if (title === 'Class') {
      dispatch(storeActions.fetchListSelectCourseShop(1, '', '', '1,3'));
    } else {
      dispatch(storeActions.fetchListSelectCourseShop(1, '', '', '1,2'));
    }
    dispatch(storeActions.fetchListSelectExamShop('', '', ''));
  }, []);

  return (
    <ModalContent
      onClickDefault={courseId ? onClose : onCloseAndExam}
      btnLabelModal={courseId ? lang.btnStartStudy : lang.btnStartExam}
      isButtonExtend
      onClickExtend={notCoin || notCoinExam ? onMoveBuyCoin : onCloseAndBuy}
      isButton={success || successExam}
      btnLabelModalExtend={notCoin || notCoinExam ? lang.btnBuyCoin : lang.stepBuy}
      title={title === 'Course' ? lang.title : title === 'Class' ? lang.titleClass : langExam.title}
    >
      {success && <span className={classes.message}>{lang.confirmBuySuccess}</span>}
      {fail && <span className={classes.message}>{lang.confirmBuyFail}</span>}
      {notCoin && <span className={classes.message}>{lang.confirmNotCoin}</span>}
      {successExam && <span className={classes.message}>{langExam.confirmBuySuccess}</span>}
      {failExam && <span className={classes.message}>{langExam.confirmBuyFail}</span>}
      {notCoinExam && <span className={classes.message}>{langExam.confirmNotCoin}</span>}
      {alreadyExam && <span className={classes.message}>{langExam.confirmBought}</span>}
      {createdClass && <span className={classes.message}>{langExam.createdClass}</span>}
      {successClass && (
        <>
          <span className={classes.message}>{lang.successClass}</span>
          <a className={classes.url} href={dataClass.data.url}>
            {lang.managementClass}
          </a>
        </>
      )}
    </ModalContent>
  );
};

ModalConfirm.defaultProps = {
  success: false,
  fail: false,
  notCoin: false,
  successExam: false,
  title: 'Title',
  failExam: false,
  notCoinExam: false,
  alreadyExam: false,
  successClass: false,
  createdClass: false
};

export default compose(
  connect(),
  injectSheet(styles),
  translator
)(withRouter(ModalConfirm));
