/*
 * @Author: loc.do@bstarsolutions.com
 * @Date: 2019-06-07 13:59:59
 * @Last Modified by: loc.do@bstarsolutions.com
 * @Last Modified time: 2019-10-02 11:41:59
 */

export const COIN_INIT = 'COIN_INIT';
export const COIN_FETCHING = 'COIN_FETCHING';
export const COIN_SUCCESS = 'COIN_SUCCESS';
export const COIN_FAIL = 'COIN_FAIL';

export const COURSE_SHOP_INIT = 'COURSE_SHOP_INIT';
export const COURSE_SHOP_FETCHING = 'COURSE_SHOP_FETCHING';
export const COURSE_SHOP_SUCCESS = 'COURSE_SHOP_SUCCESS';
export const COURSE_SHOP_FAIL = 'COURSE_SHOP_FAIL';

export const SKILL_SHOP_INIT = 'SKILL_SHOP_INIT';
export const SKILL_SHOP_FETCHING = 'SKILL_SHOP_FETCHING';
export const SKILL_SHOP_SUCCESS = 'SKILL_SHOP_SUCCESS';
export const SKILL_SHOP_FAIL = 'SKILL_SHOP_FAIL';

export const SELECT_COURSE_SHOP_INIT = 'SELECT_COURSE_INIT';
export const SELECT_COURSE_FETCHING = 'SELECT_COURSE_FETCHING';
export const SELECT_COURSE_SUCCESS = 'SELECT_COURSE_SUCCESS';
export const SELECT_COURSE_FAIL = 'SELECT_COURSE_FAIL';

export const EXAM_SHOP_INIT = 'EXAM_SHOP_INIT';
export const EXAM_SHOP_FETCHING = 'EXAM_SHOP_FETCHING';
export const EXAM_SHOP_SUCCESS = 'EXAM_SHOP_SUCCESS';
export const EXAM_SHOP_FAIL = 'EXAM_SHOP_FAIL';

export const PAYMENT_INIT = 'PAYMENT_INIT';
export const PAYMENT_FETCHING = 'PAYMENT_FETCHING';
export const PAYMENT_SUCCESS = 'PAYMENT_SUCCESS';
export const PAYMENT_FAIL = 'PAYMENT_FAIL';

export const FILTER_COURSE_FROM_COURSE_TO_SHOP_INIT = 'FILTER_COURSE_FROM_COURSE_TO_SHOP_INIT';
export const FILTER_COURSE_FROM_COURSE_TO_SHOP_FETCHING =
  'FILTER_COURSE_FROM_COURSE_TO_SHOP_FETCHING';
export const FILTER_COURSE_FROM_COURSE_TO_SHOP_SUCCESS =
  'FILTER_COURSE_FROM_COURSE_TO_SHOP_SUCCESS';
export const FILTER_COURSE_FROM_COURSE_TO_SHOP_FAIL = 'FILTER_COURSE_FROM_COURSE_TO_SHOP_FAIL';

export const SHOW_PAYMENT_PAGE = 'SHOW_PAYMENT_PAGE';
export const HIDE_PAYMENT_PAGE = 'HIDE_PAYMENT_PAGE';

export const PAYMENT_BUY_OFFLINE_INIT = 'PAYMENT_BUY_OFFLINE_INIT';
export const PAYMENT_BUY_OFFLINE_FETCHING = 'PAYMENT_BUY_OFFLINE_FETCHING';
export const PAYMENT_BUY_OFFLINE_SUCCESS = 'PAYMENT_BUY_OFFLINE_SUCCESS';
export const PAYMENT_BUY_OFFLINE_FAIL = 'PAYMENT_BUY_OFFLINE_FAIL';

export const FILTER_EXAM_IN_COURSE_INIT = 'FILTER_EXAM_IN_COURSE_INIT';
export const FILTER_EXAM_IN_COURSE_FETCHING = 'FILTER_EXAM_IN_COURSE_FETCHING';
export const FILTER_EXAM_IN_COURSE_SUCCESS = 'FILTER_EXAM_IN_COURSE_SUCCESS';
export const FILTER_EXAM_IN_COURSE_FAIL = 'FILTER_EXAM_IN_COURSE_FAIL';

export const BUY_COURSE_IN_SHOP_INIT = 'BUY_COURSE_IN_SHOP_INIT';
export const BUY_COURSE_IN_SHOP_FETCHING = 'BUY_COURSE_IN_SHOP_FETCHING';
export const BUY_COURSE_IN_SHOP_SUCCESS = 'BUY_COURSE_IN_SHOP_SUCCESS';
export const BUY_COURSE_IN_SHOP_FAIL = 'BUY_COURSE_IN_SHOP_FAIL';

export const BUY_EXAM_IN_SHOP_INIT = 'BUY_EXAM_IN_SHOP_INIT';
export const BUY_EXAM_IN_SHOP_FETCHING = 'BUY_EXAM_IN_SHOP_FETCHING';
export const BUY_EXAM_IN_SHOP_SUCCESS = 'BUY_EXAM_IN_SHOP_SUCESS';
export const BUY_EXAM_IN_SHOP_FAIL = 'BUY_EXAM_IN_SHOP_FAIL';

export const BUY_CLASS_IN_SHOP_INIT = 'BUY_CLASS_IN_SHOP_INIT';
export const BUY_CLASS_IN_SHOP_FETCHING = 'BUY_CLASS_IN_SHOP_FETCHING';
export const BUY_CLASS_IN_SHOP_SUCCESS = 'BUY_CLASS_IN_SHOP_SUCCESS';
export const BUY_CLASS_IN_SHOP_FAIL = 'BUY_CLASS_IN_SHOP_FAIL';

export const BUY_CLASS_NOT_EXIST_INIT = 'BUY_CLASS_NOT_EXIST_INIT';
export const BUY_CLASS_NOT_EXIST_FETCHING = 'BUY_CLASS_NOT_EXIST_FETCHING';
export const BUY_CLASS_NOT_EXIST_SUCCESS = 'BUY_CLASS_NOT_EXIST_SUCCESS';
export const BUY_CLASS_NOT_EXIST_FAIL = 'BUY_CLASS_NOT_EXIST_FAIL';

export const CHECK_BOUGHT_CLASS_INIT = 'CHECK_BOUGHT_CLASS_INIT';
export const CHECK_BOUGHT_CLASS_FETCHING = 'CHECK_BOUGHT_CLASS_FETCHING';
export const CHECK_BOUGHT_CLASS_SUCCESS = 'CHECK_BOUGHT_CLASS_SUCCESS';
export const CHECK_BOUGHT_CLASS_FAIL = 'CHECK_BOUGHT_CLASS_FAIL';

export const BUY_COURSE_VNPAY_INIT = 'BUY_COURSE_VNPAY_INIT';
export const BUY_COURSE_VNPAY_FETCHING = 'BUY_COURSE_VNPAY_FETCHING';
export const BUY_COURSE_VNPAY_SUCCESS = 'BUY_COURSE_VNPAY_SUCCESS';
export const BUY_COURSE_VNPAY_FAIL = 'BUY_COURSE_VNPAY_FAIL';
