import { put, takeLatest } from 'redux-saga/effects';
import { LanguageAPIs, BaseAPIs } from 'services';
import * as types from './types';

function* getLanguage() {
  try {
    yield put({ type: types.SUPPORT_LANGUAGE_FETCHING });
    const rawData = yield LanguageAPIs.getSupportLanguage();
    const data = rawData ? rawData.data.data : [];
    yield put({
      type: types.SUPPORT_LANGUAGE_SUCCESS,
      payload: {
        languages: data,
        currentLang: data.find(l => l.is_default).code
      }
    });
  } catch (e) {
    yield put({ type: types.SUPPORT_LANGUAGE_FAILURE });
  }
}

function* getListMenuSaga() {
  try {
    yield put({ type: types.GET_LIST_MENU_FETCHING });
    const rawData = yield BaseAPIs.getListMenu();
    yield put({
      type: types.GET_LIST_MENU_SUCCESS,
      payload: {
        listMenu: rawData.data.data
      }
    });
  } catch (e) {
    yield put({ type: types.GET_LIST_MENU_FAIL });
  }
}

function* getListConfigurationSaga() {
  try {
    yield put({ type: types.GET_LIST_CONFIGURATION_FETCHING });
    const rawData = yield BaseAPIs.getListConfiguration();
    yield put({
      type: types.GET_LIST_CONFIGURATION_SUCCESS,
      payload: {
        listConfiguration: rawData.data.data
      }
    });
  } catch (e) {
    yield put({ type: types.GET_LIST_CONFIGURATION_FAIL });
  }
}

export const getListMenuInSaga = takeLatest(types.GET_LIST_MENU_INIT, getListMenuSaga);
export const getLanguageSaga = takeLatest(types.SUPPORT_LANGUAGE_GET, getLanguage);
export const getListConfigurationInSaga = takeLatest(
  types.GET_LIST_CONFIGURATION_INIT,
  getListConfigurationSaga
);

export default this;
