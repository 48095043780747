import React, { useState, useEffect } from 'react';
import injectSheet from 'react-jss';
import { withRouter } from 'react-router';
/* eslint-disable no-unused-vars */
import { translator, constants } from 'utils';
import { BlockExpand, Button, ModalContent } from 'components';
import c from 'classnames';
import styles from './styles';

const Payment = props => {
  const {
    classes,
    strings,
    fetchPayment,
    coin,
    price,
    showPopup,
    hideBtnClose,
    onClose,
    payTransferCoinOffline,
    coinPayment,
    match,
    courseEnterprise,
    idSelect,
    history,
    hidePaymentPage,
    listConfiguration,
    currentLang,
    fetchPaymentBuyCourse
  } = props;
  useEffect(() => {
    window.scrollTo({ top: 0, left: 0, behavior: 'smooth' });
  }, []);

  const [expandIndex, setExpandIndex] = useState(-1);

  const lang = strings.blockPayment;

  const clickTransferOnline = () => {
    if (match.params.name === 'lop-hoc') {
      if (courseEnterprise.prices[idSelect]) {
        fetchPayment(
          '',
          courseEnterprise.prices[idSelect].buying_credits,
          0,
          courseEnterprise.id,
          true,
          courseEnterprise.prices[idSelect].price_types.duration,
          courseEnterprise.num_of_employee
        );
      } else {
        fetchPayment(
          '',
          courseEnterprise.prices.landing_discount_credit,
          0,
          courseEnterprise.id,
          true,
          999999,
          courseEnterprise.num_of_employee
        );
      }
      onClose();
    } else if (match.params.name === 'khoa-hoc') {
      fetchPaymentBuyCourse('', courseEnterprise.prices.landing_buy_credit, 0, courseEnterprise.id);
    } else {
      fetchPayment('ATM', price, coin);
    }
  };

  const onExpand = index => {
    setExpandIndex(index);
  };

  const onTransfer = namePay => {
    if (match.params.name === 'lop-hoc') {
      if (courseEnterprise.prices[idSelect]) {
        payTransferCoinOffline(
          namePay,
          courseEnterprise.prices[idSelect].buying_credits,
          0,
          courseEnterprise.id,
          true,
          courseEnterprise.prices[idSelect].price_types.duration,
          courseEnterprise.num_of_employee
        );
      } else {
        payTransferCoinOffline(
          namePay,
          courseEnterprise.prices.landing_discount_credit,
          0,
          courseEnterprise.id,
          true,
          999999,
          courseEnterprise.num_of_employee
        );
      }

      onClose();
      hidePaymentPage();
      history.push(`/cua-hang/lop-hoc`);
      window.scrollTo({ top: 0, left: 0, behavior: 'smooth' });
    } else {
      payTransferCoinOffline(namePay, price, coin);
      hidePaymentPage();
      onClose();
      history.push(`/cua-hang`);
      window.scrollTo({ top: 0, left: 0, behavior: 'smooth' });
    }
  };

  const clickShowPopup = namePopup => {
    const children = (
      <ModalContent
        onClickDefault={() => onTransfer(namePopup)}
        btnLabelModal="OK"
        title={lang.message}
      >
        {namePopup === 'TRANSFER' ? (
          <>
            <span className={classes.info}>{lang.infoTransfer1}</span>
            <span className={classes.info}>{lang.infoTransfer2}</span>
            <span className={classes.info}>
              {lang.infoTransfer3} {listConfiguration.phone}
            </span>{' '}
          </>
        ) : (
          <>
            <span className={classes.info}>{lang.infoTransfer4}</span>
            <span className={classes.info}>
              {listConfiguration.translations[currentLang]
                ? listConfiguration.translations[currentLang].address
                : listConfiguration.translations.vi.address}
            </span>
            <span className={classes.info}>
              {lang.infoTransfer6} {listConfiguration.phone}
            </span>{' '}
          </>
        )}
      </ModalContent>
    );

    showPopup({ children });
    hideBtnClose();
  };

  const anoumentBuyCoin = () => {
    clickShowPopup('TRANSFER');
  };

  const clickBuyOffline = () => {
    clickShowPopup('OFFLINE');
  };

  const defaultBlock = [
    {
      id: 0,
      name: lang.transfer,
      description: expandIndex === 0 && (
        <>
          <p className={classes.bankName}>{lang.infoBank1}</p>
          <span className={classes.info}>+ {lang.infoBank2}</span>
          <span className={classes.info}>+ {lang.infoBank3}</span>
          <span className={classes.info}>+ {lang.infoBank4}</span>
          <span className={classes.info}>+ {lang.infoBank5}</span>
          <div className={classes.btnTransfer}>
            <Button onClick={anoumentBuyCoin} label={lang.btnConfirm} />
          </div>
        </>
      ),
      onClick: onExpand
    },
    {
      id: 1,
      name: lang.online,
      onClick: clickTransferOnline
    },
    {
      id: 2,
      name: lang.center,
      description: expandIndex === 2 && (
        <>
          <span className={classes.info}>
            {lang.infoOffline}{' '}
            {listConfiguration.translations[currentLang]
              ? listConfiguration.translations[currentLang].address
              : listConfiguration.translations.vi.address}
          </span>
          <div className={classes.btnTransfer}>
            <Button onClick={clickBuyOffline} label={lang.btnConfirm} />
          </div>
        </>
      ),
      onClick: onExpand
    }
  ];

  const renderPaymentList = () => (
    <div className={classes.list}>
      {defaultBlock.map((l, i) => (
        <BlockExpand
          className={c(classes.hideExpand, { [classes.showExpand]: expandIndex === i })}
          key={i}
          lable={l.name}
          description={l.description}
          onClick={() => l.onClick(i)}
        />
      ))}
    </div>
  );
  const priceShow = !coinPayment
    ? courseEnterprise.prices[idSelect]
      ? courseEnterprise.prices[idSelect].buying_credits
      : courseEnterprise.prices.landing_discount_credit ||
        courseEnterprise.prices.landing_buy_credit
    : 0;
  return (
    <div className={classes.contentPayment}>
      {!coinPayment && (
        <h1 className={classes.courseTitle}>
          {courseEnterprise.translations[currentLang]
            ? courseEnterprise.translations[currentLang].name
            : courseEnterprise.translations.vi.name}{' '}
          : {constants.formatNumber(priceShow)}
        </h1>
      )}
      <span className={classes.titlePayment}>{lang.title}</span>
      {renderPaymentList()}
    </div>
  );
};

export default withRouter(translator(injectSheet(styles)(Payment)));
