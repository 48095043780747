/*
 * @Author: loc.do@bstarsolutions.com
 * @Date: 2019-05-10 10:49:01
 * @Last Modified by: loc.do@bstarsolutions.com
 * @Last Modified time: 2019-08-26 10:38:03
 */

import { constants } from 'utils';
import axiosInstance from './interceptor';

const Home = {
  getListSlide: slugPage =>
    axiosInstance.get(`${constants.API}/slider/page?slug=${slugPage}&type=1`),
  getListGallery: slugPage =>
    axiosInstance.get(`${constants.API}/slider/page?slug=${slugPage}&type=2`),
  getLecturers: () => axiosInstance.get(`${constants.API}/user/teachers`),
  getListCourseInHome: () =>
    axiosInstance.get(`${constants.API}/landing-page/course?customer_type_id=1,2`),
  submitRegisterCourseHome: (typeForm, name, phone, email, courseId, content) =>
    axiosInstance
      .post(`/contact`, {
        type: typeForm,
        name,
        phone,
        email,
        course_id: courseId,
        content
      })
      .then(res => res)
      .catch(e =>
        e.response && (e.response.status === 401 || e.response.status === 422) && e.response.data
          ? e.response.data
          : new Error(e)
      ),
  getListAllDataLandingPage: pageId => axiosInstance.get(`/landing-page?page_id=${pageId}`)
};

export default Home;
