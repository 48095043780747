/*
 * @Author: loc.do@bstarsolutions.com
 * @Date: 2019-06-10 17:09:20
 * @Last Modified by: loc.do@bstarsolutions.com
 * @Last Modified time: 2019-10-23 10:53:12
 */
import { scaleStyle } from 'utils';
import { theme } from 'appCore';

export default {
  container: {
    width: `99%`,
    display: 'flex',
    flexDirection: 'column'
  },
  block: {
    width: `100%`,
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'space-between',
    ...scaleStyle({
      marginBottom: 10
    })
  },
  blockChild: {
    display: 'flex',
    flexDirection: 'row',
    border: 'none',
    width: `75%`
  },
  imgAvatar: {
    objectFit: 'cover',
    ...scaleStyle({
      width: 242,
      borderRadius: 20,
      marginTop: 30,
      marginBottom: 24
    })
  },
  imgAvatarPopup: {
    ...scaleStyle({
      width: 242,
      height: 145,
      borderRadius: 20,
      marginBottom: 24
    })
  },
  name: {
    fontWeight: 'bold',
    textAlign: 'center',
    color: theme.colors.primary,
    display: 'flex',
    width: '100%',
    justifyContent: 'center',
    maxWidth: 225,
    ...scaleStyle({
      fontSize: 25,
      lineHeight: `34px`,
      marginBottom: 27
    })
  },
  blockOne: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'flex-start',
    width: '100%',
    flex: 1,
    ...scaleStyle({
      marginLeft: 30
    })
  },
  price: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    width: '100%',
    ...scaleStyle({
      marginLeft: 32,
      marginRight: 32
    }),
    [`@media screen and (max-width: 576px)`]: {
      flexDirection: 'row',
      alignItems: 'start'
    }
  },
  coin: {
    ...scaleStyle({
      width: 38,
      height: 38,
      marginTop: 5,
      marginBottom: 5
    })
  },
  credit: {
    fontWeight: 'bold',
    textAlign: 'center',
    color: '#0062ff',
    ...scaleStyle({
      lineHeight: `34px`,
      fontSize: 25,
      marginTop: 20
    }),
    [`@media screen and (max-width: 576px)`]: {
      paddingLeft: '5px'
    }
  },
  reward: {
    fontWeight: 'bold',
    textAlign: 'center',
    color: '#4e4e4e',
    WebkitTextDecorationLine: 'line-through',
    textDecorationLine: 'line-through',
    ...scaleStyle({
      marginBottom: 20,
      lineHeight: `27px`,
      fontSize: 20
    })
  },
  btnBuy: {
    ...scaleStyle({
      marginRight: 30
    })
  },
  btnDefault: {
    ...scaleStyle({
      marginBottom: 30
    })
  },
  wrapPricesCourse: {
    display: 'flex',
    flex: 2,
    [`@media screen and (max-width: 576px)`]: {
      flexWrap: 'wrap'
    }
  },
  [`@media screen and (max-width: ${theme.screens.maxWidth800}px)`]: {
    imgAvatar: {
      width: 66,
      height: 66,
      marginTop: 0
    },
    block: {
      display: 'flex',
      flexDirection: 'column',
      borderRadius: 10
    },
    blockChild: {
      display: 'flex',
      flexDirection: 'row',
      border: 'none',
      paddingTop: 10,
      [`@media screen and (max-width: 576px)`]: {
        width: '100%',
        flexDirection: 'column'
      }
    },
    btnBuy: {
      marginBottom: 10,
      borderRadius: 10,
      width: '100%',
      display: 'flex',
      justifyContent: 'center',
      alignItems: 'center',
      marginLeft: 18,
      height: 45,
      fontSize: 14
    },
    blockOne: {
      display: 'flex',
      justifyContent: 'flex-start',
      flexDirection: 'column',
      alignItems: 'center',
      height: 170,
      [`@media screen and (max-width: 576px)`]: {
        flexDirection: 'row',
        margin: 0,
        padding: '0 15px',
        height: 'auto',
        flex: 'none'
      }
    },
    wrapBtnBuy: {
      display: 'flex',
      width: 140,
      justifyContent: 'center',
      alignItems: 'center',
      marginBottom: 10,
      marginLeft: 70,
      [`@media screen and (max-width: 576px)`]: {
        marginLeft: '0'
      }
    },
    name: {
      justifyContent: 'flex-start',
      marginLeft: 10
    },
    coin: {
      marginTop: 12
    }
  }
};
