/*
 * @Author: loc.do@bstarsolutions.com
 * @Date: 2019-06-26 15:33:59
 * @Last Modified by: loc.do@bstarsolutions.com
 * @Last Modified time: 2019-08-06 17:37:25
 */
import { theme } from 'appCore';
import { scaleStyle } from 'utils';

export default {
  container: {
    ...scaleStyle({
      marginBottom: 10
    })
  },
  titleExam: {
    fontWeight: 'bold',
    color: theme.colors.primary,
    display: 'flex',
    width: '100%',
    ...scaleStyle({
      fontSize: 20,
      paddingLeft: 100,
      marginTop: 36
    })
  },
  containerInfoExam: {
    display: 'flex',
    width: '100%',
    ...scaleStyle({
      marginBottom: 26,
      marginTop: 26
    })
  },
  rightInforExam: {
    display: 'flex',
    flexDirection: 'column',
    width: '100%',
    ...scaleStyle({
      paddingLeft: 50
    })
  },
  name: {
    ...scaleStyle({
      fontSize: 20,
      lineHeight: `35px`,
      width: 160
    })
  },
  boxExam: {
    display: 'flex',
    width: `100%`,
    // height: '100%',
    alignItems: 'center'
  },
  result: {
    fontWeight: 'bold',
    color: theme.colors.primary,
    ...scaleStyle({
      lineHeight: `35px`,
      fontSize: 20
    })
  },
  boxBtn: {
    display: 'flex',
    justifyContent: 'flex-start',
    flexDirection: 'column',
    ...scaleStyle({
      paddingRight: 21.3
    })
  },
  btn: {
    display: 'flex',
    justifyContent: 'center',
    ...scaleStyle({
      marginTop: 10
    })
  },
  progress: {
    width: `100%`,
    height: 5,
    objectFit: 'contain',
    borderRadius: 25,
    backgroundColor: theme.colors.gray
  },
  [`@media screen and (max-width: ${theme.screens.maxWidth800}px)`]: {
    containerInfoExam: {
      display: 'flex',
      flexDirection: 'column',
      justifyContent: 'center',
      alignItems: 'center'
    },
    container: {
      width: '100%'
    }
  }
};
