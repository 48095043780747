/*
 * @Author: loc.do@bstarsolutions.com
 * @Date: 2019-06-07 15:36:58
 * @Last Modified by: loc.do@bstarsolutions.com
 * @Last Modified time: 2019-08-22 18:40:07
 */

import * as types from './types';

const INITIAL_STATE = {
  statusFetchCoin: false,
  statusFetchCourseInShop: false,
  statusFetchSkillInShop: false,
  statusFetchSelectCourseInShop: false,
  statusFetchSelectExamInShop: false,
  listCoin: [],
  listCourseInShop: [],
  listSkillInShop: [],
  listSelectCourseInShop: [],
  listSelectExamInShop: [],
  paymentInfo: '',
  paymentInfoOffline: [],
  isShowPaymentPage: false,
  isFetchingLoadingSelect: false,
  statusBuyCourse: false,
  statusBuyClass: false,
  courseEnterprise: {}
};

export default (state = INITIAL_STATE, action) => {
  switch (action.type) {
    case types.COIN_SUCCESS:
      return {
        ...state,
        statusFetchCoin: action.payload.statusFetchCoin,
        listCoin: action.payload.listCoin,
        isShowPaymentPage: false,
        isFetchingLoadingSelect: false
      };
    case types.COIN_FETCHING:
      return {
        ...state,
        isFetchingLoadingSelect: true
      };
    case types.COIN_FAIL:
      return {
        ...state,
        isFetchingLoadingSelect: true
      };
    case types.COURSE_SHOP_SUCCESS:
      return {
        ...state,
        statusFetchCourseInShop: action.payload.statusFetchCourseInShop,
        listCourseInShop: action.payload.listCourseInShop
      };
    case types.SKILL_SHOP_SUCCESS:
      return {
        ...state,
        statusFetchSkillInShop: action.payload.statusFetchSkillInShop,
        listSkillInShop: action.payload.listSkillInShop
      };
    case types.SELECT_COURSE_FETCHING:
      return {
        ...state,
        isFetchingLoadingSelect: true
      };
    case types.SELECT_COURSE_SHOP_INIT:
      return {
        ...state,
        statusBuyCourse: false
      };
    case types.SELECT_COURSE_SUCCESS:
      return {
        ...state,
        statusFetchSelectCourseInShop: action.payload.statusFetchSelectCourseInShop,
        listSelectCourseInShop: action.payload.listSelectCourseInShop,
        isFetchingLoadingSelect: false
      };
    case types.EXAM_SHOP_FETCHING:
      return {
        ...state,
        isFetchingLoadingSelect: true
      };
    case types.EXAM_SHOP_SUCCESS:
      return {
        ...state,
        statusFetchSelectExamInShop: action.payload.statusFetchSelectExamInShop,
        listSelectExamInShop: action.payload.listSelectExamInShop,
        isFetchingLoadingSelect: false
      };
    case types.EXAM_SHOP_FAIL:
      return {
        ...state,
        isFetchingLoadingSelect: true
      };
    case types.FILTER_COURSE_FROM_COURSE_TO_SHOP_INIT:
      return {
        ...state,
        statusBuyCourse: false
      };
    case types.FILTER_COURSE_FROM_COURSE_TO_SHOP_SUCCESS:
      return {
        ...state,
        statusFetchSelectCourseInShop: action.payload.statusFetchSelectCourseInShop,
        listSelectCourseInShop: action.payload.listSelectCourseInShop
      };
    case types.PAYMENT_SUCCESS:
      return {
        ...state,
        paymentInfo: action.payload.paymentInfo
      };
    case types.PAYMENT_BUY_OFFLINE_SUCCESS:
      return {
        ...state,
        paymentInfoOffline: action.payload.paymentInfoOffline
      };
    case types.SHOW_PAYMENT_PAGE:
      return {
        ...state,
        isShowPaymentPage: true,
        courseEnterprise: action.payload.courseEnterprise
      };
    case types.HIDE_PAYMENT_PAGE:
      return {
        ...state,
        isShowPaymentPage: false
      };
    case types.FILTER_EXAM_IN_COURSE_SUCCESS:
      return {
        ...state,
        statusFetchSelectExamInShop: action.payload.statusFetchSelectExamInShop,
        listSelectExamInShop: action.payload.listSelectExamInShop
      };
    case types.BUY_COURSE_IN_SHOP_INIT:
      return {
        ...state,
        statusBuyCourse: false
      };
    case types.BUY_COURSE_IN_SHOP_FETCHING:
      return {
        ...state,
        isFetchingLoadingSelect: true,
        statusBuyCourse: false
      };
    case types.BUY_COURSE_IN_SHOP_SUCCESS:
      return {
        ...state,
        isFetchingLoadingSelect: false,
        statusBuyCourse: true
      };
    case types.BUY_COURSE_IN_SHOP_FAIL:
      return {
        ...state,
        isFetchingLoadingSelect: false
      };
    case types.BUY_EXAM_IN_SHOP_FETCHING:
      return {
        ...state,
        isFetchingLoadingSelect: true
      };
    case types.BUY_EXAM_IN_SHOP_SUCCESS:
      return {
        ...state,
        isFetchingLoadingSelect: false
      };
    case types.BUY_EXAM_IN_SHOP_FAIL:
      return {
        ...state,
        isFetchingLoadingSelect: false
      };
    case types.BUY_CLASS_IN_SHOP_FETCHING:
      return {
        ...state,
        isFetchingLoadingSelect: true,
        statusBuyClass: false
      };
    case types.BUY_CLASS_IN_SHOP_SUCCESS:
      return {
        ...state,
        isFetchingLoadingSelect: false,
        statusBuyClass: true
      };
    case types.BUY_CLASS_IN_SHOP_FAIL:
      return {
        ...state,
        isFetchingLoadingSelect: false
      };
    case types.BUY_CLASS_NOT_EXIST_FETCHING:
      return {
        ...state,
        isFetchingLoadingSelect: true,
        statusBuyClass: false
      };
    case types.BUY_CLASS_NOT_EXIST_SUCCESS:
      return {
        ...state,
        isFetchingLoadingSelect: false,
        statusBuyClass: true
      };
    case types.BUY_CLASS_NOT_EXIST_FAIL:
      return {
        ...state,
        isFetchingLoadingSelect: false
      };
    case types.CHECK_BOUGHT_CLASS_FETCHING:
      return {
        ...state
      };
    case types.CHECK_BOUGHT_CLASS_SUCCESS:
      return {
        ...state,
        courseEnterprise: action.payload.courseEnterprise
      };
    case types.CHECK_BOUGHT_CLASS_FAIL:
      return {
        ...state
      };
    default:
      return state;
  }
};
