import * as types from './types';

export const submitSignUp = ({ email }) => ({
  type: types.SIGNUP_SUBMIT,
  payload: { email }
});

export const submitSignUpActive = ({ name, email, activationToken, password, phone }) => ({
  type: types.SIGNUP_ACTIVE_SUBMIT,
  payload: { name, email, activationToken, password, phone }
});

export const removeEmail = () => ({
  type: types.EMAIL_REMOVE
});

export default this;
