import { put, takeMaybe } from 'redux-saga/effects';

import * as types from './types';

function* disableMic() {
  yield put({
    type: types.DISABLE_MIC
  });
}

function* enableMic() {
  yield put({
    type: types.ENABLE_MIC
  });
}

function* disableVolume() {
  yield put({
    type: types.DISABLE_VOLUME
  });
}

function* enableVolume() {
  yield put({
    type: types.ENABLE_VOLUME
  });
}

export const disableMicSaga = takeMaybe(disableMic);
export const enableMicSaga = takeMaybe(enableMic);
export const disableVolumeSaga = takeMaybe(disableVolume);
export const enableVolumeSaga = takeMaybe(enableVolume);

export default this;
