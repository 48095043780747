/*
 * @Author: loc.do@bstarsolutions.com
 * @Date: 2019-06-06 15:25:48
 * @Last Modified by: loc.do@bstarsolutions.com
 * @Last Modified time: 2019-06-06 15:58:32
 */

export const DISABLE_MIC = 'DISABLE_MIC';
export const ENABLE_MIC = 'ENABLE_MIC';

export const DISABLE_VOLUME = 'DISABLE_VOLUME';
export const ENABLE_VOLUME = 'ENABLE_VOLUME';

export default this;
