import * as types from './types';

const INITIAL_STATE = {
  isFetching: false,
  email: null,
  already: false,
  isActive: false
};

export default (state = INITIAL_STATE, action) => {
  switch (action.type) {
    case types.SIGNUP_SUCCESS:
    case types.WATING_FOR_ACTIVE:
      return {
        ...state,
        email: action.payload.email,
        isFetching: false,
        already: false
      };
    case types.SIGNUP_FETCHING:
    case types.SIGNUP_ACTIVE_FETCHING:
      return {
        ...state,
        isFetching: true,
        already: false
      };
    case types.SIGNUP_FAILURE:
    case types.SIGNUP_ACTIVE_FAILURE:
      return {
        ...state,
        isFetching: false,
        already: false
      };

    case types.EMAIL_HAS_BEEN_TAKEN:
      return {
        ...state,
        already: true,
        isFetching: false
      };
    case types.SIGNUP_ACTIVE_SUCCESS:
      return {
        ...state,
        isActive: true,
        already: false,
        isFetching: false
      };
    case types.EMAIL_REMOVE:
      return {
        ...state,
        email: null
      };
    default:
      return state;
  }
};
