import { put, takeLatest, select } from 'redux-saga/effects';
import { ExamsAPI } from 'services';
import * as types from './types';

const userToken = state => (state.login.user ? state.login.user.access_token : '');
function* getExamChildApi(action) {
  const token = yield select(userToken);
  const {
    payload: { idExam, level, typeExam }
  } = action;
  try {
    yield put({ type: types.EXAM_CHILD_FETCHING, payload: { isLoading: false } });
    let rawData;
    if (token) {
      rawData =
        parseInt(level, 10) > 0
          ? yield ExamsAPI.getExamChildsByLevel(idExam, level, typeExam)
          : yield ExamsAPI.getExamChilds(idExam, typeExam);
    } else {
      rawData =
        parseInt(level, 10) > 0
          ? yield ExamsAPI.getExamChildsByLevelNotAuthen(idExam, level, typeExam)
          : yield ExamsAPI.getExamChildsNotAuthen(idExam, typeExam);
    }

    yield put({
      type: types.EXAM_CHILD_SUCCESS,
      payload: {
        status: rawData.data.success,
        examChilds: rawData.data.data,
        isLoading: rawData.data.success,
        parentId: idExam
      }
    });
  } catch (e) {
    yield put({ type: types.EXAM_DETAILS_FAIL });
  }
}

function* getExamDetailApi(action) {
  try {
    yield put({ type: types.EXAM_DETAILS_FETCHING });
    const rawData = yield ExamsAPI.getExamDetails(action.id);

    yield put({
      type: types.EXAM_DETAILS_SUCCESS,
      payload: {
        examDetails: rawData.data.data,
        statusFetchExam: rawData.data.success
      }
    });
  } catch (e) {
    yield put({ type: types.EXAM_DETAILS_FAIL });
  }
}

export const getExamChildsSaga = takeLatest(types.EXAM_CHILD_INIT, getExamChildApi);
export const getExamDetailsSaga = takeLatest(types.EXAM_DETAILS_INIT, getExamDetailApi);
export default this;
