/*
 * @Author: loc.do@bstarsolutions.com
 * @Date: 2019-05-10 10:45:42
 * @Last Modified by: loc.do@bstarsolutions.com
 * @Last Modified time: 2019-10-22 11:52:08
 */

import { put, takeLatest, select } from 'redux-saga/effects';

import { CoursesAPI } from 'services';

import {
  COURSE_CHILD_INIT,
  COURSE_CHILD_FETCHING,
  COURSE_CHILD_SUCCESS,
  COURSE_CHILD_FAIL,
  COURSE_DETAIL_INIT,
  COURSE_DETAIL_FETCHING,
  COURSE_DETAIL_SUCCESS,
  COURSE_DETAIL_FAIL
} from './types';

const tokenSelector = state => (state.login.user ? state.login.user.access_token : '');

function* getCourseChildAPI(action) {
  const token = yield select(tokenSelector);
  try {
    yield put({ type: COURSE_CHILD_FETCHING, payload: { isLoading: false } });
    let rawData;
    if (token) {
      rawData = yield CoursesAPI.getCourseChild(action.id);
    } else {
      rawData = yield CoursesAPI.getCourseChildNotAuthen(action.id);
    }

    yield put({
      type: COURSE_CHILD_SUCCESS,
      payload: {
        status: rawData.data.success,
        courseChild: rawData.data.data,
        isLoading: rawData.data.success
      }
    });
  } catch (e) {
    yield put({ type: COURSE_CHILD_FAIL });
  }
}

function* getCourseDetailAPI(action) {
  const token = yield select(tokenSelector);
  try {
    yield put({ type: COURSE_DETAIL_FETCHING });
    let rawData;
    if (token) {
      rawData = yield CoursesAPI.getCourseDetail(action.id);
    } else {
      rawData = yield CoursesAPI.getCourseDetailNotAuthen(action.id);
    }

    yield put({
      type: COURSE_DETAIL_SUCCESS,
      payload: {
        statusFetchCourse: rawData.data.success,
        courseDetail: rawData.data.data
      }
    });
  } catch (e) {
    yield put({ type: COURSE_DETAIL_FAIL });
  }
}

export const getCourseChild = takeLatest(COURSE_CHILD_INIT, getCourseChildAPI);
export const getCourseDetail = takeLatest(COURSE_DETAIL_INIT, getCourseDetailAPI);

export default this;
