import React from 'react';
import { put, takeEvery, select } from 'redux-saga/effects';
import { authAPIs } from 'services';
import { localization } from 'utils';
import { ModalCheckLogin } from 'components';
import { modalActions } from 'containers/ModalManagement/duck';
import { profileActions } from 'containers/Profile/duck';
import * as types from './types';

const currentLangSelector = state => state.app.currentLang;

function* submitSignUp(action) {
  const { payload } = action;
  try {
    yield put({ type: types.SIGNUP_FETCHING });
    const rawData = yield authAPIs.signUp(payload);
    if (rawData.message === 'validation.required') {
      const children = <ModalCheckLogin checkEmailNotEnter />;
      yield put(modalActions.showModal({ children }));
      yield put({ type: types.EMAIL_HAS_BEEN_TAKEN });
    } else if (rawData.message === 'validation.email') {
      const children = <ModalCheckLogin checkEmail />;
      yield put(modalActions.showModal({ children }));
      yield put({ type: types.EMAIL_HAS_BEEN_TAKEN });
    } else if (rawData.code === 16) {
      const children = <ModalCheckLogin checkUserExits />;
      yield put(modalActions.showModal({ children }));
      yield put({ type: types.EMAIL_HAS_BEEN_TAKEN });
    } else if (rawData.code === 401) {
      yield put({
        type: types.WATING_FOR_ACTIVE,
        payload
      });
    } else {
      yield put({
        type: types.SIGNUP_SUCCESS,
        payload
      });
    }
  } catch (e) {
    yield put(modalActions.showModal({ title: 'ERROR', message: 'Something went wrong' }));
    yield put({ type: types.SIGNUP_FAILURE });
  }
}

function* submitSignUpActive(action) {
  const { payload } = action;
  const currentLang = yield select(currentLangSelector);
  try {
    yield put({ type: types.SIGNUP_ACTIVE_FETCHING });
    const rawData = yield authAPIs.signUpActive(payload);
    const lang = localization(currentLang);
    const errorTitle = lang.general.error;
    if (rawData.code === 11) {
      yield put(modalActions.showModal({ title: errorTitle, message: lang.login.invalidCode }));
      yield put({ type: types.SIGNUP_ACTIVE_FAILURE });
    }
    if (rawData.errors && rawData.errors.password) {
      let errorMessage = '';

      errorMessage += `\n${lang.login.password_wrong_format}`;

      yield put(modalActions.showModal({ title: errorTitle, message: errorMessage }));
      yield put({ type: types.SIGNUP_ACTIVE_FAILURE });
    } else {
      yield put({
        type: types.SIGNUP_ACTIVE_SUCCESS,
        payload: {
          status: rawData.data.success,
          user: rawData.data.data
        }
      });
      if (rawData.data.data) {
        yield put(profileActions.getInformationUser());
      }
    }
  } catch (e) {
    yield put({ type: types.SIGNUP_ACTIVE_FAILURE });
  }
}

export const signUpSaga = takeEvery(types.SIGNUP_SUBMIT, submitSignUp);
export const signUpActiveSaga = takeEvery(types.SIGNUP_ACTIVE_SUBMIT, submitSignUpActive);

export default this;
