import React from 'react';
import injectSheet from 'react-jss';
import { constants } from 'utils';
import styles from './styles';

function RibbonTopLeft(props) {
  const { classes, isFoundation, isHot, isNew, isTop } = props;
  const classRibbonLeft = `${classes.ribbonTopLeft} ${classes.ribbon}`;
  let isShow = false;

  function generateText() {
    if (isFoundation) {
      return constants.RIBBON_STATUS_TEXT.isFoundation;
    }
    if (isHot) {
      return constants.RIBBON_STATUS_TEXT.isHot;
    }
    if (isNew) {
      return constants.RIBBON_STATUS_TEXT.isNew;
    }
    if (isTop) {
      return constants.RIBBON_STATUS_TEXT.isTop;
    }
    return '';
  }

  if (isFoundation === true || isHot === true || isNew === true || isTop === true) {
    isShow = true;
  }
  return isShow === true ? (
    <div className={classRibbonLeft}>
      <span>{generateText()}</span>
    </div>
  ) : (
    ''
  );
}

RibbonTopLeft.defaultProps = {
  text: '',
  isShow: false,
  isFoundation: false,
  isHot: false,
  isNew: false,
  isTop: false
};

export default injectSheet(styles)(RibbonTopLeft);
