import React, { useEffect, useState, useRef } from 'react';
import ReactDOM from 'react-dom';
import injectSheet from 'react-jss';
import PropTypes from 'prop-types';
import styles from './styles';

const ToolTipPortal = props => {
  const el = document.createElement('div');
  useEffect(() => {
    document.body.appendChild(el);
    return () => {
      document.body.removeChild(el);
    };
  }, []);
  return ReactDOM.createPortal(props.children, el);
};

const Tooltip = props => {
  const { width, space, children, classes, text, trigger } = props;

  const [visible, setVisible] = useState(false);
  const [style, setStyle] = useState({ width });

  const refTootip = useRef(null);
  const showTooltip = () => {
    if (visible) return;
    const dimensions = refTootip.current.getBoundingClientRect();
    const newLeft = Math.min(
      Math.max(space, dimensions.left + dimensions.width / 2 - width / 2) - 10,
      document.body.clientWidth - width - space
    );
    const newStyle = {
      ...style,
      left: newLeft,
      bottom: window.innerHeight - dimensions.top + space
    };
    setStyle(newStyle);
    setVisible(true);
  };

  const hideTooltip = () => {
    setVisible(false);
  };

  const toggleTooltip = () => (visible ? hideTooltip() : showTooltip());

  const control =
    trigger === 'click'
      ? {
          onClick: toggleTooltip
        }
      : {
          onMouseOver: showTooltip,
          onFocus: showTooltip,
          onMouseOut: hideTooltip,
          onBlur: hideTooltip
        };

  return (
    <span className={classes.container} {...control} ref={refTootip}>
      {children}

      {visible && (
        <ToolTipPortal>
          <div className={classes.tooltipBody} style={style}>
            {text}
          </div>
        </ToolTipPortal>
      )}
    </span>
  );
};

Tooltip.propTypes = {
  width: PropTypes.number,
  space: PropTypes.number,
  trigger: PropTypes.string
};

Tooltip.defaultProps = {
  width: 100, // Temp value
  space: 16,
  trigger: 'hover'
};

export default injectSheet(styles)(Tooltip);
