import { constants } from 'utils';
import axiosInstance from './interceptor';

const Article = {
  getArticles: (slug, currentPage) =>
    axiosInstance.get(
      `${constants.API}/category/articles?slug=${slug}&page=${currentPage}&per_page=12`
    ),
  getArticleDetails: (slug, idSlug) =>
    axiosInstance.get(`${constants.API}/article/${idSlug}?slug=${slug}`),
  applyToJob: (idJob, formData) =>
    axiosInstance
      .post(`${constants.API}/article/${idJob}/apply`, formData)
      .then(res => res)
      .catch(e =>
        e.response && (e.response.status === 401 || e.response.status === 422) && e.response.data
          ? e.response.data
          : new Error(e)
      ),
  postSendFormContact: (typeForm, name, phone, email, address, content) =>
    axiosInstance
      .post(`${constants.API}/contact`, {
        type: typeForm,
        name,
        email,
        phone,
        address,
        content
      })
      .then(res => res)
      .catch(e =>
        e.response && (e.response.status === 401 || e.response.status === 422) && e.response.data
          ? e.response.data
          : new Error(e)
      )
};

export default Article;
