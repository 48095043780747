/*
 * @Author: loc.do@bstarsolutions.com
 * @Date: 2019-08-02 16:10:48
 * @Last Modified by: Do Loc - doxuanloc2016@gmail.com
 * @Last Modified time: 2019-08-12 06:52:59
 */
import * as types from './types';

const INITIAL_STATE = {
  isFetching: false,
  resultRegisterEnterprise: {},
  listCourseEnterprise: []
};

export default (state = INITIAL_STATE, action) => {
  switch (action.type) {
    case types.POST_FORM_REGISTER_ENTERPRISE_FETCHING:
      return {
        ...state,
        isFetching: true
      };
    case types.POST_FORM_REGISTER_ENTERPRISE_SUCCESS:
      return {
        ...state,
        isFetching: false,
        resultRegisterEnterprise: action.payload.resultRegisterEnterprise
      };

    case types.POST_FORM_REGISTER_ENTERPRISE_FAIL:
      return {
        ...state,
        resultRegisterEnterprise: {},
        isFetching: false
      };
    case types.GET_LIST_COURSE_ENTERPRISE_FETCHING:
      return {
        ...state,
        isFetching: true
      };
    case types.GET_LIST_COURSE_ENTERPRISE_SUCCESS:
      return {
        ...state,
        isFetching: false,
        listCourseEnterprise: action.payload.listCourseEnterprise
      };

    case types.GET_LIST_COURSE_ENTERPRISE_FAIL:
      return {
        ...state,
        listCourseEnterprise: [],
        isFetching: false
      };

    default:
      return state;
  }
};
