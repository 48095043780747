/*
 * @Author: loc.do@bstarsolutions.com
 * @Date: 2019-06-10 17:21:49
 * @Last Modified by: Do Loc - doxuanloc2016@gmail.com
 * @Last Modified time: 2019-06-11 20:34:07
 */

import React from 'react';
import injectSheet from 'react-jss';

import styles from './styles';

const RadioButton = props => {
  const { classes, name, onClick, index, value, checked } = props;

  return (
    <div onClick={onClick} className={`${classes.container} radioCustom`}>
      <span className={classes.nameTop}>{name}</span>
      <input value={value} type="radio" defaultChecked={checked} name={`${index}`} />
      <span className={classes.name} />
    </div>
  );
};

RadioButton.defaultProps = {
  name: 'Radio Button',
  checked: 'checked'
};

export default injectSheet(styles)(RadioButton);
