import { theme } from 'appCore';

export default {
  popup: {
    position: 'relative',
    cursor: 'pointer'
  },
  list: {
    display: 'none',
    top: '100%',
    background: theme.colors.white,
    borderRadius: 10,
    boxShadow: `0px 5px 5px -3px ${theme.colors.gray}33,
      0px 8px 10px 1px ${theme.colors.gray}33,
      0px 3px 14px 2px ${theme.colors.gray}33`,
    padding: 5,
    right: 0
  },
  open: {
    display: 'flex',
    flexDirection: 'column',
    position: 'absolute',
    zIndex: 2
  },
  item: {
    padding: 5,
    textOverflow: 'ellipsis',
    overflow: 'hidden',
    whiteSpace: 'nowrap',
    '&:hover': {
      background: theme.colors.highlight
    },
    '&:first-child': {
      fontWeight: 'bold'
    },
    '&:last-child': {
      borderTop: `1px solid ${theme.colors.gray}33`
    },
    ...theme.components.itemPopup
  },
  value: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center'
  }
};
