/*
 * @Author: loc.do@bstarsolutions.com
 * @Date: 2019-05-14 14:33:58
 * @Last Modified by: loc.do@bstarsolutions.com
 * @Last Modified time: 2019-05-14 17:12:05
 */

export const LESSON_IN_COURSE_INIT = 'LESSON_IN_COURSE_INIT';
export const LESSON_IN_COURSE_FETCHING = 'LESSON_IN_COURSE_FETCHING';
export const LESSON_IN_COURSE_SUCCESS = 'LESSON_IN_COURSE_SUCCESS';
export const LESSON_IN_COURSE_FAIL = 'LESSON_IN_COURSE_FAIL';
