export default {
  general: {
    alert: 'Alert',
    error: 'Error',
    ok: 'Ok',
    noToken: 'Bạn chưa đăng nhập',
    networkError: 'Network Error',
    relogin: 'Re-Login',
    login: 'Login',
    loginStudent: 'Login for student',
    signup: 'Sign In',
    signupStudent: 'Signup for student',
    activeStudent: 'Active student',
    forgotPassword: 'Forgot password',
    confirm: 'Confirm',
    emailNotExist: 'We cant find a user with that e-mail address.',
    emailResetLink: 'We have e-mailed your password reset link !'
  },
  header: {
    language: 'Language',
    btnNameSignUp: 'Sign In / Sign Up',
    btnNameEnterprise: 'Enterprise',
    btnNameLanguage: 'Language',
    logout: 'Logout',
    profile: 'Profile',
    message: 'Message',
    adminClass: 'Classroom management',
    inforModal1: 'This section is for you who bought business class',
    inforModal2: `If you do not have, then go to the store page`,
    btnMoveShop: `Classroom at the Store`
  },
  footer: {
    copyright: `VIỆT LA BO Limited Liability Company. Business license 0309716416 provided by Department of 
    Planning and Investment in 08/01/2010. <br/>
      © 2017 - Vietlabo Copyright. Headquarter: 231/1 Ba Dinh, Ward 8, District 8, Ho Chi Minh City.`,
    downloadNow: 'Download Now',
    appStore: 'App Store',
    googlePlay: 'Google Play',
    hotline: 'Hotline',
    address: 'Address',
    email: 'Email',
    home: 'Home',
    contact: 'Contact',
    faq: 'FAQ',
    paymentGuide: 'Payment Guide',
    userManual: 'User Manual',
    about: 'About us',
    enterprise: 'Enterprise',
    privacyPolicy: 'Privacy Policy',
    studentPolicy: 'Student policy'
  },
  slidingBanner: {
    title: 'Giải pháp học tiếng nhật cho người bận rộn',
    subTitle: 'Học tiếng nhật trực tuyến - mọi lúc - mọi nơi - mọi chỗ trên mọi thiết bị ',
    appStore: 'App Store',
    googlePlay: 'Google Play',
    nowStudy: 'Học ngay thôi',
    titleBottom: `Download app on google play or app store to study online by phone or computer`
  },
  login: {
    title: 'Login',
    active: 'Free Sign Up',
    activeAccount: 'Signup for student',
    loginNowButton: 'Login now',
    forgetPassword: 'Forget your password',
    wantToRegister: "You don't have Rainichi account. Let's ",
    wantToLogin: "You had Rainichi account. Let's ",
    signUpNow: 'Signup now!',
    loginNow: 'Login now!',
    loginNowWIth: 'Login with',
    yourEmail: 'Your Email',
    password: 'Password',
    activeNow: 'Active now',
    name: 'Fullname',
    phone: 'Phone number',
    repassword: 'Confirm password',
    activationToken: 'Activation code',
    sendViaMail: 'Send message via mail',
    passcodeInMail: 'Activation code has been sent to your email',
    password_wrong_format: 'Password must contain at least one number and must least 8 character.',
    name_wrong_format: 'Name is required',
    phone_wrong_format: 'Phone is required',
    invalidCode: 'This activation code is invalid.'
  },
  coursePage: {
    day: 'day',
    course: 'Courses'
  },
  introCourse: {
    btnTrial: 'Trial',
    btnBuy: 'Buy Now',
    btnStudy: 'Study Now'
  },
  vocabularySlide: {
    theSentence: 'The Sentence',
    chineseVietnameseWord: 'Chinese Vietnamese Word',
    meaning: 'Meaning',
    example: 'Example'
  },
  vocabularyDetail: {
    origin: 'Original',
    resultRecord: 'Speech Recognize Result',
    resultFail: 'Not Pass',
    resultPass: 'Pass',
    speak: 'Speak It Again!'
  },
  practice: {
    btnSubmit: 'Submit',
    btnTranslate: 'Translate',
    btnTranslateOrigin: 'Show Text Origin',
    btnHintShow: 'Show Hint',
    btnHintHide: 'Hide Hint',
    checkPreventPage: `The exam's result will not store. Do you want to cancel? `,
    notData: 'No data',
    btnGoBack: 'Go Back',
    message: 'Message',
    disconnect: 'The internet was disconnected',
    submitExam: 'Want to submit the exam?',
    no: 'Cancel',
    goToPageListSectionExam: 'Go to List Exam Page',
    descriptionExamNow: `Are you ready to take the exam yet?
     Click the Exam Now button to enter the exam.`,
    btnClose: 'Close',
    titleExam: 'Contest',
    btnStartContest: 'Start Contest',
    timeContest: 'Time will be',
    time: 'minutes',
    notPractice: `The Conversation section has no practice.
    Please click Open the next lesson to proceed to the next lesson.`,
    notPracticeCommon: `The section has no practice.
    Please click Open the next lesson to proceed to the next lesson.`,
    goodLuckPractice: `Wish you have fun learning!`,
    btnNextLesson: 'Open the next lesson',
    notTime: 'Free Time'
  },
  submitPractice: {
    titleResult: 'Result',
    contentResult: 'My score',
    passResult: 'Pass',
    failResult: 'Failure',
    failResultExam: 'OK',
    failScore: 'Fail Score',
    score: 'Score',
    questions: 'Questions',
    resultContest: 'Result',
    resultDetail: 'Result Detail',
    seeResults: 'See Results',
    summary: 'Summary',
    passScore: 'Pass Score ',
    nextPractice: `The next lesson has been opened.
    Wish you have fun learning!`,
    titleNotPractice: 'Message',
    victory: 'Congratulations, you have achieved'
  },
  listen: {
    empty: 'Not data',
    showTip: 'Show Tip',
    hideTip: 'Hide Tip',
    btnPrev: 'Prev',
    btnNext: 'Next',
    sentence: 'The Sentence'
  },
  examPage: {
    day: 'day',
    exam: 'Exams'
  },
  exam: {
    exam: `Exams`,
    description: `Excepteur sint occaecat cupidatat non proident, sunt in culpa qui officia deserunt
     mollit anim id est laborum. This is page exams`,
    title: 'MESSAGE',
    descriptionLevel: 'Please select the appropriate level',
    submitOK: 'Choose',
    chooseLevel: 'Choose level'
  },
  examChild: {
    examNotFound: `Can't not find any exams`,
    descriptionExam: 'Please select the exam format below. Thank you !',
    free: 'Free',
    title: 'MESSAGE',
    hasCountTime: 'Count time',
    noneCountTime: 'Free time',
    titleBuyNow: 'Buy Now',
    titleGetExercise: 'Exam Now',
    titleGetExerciseFree: 'Start Exam',
    youTookTheExam: 'You took the Exam',
    checkPreventPage: `The exam's result will not store. Do you want to cancel? `,
    btnAccept: 'Accept',
    btnNotAccept: 'Cancel',
    descriptionToTalSubmit: `You have not completed all the parts,
    would you like to submit the test or continue?`,
    duration: 'Duration',
    btnToTalSubmit: 'Submit the General Test',
    atExam: 'List',
    time: 'minutes'
  },
  store: {
    name: 'Shops',
    buyCoin: 'Buy Coin',
    buyCourse: 'Course',
    buyExam: 'Exam',
    buyClass: 'Class'
  },
  blockCoin: {
    btnBuy: 'Buy Now',
    coin: 'Coin'
  },
  blockBuyCourse: {
    valueDefaultCourse: 'Select a course',
    valueDefaultLevel: 'Select a level',
    btnBuy: 'Buy Now',
    title: 'Buy Course',
    confirmBuySuccess: 'Purchase course sucessfully',
    confirmBuyFail: 'Purchase course failure',
    confirmNotCoin: `Your balance is insufficient.
    Please deposit coins to buy the course`,
    btnConfirm: 'Accept',
    confirm: `You have already bought this course. The remaining
    time is`,
    confirmMore: 'Do you  want to buy more?',
    day: 'date',
    confirmSure: 'Do you want to buy this course?',
    onTime: 'Unlimited',
    coin: 'Coin',
    successClass: `Create classroom & add course successfully.
    Please contact admin and login to admin page`,
    titleClass: 'Class',
    managementClass: 'Classroom management',
    requestBuyClass: 'Request',
    btnStartStudy: 'Start learning',
    btnStartExam: 'Start the exam',
    stepBuy: 'Continue to buy',
    btnBuyCoin: 'Buy Coin',
    durationForever: `You have purchased this course for life.
    You do not need to redeem the course. Thank you`
  },
  blockBuyExam: {
    valueDefaultPackageExam: 'Select a package',
    valueDefaultLevel: 'Select a level',
    valueDefaultExam: 'Select an exam',
    btnBuy: 'Buy Now',
    coin: 'Coin',
    confirm: 'Do you want to buy this exam?',
    btnConfirm: 'Accept',
    title: 'Buy Exams',
    confirmBought: 'This exam has already been bought',
    confirmBuySuccess: 'Purchase exam sucessfully',
    confirmBuyFail: 'Purchase exam failure',
    confirmNotCoin: `Your balance is insufficient.
    Please deposit coins to buy the exam`,
    btnBought: 'Bought',
    free: 'Free',
    createdClass: `You have classroom. 
    Please go to admin page buy more course
    `
  },
  blockPayment: {
    title: 'Choose a the payment',
    transfer: 'Transfer',
    online: 'Onlline payment',
    center: 'Contact Rainichi center',
    btnConfirm: 'Create an order',
    infoTransfer1: `Your order has been shipped to Rainichi's system.`,
    infoTransfer2: `Please pay to the following account of Rainichi.`,
    infoTransfer3: `More detail please call `,
    infoTransfer4: `Your order has been recorded, please arrive official office at`,
    infoTransfer6: `More detail please call `,
    infoBank1: `VCB - VietCombank`,
    infoBank2: `Account name: Nguyen Duy Cuong`,
    infoBank3: `Account number : 0181003435738`,
    infoBank4: `Branch: District 4`,
    infoBank5: `City: Ho Chi Minh`,
    message: 'Message',
    infoOffline: `Please come to Rainichi center at: `
  },
  alphabet: {
    listen: 'Pronounce'
  },
  infoPractice: {
    theSentence: 'The Sentence'
  },
  countTime: {
    title: 'You Are Taking The Exam',
    withTime: 'With Countdown Time',
    freeTime: 'Free Time',
    btnSubmit: 'Submit',
    message: 'Message',
    disconnect: 'The internet was disconnected'
  },
  profile: {
    titleLevel: 'Current Level',
    btnBuyCoin: 'Buy More',
    remainingCoin: 'Remaining',
    myProfile: 'My Profile',
    myExams: 'My Exams',
    myCourse: 'My Courses',
    support: 'Support',
    credit: 'Credit',
    setting: 'Setting',
    methodsCredit: 'Method of gaining coins'
  },
  modalUpdateInformation: {
    title: 'Update Information',
    success: 'Update information Successfully',
    fail: 'Update information failed',
    failImage: 'Image does not support this format or email already exists',
    passwordFail: 'Password does not match',
    passwordSameFail: 'The new password and password must be different.',
    failEmptyName: 'The name cannot be empty'
  },
  updateInformation: {
    title: 'Update Information',
    btnChangeInformation: 'Change Information',
    titleAvatar: 'Avatar Representative',
    btnUploadAvatar: 'Choose your avatar',
    or: 'OR',
    chooseAvatarDefault: 'Choose an existing Avatar',
    btnUpdate: 'Update',
    checkNaN: 'Data must be numeric ',
    checkValidatePhone: 'Phone number must be exactly 10 digits',
    checkIsEmpty: 'Data cannot be blank',
    message: 'Message',
    checkEmail: 'Email not valid',
    checkName: 'Name cannot be empty',
    checkEmailEmpty: 'Email cannot be empty',
    addressIsEmpty: 'You have not enterd a address',
    contentIsEmpty: 'Please send a message to Rainichi',
    phoneIsZero: `You should not enter too many zeroes`,
    passwordTheSame: 'Password and password entered do not match',
    passwordNotIsCorrect: `Password must contain at least one number
    and must be at least 8 characters long.`,
    checkPasswordNew: `You have not entered a new password`,
    courseNotSelect: 'You have not selected course',
    studentNull: 'You have not entered a student number'
  },
  modalCheckLogin: {
    title: 'Login',
    checkUserExits: 'Email has already been taken',
    checkFailUserExist: 'Email and password are not correct',
    checkEmailNotEnter: 'You have not entered an email',
    checkEmail: 'You have not entered the correct email syntax',
    checkPasswordNotEnter: 'You have not entered a password'
  },
  myExams: {
    title: `Your Exam`,
    titleExam: 'Exam',
    examResult: 'Result',
    examSectionResult: 'Result Times',
    notData: 'You have no exams'
  },
  yourBlock: {
    scorePractice: 'Point',
    learningResult: 'Learning Result',
    date: 'Date',
    btnExam: 'Start',
    btnDetail: 'Detail',
    btnStudyNow: 'Learning',
    nope: 'Nope',
    fail: 'Failed',
    pass: 'Pass',
    progress: 'Progress',
    statusIncomplete: 'Incomplete',
    statusComplete: 'Done',
    statusStudying: 'In progress',
    statusNotStudy: 'New',
    day: 'date',
    expirationDate: 'Expiration date',
    status: 'Status',
    forever: 'Forever',
    score: 'Score',
    time: 'Time',
    resultListQuestion: 'Exam history',
    resultHistoryMyExam: 'Result',
    resultHistoryRight: `It's correct`,
    resultHistoryWrong: `It's incorrect`
  },
  myCourses: {
    learningResult: 'Learning Result',
    student: 'Student',
    course: 'Course',
    notData: 'You have no courses'
  },
  myCoin: {
    title: 'Trading History',
    coinCurrent: 'Coin Current',
    buyCoin: 'Buy',
    quantity: 'Coin Number',
    dateBuy: 'Date',
    notTransactions: 'Not have any transaction',
    changeCoin: 'Trade',
    dateChange: 'Date',
    rewardCoin: 'Get',
    receivedDate: 'Date'
  },
  methodCredit: {
    title: 'Method',
    quantity: 'Coin Number',
    method: 'Method'
  },
  lessonInCourse: {
    notData: 'No data',
    message: 'Message',
    buy: 'You must complete the previous lesson to continue this lesson. Thank you.',
    bought: 'You need to buy this course to study this part. Thank you.',
    btnBuy: 'Buy Now'
  },
  settings: {
    passwordNotSame: 'The confirmation password is not the same as the new password',
    checkPassword: `
    Password must contain at least one number and must have at least 8 characters`,
    passwordNotEnter: 'You have not entered the old password',
    showAnnoument: 'Notification',
    remindStudy: 'Reminder',
    title: 'Change Password',
    placeholderPasswordOld: 'Old password',
    placeholderPasswordNew: 'New password',
    confirmPassword: 'Confirm new password',
    btnAccept: 'Accept'
  },
  article: {
    news: `News`,
    notData: 'Not Data'
  },
  articleDetail: {
    btnApply: 'Apply Now',
    btnApplyed: 'Applied',
    btnShareFacebook: 'Share Facebook',
    btnShareGooglePlust: 'Share Google+',
    btnShareTwitter: 'Share Twitter',
    articlePrevNotData: 'No previous posts',
    articleNextNotData: 'No next post',
    expireDate: 'Expiration Date'
  },
  modalContent: {
    resultDescriptionJob: 'You have successfully applied',
    title: 'Job',
    checkCV: 'CV is not empty',
    checkEmail: 'Email is not empty',
    emailValid: 'Email is not valid',
    checkName: 'Name is not empty',
    checkDescription: 'Description is not empty',
    checkCVIsApplied: 'You have already submitted this CV',
    checkCVMaxFile: 'File size does not exceed 1MB',
    fileEmpty: 'Please fill in the CV file'
  },
  faq: {
    frequentlyAskedQuestions: 'FREQUENTLY ASKED QUESTIONS'
  },
  slideCenter: {
    registerStudy: 'Register now'
  },
  contact: {
    fullName: 'Fullname',
    enterFullName: 'Enter Fullname',
    phone: 'Phone Number',
    enterPhone: 'Enter phone number',
    email: 'Email',
    enterEmail: 'Enter email',
    address: 'Address',
    enterAddress: 'Enter address',
    message: 'Your message to Rainichi Center',
    enterMessage: 'Please send a message to Rainichi',
    btnSend: 'Send'
  },
  applyJob: {
    btnComeBack: 'Back',
    applyToJob: 'Apply',
    nameCandidate: 'Your name',
    enterNameCandidate: 'Enter your name',
    emailCandidate: 'Your email',
    enterEmailCandidate: 'Enter your email',
    uploadMyCV: 'Upload Your CV',
    uploadFile: 'Choose file',
    notFile: 'No files selected',
    descriptionStrongMySkill: 'Describe your strengths',
    enterStrongMySkill: 'Enter your strengths',
    btnSendCV: 'Send CV'
  },
  pageAnnoumentMobile: {
    message: 'Notification',
    subMessage: `Please use 1 of the following 2 methods to use this section`,
    useDesktop: `Use the version shown on the computer`,
    useMobile: `Download the application from Apple Store or Google to use`
  },
  modalContact: {
    title: 'Message',
    emptyName: 'You did not enter a first and last name',
    emptyEmail: 'You have not entered an email',
    emptyPhone: 'You did not enter a phone number',
    emptyCourse: 'You have not selected a course',
    emptyNumOfEmployee: 'You have not selected the number of students',
    registerSuccess: `You have successfully registered.
    We will contact you later.
    Thank you.`,
    emptyAddress: `You do not enter an address`,
    emptyContent: `Please send a message to Rainichi`,
    sendSuccess: `Thank you for contacting us.`,
    phoneIsZero: `You should not enter too many zeroes`,
    phoneNotValid: 'Your phone number is incorrect',
    emailNotValid: 'Email is not correct syntax',
    nameCourse: 'Buy Course',
    enterName: 'Enter Fullname',
    enterPhone: 'Enter Number Phone',
    enterEmail: 'Enter Email',
    enterContent: 'Enter messsage for Rainichi',
    btnRegister: 'Register',
    btnRegisterNow: 'Register Now',
    userExist: `You already have classes. Do you want to buy more classes?`,
    userJoined: `Students have entered the class.
    This function is only for students who do not have a class`,
    notAccept: 'Disagree',
    accept: 'Agree'
  },
  buyClass: {
    buyClassNotExist: 'Buy classes as required',
    enterNumberOfEmployee: 'Enter the number of students',
    enterCourse: 'Select course',
    btnRequest: 'Request'
  },
  strengthForEnterprise: {
    titlePrimary: 'Strengths when businesses use the application',
    titleBlack: 'Class management features',
    btnMovePageAdmin: 'Course management page'
  },
  enterprise: {
    btnBuyClass: 'Buy Class'
  },
  joinCenter: {
    day: 'day',
    hour: 'hours',
    minutes: 'minute',
    seconds: 'seconds',
    selectCourse: 'Choose a course',
    allItemAreSelected: 'Selected all courses',
    selectAll: 'Select a course',
    search: 'Search',
    expired: 'Expired',
    quickUp: 'QUICK UP',
    getGoodDeals: 'You Want To Get Good Deals'
  },
  allCoursePageCenter: {
    btnRegisterNow: 'Register Now'
  },
  commingSoon: {
    btnRegister: 'Register'
  },
  training: {
    btnJoinTeam: `Join the "Rainichi" team today`
  },
  joinEnterprise: {
    descriptionIntro: `Discovering his super powers with Rainichi !!!!!`,
    titlePrimaryIntro: `TEAM RAINICHI`,
    titleBlackIntro: `JOIN`,
    registerCourseEnterprise: `REGISTRATION CLASS FOR BUSINESS`,
    youWantSale: `You Want To Get Good Deals`,
    selectNumberStudent: `Select the number of students`,
    registerNow: `REGISTER NOW`
  },
  articleJob: {
    workPlace: `Workplace`,
    expire: `Expired`,
    salary: `Salary`,
    position: `Position`
  },
  seoTitle: {
    event: `EVENT`,
    jobs: `JOBS`,
    faq: `FAQ`,
    center: `CENTER`,
    store: `STORE`,
    exam: `EXAM`,
    course: `COURSES`,
    enterprise: `ENTERPRISE`,
    profile: `PROFILE`,
    login: `SIGN IN`,
    home: `RAINICHI`
  }
};
