/*
 * @Author: Do Loc - doxuanloc2016@gmail.com
 * @Date: 2019-06-22 19:24:46
 * @Last Modified by: Do Loc - doxuanloc2016@gmail.comail.com
 * @Last Modified time: 2019-08-12 22:30:00
 */

import React from 'react';
import injectSheet from 'react-jss';
import { translator } from 'utils';
import { ModalContent } from 'components';

import styles from './styles';

const ModalArticle = props => {
  const {
    strings,
    success,
    cv,
    email,
    emailValid,
    name,
    description,
    cvIsAppplied,
    maxFile,
    fileEmpty
  } = props;

  const lang = strings.modalContent;
  return (
    <ModalContent isButton={false} title={lang.title}>
      {success && <span>{lang.resultDescriptionJob}</span>}
      {cv && <span>{lang.checkCV}</span>}
      {email && <span>{lang.checkEmail}</span>}
      {emailValid && <span>{lang.emailValid}</span>}
      {name && <span>{lang.checkName}</span>}
      {description && <span>{lang.checkDescription}</span>}
      {cvIsAppplied && <span>{lang.checkCVIsApplied}</span>}
      {maxFile && <span>{lang.checkCVMaxFile}</span>}
      {fileEmpty && <span>{lang.fileEmpty}</span>}
    </ModalContent>
  );
};

ModalArticle.defaultProps = {
  success: false,
  cv: false,
  email: false,
  emailValid: false,
  name: false,
  description: false,
  cvIsAppplied: false,
  maxFile: false,
  fileEmpty: false
};

export default translator(injectSheet(styles)(ModalArticle));
