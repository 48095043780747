/*
 * @Author: loc.do@bstarsolutions.com
 * @Date: 2019-06-07 13:56:40
 * @Last Modified by: loc.do@bstarsolutions.com
 * @Last Modified time: 2019-10-15 11:12:14
 */

import React from 'react';
import injectSheet from 'react-jss';
import { translator } from 'utils';
import c from 'classnames';
import { Block, Button } from 'components';
import styles from './styles';
import { constants } from '../../utils';

function BlockCoin(props) {
  const { classes, image, coin, priceOnSale, price, className, strings, onClick } = props;

  const lang = strings.blockCoin;
  return (
    <Block className={c(className, classes.block)}>
      <div className={classes.top}>
        <img className={classes.img} src={image} alt="Coin" />
        <span className={classes.coin}>
          {coin} {lang.coin}
        </span>
      </div>
      <div className={classes.bottom}>
        <span className={classes.priceOnSale}>
          {priceOnSale && `${constants.formatNumber(priceOnSale)}`}
        </span>
        <span className={classes.price}>{price && `${constants.formatNumber(price)}`} </span>
        <Button className={classes.btn} label={lang.btnBuy} onClick={onClick} />
      </div>
    </Block>
  );
}

BlockCoin.defaultProps = {
  image:
    // eslint-disable-next-line max-len
    'https://stg.api.rainichi.com/storage/files/credit/images/ac3135ef3049f1b5952f26ad3921383fPwVfAd.jpg',
  coin: '400',
  priceOnSale: '400.000',
  price: '450.000'
};

export default translator(injectSheet(styles)(BlockCoin));
