/*
 * @Author: loc.do@bstarsolutions.com
 * @Date: 2019-08-02 16:10:48
 * @Last Modified by: loc.do@bstarsolutions.com
 * @Last Modified time: 2019-08-20 15:43:42
 */
import * as types from './types';

const INITIAL_STATE = {
  isFetching: false,
  listSlide: [],
  listGallery: [],
  listLecturers: [],
  listCourseInHome: [],
  resultBuyCourseInHome: {},
  listAllDataLandingPage: []
};

export default (state = INITIAL_STATE, action) => {
  switch (action.type) {
    case types.GET_LIST_SLIDE_FETCHING:
      return {
        ...state,
        isFetching: true
      };
    case types.GET_LIST_SLIDE_SUCCESS:
      return {
        ...state,
        isFetching: false,
        listSlide: action.payload.listSlide
      };

    case types.GET_LIST_SLIDE_FAIL:
      return {
        ...state,
        listSlide: [],
        isFetching: false
      };
    case types.GET_LIST_GALLERY_FETCHING:
      return {
        ...state,
        isFetching: true
      };
    case types.GET_LIST_GALLERY_SUCCESS:
      return {
        ...state,
        isFetching: false,
        listGallery: action.payload.listGallery
      };

    case types.GET_LIST_GALLERY_FAIL:
      return {
        ...state,
        listGallery: [],
        isFetching: false
      };
    case types.GET_LIST_LECTURERS_FETCHING:
      return {
        ...state,
        isFetching: true
      };
    case types.GET_LIST_LECTURERS_SUCCESS:
      return {
        ...state,
        isFetching: false,
        listLecturers: action.payload.listLecturers
      };

    case types.GET_LIST_LECTURERS_FAIL:
      return {
        ...state,
        listLecturers: [],
        isFetching: false
      };
    case types.GET_LIST_COURSE_IN_HOME_FETCHING:
      return {
        ...state,
        isFetching: true
      };
    case types.GET_LIST_COURSE_IN_HOME_SUCCESS:
      return {
        ...state,
        isFetching: false,
        listCourseInHome: action.payload.listCourseInHome
      };

    case types.GET_LIST_COURSE_IN_HOME_FAIL:
      return {
        ...state,
        listCourseInHome: [],
        isFetching: false
      };
    case types.REGISTER_BUY_COURSE_IN_HOME_FETCHING:
      return {
        ...state,
        isFetching: true
      };
    case types.REGISTER_BUY_COURSE_IN_HOME_SUCCESS:
      return {
        ...state,
        isFetching: false,
        resultBuyCourseInHome: action.payload.resultBuyCourseInHome
      };

    case types.REGISTER_BUY_COURSE_IN_HOME_FAIL:
      return {
        ...state,
        resultBuyCourseInHome: {},
        isFetching: false
      };
    case types.GET_LIST_ALL_DATA_LANDING_PAGE_FETCHING:
      return {
        ...state,
        isFetching: true
      };
    case types.GET_LIST_ALL_DATA_LANDING_PAGE_SUCCESS:
      return {
        ...state,
        isFetching: false,
        listAllDataLandingPage: action.payload.listAllDataLandingPage
      };

    case types.GET_LIST_ALL_DATA_LANDING_PAGE_FAIL:
      return {
        ...state,
        listAllDataLandingPage: [],
        isFetching: false
      };
    default:
      return state;
  }
};
