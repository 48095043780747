/*
 * @Author: loc.do@bstarsolutions.com
 * @Date: 2019-05-10 10:49:01
 * @Last Modified by: loc.do@bstarsolutions.com
 * @Last Modified time: 2019-10-22 11:51:23
 */

import { constants } from 'utils';
import axiosInstance from './interceptor';

const Courses = {
  getCourses: () => axiosInstance.get(`${constants.API}/course`),
  getCoursesNotAuthen: () => axiosInstance.get(`/pure-route/course`),
  getCourseChild: id => axiosInstance.get(`/course/children/${id}`),
  getCourseChildNotAuthen: id =>
    axiosInstance.get(`${constants.API}/pure-route/course-children/${id}`),
  getCourseDetail: id => axiosInstance.get(`${constants.API}/course/${id}`),
  getCourseDetailNotAuthen: id => axiosInstance.get(`${constants.API}/pure-route/course/${id}`),
  getLessonInCourse: id =>
    axiosInstance
      .get(`/course/get-lesson-by/${id}`)
      .then(res => res)
      .catch(e =>
        e.response && e.response.status === 401 && e.response.data
          ? e.response.data
          : e.response.data
      ),
  getVocabInLesson: lessonId =>
    axiosInstance.get(`${constants.API}/lesson/get-vocabulary/${lessonId}?per_page=1000`),
  geGrammarInLesson: lessonId =>
    axiosInstance.get(`${constants.API}/grammar/in-lesson/${lessonId}?per_page=1000`),
  geReadingInLesson: lessonId =>
    axiosInstance.get(`${constants.API}/reading/in-lesson/${lessonId}?per_page=1000`),
  getListeningInLesson: lessonId =>
    axiosInstance.get(`${constants.API}/listening/in-lesson/${lessonId}?per_page=1000`),
  getExerciseInLesson: lessonId =>
    axiosInstance.get(`${constants.API}/exercise/in-lesson/${lessonId}?per_page=1000`),
  getConversationInLesson: lessonId =>
    axiosInstance.get(`${constants.API}/conversation/in-lesson/${lessonId}?per_page=1000`),
  getKanjiInLesson: lessonId =>
    axiosInstance.get(`${constants.API}/kanji/in-lesson/${lessonId}?per_page=1000`)
};

export default Courses;
