/*
 * @Author: loc.do@bstarsolutions.com
 * @Date: 2019-08-08 16:51:19
 * @Last Modified by: loc.do@bstarsolutions.com
 * @Last Modified time: 2019-08-22 08:56:18
 */

import React from 'react';
import injectSheet from 'react-jss';
import { images } from 'appCore';
import { translator } from 'utils';
import styles from './styles';

const PageAnnoumentMobile = ({ classes, strings }) => {
  const lang = strings.pageAnnoumentMobile;
  const goToGooglePlay = () => {
    window.open('https://play.google.com/store/apps/details?id=com.rainichi', '_blank');
  };

  const goToAppleStore = () => {
    window.open('https://apps.apple.com/us/app/rainichi/id1472177022', '_blank');
  };

  return (
    <div className={classes.container}>
      <div className={classes.wrapMessage}>
        <span className={classes.messsage}>{lang.message.toLocaleUpperCase()}</span>
      </div>
      <div className={classes.wrapSubMessage}>
        <span className={classes.subMessage}>{lang.subMessage}</span>
      </div>
      <div className={classes.wrapImgDesktop}>
        <img className={classes.imgDesktop} src={images.desktop} alt="Desktop" />
        <span className={classes.useDesktop}>1./ {lang.useDesktop}</span>
      </div>
      <div className={classes.wrapImgDesktop}>
        <div className={classes.buttonStore}>
          <img
            onClick={goToAppleStore}
            className={classes.googlePlay}
            src={images.google}
            alt="Desktop"
          />
          <img
            onClick={goToGooglePlay}
            className={classes.appleStore}
            src={images.apple}
            alt="Desktop"
          />
        </div>
        <span className={classes.useDesktop}>2./ {lang.useMobile}</span>
      </div>
    </div>
  );
};

export default translator(injectSheet(styles)(PageAnnoumentMobile));
