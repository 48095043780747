/*
 * @Author: loc.do@bstarsolutions.com
 * @Date: 2019-05-10 11:04:10
 * @Last Modified by: loc.do@bstarsolutions.com
 * @Last Modified time: 2019-06-06 16:02:55
 */

import { createStructuredSelector } from 'reselect';

const resultSpeechToText = state => state.lessonBySkill.resultSpeechToText;
const statusSpeechToText = state => state.lessonBySkill.statusSpeechToText;
const resultDisableMic = state => state.modalVocabularyDetail.statusDisableMic;
const resultDisableVolume = state => state.modalVocabularyDetail.statusDisableVolume;

export default createStructuredSelector({
  resultSpeechToText,
  statusSpeechToText,
  resultDisableMic,
  resultDisableVolume
});
