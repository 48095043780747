import { signUptypes } from 'containers/SignUp/duck';
import StorageFactory from 'services/storageFactory';

const storage = StorageFactory.getStorage();
/* eslint-disable */
import { merge } from 'lodash';
import * as types from './types';

const INITIAL_STATE = {
  error: '',
  isFetching: false,
  accessTokenNew: null,
  status: false,
  readyToContinue: false,
  user: {
    access_token: storage.getItem('access_token'),
    refresh_token: storage.getItem('refresh_token')
  }
};

export default (state = INITIAL_STATE, action) => {
  switch (action.type) {
    case types.LOGIN_SUCCESS:
      return {
        ...state,
        status: action.payload.status,
        user: action.payload.user,
        readyToContinue: true
      };
    case types.AUTO_LOGIN_SUCCESS:
      // eslint-disable-next-line no-case-declarations
      const user = merge(state.user, action.payload.data);
      return {
        ...state,
        ...user,
        status: action.payload.status,
        accessTokenNew: action.payload.access_token_new,
        readyToContinue: true
      };
    case types.AUTO_LOGIN_FAILURE:
      return {
        ...state,
        status: false,
        user: null,
        accessTokenNew: null,
        readyToContinue: false,
        isFetching: false
      };
    case types.LOGIN_SOCIAL_SUCCESS:
      return {
        ...state,
        status: action.payload.status,
        user: action.payload.user,
        readyToContinue: true
      };

    case signUptypes.SIGNUP_ACTIVE_SUCCESS:
      return {
        ...state,
        status: action.payload.status,
        user: action.payload.user,
        isFetching: false,
        error: ''
      };
    case types.LOGIN_FETCHING:
    case types.AUTO_LOGIN_FETCHING:
      return {
        ...state,
        isFetching: true,
        error: '',
        readyToContinue: false
      };

    case types.LOGIN_SOCIAL_FETCHING:
      return {
        ...state,
        isFetching: true,
        error: '',
        readyToContinue: false
      };

    case types.LOGIN_FAILURE:
      return {
        ...state,
        error: action.payload,
        isFetching: false,
        readyToContinue: false
      };

    case types.LOGIN_SOCIAL_FAILURE:
      return {
        ...state,
        error: action.payload,
        isFetching: false,
        readyToContinue: false
      };

    case types.LOGIN_ERROR_EMPTY:
      return {
        ...state,
        error: '',
        readyToContinue: false
      };
    case types.LOGOUT:
      return {
        ...INITIAL_STATE,
        user: {}
      };
    default:
      return state;
  }
};
