import React from 'react';
import injectSheet from 'react-jss';
import c from 'classnames';
import { translator } from 'utils';
import { Block } from 'components';
import { images } from 'appCore';
import styles from './styles';

function BlockExpand(props) {
  const {
    classes,
    onClick,
    key,
    lable,
    description,
    className,
    icon,
    classNameIcon,
    classNameLable
  } = props;

  return (
    <Block key={key} className={classes.expandBlock} onClick={onClick}>
      <div className={classes.expandItem}>
        <span className={c(classes.expandLable, classNameLable)}>{lable}</span>
        <img className={c(classes.expandIcon, classNameIcon)} src={icon} alt="Expand"></img>
      </div>
      <div className={c(className, classes.expandDescription)}>{description}</div>
    </Block>
  );
}

BlockExpand.defaultProps = {
  id: 1,
  name: 'Demo Block',
  image: images.next,
  icon: images.next
};
export default translator(injectSheet(styles)(BlockExpand));
