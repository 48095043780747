/*
 * @Author: loc.do@bstarsolutions.com
 * @Date: 2019-06-25 10:46:28
 * @Last Modified by: loc.do@bstarsolutions.com
 * @Last Modified time: 2019-07-22 10:52:24
 */

import * as types from './types';

export const getInformationUser = () => ({
  type: types.GET_INFOMATION_USER_INIT
});

export const updateInformationUser = formData => ({
  type: types.UPDATE_INFOMATION_USER_INIT,
  payload: {
    formData
  }
});

export const getAvatarDefault = () => ({
  type: types.GET_AVATAR_DEFAULT_INIT
});

export const getMyExams = () => ({
  type: types.GET_MY_EXAM_INIT
});

export const getMyExamDetail = idExamSection => ({
  type: types.GET_MY_EXAM_DETAIL_INIT,
  payload: {
    idExamSection
  }
});

export const showDetailMyExam = () => ({
  type: types.SHOW_DETAIL_MY_EXAM
});

export const hideDetailMyExam = () => ({
  type: types.HIDE_DETAIL_MY_EXAM
});

export const showDetailMyExamSection = () => ({
  type: types.SHOW_DETAIL_MY_EXAM_SECTION
});

export const hideDetailMyExamSection = () => ({
  type: types.HIDE_DETAIL_MY_EXAM_SECTION
});

export const getMyCourses = () => ({
  type: types.GET_MY_COURSE_INIT
});

export const showDetailMyCourseDetail = () => ({
  type: types.SHOW_DETAIL_MY_COURSE_DETAIL
});

export const hideDetailMyCourseDetail = () => ({
  type: types.HIDE_DETAIL_MY_COURSE_DETAIL
});

export const getMyCourseDetail = idCourse => ({
  type: types.GET_MY_COURSE_DETAIL_INIT,
  payload: {
    idCourse
  }
});

export const getMyCoins = () => ({
  type: types.GET_MY_COIN_INIT
});

export const getMethodCredits = () => ({
  type: types.GET_MEMTHOD_CREDIT_INIT
});

export const updatePassword = formData => ({
  type: types.UPDATE_NEW_PASSWORD_INIT,
  payload: {
    formData
  }
});

export const saveMyCourseId = (courseId, courseName) => ({
  type: types.SAVE_MY_COURSE_ID,
  payload: {
    courseId,
    courseName
  }
});

export const saveAvatarDefault = myAvatarDefault => ({
  type: types.SAVE_AVATAR_DEFAULT,
  payload: {
    myAvatarDefault
  }
});

export const removeAvatarDefault = () => ({
  type: types.REMOVE_AVATAR_DEFAULT
});

export const savePositionAvatarDefault = myPositionAvatarDefault => ({
  type: types.SAVE_POSITION_AVATAR_DEFAULT,
  payload: {
    myPositionAvatarDefault
  }
});

export const removePositionAvatarDefault = () => ({
  type: types.REMOVE_POSITION_AVATAR_DEFAULT
});

export default this;
