import { theme } from 'appCore';
import { scaleStyle } from 'utils';

export default {
  wrapper: {
    display: 'flex',
    flexDirection: 'column',
    width: '100%'
  },
  bottom: {
    display: 'flex',
    justifyContent: 'center',

    ...scaleStyle({
      height: 60
    })
  },
  word: {
    ...scaleStyle({
      height: 280
    })
  },
  vocabulary: {
    ...scaleStyle({
      fontSize: 200,
      lineHeight: '200px'
    })
  },
  spelling: {
    textAlign: 'center',
    ...scaleStyle({
      lineHeight: '60px',
      marginBottm: 60,
      fontSize: 30
    })
  },
  button: {
    display: 'flex',

    background: theme.colors.secondary,
    transition: 'all 1s',
    cursor: 'pointer',
    '&:hover': {
      transform: 'rotate(180deg)'
    },
    ...scaleStyle({
      margin: '0 20px',
      width: 60,
      height: 60,
      borderRadius: 30
    })
  },
  reload: {
    margin: 'auto'
  },
  play: {
    cursor: 'pointer',
    transition: 'all 1s',
    background: 'white',
    '&:hover': {
      background: theme.colors.secondary
    },
    ...scaleStyle({
      borderRadius: 30
    })
  },
  disable: {
    cursor: 'default',
    mixBlendMode: 'luminosity',
    pointerEvents: 'none'
  },
  blockKanji: {
    ...scaleStyle({
      margin: 20
    })
  },
  blockWordKanji: {
    display: 'flex',
    width: '100%'
  },
  blockWordKanjiExample: {
    display: 'flex',
    width: '100%',
    flexDirection: 'column'
  },
  wordKanjiFirst: {
    width: '100%',
    display: 'flex',
    ...scaleStyle({
      fontSize: 20,
      lineHeight: `40px`,
      marginLeft: 50
    })
  },
  wordKanjiFirstExample: {
    width: '100%',
    display: 'flex',
    color: theme.colors.primary,
    ...scaleStyle({
      fontSize: 25,
      lineHeight: `40px`,
      marginLeft: 50
    })
  },
  wordKanji: {
    width: '100%',
    display: 'flex',
    textAlign: 'left',
    ...scaleStyle({
      fontSize: 20,
      lineHeight: `40px`
    })
  },
  wordKanjiExample: {
    whiteSpace: 'pre-line',
    textAlign: 'left',
    width: '100%',
    display: 'flex',
    ...scaleStyle({
      fontSize: 20,
      lineHeight: `40px`,
      marginLeft: 50
    })
  }
};
