import { scaleStyle } from 'utils';

export default {
  ribbon: {
    ...scaleStyle({}),
    width: 150,
    height: 150,
    overflow: 'hidden',
    position: 'absolute',
    '&:before, &:after': {
      position: 'absolute',
      zIndex: -1,
      content: '""',
      display: 'block',
      border: '5px solid #2980b9'
    },
    '& > span': {
      position: 'absolute',
      display: 'block',
      width: 225,
      padding: '15px 0',
      backgroundColor: '#a20a0a',
      boxShadow: '0 5px 10px rgba(0,0,0,.1)',
      color: '#fff',
      font: '700 18px/1 "Lato", sans-serif',
      textShadow: '0 1px 1px rgba(0,0,0,.2)',
      textTransform: 'uppercase',
      textAlign: 'center'
    }
  },
  ribbonTopLeft: {
    top: -10,
    left: -10,
    '&:before, &:after': {
      borderTopColor: 'transparent',
      borderLeftColor: 'transparent'
    },
    '&:before': {
      top: 0,
      right: 0
    },
    '&:after': {
      bottom: 0,
      left: 0
    },
    '& > span': {
      right: -25,
      top: 30,
      transform: 'rotate(-45deg)'
    }
  }
};
