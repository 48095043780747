import React, { useState, useEffect, useRef } from 'react';
import injectSheet from 'react-jss';
import PropTypes from 'prop-types';
import c from 'classnames';
import styles from './styles';

const defaultList = [
  {
    value: 0,
    label: 'New York'
  },
  {
    value: 1,
    label: 'Dublin'
  },
  {
    value: 2,
    label: 'California'
  },
  {
    value: 3,
    label: 'Istanbul'
  },
  {
    value: 4,
    label: 'Izmir'
  },
  {
    value: 5,
    label: 'Oslo'
  }
];

const useOuterClickNotifier = (onOuterClick, innerRef) => {
  useEffect(() => {
    const handleClick = e => {
      if (!innerRef.current.contains(e.target)) onOuterClick(e);
    };
    if (innerRef.current) document.addEventListener('click', handleClick);
    return () => document.removeEventListener('click', handleClick);
  }, [onOuterClick, innerRef]);
};

const Selector = ({ classes, list, value, onChange }) => {
  const [open, setOpen] = useState(false);
  const innerRef = useRef(null);
  useOuterClickNotifier(() => setOpen(false), innerRef);
  return (
    <div ref={innerRef} className={classes.select} onClick={() => setOpen(!open)}>
      {value && <div className={classes.value}>{value.label}</div>}
      <div className={c(classes.list, { [classes.open]: open })}>
        {list.map((e, i) => (
          <div className={classes.item} key={i} value={e.value} onClick={() => onChange(e)}>
            {e.label}
          </div>
        ))}
      </div>
    </div>
  );
};

Selector.propTypes = {
  list: PropTypes.arrayOf(Object),
  value: PropTypes.objectOf(Object),
  onChange: PropTypes.func
};

Selector.defaultProps = {
  list: defaultList,
  value: null,
  onChange: () => {}
};

export default injectSheet(styles)(Selector);
