/*
 * @Author: loc.do@bstarsolutions.com
 * @Date: 2019-06-07 11:19:10
 * @Last Modified by: loc.do@bstarsolutions.com
 * @Last Modified time: 2019-10-22 14:08:31
 */

import { createStructuredSelector } from 'reselect';

const leftMenu = state => state.app.leftMenu;
const breadcrumb = state => state.app.breadcrumb;
const listCoin = state => state.shop.listCoin;
const listCourseInShop = state => state.shop.listCourseInShop;
const listSkillInShop = state => state.shop.listSkillInShop;
const currentLang = state => state.app.currentLang;
const listSelectCourseInShop = state => state.shop.listSelectCourseInShop;
const listSelectExamInShop = state => state.shop.listSelectExamInShop;
const paymentInfo = state => state.shop.paymentInfo;
const isShowPaymentPage = state => state.shop.isShowPaymentPage;
const resultOfBuyCourse = state => state.shop.resultOfBuyCourse;
const statusBuyCourse = state => state.shop.statusBuyCourse;
const isFetchingLoadingSelect = state => state.shop.isFetchingLoadingSelect;
const isShowMenu = state => state.header.isShowMenu;
const user = state => state.profile.myInformation;
const courseEnterprise = state => state.shop.courseEnterprise;
const listConfiguration = state => state.app.listConfiguration;
const tokenSelector = state => (state.login.user ? state.login.user.access_token : '');

export default createStructuredSelector({
  leftMenu,
  breadcrumb,
  listCoin,
  listCourseInShop,
  listSkillInShop,
  currentLang,
  listSelectCourseInShop,
  listSelectExamInShop,
  paymentInfo,
  isShowPaymentPage,
  resultOfBuyCourse,
  statusBuyCourse,
  isFetchingLoadingSelect,
  isShowMenu,
  user,
  courseEnterprise,
  listConfiguration,
  tokenSelector
});
