/*
 * @Author: loc.do@bstarsolutions.com
 * @Date: 2019-06-10 14:10:40
 * @Last Modified by: loc.do@bstarsolutions.com
 * @Last Modified time: 2019-10-23 15:23:28
 */
import { theme, images } from 'appCore';
import { scaleStyle } from 'utils';

export default {
  containerSelect: {
    position: 'relative',
    width: `100%`,
    marginRight: 22,
    flex: '40% 2 2',
    marginBottom: 10,
    '&:first-child': {
      marginRight: 10
    }
  },
  container: {
    position: 'relative',
    opacity: 0.9,
    width: `100%`,
    outline: 'none',
    cursor: 'pointer',
    display: 'flex',
    border: `solid 1px ${theme.colors.gray203}`,
    backgroundColor: theme.colors.gray253,
    fontFamily: 'Open Sans',
    ...scaleStyle({
      height: 60,
      fontSize: 20,
      lineHeight: `27px`,
      borderRadius: 40,
      paddingLeft: 68,
      backgroundPosition: '30px 15px',
      backgroundSize: '30px 30px'
    }),
    backgroundRepeat: 'no-repeat',
    WebkitAppearance: 'none'
  },
  iconImageContainer: {
    backgroundImage: `url(${images.preSelect})`
  },
  option: {
    cursor: 'pointer'
  },
  bottomArrow: {
    position: 'absolute',
    ...scaleStyle({
      width: 10,
      height: 10,
      top: 35,
      right: 20
    })
  },
  [`@media screen and (max-width: ${theme.screens.maxWidth800}px)`]: {
    containerSelect: {
      marginRight: 11,
      marginLeft: 11
    },
    container: {
      fontSize: 12,
      fontWeight: 'bold',
      borderRadius: 10,
      backgroundPosition: '10px',
      paddingLeft: 30.8
    },
    bottomArrow: {
      top: 35,
      right: 25
    }
  }
};
