import { constants } from 'utils';
import axiosInstance from './interceptor';

const Exercise = {
  getExercise: examId => axiosInstance.get(`${constants.API}/test/${examId}`),
  getExerciseNotAuthen: examId => axiosInstance.get(`${constants.API}/pure-route/test/${examId}`),
  getPreacticeExercise: skillId =>
    axiosInstance.get(`${constants.API}/test/get-question-by-tab/${skillId}`),
  submitPracticeExercise: (sectionId, dataSubmit) =>
    axiosInstance.post(`${constants.API}/test/submit-test-detail/${sectionId}`, {
      test_result: dataSubmit
    }),
  submitNewTestPracticeAPI: exerciseId =>
    axiosInstance.post(`${constants.API}/test/submit-new-test/${exerciseId}`, {})
};

export default Exercise;
