import { types as practiceTypes } from 'containers/Practice/duck';
import * as types from './types';

const INITIAL_STATE = {
  status: false,
  isChoice: false,
  lesson: [],
  subtitle: null,
  totalScore: 0,
  metaLesson: [],
  resultSpeechToText: '',
  statusSpeechToText: 'run',
  isFetching: false,
  kanji: {}
};

export default (state = INITIAL_STATE, action) => {
  switch (action.type) {
    case types.LESSON_BY_SKILL_FETCHING:
      return {
        ...state,
        isFetching: true
      };
    case types.LESSON_BY_SKILL_SUCCESS:
      return {
        ...state,
        status: action.payload.status,
        lesson: action.payload.lesson,
        metaLesson: action.payload.metaLesson,
        totalScore: action.payload.totalScore,
        isFetching: false
      };
    case types.LESSON_BY_SKILL_FAIL:
      return {
        ...state,
        isFetching: false,
        lesson: []
      };
    case practiceTypes.REMAKE_DATA_LESSON:
      return {
        ...state,
        status: action.payload.status,
        lesson: action.payload.lesson,
        totalScore: action.payload.totalScore
      };
    case practiceTypes.REMAKE_DATA_LESSON_INIT:
      return {
        ...state,
        isChoice: action.payload.isChoice
      };
    case types.SUBTITLE_SUCCESS:
      return {
        ...state,
        subtitle: action.payload
      };
    case types.SPEECH_TO_TEXT_INIT:
      return {
        ...state,
        statusSpeechToText: 'running',
        resultSpeechToText: ''
      };
    case types.SPEECH_TO_TEXT_SUCCESS:
      return {
        ...state,
        statusSpeechToText: action.payload.statusSpeechToText,
        resultSpeechToText: action.payload.resultSpeechToText
      };
    case types.SPEECH_TO_TEXT_FAIL:
      return {
        ...state,
        statusSpeechToText: action.payload.statusSpeechToText,
        resultSpeechToText: action.payload.resultSpeechToText
      };
    case types.REMOVE_RESULT_SPEECH_TO_TEXT:
      return {
        ...state,
        resultSpeechToText: ''
      };
    case types.REMOVE_RESULT_SPEECH_TO_TEXT_PRACTICE:
      return {
        ...state,
        statusSpeechToText: 'running',
        resultSpeechToText: ''
      };
    case types.GET_KANJI_DETAIL_FETCHING:
      return {
        ...state,
        status: true,
        isFetching: false
      };
    case types.GET_KANJI_DETAIL_FAIL:
      return {
        ...state,
        status: true,
        isFetching: false,
        kanji: []
      };
    case types.GET_KANJI_DETAIL_SUCCESS:
      return {
        ...state,
        status: true,
        kanji: action.payload.kanji,
        isFetching: false
      };
    case types.REMOVE_DATA_KANJI: {
      return {
        ...state,
        kanji: {}
      };
    }
    default:
      return state;
  }
};
