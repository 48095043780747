import * as type from './type';
// import { types } from '.';

export const fetchExams = () => ({
  type: type.EXAM_INIT
});

export const fetchLevels = () => ({
  type: type.LEVEL_INIT
});

export const isExamCombo = value => ({
  type: type.EXAM_PACKAGE_COMBO,
  payload: value
});

export const isExamPrimary = value => ({
  type: type.EXAM_PACKAGE_PRIMARY,
  payload: value
});

export const selectValueExam = value => ({
  type: type.SELECT_VALUE_EXAM,
  payload: {
    value
  }
});

export default this;
