import * as types from './types';

const INITIAL_STATE = {
  status: false,
  active: '',
  isShowMenu: false
};

export default (state = INITIAL_STATE, action) => {
  switch (action.type) {
    case types.MENU_ITEM_ACTIVE:
      return {
        ...state,
        active: action.payload,
        isShowMenu: false
      };
    case types.IS_SHOW_LIST_MENU_IN_RESPONSIVE:
      return {
        ...state,
        isShowMenu: true
      };
    case types.IS_HIDE_LIST_MENU_IN_RESPONSIVE:
      return {
        ...state,
        isShowMenu: false
      };
    default:
      return state;
  }
};
