import { Translator } from 'appCore';

import * as en from './en';
import * as vi from './vi';

const strings = Translator.create(en, {
  en,
  vi
});

export default strings;
