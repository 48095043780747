import { createStructuredSelector } from 'reselect';

const listExamChilds = state => state.examChilds;
const breadcrumb = state => state.app.breadcrumb;
const currentLang = state => state.app.currentLang;
const isFetching = state => state.examChilds.isFetching;
export default createStructuredSelector({
  breadcrumb,
  listExamChilds,
  currentLang,
  isFetching
});
