import CookieStorage from './storageService';
import storageAvailable from './storageAvailable';

export default class StorageFactory {
  static getStorage = () =>
    storageAvailable('localStorage')
      ? localStorage
      : storageAvailable('sessionStorage')
      ? sessionStorage
      : new CookieStorage();
}
