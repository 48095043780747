/*
 * @Author: loc.do@bstarsolutions.com
 * @Date: 2019-06-06 09:23:57
 * @Last Modified by: loc.do@bstarsolutions.com
 * @Last Modified time: 2019-08-14 10:40:55
 */
import { theme } from 'appCore';
import { scaleStyle } from 'utils';

export default {
  sentence: {
    color: theme.colors.black,
    alignSelf: 'flex-start',
    objectFit: 'contain',
    ...scaleStyle({
      fontSize: 24,
      lineHeight: '40px',
      marginLeft: 36,
      marginTop: 30
    })
  },
  scrollInformationPractice: {
    overflowY: 'auto',
    display: 'flex',
    flexDirection: 'column',
    width: '100%',
    height: '100%'
  },
  answer: {
    color: theme.colors.black,
    alignSelf: 'flex-start',
    margin: '0px',
    height: `100%`,
    width: '95%',
    ...scaleStyle({
      fontSize: 24,
      lineHeight: '20px',
      marginLeft: 36,
      marginTop: 20,
      marginBottom: 20
    }),
    '& ruby > rt': {
      display: 'none'
    },
    '& img': {
      width: `100%`
    },
    '& > p': {
      margin: 0,
      ...scaleStyle({
        lineHeight: `40px`
      })
    }
  },
  showHint: {
    '& ruby > rt': {
      display: 'block',
      color: theme.colors.primary
    }
  },
  imgPractice: {
    objectFit: 'contain',
    maxWidth: '100%',
    ...scaleStyle({
      marginBottom: 20
    })
  },
  audio: {
    ...scaleStyle({
      marginBottom: 20
    })
  }
};
