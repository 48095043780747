/*
 * @Author: loc.do@bstarsolutions.com
 * @Date: 2019-06-12 11:16:35
 * @Last Modified by: loc.do@bstarsolutions.com
 * @Last Modified time: 2019-10-22 10:19:52
 */
import { theme } from 'appCore';
import { scaleStyle } from 'utils';

export default {
  container: {
    // display: 'flex',
    // flexWrap: 'wrap',
    // width: `100%`,
    justifyContent: 'center'
  },
  block: {
    display: 'flex',
    justifyContent: 'start',
    opacity: 0.9,
    height: '100%',
    backgroundColor: theme.colors.gray253,
    ...scaleStyle({
      // marginTop: 30,
      // marginBottom: 24,
      // marginRight: 11,
      // marginLeft: 11,
      // width: 332,
      borderRadius: 40
    }),
    '&:nth-child(even)': {
      border: `1px solid ${theme.colors.secondary}`
    },
    '&:nth-child(odd)': {
      border: `1px solid ${theme.colors.primary}`
    }
  },
  img: {
    maxWidth: `100%`,
    marginBottom: 'auto',
    position: 'absolute',
    top: 0,
    left: 0,
    width: '100%'
  },
  name: {
    fontWeight: 'bold',
    textAlign: 'center',
    color: theme.colors.primary,
    height: 'auto',
    width: '90%',
    ...scaleStyle({
      marginTop: 28,
      lineHeight: `40px`,
      fontSize: 30
    }),
    [`@media screen and (max-width: 1440px)`]: {
      height: 55
    },
    [`@media screen and (max-width: 576px)`]: {
      height: 'auto'
    }
  },
  info: {
    display: 'flex',
    ...scaleStyle({
      marginTop: 20,
      marginBottom: 20
    })
  },
  imgCoin: {
    ...scaleStyle({
      marginRight: 10,
      width: 39,
      height: 39
    })
  },
  wpimg: {
    width: '100%',
    paddingTop: '75%',
    overflow: 'hidden',
    position: 'relative',
    [`@media screen and (max-width: 576px)`]: {
      // maxHeight: 135,
      // minHeight: 135,
      // overflow: 'hidden'
    }
  },
  price: {
    fontWeight: 'bold',
    color: theme.colors.price,
    ...scaleStyle({
      lineHeight: `41px`,
      fontSize: 30
    })
  },
  btn: {
    ...scaleStyle({
      marginBottom: 20
    })
  },
  confirm: {
    ...scaleStyle({
      marginTop: 20
    })
  },
  [`@media screen and (max-width: ${theme.screens.maxWidth800}px)`]: {
    block: {
      // width: 160
    },
    btn: {
      borderRadius: 10
    }
  }
};
