/*
 * @Author: loc.do@bstarsolutions.com
 * @Date: 2019-06-10 14:10:59
 * @Last Modified by: loc.do@bstarsolutions.com
 * @Last Modified time: 2019-08-22 21:18:11
 */

import React from 'react';
import injectSheet from 'react-jss';
import c from 'classnames';
import { images } from 'appCore';
import styles from './styles';

const listCourse = [
  {
    id: 0,
    name: 'Khóa học riêng lẻ'
  },
  {
    id: 1,
    name: 'Khóa học Combo'
  }
];

const InputSelect = props => {
  const {
    classes,
    listValueSelect,
    valueDefault,
    className,
    onChange,
    currentLang,
    disabled,
    classNameWrap,
    isIconContainer,
    multiple
  } = props;
  return (
    <div className={c(classes.containerSelect, classNameWrap)}>
      <select
        multiple={multiple}
        onChange={onChange}
        className={c(className, classes.container, {
          [classes.iconImageContainer]: isIconContainer
        })}
        disabled={disabled}
      >
        <option value="default">{valueDefault}</option>
        {listValueSelect &&
          listValueSelect.map(value => {
            return (
              <option key={value.id} value={value.id}>
                {value.translations && value.translations[currentLang]
                  ? value.translations[currentLang].name
                  : (value.translations && value.translations.vi && value.translations.vi.name) ||
                    value.name}
              </option>
            );
          })}
      </select>
      <img className={classes.bottomArrow} src={images.bottomArrow} alt="Bottom Arrow" />
    </div>
  );
};

InputSelect.defaultProps = {
  listValueSelect: listCourse,
  valueDefault: 'Chọn khóa học',
  disabled: false,
  isIconContainer: true,
  multiple: false
};

export default injectSheet(styles)(InputSelect);
