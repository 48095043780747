import * as types from './types';

const INITIAL_STATE = {
  status: false,
  isLoading: false,
  examChilds: {},
  statusFetchExam: false,
  examDetails: {},
  isFetching: false
};

export default (state = INITIAL_STATE, action) => {
  switch (action.type) {
    case types.EXAM_CHILD_FETCHING:
      return {
        ...state,
        isLoading: true,
        isFetching: true
      };
    case types.EXAM_CHILD_SUCCESS:
      return {
        ...state,
        status: true,
        examChilds: action.payload.examChilds,
        isLoading: false,
        isFetching: false,
        parentId: action.payload.parentId
      };
    case types.EXAM_CHILD_FAIL:
      return {
        ...state,
        isLoading: false,
        isFetching: false
      };
    case types.EXAM_DETAILS_SUCCESS:
      return {
        ...state,
        statusFetchExam: true,
        examDetails: action.payload.examDetails
      };
    default:
      return state;
  }
};
