import * as types from './types';

export const autoLogin = token => ({
  type: types.AUTO_LOGIN_SUBMIT,
  payload: { token }
});

export const getSupportLanguage = () => ({
  type: types.SUPPORT_LANGUAGE_GET
});

export const changeLanguage = lang => ({
  type: types.LANGUAGE_CHANGE,
  payload: lang
});

export const changeBreadcrumb = newBreadcrumb => ({
  type: types.BREADCRUMB_CHANGE,
  payload: newBreadcrumb
});

export const changeTabs = newTabs => ({
  type: types.TABS_CHANGE,
  payload: newTabs
});

export const changeLeftMenu = newLeftMenu => ({
  type: types.LEFTMENU_CHANGE,
  payload: newLeftMenu
});

export const getListMenu = () => ({
  type: types.GET_LIST_MENU_INIT
});

export const getListConfiguration = () => ({
  type: types.GET_LIST_CONFIGURATION_INIT
});

export default this;
