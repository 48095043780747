import { theme } from 'appCore';

export default {
  slide: {
    display: 'flex',
    overflow: 'hidden',
    position: 'relative',
    width: '100%'
  },
  showDot: {
    paddingBottom: 45
  },
  list: {
    transition: 'all 0.5s',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center'
  },
  item: {
    position: 'relative',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center'
  },
  dot: {
    width: 10,
    height: 10,
    background: theme.colors.lightgray,
    borderRadius: '50%',
    margin: '0 5px',
    cursor: 'pointer'
  },
  active: {
    background: theme.colors.primary
  },
  dots: {
    position: 'absolute',
    bottom: 0,
    left: 0,
    right: 0,
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center'
  }
};
