import { createStructuredSelector } from 'reselect';

const isLoggedIn = state => state.login.status;
const accessToken = state => (state.login.user ? state.login.user.access_token : null);
const refreshToken = state => (state.login.user ? state.login.user.refresh_token : null);
const languages = state => state.app.languages;
const listConfiguration = state => state.app.listConfiguration;
const currentLang = state => state.app.currentLang;
const readyToContinue = state => state.login.readyToContinue;
const accessTokenNew = state => state.login.accessTokenNew;

export default createStructuredSelector({
  isLoggedIn,
  refreshToken,
  accessToken,
  languages,
  listConfiguration,
  currentLang,
  readyToContinue,
  accessTokenNew
});
