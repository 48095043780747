import { put, takeMaybe } from 'redux-saga/effects';
import * as types from './types';

function* hideModal() {
  yield put({
    type: types.MODAL_HIDE,
    payload: {
      isOpen: false,
      modalProps: {}
    }
  });
}

export const hideModalSage = takeMaybe(hideModal);

export default this;
