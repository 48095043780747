/*
 * @Author: loc.do@bstarsolutions.com
 * @Date: 2019-05-27 15:14:22
 * @Last Modified by: loc.do@bstarsolutions.com
 * @Last Modified time: 2019-05-29 09:49:26
 */

export const CHANGE_ACTIVE_INIT = 'CHANGE_ACTIVE_INIT';
export const CHANGE_ACTIVE_ANSWER_SUCCESS = 'CHANGE_ACTIVE_ANSWER_SUCCESS';
export const CHANGE_ACTIVE_ANSWER_FAIL = 'CHANGE_ACTIVE_ANSWER_FAIL';

export const REMAKE_DATA_LESSON_INIT = 'REMAKE_DATA_LESSON_INIT';
export const REMAKE_DATA_LESSON = 'REMAKE_DATA_LESSON';

export const SUBMIT_PRACTICE_INIT = 'SUBMIT_PRACTICE_INIT';
export const SUBMIT_PRACTICE_FETCHING = 'SUBMIT_PRACTICE_FETCHING';
export const SUBMIT_PRACTICE_SUCCESS = 'SUBMIT_PRACTICE_SUCCESS';
export const SUBMIT_PRACTICE_FAIL = 'SUBMIT_PRACTICE_FAIL';

export default this;
