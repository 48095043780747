/*
 * @Author: loc.do@bstarsolutions.com
 * @Date: 2019-05-03 12:47:06
 * @Last Modified by: Do Loc - doxuanloc2016@gmail.com
 * @Last Modified time: 2019-07-11 13:31:31
 */

import React from 'react';
import c from 'classnames';
import injectSheet from 'react-jss';
import PropTypes from 'prop-types';
import styles from './styles';

function Block(props) {
  const { classes, children, borderColor, className, isRow, style, onClick, refDemo } = props;

  return (
    <div
      ref={refDemo}
      style={style}
      className={c(
        className,
        classes.block,
        { [classes.borderColorDefault]: borderColor === 'borderColorDefault' },
        { [classes.borderColorPrimary]: borderColor === 'borderColorPrimary' },
        { [classes.borderColorSecondary]: borderColor === 'borderColorSecondary' },
        { [classes.row]: isRow }
      )}
      onClick={onClick}
    >
      {children}
    </div>
  );
}

Block.propTypes = {
  borderColor: PropTypes.string,
  isRow: PropTypes.bool
};

Block.defaultProps = {
  borderColor: 'borderColorDefault',
  isRow: false
};

export default injectSheet(styles)(Block);
