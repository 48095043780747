/*
 * @Author: loc.do@bstarsolutions.com
 * @Date: 2019-05-10 09:36:23
 * @Last Modified by: loc.do@bstarsolutions.com
 * @Last Modified time: 2019-07-19 13:48:00
 */

import React from 'react';
import injectSheet from 'react-jss';
import c from 'classnames';
import PropTypes from 'prop-types';
/* eslint-disable */
import { isString } from 'lodash';
import styles from './styles';

function Introduction(props) {
  const {
    titleBlack,
    titlePrimary,
    isTitleBlack,
    description,
    isDescription,
    classes,
    type,
    className,
    isImage,
    image
  } = props;

  const renderDescription = () => {
    if (isString(description)) {
      return <span dangerouslySetInnerHTML={{ __html: description }}></span>;
    }
    return description;
  };

  return (
    <div className={c(className, classes.container)}>
      {isTitleBlack && <span className={classes.introductionFeature}>{titleBlack}</span>}
      <span className={c(classes.whoUseFeature, { [classes.title]: type === 'title' })}>
        {titlePrimary}
      </span>
      <div className={classes.borderFeature} />
      {isImage && <img className={classes.imgPracticeExercise} src={image} alt="Exam" />}
      {isDescription && (
        <span className={classes.descriptionCommomFeature}>{renderDescription()}</span>
      )}
    </div>
  );
}

Introduction.propTypes = {
  titleBlack: PropTypes.string,
  titlePrimary: PropTypes.string,
  description: PropTypes.string,
  isTitleBlack: PropTypes.bool,
  isDescription: PropTypes.bool,
  type: PropTypes.string,
  isImage: PropTypes.bool
};

Introduction.defaultProps = {
  titleBlack: `Title Black`,
  titlePrimary: `Titte Primary`,
  description: `Excepteur sint occaecat cupidatat non proident,
   sunt in culpa qui officia deserunt mollit anim id est laborum.`,
  isTitleBlack: true,
  isDescription: true,
  type: 'default',
  isImage: false
};

export default injectSheet(styles)(Introduction);
