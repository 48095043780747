import React from 'react';
import { connect } from 'react-redux';
import locale from 'locale';
import { createStructuredSelector } from 'reselect';
import constants from './constants';

const currentLang = state => state.app.currentLang;

const selector = createStructuredSelector({
  lang: currentLang
});

const strings = lang => locale.resolve(lang);

export const localization = lang => strings(lang).default;

const LanguageProvider = props => {
  const { children, lang } = props;
  const Component = children;
  const newProps = Object.keys(props).reduce(
    (object, key) => (key !== children ? { ...object, [key]: props[key] } : object),
    {}
  );
  return <Component {...newProps} strings={localization(lang || constants.LANGUAGE.VI)} />;
};

const mapStateToProps = state => selector(state);

const translator = component =>
  connect(mapStateToProps)(props => <LanguageProvider {...props}>{component}</LanguageProvider>);

export default translator;
