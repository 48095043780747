/*
 * @Author: loc.do@bstarsolutions.com
 * @Date: 2019-06-04 11:05:20
 * @Last Modified by: loc.do@bstarsolutions.com
 * @Last Modified time: 2019-07-09 10:20:40
 */
import { theme } from 'appCore';
import { scaleStyle } from 'utils';

export default {
  icon: {
    ...scaleStyle({
      marginRight: 10,
      width: 60,
      height: 60
    }),
    borderRadius: 30,
    cursor: 'pointer',
    background: 'white'
  },
  disable: {
    cursor: 'default',
    pointerEvents: 'none'
  },
  disableVolume: {
    cursor: 'default',
    pointerEvents: 'none',
    backgroundColor: theme.colors.gray
  }
};
