import { createStructuredSelector } from 'reselect';

const listExams = state => state.exams;
const breadcrumb = state => state.app.breadcrumb;
const currentLang = state => state.app.currentLang;
const valueExam = state => state.exams.valueExam;
const isFetching = state => state.exams.isFetching;
export default createStructuredSelector({
  listExams,
  breadcrumb,
  currentLang,
  valueExam,
  isFetching
});
