import { constants } from 'utils';
import axiosInstance from './interceptor';

const Exams = {
  getLevels: () => axiosInstance.get(`${constants.API}/pure-route/level`),
  getLevelsNotAuthen: () => axiosInstance.get(`${constants.API}/pure-route/level`),
  getExams: () =>
    axiosInstance.get(`${constants.API}/course?category=${constants.COURSE_CATEGORY_TYPE.EXAM}`),
  getExamsNotAuthen: () =>
    axiosInstance.get(
      `${constants.API}/pure-route/course?category=${constants.COURSE_CATEGORY_TYPE.EXAM}`
    ),
  getExamChilds: (examId, type) =>
    axiosInstance.get(`${constants.API}/test?course_id=${examId}&type=${type}`),
  getExamChildsNotAuthen: (examId, type) =>
    axiosInstance.get(`${constants.API}/pure-route/test/?course_id=${examId}&type=${type}`),
  getExamChildsByLevel: (examId, level, type) =>
    axiosInstance.get(`${constants.API}/test?course_id=${examId}&levels=[${level}]&type=${type}`),
  getExamChildsByLevelNotAuthen: (examId, level, type) =>
    axiosInstance.get(
      `${constants.API}/pure-route/test?course_id=${examId}&levels=[${level}]&type=${type}`
    ),
  getExamDetails: examId =>
    axiosInstance.get(
      `${constants.API}/course/${examId}/?category=${constants.COURSE_CATEGORY_TYPE.EXAM}`
    )
};

export default Exams;
