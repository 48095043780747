import { createStructuredSelector } from 'reselect';

const isFetching = state => state.forgotPassword.isFetching;
const currentLang = state => state.app.currentLang;
const isShowMenu = state => state.header.isShowMenu;
export default createStructuredSelector({
  isFetching,
  currentLang,
  isShowMenu
});
