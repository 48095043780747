import { theme } from 'appCore';

export default {
  container: {
    cursor: 'pointer'
  },
  tooltipBody: {
    position: 'fixed',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    borderRadius: 15,
    padding: '5px 10px',
    background: 'white',
    color: theme.colors.black,
    boxShadow: '1px 1px 2px rgba(0 ,0, 0, 0.3)',
    textAlign: 'center',
    fontSize: 16,
    border: `1px solid ${theme.colors.lightgray}`,
    minHeight: 40,
    maxWidth: 600,
    flexDirection: 'column-reverse',
    lineBreak: 'auto',
    wordBreak: 'normal',
    wordSpacing: 'normal',
    wordWrap: 'normal',
    whiteSpace: 'normal',
    minWidth: 100,
    right: 0,
    '&:before': {
      position: 'absolute',
      bottom: -7,
      left: `calc(50% - 3px)`,
      borderLeft: '6px solid transparent',
      borderRight: '6px solid transparent',
      borderTop: '6px solid gray',
      content: '""',
      fontSize: 0,
      lineHeight: 0,
      marginLeft: -6,
      width: 0
    },
    '&:after': {
      position: 'absolute',
      bottom: -6,
      left: `calc(50% - 3px)`,
      borderLeft: '6px solid transparent',
      borderRight: '6px solid transparent',
      borderTop: '6px solid white',
      content: '""',
      fontSize: 0,
      lineHeight: 0,
      marginLeft: -6,
      width: 0
    },
    '& > p': {
      margin: 0,
      padding: 0,
      width: 'auto',
      height: '100%'
    }
  }
};
