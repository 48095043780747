/*
 * @Author: loc.do@bstarsolutions.com
 * @Date: 2019-07-01 14:55:41
 * @Last Modified by: loc.do@bstarsolutions.com
 * @Last Modified time: 2019-07-01 16:22:45
 */

import React from 'react';
import injectSheet from 'react-jss';
import c from 'classnames';

import styles from './styles';

const SwitchButton = props => {
  const { classes, onClick, value, className } = props;

  return (
    <label onClick={onClick} htmlFor="checkbox" className={c(className, classes.switch)}>
      <input value={value} checked={value} className={classes.input} type="checkbox" />
      <span className={c(classes.slider, classes.round)}></span>
    </label>
  );
};

export default injectSheet(styles)(SwitchButton);
