/*
 * @Author: loc.do@bstarsolutions.com
 * @Date: 2019-06-26 15:30:34
 * @Last Modified by: Do Loc - doxuanloc2016@gmail.com
 * @Last Modified time: 2019-07-19 22:48:14
 */

import React from 'react';
import injectSheet from 'react-jss';
import { translator } from 'utils';
import { Button, Block } from 'components';
import { theme } from 'appCore';
import styles from './styles';

const YourBlock = props => {
  const {
    classes,
    titleExam,
    progress,
    onClickDetail,
    onClickMove,
    strings,
    status,
    result,
    duration,
    isShowMyCourseDetail,
    score
  } = props;

  const lang = strings.yourBlock;

  return (
    <Block className={classes.container}>
      <span className={classes.titleExam}>{titleExam.toUpperCase()} </span>
      <div className={classes.containerInfoExam}>
        <div className={`${classes.rightInforExam} progress-wp`}>
          <div className={`${classes.boxExam}`}>
            <span className={classes.name}>{lang.progress}</span>
            <span className={classes.result}>: {progress}%</span>
          </div>
          <div className={`${classes.boxExam} my-2`}>
            <div className={classes.progress}>
              <div
                style={{
                  width: `${progress}%`,
                  height: 5,
                  objectFit: 'contain',
                  borderRadius: 25,
                  backgroundColor: theme.colors.secondary
                }}
              />
            </div>
          </div>
        </div>
        <div className={classes.rightInforExam}>
          <div className={classes.boxExam}>
            <span className={classes.name}>{lang.status}</span>
            <span className={classes.result}>
              : {(status === 'IN_PROGRESS' || status === 'NEW') && lang.statusIncomplete}
              {status === 'DONE' && lang.statusComplete}
            </span>
          </div>

          <div className={classes.boxExam}>
            <span className={classes.name}>{lang.learningResult}</span>
            <span className={classes.result}>
              : {result === 'IN_PROGRESS' && lang.statusStudying}
              {result === 'NEW' && lang.statusNotStudy}
              {result === 'DONE' && lang.statusComplete}
            </span>
          </div>
          {!isShowMyCourseDetail ? (
            <div className={classes.boxExam}>
              <span className={classes.name}>{lang.expirationDate}</span>
              <span className={classes.result}>
                : {duration === 999999 ? lang.forever : `${duration} ${lang.day}`}
              </span>
            </div>
          ) : (
            <div className={classes.boxExam}>
              <span className={classes.name}>{lang.score}</span>
              <span className={classes.result}>: {score}/100</span>
            </div>
          )}
        </div>
        <div className={classes.boxBtn}>
          <Button onClick={onClickMove} label={lang.btnStudyNow} />
          {!isShowMyCourseDetail && (
            <Button
              onClick={onClickDetail}
              className={classes.btn}
              label={lang.btnDetail}
              typeButton="fourth"
            />
          )}
        </div>
      </div>
    </Block>
  );
};

YourBlock.defaultProps = {
  titleExam: 'Title',
  progress: 0,
  status: 'IN_PROGRESS',
  result: 'IN_PROGRESS',
  duration: 0,
  score: 0
};

export default translator(injectSheet(styles)(YourBlock));
