import { scaleStyle } from 'utils';

export default {
  audioWrapper: {
    position: 'relative',
    minHeight: 80,
    width: `100%`
  },
  audio: {
    display: 'none'
  },
  control: {
    position: 'absolute',
    ...scaleStyle({
      left: 45,
      right: 45,
      height: 50
    }),
    // opacity: 0.8,
    bottom: 36
  },
  playBtn: {
    cursor: 'pointer',
    ...scaleStyle({
      height: 40,
      width: 40,
      marginTop: 10
    }),
    marginLeft: -7
  }
};
