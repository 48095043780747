/*
 * @Author: loc.do@bstarsolutions.com
 * @Date: 2019-06-06 09:04:12
 * @Last Modified by: loc.do@bstarsolutions.com
 * @Last Modified time: 2019-07-19 13:24:55
 */

import React from 'react';
import renderHTML from 'react-render-html';
import c from 'classnames';
import injectSheet from 'react-jss';
import { Block, Audio } from 'components';

import { constants, translator } from 'utils';

import styles from './styles';

function InformationPractice(props) {
  const {
    currentInfo,
    totalList,
    lessonCurrentInfo,
    classes,
    currentLang,
    isHint,
    isTranslate,
    strings,
    refDemo,
    className
  } = props;

  const lang = strings.infoPractice;

  return (
    <Block className={className} refDemo={refDemo}>
      {lessonCurrentInfo && (
        <>
          <span className={classes.sentence}>
            {lang.theSentence}: {currentInfo + 1}/ {totalList}
          </span>
          <div className={`${classes.scrollInformationPractice} informationPractice--isMod`}>
            <span className={c(classes.answer, { [classes.showHint]: isHint })}>
              {isTranslate
                ? lessonCurrentInfo.question
                  ? lessonCurrentInfo.question && renderHTML(lessonCurrentInfo.question)
                  : lessonCurrentInfo.translations &&
                    lessonCurrentInfo.translations[currentLang] &&
                    renderHTML(lessonCurrentInfo.translations[currentLang].description)
                : lessonCurrentInfo.question
                ? lessonCurrentInfo.question && renderHTML(lessonCurrentInfo.question)
                : lessonCurrentInfo.paragraph && renderHTML(lessonCurrentInfo.paragraph)}
            </span>
            {lessonCurrentInfo.image &&
              lessonCurrentInfo.image !== 'https://stg.api.rainichi.com/storage/null' &&
              lessonCurrentInfo.image !== 'https://api-prod.rainichi.com/api/storage/null' && (
                <img className={classes.imgPractice} src={lessonCurrentInfo.image} alt="Sumo" />
              )}
            {Number(lessonCurrentInfo.category) === constants.SKILL_TYPE.LISTENING &&
              lessonCurrentInfo.media && (
                <Audio className={classes.audio} controls src={lessonCurrentInfo.media} />
              )}
          </div>
        </>
      )}
    </Block>
  );
}

export default translator(injectSheet(styles)(InformationPractice));
