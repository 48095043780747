/*
 * @Author: loc.do@bstarsolutions.com
 * @Date: 2019-05-14 14:35:14
 * @Last Modified by: loc.do@bstarsolutions.com
 * @Last Modified time: 2019-05-14 17:09:46
 */

import * as types from './types';

export const fetchLessonInCourse = id => ({
  type: types.LESSON_IN_COURSE_INIT,
  id
});

export default this;
