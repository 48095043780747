import { theme } from 'appCore';
import { scaleStyle } from 'utils';

export default {
  expandBlock: {
    fontSize: '20px',
    flexDirection: 'column',
    display: 'flex',
    ...scaleStyle({
      marginBottom: 10,
      padding: 22
    }),
    '&:hover': {
      border: `1px solid ${theme.colors.primary}`
    }
  },
  expandItem: {
    ...scaleStyle({
      width: '100%'
    }),
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'space-between',
    cursor: 'pointer',
    '&:hover': {
      borderColor: theme.colors.primary
    }
  },
  expandLable: {
    alignSelf: 'center',
    fontWeight: 'bold',
    ...scaleStyle({
      lineHeight: `60px`,
      fontSize: 20
    }),
    '&:hover': {
      color: theme.colors.primary
    }
  },
  expandIcon: {
    ...scaleStyle({
      width: 55,
      height: 55
    }),
    opacity: 0.3
  },
  expandDescription: {
    width: '100%',
    display: 'flex',
    flexDirection: 'column'
  },
  [`@media screen and (max-width: ${theme.screens.maxWidth800}px)`]: {
    expandIcon: {
      width: 24,
      height: 24
    },
    expandLable: {
      lineHeight: `24px`
    }
  }
};
