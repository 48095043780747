/*
 * @Author: loc.do@bstarsolutions.com
 * @Date: 2019-05-10 11:04:10
 * @Last Modified by: loc.do@bstarsolutions.com
 * @Last Modified time: 2019-08-08 17:28:35
 */

import { createStructuredSelector } from 'reselect';

const listCourses = state => state.courses;
const breadcrumb = state => state.app.breadcrumb;
const currentLang = state => state.app.currentLang;
const isFetching = state => state.courses.isFetching;
const isShowMenu = state => state.header.isShowMenu;
const accessTokenNew = state => state.login.accessTokenNew;

export default createStructuredSelector({
  listCourses,
  breadcrumb,
  currentLang,
  isFetching,
  isShowMenu,
  accessTokenNew
});
