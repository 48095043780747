/*
 * @Author: loc.do@bstarsolutions.com
 * @Date: 2019-08-02 13:30:16
 * @Last Modified by: loc.do@bstarsolutions.com
 * @Last Modified time: 2019-08-13 10:20:04
 */

import { constants } from 'utils';
import axios from 'axios';
import axiosInstance from './interceptor';

const Base = {
  getByUrl: url => axios.get(url),
  getListMenu: () => axiosInstance.get(`${constants.API}/category`),
  getListConfiguration: () => axiosInstance.get(`${constants.API}/configuration`)
};

export default Base;
