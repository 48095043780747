/*
 * @Author: loc.do@bstarsolutions.com
 * @Date: 2019-06-18 10:57:27
 * @Last Modified by: loc.do@bstarsolutions.com
 * @Last Modified time: 2019-08-14 10:23:10
 */

import React, { useEffect, useState } from 'react';
import injectSheet from 'react-jss';
import { Block, Button, ModalContent } from 'components';
import { translator, formatTime } from 'utils';
import styles from './styles';

const CountTime = props => {
  const {
    classes,
    time,
    submitPracticeExercise,
    testSectionId,
    totalScore,
    totalQuestion,
    strings,
    nameExercise,
    currentLang,
    questions,
    resultDataSubmitNewTest,
    totalScoreApi,
    hasTime,
    showPopup,
    answers
  } = props;

  const [seconds, setSeconds] = useState(time * 60);
  let idInterval = null;
  const lang = strings.countTime;
  const timer = () => setSeconds(Math.max(0, seconds - 1));

  const onSubmit = () => {
    if (!navigator.onLine) {
      const children = (
        <ModalContent isButton={false} title={lang.message} description={lang.disconnect} />
      );
      showPopup({ children });
    } else {
      const dataSubmit = [
        {
          test_section_id: testSectionId,
          score: totalScore,
          total_score: totalScoreApi,
          total_question: totalQuestion,
          questions,
          answers
        }
      ];
      submitPracticeExercise(resultDataSubmitNewTest.id, dataSubmit, true);
      clearInterval(idInterval);
    }
  };

  useEffect(() => {
    if (seconds <= 0 && time !== 0) {
      onSubmit();
      return;
    }
    if (!idInterval) idInterval = setInterval(timer, 1000);
    // eslint-disable-next-line consistent-return
    return () => {
      clearInterval(idInterval);
    };
  }, [seconds]);

  return (
    <div className={classes.countTimeContainer}>
      <Block>
        <span className={classes.nameExercise}>{lang.title}</span>
        <span className={classes.sectionPractice}>
          {nameExercise.translations[currentLang]
            ? nameExercise.translations[currentLang].name
            : nameExercise.translations.vi.name}
        </span>
        <span className={classes.countTime}>
          {!hasTime ? lang.freeTime.toUpperCase() : lang.withTime.toUpperCase()}
        </span>
        <span className={hasTime ? classes.time : classes.notTime}>
          {hasTime && formatTime(seconds)}
        </span>
        <Button className={classes.btnSubmit} label={lang.btnSubmit} onClick={onSubmit} />
      </Block>
    </div>
  );
};

export default translator(injectSheet(styles)(CountTime));
