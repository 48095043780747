import React, { useRef, useState } from 'react';
import injectSheet from 'react-jss';
import c from 'classnames';
import PropTypes from 'prop-types';
import { images } from 'appCore';
import Slider from 'react-slick';
import 'slick-carousel/slick/slick.css';
import 'slick-carousel/slick/slick-theme.css';
import styles from './styles';
import './overrideStyleSlider.css';

const Dot = injectSheet(styles)(({ classes, active, action }) => {
  return <div className={c(classes.dot, { [classes.active]: active })} onClick={action} />;
});

const Slider2 = props => {
  const { list, time, bunch, classes, hideDot, className } = props;
  const [current, setCurrent] = useState(0);
  const slideRef = useRef();
  const clickDot = i => slideRef && slideRef.current.slickGoTo(i);
  const settings = {
    infinite: true,
    speed: time,
    slidesToShow: bunch,
    slidesToScroll: 1,
    autoplay: true,
    dots: !hideDot,
    arrows: false,
    focusOnSelect: true,
    afterChange: i => setCurrent(i),
    appendDots: dots => (
      <div className={classes.dots}>
        <ul>{dots}</ul>
      </div>
    ),
    customPaging: i => <Dot key={i} action={() => clickDot(i)} active={i === current} />
  };
  return (
    <Slider className={className} ref={slideRef} {...settings}>
      {list.map((e, i) => (
        <div className={c(classes.item)} key={i}>
          {e}
        </div>
      ))}
    </Slider>
  );
};

Slider2.propTypes = {
  list: PropTypes.arrayOf(Object),
  time: PropTypes.number,
  bunch: PropTypes.number,
  minWidthItem: PropTypes.number,
  hideDot: PropTypes.bool
};

Slider2.defaultProps = {
  list: [
    <img src={images.bannerOne} alt="baner" style={{ width: '100%' }} />,
    <img src={images.banner} alt="baner" style={{ width: '100%' }} />,
    <img src={images.bannerOne} alt="baner" style={{ width: '100%' }} />,
    <img src={images.banner} alt="baner" style={{ width: '100%' }} />,
    <img src={images.bannerOne} alt="baner" style={{ width: '100%' }} />,
    <img src={images.banner} alt="baner" style={{ width: '100%' }} />,
    <img src={images.bannerOne} alt="baner" style={{ width: '100%' }} />
  ],
  time: 500,
  bunch: 1,
  minWidthItem: 0,
  hideDot: false
};

export default injectSheet(styles)(Slider2);
