/*
 * @Author: loc.do@bstarsolutions.com
 * @Date: 2019-06-10 17:21:24
 * @Last Modified by: loc.do@bstarsolutions.com
 * @Last Modified time: 2019-10-23 10:45:47
 */
import { theme } from 'appCore';
import { scaleStyle } from 'utils';

export default {
  container: {
    width: '100%',
    display: 'flex',
    alignItems: 'center',
    position: 'relative',
    cursor: 'pointer',
    WebkitUserSelect: 'none',
    MozUserSelect: 'none',
    MsUserSelect: 'none',
    userSelect: 'none',
    flexDirection: 'column-reverse',
    justifyContent: 'center',
    ...scaleStyle({
      fontSize: 22
    }),
    '& > input': {
      position: 'absolute',
      opacity: 0,
      cursor: 'pointer',
      marginLeft: 0,
      zIndex: 1,
      height: `100%`,
      width: `100%`,
      ...scaleStyle({
        bottom: 55
      }),
      '&:checked': {
        '&~span': {
          border: `1px solid ${theme.colors.primary}`,
          color: theme.colors.primary,
          '&:after': {
            display: 'block'
          }
        }
      }
    },
    '&:hover': {
      '& > input': {
        '& ~ span': {
          backgroundColor: theme.colors.white,
          border: `1px solid ${theme.colors.primary}`
        }
      }
    },
    '& > span': {
      '&:after': {
        borderRadius: '50%',
        background: theme.colors.primary,
        ...scaleStyle({
          top: 10,
          left: 10,
          width: 10,
          height: 10
        })
      }
    }
  },
  name: {
    position: 'relative',
    ...scaleStyle({
      height: 30,
      width: 30
    }),
    backgroundColor: theme.colors.white,
    border: `1px solid ${theme.colors.gray56}`,
    borderRadius: '50%',
    '&:after': {
      content: `""`,
      position: 'absolute',
      display: 'none'
    }
  },
  nameTop: {
    width: '100%',
    textAlign: 'center',
    ...scaleStyle({
      marginTop: 10,
      height: 50
    })
  },
  [`@media screen and (max-width: ${theme.screens.maxWidth800}px)`]: {
    nameTop: {
      height: 35
    },
    container: {
      marginBottom: 12
    }
  }
};
