const PRODUCTION_API = 'https://api-prod.rainichi.com/api';
// const DEV_API = 'https://stg.api.rainichi.com/api';
const KANJI_API = 'https://api-prod.rainichi.com/kanji/';

const API = !process.env.NODE_ENV || process.env.NODE_ENV === 'development' ? PRODUCTION_API : PRODUCTION_API;

const LANGUAGE = {
  VI: 'vi',
  EN: 'en'
};

const SKILL_TYPE = {
  VOCABULARY: 1,
  GRAMMAR: 2,
  KANJI: 3,
  READING: 4,
  LISTENING: 5,
  CONVERSATION: 6,
  SONG: 7,
  TEST: 8,
  PRACTICE: 9
};

const BASIC_TYPE = {
  SKILL: 0,
  CONCEPT: 1,
  ALPHABET: 2,
  NUMBER: 3,
  PRACTICE: 4,
  CONNECTION_WORD: 5
};

const QUESTION_CATEGORY_TYPE = {
  READING: 4,
  LISTENING: 5,
  EXERCISE: 9
};

const LESSON_STATUS = {
  LOCK: 0,
  IN_PROGRESS: 1,
  DONE: 2
};

const STORE_TYPE = {
  COIN: undefined,
  COURSE: 'khoa-hoc',
  EXAM: 'de-thi',
  CLASS: 'lop-hoc',
  PAYMENT: 'payment'
};

const PROFILE_TYPE = {
  PROFILE: 'id',
  EXAM: 'de-thi',
  COURSE: 'khoa-hoc',
  SUPPORT: 'ho-tro',
  CREDIT: 'quan-li',
  SETTING: 'cai-dat',
  METHODCREDIT: 'phuong-thuc-dat-xu'
};

const CATEGORY_TYPE = {
  COURSE: 1
};

const COURSE_CATEGORY_TYPE = {
  COURSE: 1,
  EXAM: 2
};

const EXAM_TYPE = {
  JLPT: 1,
  LT: 2
};

const EXAM_TYPE_STRING = [{ id: 1, name: 'JLPT' }, { id: 2, name: 'LT' }];

const RIBBON_STATUS_TEXT = {
  isFoundation: 'Foundation',
  isHot: 'HOT',
  isNew: 'NEW',
  isTop: 'TOP'
};

const ALPHA = 'ABCDEFGHIJKLMNOPQRSTUVWXYZ';

const API_SPEECH_TO_TEXT = 'https://speech.googleapis.com/v1/speech:recognize';

//const KEY_SPEECH_TO_TEXT = 'AIzaSyDfJdnSA6DmlRhN1HzTzjCz5Hij_9FCJxs';
const KEY_SPEECH_TO_TEXT = 'AIzaSyCEzc8vFjn0IZ8EJcbJYtLhF1fMq2t_CC8';

/*const GOOGLE_AUTH_KEYS = {
  APP_ID: '400787560705-pfhbt2tvju0nftv0oqjd6rv8ecuso34i.apps.googleusercontent.com',
  APP_SECRET: 'PAU1gOBvO923nQ5P4PyJFD91',
  PROVIDER: 'google'
};*/

const GOOGLE_AUTH_KEYS = {
  APP_ID: '1083436439716-bih9elku7jfhepr3d2g0lij81r84cmln.apps.googleusercontent.com',
  APP_SECRET: 'PAU1gOBvO923nQ5P4PyJFD91',
  PROVIDER: 'google'
};

const FACEBOOK_AUTH_KEYS = {
  APP_ID: '2458511104207144',
  APP_SECRET: '8c361fe98244c0460207f75150020d5f',
  PROVIDER: 'facebook'
};

const CATEGORY_LINK = {
  LINK_TO_NONE: 1,
  LINK_TO_BLOG: 2,
  LINK_TO_POST: 3,
  LINK_TO_CONTACT: 4,
  LINK_TO_EXAM: 5,
  LINK_TO_COURSE: 6
};

const ARTICLE_TYPE = {
  NEWS: 1,
  JOB: 2,
  FAQ: 3,
  NORMAL: 4
};

const LANDING_PAGE = {
  STRONG_APP: 1,
  INTRO_APP: 2,
  ONLINE_COURSE: 3,
  CENTER: 4,
  CUSTOMER: 5,
  PARTNER: 6,
  CENTER_COURSE:  7,
  OPENING_COURSE:  8,
  EDUCATION: 9,
  JOIN_TEAM: 10,
  REGISTER_NOW: 11,
}

const headers = token => {
  return {
    headers: {
      Authorization: `Bearer ${token}`
    }
  };
};

const headerPosts = token => {
  return {
    headers: {
      Authorization: `Bearer ${token}`,
      'Content-Type': 'multipart/form-data'
    }
  };
};

const REGEXCHECKPHONE = /^[(]{0,1}[0-9]{3}[)]{0,1}[-\s\.]{0,1}[0-9]{3}[-\s\.]{0,1}[0-9]{4}$/;

const REGEXCHECKEMAIL = /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;

const REGEXCHECKPASS = /^([a-zA-Z0-9_-]){8,100}$/;

const addressRainichi = `https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3919.4865511110816!2d106.66714041395116!3d10.773997862186679!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x31752edc11b56067%3A0xd729b19a8caaad97!2zSOG6u20gNzgwIFPGsCBW4bqhbiBI4bqhbmgsIFBoxrDhu51uZyAxMCwgUXXhuq1uIDEwLCBI4buTIENow60gTWluaCwgVmnhu4d0IE5hbQ!5e0!3m2!1svi!2s!4v1545361311429`;


const formatNumber = (x) => {
   return Number(x).toLocaleString('it-IT', {style : 'currency', currency : 'VND'});
}

const formatCoin = (x) => {
   const number = Number(x).toLocaleString();
   return number.replace(/|s/s,"").replace(",",".");
}

const numberWithCommas = (x) => {
  return x.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ".");
}

function createMarkup(dataHtml) {
  return { __html: dataHtml };
}

export default {
  API,
  KANJI_API,
  LANGUAGE,
  SKILL_TYPE,
  LESSON_STATUS,
  BASIC_TYPE,
  headers,
  headerPosts,
  ALPHA,
  API_SPEECH_TO_TEXT,
  KEY_SPEECH_TO_TEXT,
  QUESTION_CATEGORY_TYPE,
  STORE_TYPE,
  CATEGORY_TYPE,
  COURSE_CATEGORY_TYPE,
  RIBBON_STATUS_TEXT,
  EXAM_TYPE,
  EXAM_TYPE_STRING,
  PROFILE_TYPE,
  GOOGLE_AUTH_KEYS,
  FACEBOOK_AUTH_KEYS,
  CATEGORY_LINK,
  ARTICLE_TYPE,
  REGEXCHECKPHONE,
  REGEXCHECKEMAIL,
  addressRainichi,
  LANDING_PAGE,
  REGEXCHECKPASS,
  formatNumber,
  formatCoin,
  createMarkup,
  numberWithCommas
};
