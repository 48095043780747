/* eslint-disable max-len */
/*
 * @Author: loc.do@bstarsolutions.com
 * @Date: 2019-05-16 16:16:04
 * @Last Modified by: loc.do@bstarsolutions.com
 * @Last Modified time: 2019-09-20 17:02:33
 */

import React from 'react';
import injectSheet from 'react-jss';
import { images } from 'appCore';
import styles from './styles';

function LoadingComponent(props) {
  const { classes, id } = props;
  return (
    <div className={classes.container} id={id || 'loading'}>
      <img src={images.loadingComponent} alt="Loading" />
    </div>
  );
}

export default injectSheet(styles)(LoadingComponent);
