import * as types from './types';

export const disableMic = () => ({
  type: types.DISABLE_MIC
});

export const enableMic = () => ({
  type: types.ENABLE_MIC
});

export const disableVolume = () => ({
  type: types.DISABLE_VOLUME
});

export const enableVolume = () => ({
  type: types.ENABLE_VOLUME
});

export default this;
