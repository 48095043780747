/*
 * @Author: loc.do@bstarsolutions.com
 * @Date: 2019-05-03 09:32:20
 * @Last Modified by: loc.do@bstarsolutions.com
 * @Last Modified time: 2019-05-03 14:13:23
 */
import React from 'react';
import injectSheet from 'react-jss';
import c from 'classnames';
import styles from './styles';

const Divider = ({ classes, className }) => <div className={c(classes.divider, className)} />;

export default injectSheet(styles)(Divider);
