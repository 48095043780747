import * as types from './types';

const INITIAL_STATE = {
  statusDisableMic: false,
  statusDisableVolume: false
};

export default (state = INITIAL_STATE, action) => {
  switch (action.type) {
    case types.DISABLE_MIC:
      return {
        ...state,
        statusDisableMic: true
      };
    case types.ENABLE_MIC:
      return {
        ...state,
        statusDisableMic: false
      };
    case types.DISABLE_VOLUME:
      return {
        ...state,
        statusDisableVolume: true
      };
    case types.ENABLE_VOLUME:
      return {
        ...state,
        statusDisableVolume: false
      };
    default:
      return state;
  }
};
