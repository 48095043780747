import * as types from './type';

const INITIAL_STATE = {
  status: false,
  exams: {},
  isCombo: false,
  isPrimary: false,
  valueExam: 0,
  isFetching: false
};

export default (state = INITIAL_STATE, action) => {
  switch (action.type) {
    case types.LEVEL_SUCCESS:
      return {
        ...state,
        levels: action.payload.levels
      };
    case types.EXAM_FETCHING:
      return {
        ...state,
        isFetching: true
      };
    case types.EXAM_SUCCESS:
      return {
        ...state,
        status: action.payload.status,
        exams: action.payload.exams,
        isFetching: false
      };
    case types.EXAM_FAIL:
      return {
        ...state,
        isFetching: true
      };
    case types.EXAM_PACKAGE_COMBO:
      return {
        ...state,
        isCombo: action.payload
      };

    case types.EXAM_PACKAGE_PRIMARY:
      return {
        ...state,
        isPrimary: action.payload
      };
    case types.SELECT_VALUE_EXAM:
      return {
        ...state,
        valueExam: action.payload.value
      };
    default:
      return state;
  }
};
