import { theme } from 'appCore';
import { scaleStyle } from 'utils';

export default {
  wrapper: {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    alignItems: 'center',
    zIndex: 0,
    cursor: 'pointer',
    flex: '1 0 17%',
    '&:hover': {
      backgroundColor: theme.colors.highlight
    },
    ...scaleStyle({
      height: 178,
      maxWidth: 224,
      borderRadius: 40
    })
  },
  active: {
    backgroundColor: theme.colors.highlight
  },
  vocabulary: {
    fontFamily: theme.fonts.montserrat,
    fontWeight: 600,
    ...theme.components.vocabulary
  },
  spelling: {
    whiteSpace: 'nowrap',
    overflow: 'hidden',
    textOverflow: 'ellipsis',
    ...scaleStyle({
      maxWidth: 190,
      lineHeight: '60px',
      fontSize: 30
    })
  }
};
