import * as types from './types';

const INITIAL_STATE = {
  status: false,
  lesson: [],
  result: {},
  statusSubmit: false
};

export default (state = INITIAL_STATE, action) => {
  switch (action.type) {
    case types.CHANGE_ACTIVE_ANSWER_SUCCESS:
      return {
        ...state,
        status: action.payload.status,
        lesson: action.payload.lesson
      };
    case types.SUBMIT_PRACTICE_FETCHING:
      return {
        ...state,
        statusSubmit: action.payload.statusSubmit
      };
    case types.SUBMIT_PRACTICE_SUCCESS:
      return {
        ...state,
        status: action.payload.status,
        result: action.payload.result,
        statusSubmit: action.payload.statusSubmit
      };
    default:
      return state;
  }
};
