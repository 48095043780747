/*
 * @Author: loc.do@bstarsolutions.com
 * @Date: 2019-05-27 15:14:18
 * @Last Modified by: loc.do@bstarsolutions.com
 * @Last Modified time: 2019-08-22 18:53:40
 */

import * as types from './types';

export const changeActiveAnswer = (id, type, lessonCurrentId) => ({
  type: types.CHANGE_ACTIVE_INIT,
  payload: { id, type, lessonCurrentId }
});

export const submitPractice = (
  lessionId,
  score,
  totalQuestion,
  courseId,
  hasPractice,
  courseName
) => ({
  type: types.SUBMIT_PRACTICE_INIT,
  payload: { lessionId, score, totalQuestion, courseId, hasPractice, courseName }
});

export const remakeDataLesson = () => ({
  type: types.REMAKE_DATA_LESSON,
  payload: {}
});

export default this;
