/*
 * @Author: loc.do@bstarsolutions.com
 * @Date: 2019-05-13 16:58:03
 * @Last Modified by: loc.do@bstarsolutions.com
 * @Last Modified time: 2019-05-13 17:15:37
 */

import React, { useEffect } from 'react';
import injectSheet from 'react-jss';
import { translator } from 'utils';

import styles from './styles';

function Dot(props) {
  const { classes, dataDots, idDot } = props;

  let slideIndex = 1;

  const dotS = n => {
    const x = document.querySelectorAll(`#${idDot}`);

    let i = 0;

    if (n > x.length) {
      slideIndex = 1;
    }
    if (n < 1) {
      slideIndex = x.length;
    }
    for (i; i < x.length; i += 1) {
      x[i].style.background = '#b2b2b2';
    }
    if (x !== undefined && x.length !== 0) {
      x[slideIndex - 1].style.background = '#f37021';
    }
  };

  const autoSlide = n => {
    dotS((slideIndex += n));
    setInterval(() => {
      dotS((slideIndex += n));
    }, 5000);
  };

  useEffect(() => {
    autoSlide(1);
  }, []);

  return (
    <div className={classes.dotContainer}>
      {dataDots.map((dot, i) => {
        return <div key={i} id={idDot} className={classes.dot} />;
      })}
    </div>
  );
}

Dot.defaultProps = {
  dataDots: [
    {
      id: 1
    },
    {
      id: 2
    }
  ]
};

export default translator(injectSheet(styles)(Dot));
