import { images, theme } from 'appCore';

export default {
  select: {
    minWidth: 40,
    position: 'relative',
    marginLeft: 5,
    padding: '0px 20px 0px 0px',
    ...theme.fontSizes.font16,
    border: 'none',
    outline: 'none',
    height: 40,
    appearance: 'none',
    background: `url(${images.down}) 90% / 10px no-repeat transparent`,
    display: 'flex',
    justifyContent: 'center',
    cursor: 'pointer'
  },
  list: {
    display: 'none',
    top: '100%',
    background: theme.colors.white,
    boxShadow: `1px 1px 2px ${theme.colors.gray}`,
    padding: 5
  },
  open: {
    display: 'flex',
    flexDirection: 'column',
    position: 'absolute',
    zIndex: 999
  },
  item: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    minWidth: 50,
    padding: 5,
    '&:hover': {
      background: theme.colors.highlight
    }
  },
  value: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center'
  }
};
