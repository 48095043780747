import * as types from './types';

export const hideAll = () => ({
  type: types.ALL_MODALS_HIDE
});

export const showModal = modalProps => ({
  type: types.MODAL_SHOW,
  payload: modalProps
});

export const hideModal = () => ({
  type: types.MODAL_HIDE,
  payload: {}
});

export const hideBtnClose = () => ({
  type: types.HIDE_BTN_CLOSE_IN_MODAL
});

export const showBtnClose = () => ({
  type: types.SHOW_BTN_CLOSE_IN_MODAL
});

export default this;
