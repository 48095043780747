export const SIGNUP_SUBMIT = 'SIGNUP_SUBMIT';
export const SIGNUP_FETCHING = 'SIGNUP_FETCHING';
export const SIGNUP_SUCCESS = 'SIGNUP_SUCCESS';
export const SIGNUP_FAILURE = 'SIGNUP_FAILURE';
export const WATING_FOR_ACTIVE = 'WATING_FOR_ACTIVE';
export const EMAIL_HAS_BEEN_TAKEN = 'EMAIL_HAS_BEEN_TAKEN';
export const EMAIL_REMOVE = 'EMAIL_REMOVE';

export const SIGNUP_ACTIVE_SUBMIT = 'SIGNUP_ACTIVE_SUBMIT';
export const SIGNUP_ACTIVE_FETCHING = 'SIGNUP_ACTIVE_FETCHING';
export const SIGNUP_ACTIVE_SUCCESS = 'SIGNUP_ACTIVE_SUCCESS';
export const SIGNUP_ACTIVE_FAILURE = 'SIGNUP_ACTIVE_FAILURE';
