/*
 * @Author: loc.do@bstarsolutions.com
 * @Date: 2019-05-10 11:04:10
 * @Last Modified by: loc.do@bstarsolutions.com
 * @Last Modified time: 2019-07-04 16:15:36
 */

import { createStructuredSelector } from 'reselect';

const listCourseChild = state => state.courseChild;
const breadcrumb = state => state.app.breadcrumb;
const currentLang = state => state.app.currentLang;
const isFetching = state => state.courseChild.isFetching;

export default createStructuredSelector({
  breadcrumb,
  listCourseChild,
  currentLang,
  isFetching
});
