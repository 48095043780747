import { createStructuredSelector } from 'reselect';

const listExercises = state => state.exercise;
const breadcrumb = state => state.app.breadcrumb;
const currentLang = state => state.app.currentLang;
const listPracticeExercises = state => state.exercise.practiceExercise;
const totalScore = state => state.exercise.totalScore;
const totalQuestion = state => state.exercise.totalQuestion;
const totalScoreApi = state => state.exercise.totalScoreApi;
const isShowPagePracticeExercise = state => state.exercise.isShowPagePracticeExercise;
const isFetching = state => state.exercise.isFetching;
const idExercise = state => state.exercise.idExercise;
const questions = state => state.exercise.questions;
const answers = state => state.exercise.answers;
const resultDataSubmitNewTest = state => state.exercise.resultDataSubmitNewTest;
const resultExercise = state => state.exercise.resultExercise;
const isFetchingPractice = state => state.exercise.isFetchingPractice;
const timeExam = state => state.exercise.hasTime;
const isPrimary = state => state.exams.isPrimary;
const isCompleted = state => state.exercise.resultExercise.is_completed;
const isReloadPageWhenPracticeExam = state => state.exercise.isReloadPageWhenPracticeExam;

export default createStructuredSelector({
  listExercises,
  breadcrumb,
  currentLang,
  listPracticeExercises,
  totalScore,
  totalQuestion,
  isShowPagePracticeExercise,
  isFetching,
  idExercise,
  questions,
  resultDataSubmitNewTest,
  totalScoreApi,
  resultExercise,
  isFetchingPractice,
  timeExam,
  isPrimary,
  isCompleted,
  isReloadPageWhenPracticeExam,
  answers
});
