export default {
  wrapUserExist: {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    alignItems: 'center'
  },
  btnOK: {
    marginTop: 20,
    marginLeft: 10,
    marginRight: 10
  },
  wrapBtn: {
    display: 'flex',
    flexDirection: 'row'
  }
};
