/*
 * @Author: loc.do@bstarsolutions.com
 * @Date: 2019-05-14 14:36:31
 * @Last Modified by: Do Loc - doxuanloc2016@gmail.comail.com
 * @Last Modified time: 2019-10-17 22:13:38
 */

import * as types from './types';

const INITIAL_STATE = {
  status: false,
  lessonInCourse: {},
  isFetching: false,
  lessonInCourseId: 0
};

export default (state = INITIAL_STATE, action) => {
  switch (action.type) {
    case types.LESSON_IN_COURSE_FETCHING:
      return {
        ...state,
        isFetching: true
      };
    case types.LESSON_IN_COURSE_SUCCESS:
      return {
        ...state,
        status: action.payload.status,
        lessonInCourse: action.payload.lessonInCourse,
        isFetching: false,
        lessonInCourseId: action.payload.lessonInCourseId
      };
    case types.LESSON_IN_COURSE_FAIL:
      return {
        ...state,
        status: false,
        lessonInCourse: action.payload.lessonInCourse,
        isFetching: false
      };
    default:
      return state;
  }
};
