/*
 * @Author: loc.do@bstarsolutions.com
 * @Date: 2019-06-07 11:34:05
 * @Last Modified by: loc.do@bstarsolutions.com
 * @Last Modified time: 2019-10-22 15:46:26
 */
import React from 'react';
import { put, takeLatest, select, takeMaybe } from 'redux-saga/effects';
import { StoreAPIs } from 'services';
import { ModalConfirm, ModalContact } from 'components';
import { modalActions } from 'containers/ModalManagement/duck';
import { storeActions } from 'containers/Shop/duck';
import * as types from './types';

const tokenSelector = state => (state.login.user ? state.login.user.access_token : '');
const isComboSelect = state => state.courses.isCombo;

function* getListCoin() {
  const token = yield select(tokenSelector);
  try {
    yield put({ type: types.COIN_FETCHING });
    let rawData;
    if (token) {
      rawData = yield StoreAPIs.getListCoin();
    } else {
      rawData = yield StoreAPIs.getListCoinNotAuthen();
    }
    const filterData = rawData.data.data;
    const sortBy = (a, b, key) => Number(a[key]) - Number(b[key]);
    const sortByCredit = (l, r) => sortBy(l, r, 'credit');
    const dataSort = filterData.sort(sortByCredit);
    yield put({
      type: types.COIN_SUCCESS,
      payload: {
        statusFetchCoin: rawData.data.success,
        listCoin: dataSort
      }
    });
  } catch (e) {
    yield put({ type: types.COIN_FAIL });
  }
}

function* getCourseInShop(action) {
  const token = yield select(tokenSelector);
  const { type } = action.payload;
  try {
    let rawData;
    yield put({ type: types.COURSE_SHOP_FETCHING });
    if (token) {
      rawData = yield StoreAPIs.getListCourse(type);
    } else {
      rawData = yield StoreAPIs.getListCourseNotAuthen(type);
    }

    yield put({
      type: types.COURSE_SHOP_SUCCESS,
      payload: {
        statusFetchCourseInShop: rawData.data.success,
        listCourseInShop: rawData.data.data
      }
    });
  } catch (e) {
    yield put({ type: types.COURSE_SHOP_FAIL });
  }
}

function* getSkillInShop() {
  const token = yield select(tokenSelector);
  try {
    yield put({ type: types.SKILL_SHOP_FETCHING });
    let rawData;
    if (token) {
      rawData = yield StoreAPIs.getListSkill();
    } else {
      rawData = yield StoreAPIs.getListSkillNotAuthen();
    }
    yield put({
      type: types.SKILL_SHOP_SUCCESS,
      payload: {
        statusFetchSkillInShop: rawData.data.success,
        listSkillInShop: rawData.data.data
      }
    });
  } catch (e) {
    yield put({ type: types.SKILL_SHOP_FAIL });
  }
}

function* getSelectCourseInShop(action) {
  const token = yield select(tokenSelector);
  const { id, courseType, levels, typeUser } = action.payload;
  try {
    yield put({ type: types.SELECT_COURSE_FETCHING });
    let rawData;
    if (token) {
      rawData = yield StoreAPIs.getListSelectCourse(id, courseType, levels, typeUser);
    } else {
      rawData = yield StoreAPIs.getListSelectCourseNotAuthen(id, courseType, levels, typeUser);
    }
    yield put({
      type: types.SELECT_COURSE_SUCCESS,
      payload: {
        statusFetchSelectCourseInShop: rawData.data.success,
        listSelectCourseInShop: rawData.data.data
      }
    });
  } catch (e) {
    yield put({ type: types.SELECT_COURSE_FAIL });
  }
}

function* filterCourseInShop(action) {
  const token = yield select(tokenSelector);
  const { id, courseId, typeCourse } = action.payload;
  try {
    yield put({ type: types.FILTER_COURSE_FROM_COURSE_TO_SHOP_FETCHING });
    let rawData;
    if (token) {
      rawData = yield StoreAPIs.filterCourseInShop(id, courseId, typeCourse);
    } else {
      rawData = yield StoreAPIs.filterCourseInShopNotAuthen(id, courseId, typeCourse);
    }

    yield put({
      type: types.FILTER_COURSE_FROM_COURSE_TO_SHOP_SUCCESS,
      payload: {
        statusFetchSelectCourseInShop: rawData.data.success,
        listSelectCourseInShop: rawData.data.data
      }
    });
  } catch (e) {
    yield put({ type: types.FILTER_COURSE_FROM_COURSE_TO_SHOP_FAIL });
  }
}

function* filterExamInShop(action) {
  const token = yield select(tokenSelector);
  const { examId } = action.payload;
  try {
    yield put({ type: types.FILTER_EXAM_IN_COURSE_FETCHING });
    let rawData;
    if (token) {
      rawData = yield StoreAPIs.filterExamInShop(examId);
    } else {
      rawData = yield StoreAPIs.filterExamInShopNotAuthen(examId);
    }
    yield put({
      type: types.FILTER_EXAM_IN_COURSE_SUCCESS,
      payload: {
        statusFetchSelectExamInShop: rawData.data.success,
        listSelectExamInShop: rawData.data.data
      }
    });
  } catch (e) {
    yield put({ type: types.FILTER_EXAM_IN_COURSE_FAIL });
  }
}

function* getSelectExamInShop(action) {
  const token = yield select(tokenSelector);
  const { type, courseType, arrayLevel } = action.payload;
  try {
    yield put({ type: types.EXAM_SHOP_FETCHING });
    let rawData;
    if (token) {
      rawData = yield StoreAPIs.getListSelectExam(arrayLevel, type, courseType);
    } else {
      rawData = yield StoreAPIs.getListSelectExamNotAuthen(arrayLevel, type, courseType);
    }
    yield put({
      type: types.EXAM_SHOP_SUCCESS,
      payload: {
        statusFetchSelectExamInShop: rawData.data.success,
        listSelectExamInShop: rawData.data.data
      }
    });
  } catch (e) {
    yield put({ type: types.EXAM_SHOP_FAIL });
  }
}

function* getPaymentVNPay(action) {
  const {
    vnpBankCode,
    amount,
    orderInfo,
    courseId,
    isEnterprise,
    duration,
    numOfEmployee
  } = action.payload;
  try {
    yield put({ type: types.PAYMENT_FETCHING });
    const rawData = yield StoreAPIs.getPaymentVnPay(
      vnpBankCode,
      amount,
      orderInfo,
      courseId,
      isEnterprise,
      duration,
      numOfEmployee
    );
    if (rawData.data.code === 200) {
      window.location.href = rawData.data.data.redirect_url;
    }
  } catch (e) {
    yield put({
      type: types.PAYMENT_FAIL
    });
  }
}

function* payTransferCoinOffline(action) {
  const {
    paymentType,
    amount,
    orderInfo,
    courseId,
    isEnterprise,
    duration,
    numOfEmployee
  } = action.payload;
  try {
    yield put({ type: types.PAYMENT_BUY_OFFLINE_FETCHING });
    const rawData = yield StoreAPIs.payTransferCoinOffline(
      paymentType,
      amount,
      orderInfo,
      courseId,
      isEnterprise,
      duration,
      numOfEmployee
    );
    yield put({
      type: types.PAYMENT_BUY_OFFLINE_SUCCESS,
      payload: {
        paymentInfoOffline: rawData.data.data
      }
    });
  } catch (e) {
    yield put({
      type: types.PAYMENT_BUY_OFFLINE_FAIL
    });
  }
}

function* buyCourseInShop(action) {
  const isCombo = yield select(isComboSelect);
  const { courseId, credit, reward, duration } = action.payload;
  try {
    yield put({ type: types.BUY_COURSE_IN_SHOP_FETCHING });
    const rawData = yield StoreAPIs.buyCourseInShop(courseId, credit, reward, duration);
    if (rawData.data.message === 'Success') {
      const children = (
        <ModalConfirm isCombo={isCombo} courseId={courseId} title="Course" success />
      );
      yield put(modalActions.showModal({ children }));
    } else {
      const children = <ModalConfirm title="Course" fail />;
      yield put(modalActions.showModal({ children }));
    }
    yield put({
      type: types.BUY_COURSE_IN_SHOP_SUCCESS
    });
  } catch (e) {
    if (e.message === 'Request failed with status code 422') {
      const children = <ModalConfirm title="Course" notCoin />;
      yield put(modalActions.showModal({ children }));
    }
    yield put({
      type: types.BUY_COURSE_IN_SHOP_FAIL
    });
  }
}

function* buyExamInShop(action) {
  const { examId, credit, reward, duration } = action.payload;
  try {
    yield put({ type: types.BUY_EXAM_IN_SHOP_FETCHING });
    const rawData = yield StoreAPIs.buyExamInShop(examId, credit, reward, duration);
    if (rawData.data.message === 'Success') {
      const children = <ModalConfirm examId={examId} successExam />;
      yield put(modalActions.showModal({ children }));
      yield put({
        type: types.BUY_EXAM_IN_SHOP_SUCCESS
      });
    } else {
      const children = <ModalConfirm failExam />;
      yield put(modalActions.showModal({ children }));
    }
  } catch (e) {
    if (e.message === 'Request failed with status code 410') {
      const children = <ModalConfirm alreadyExam examId={examId} />;
      yield put(modalActions.showModal({ children }));
    }
    if (e.message === 'Request failed with status code 422') {
      const children = <ModalConfirm notCoinExam />;
      yield put(modalActions.showModal({ children }));
    }
    yield put({
      type: types.BUY_EXAM_IN_SHOP_FAIL
    });
  }
}

function* showPaymentPage(action) {
  const { courseEnterprise } = action.payload;
  yield put({
    type: types.SHOW_PAYMENT_PAGE,
    payload: {
      courseEnterprise
    }
  });
}

function* hidePaymentPage() {
  yield put({
    type: types.HIDE_PAYMENT_PAGE
  });
}

function* buyClassInShop(action) {
  const { courseId, numOfEmployee, credit, reward, duration } = action.payload;
  try {
    yield put({ type: types.BUY_CLASS_IN_SHOP_FETCHING });
    const rawData = yield StoreAPIs.buyClassInShop(
      courseId,
      numOfEmployee,
      credit,
      reward,
      duration
    );
    if (rawData.data && rawData.data.message === 'Success') {
      const children = <ModalConfirm title="Course" success />;
      yield put(modalActions.showModal({ children }));
    } else if (rawData.data && rawData.data.code === 201) {
      const children = <ModalConfirm title="Class" dataClass={rawData.data} successClass />;
      yield put(modalActions.showModal({ children }));
    } else if (rawData && rawData.code === 12) {
      const children = <ModalConfirm title="Class" dataClass={rawData.data} createdClass />;
      yield put(modalActions.showModal({ children }));
    } else {
      const children = <ModalConfirm title="Class" fail />;
      yield put(modalActions.showModal({ children }));
    }
    yield put({
      type: types.BUY_CLASS_IN_SHOP_SUCCESS
    });
  } catch (e) {
    if (e.message === 'Request failed with status code 422') {
      const children = <ModalConfirm title="Class" notCoin />;
      yield put(modalActions.showModal({ children }));
    }
    yield put({
      type: types.BUY_CLASS_IN_SHOP_FAIL
    });
  }
}

function* buyClassInShopExistSaga(action) {
  const { typeForm, courseId, numOfEmployee, email, phone, name } = action.payload;
  try {
    yield put({ type: types.BUY_CLASS_NOT_EXIST_FETCHING });
    const rawData = yield StoreAPIs.buyClassInShopExist(
      typeForm,
      courseId,
      numOfEmployee,
      email,
      phone,
      name
    );

    if (rawData.errors && String(rawData.errors.num_of_employee) === 'validation.numeric') {
      const children = <ModalContact emptyNumOfEmployee />;
      yield put(modalActions.showModal({ children }));
    } else if (rawData.errors && String(rawData.errors.course_id) === 'validation.numeric') {
      const children = <ModalContact emptyCourse />;
      yield put(modalActions.showModal({ children }));
    } else if (rawData.data && rawData.data.code === 200) {
      const children = <ModalContact registerSuccess />;
      yield put(modalActions.showModal({ children }));
    }

    yield put({
      type: types.BUY_CLASS_NOT_EXIST_SUCCESS,
      payload: {
        status: false
      }
    });
  } catch (e) {
    yield put({
      type: types.BUY_CLASS_NOT_EXIST_FAIL
    });
  }
}

function* checkBoughtClassSaga(action) {
  const { courseEnterprise } = action.payload;
  yield put({ type: types.CHECK_BOUGHT_CLASS_FETCHING });
  const rawData = yield StoreAPIs.checkBoughtClass();
  if (rawData.data && rawData.data.code === 200) {
    yield put(storeActions.showPaymentPage(courseEnterprise));
    yield put({
      type: types.CHECK_BOUGHT_CLASS_SUCCESS,
      payload: {
        courseEnterprise
      }
    });
  } else if (rawData.code && rawData.code === 409) {
    const children = <ModalContact courseEnterprise={courseEnterprise} userExist />;
    yield put(modalActions.showModal({ children }));
    yield put({
      type: types.CHECK_BOUGHT_CLASS_SUCCESS,
      payload: {
        courseEnterprise
      }
    });
  } else if (rawData.code && rawData.code === 403) {
    yield put({ type: types.CHECK_BOUGHT_CLASS_FAIL });
    const children = <ModalContact userJoined />;
    yield put(modalActions.showModal({ children }));
  }
}

function* getPaymentVNPayBuyCourse(action) {
  const { vnpBankCode, amount, orderInfo, courseId } = action.payload;
  try {
    yield put({ type: types.BUY_COURSE_VNPAY_FETCHING });
    const rawData = yield StoreAPIs.getPaymentVnPayBuyCourse(
      vnpBankCode,
      amount,
      orderInfo,
      courseId
    );
    if (rawData.data.code === 200) {
      window.location.href = rawData.data.data.redirect_url;
    }
  } catch (e) {
    yield put({
      type: types.BUY_COURSE_VNPAY_FAIL
    });
  }
}

export const getListCoinSaga = takeLatest(types.COIN_INIT, getListCoin);
export const getCourseInShopSaga = takeLatest(types.COURSE_SHOP_INIT, getCourseInShop);
export const getSkillInShopSaga = takeLatest(types.SKILL_SHOP_INIT, getSkillInShop);
export const buyCourseInShopSaga = takeLatest(types.BUY_COURSE_IN_SHOP_INIT, buyCourseInShop);
export const buyExamInShopSaga = takeLatest(types.BUY_EXAM_IN_SHOP_INIT, buyExamInShop);
export const getSelectCourseInShopSaga = takeLatest(
  types.SELECT_COURSE_SHOP_INIT,
  getSelectCourseInShop
);
export const filterCourseInShopSaga = takeLatest(
  types.FILTER_COURSE_FROM_COURSE_TO_SHOP_INIT,
  filterCourseInShop
);

export const filterExamInShopSaga = takeLatest(types.FILTER_EXAM_IN_COURSE_INIT, filterExamInShop);

export const getSelectExamInShopSaga = takeLatest(types.EXAM_SHOP_INIT, getSelectExamInShop);
export const getPaymentInfoSaga = takeLatest(types.PAYMENT_INIT, getPaymentVNPay);
export const payTransferCoinOfflineSaga = takeLatest(
  types.PAYMENT_BUY_OFFLINE_INIT,
  payTransferCoinOffline
);
export const showPaymentPageSaga = takeMaybe(showPaymentPage);
export const hidePaymentPageSaga = takeMaybe(hidePaymentPage);
export const buyClassInShopSaga = takeLatest(types.BUY_CLASS_IN_SHOP_INIT, buyClassInShop);
export const buyClassInShopExistInSaga = takeLatest(
  types.BUY_CLASS_NOT_EXIST_INIT,
  buyClassInShopExistSaga
);
export const checkBoughtClassInSaga = takeLatest(
  types.CHECK_BOUGHT_CLASS_INIT,
  checkBoughtClassSaga
);

export const getPaymentVNPayBuyCourseInSaga = takeLatest(
  types.BUY_COURSE_VNPAY_INIT,
  getPaymentVNPayBuyCourse
);
export default this;
