/*
 * @Author: loc.do@bstarsolutions.com
 * @Date: 2019-05-10 09:42:45
 * @Last Modified by: loc.do@bstarsolutions.com
 * @Last Modified time: 2019-08-06 16:42:30
 */
import { theme } from 'appCore';
import { scaleStyle } from 'utils';

export default {
  container: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center'
  },
  introductionFeature: {
    fontWeight: `bold`,
    color: theme.colors.grayDark,
    textTransform: 'uppercase',
    ...scaleStyle({
      fontSize: 25,
      lineHeight: `34px`
    })
  },
  whoUseFeature: {
    color: theme.colors.primary,
    textTransform: 'uppercase',
    textAlign: 'center',
    fontWeight: `bold`,
    ...scaleStyle({
      fontSize: 40,
      lineHeight: `55px`,
      marginTop: 6
    })
  },
  title: {
    fontWeight: `bold`
  },
  borderFeature: {
    backgroundColor: theme.colors.primary,
    ...scaleStyle({
      marginTop: 26,
      height: 5,
      width: 60
    })
  },
  descriptionCommomFeature: {
    color: theme.colors.gray,
    textAlign: 'center',

    fontSize: 13,
    lineHeight: '24px',
    ...scaleStyle({
      marginTop: 55,
      // fontSize: 20,
      // lineHeight: `27px`,
      maxWidth: 1435,
      marginBottom: 20
    }),
    [`@media screen and (min-width: 1200px)`]: {
      fontSize: 20,
      lineHeight: `30px`
    }
  },
  imgPracticeExercise: {
    ...scaleStyle({
      width: 268,
      height: 268,
      borderRadius: 30,
      marginTop: 20
    })
  },
  [`@media screen and (max-width: ${theme.screens.maxWidth800}px)`]: {
    descriptionCommomFeature: {
      maxWidth: 350
    },
    introductionFeature: {
      fontSize: 13
    },
    whoUseFeature: {
      fontSize: 20,
      maxWidth: 240
    }
  }
};
