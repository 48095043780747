import React, { useState } from 'react';
import PropTypes from 'prop-types';
import c from 'classnames';
import injectSheet from 'react-jss';
import { images } from 'appCore';
import { Block } from 'components';
import styles from './styles';

const Collapse = props => {
  const { classes, title, content } = props;
  const [isOpen, setOpen] = useState(false);
  return (
    <Block className={classes.block}>
      <div className={classes.top} onClick={() => setOpen(!isOpen)}>
        <div className={classes.title}>{title}</div>
        <div>
          {isOpen ? (
            <img className={c(classes.icon, classes.sub)} src={images.sub} alt="collapse" />
          ) : (
            <img className={c(classes.icon, classes.plus)} src={images.plus} alt="collapse" />
          )}
        </div>
      </div>
      <div className={classes.bottom}>
        <span className={c(classes.content, { [classes.open]: isOpen })}>{isOpen && content}</span>
      </div>
    </Block>
  );
};

Collapse.propTypes = {
  title: PropTypes.string,
  content: PropTypes.string
};

Collapse.defaultProps = {
  title: 'untitle',
  content: ``
};

export default injectSheet(styles)(Collapse);
