/*
 * @Author: loc.do@bstarsolutions.com
 * @Date: 2019-05-16 10:29:29
 * @Last Modified by: loc.do@bstarsolutions.com
 * @Last Modified time: 2019-08-06 11:49:12
 */

import { theme } from 'appCore';
import { scaleStyle } from 'utils';

export default {
  boxChildCourse: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: `center`,
    ...scaleStyle({
      // width: 285
    })
  },
  childCourse: {
    cursor: `pointer`,
    position: 'relative',
    ...scaleStyle({
      // width: 245,
      // height: 229,
      // marginLeft: 27,
      // marginRight: 27
    })
  },
  name: {
    textAlign: `center`,
    lineHieght: `48px`,
    whiteSpace: `nowrap`,
    overflow: `hidden`,
    textOverflow: `ellipsis`,
    color: theme.colors.black,
    ...scaleStyle({
      width: 245,
      marginBottom: 63,
      marginTop: 28,
      fontSize: 20
    })
  },
  imgPadlock: {
    position: 'absolute',
    zIndex: 1
  },
  imgChildCourse: {
    width: `100%`,
    backgroundRepeat: 'no-repeat',
    backgroundSize: '100% auto',
    objectFit: 'contain',
    position: 'relative',
    paddingTop: '79%',
    backgroundPosition: 'center center'
  },
  boxStar: {
    marginTop: 20
  },
  star: {
    width: 24,
    height: 24
  }
};
