import React, { useState, useEffect, useRef } from 'react';
import injectSheet from 'react-jss';
import PropTypes from 'prop-types';
import c from 'classnames';
import styles from './styles';

const defaultList = [
  {
    label: 'New York'
  },
  {
    label: 'Dublin'
  }
];

const useOuterClickNotifier = (onOuterClick, innerRef) => {
  useEffect(() => {
    const handleClick = e => {
      if (!innerRef.current.contains(e.target)) onOuterClick(e);
    };
    if (innerRef.current) {
      document.addEventListener('click', handleClick);
      const elements = document.getElementsByTagName('a');
      // eslint-disable-next-line no-plusplus
      for (let i = 0, len = elements.length; i < len; i++) {
        elements[i].onclick = onOuterClick;
      }
    }
    return () => document.removeEventListener('click', handleClick);
  }, [onOuterClick, innerRef]);
};

const PopupMenu = ({ classes, className, list, children }) => {
  const [open, setOpen] = useState(false);
  const innerRef = useRef(null);
  useOuterClickNotifier(() => setOpen(false), innerRef);
  return (
    <div ref={innerRef} className={c(classes.popup, className)} onClick={() => setOpen(!open)}>
      {children}
      <div className={c(classes.list, { [classes.open]: open })}>
        {list.map((e, i) => (
          <div className={classes.item} key={i} onClick={e.action}>
            {e.label}
          </div>
        ))}
      </div>
    </div>
  );
};

PopupMenu.propTypes = {
  list: PropTypes.arrayOf(Object)
};

PopupMenu.defaultProps = {
  list: defaultList
};

export default injectSheet(styles)(PopupMenu);
