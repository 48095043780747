/*
 * @Author: loc.do@bstarsolutions.com
 * @Date: 2019-07-26 10:41:50
 * @Last Modified by: loc.do@bstarsolutions.com
 * @Last Modified time: 2019-08-12 16:57:54
 */
import { connect } from 'react-redux';
import React from 'react';
import { compose } from 'recompose';
import injectSheet from 'react-jss';
import { ModalContent } from 'components';
import { withRouter } from 'react-router';
import { translator } from 'utils';

import styles from './styles';

const ModalResultDetailExam = props => {
  const {
    classes,
    resultData,
    currentLang,
    strings,
    sectionId,
    history,
    isComplete,
    isPrimaryExam
  } = props;

  const lang = strings.submitPractice;

  const movePage = () => {
    history.push(`/de-thi/bai-thi/${sectionId}`);
  };

  const renderInformation = () => {
    return (
      <div className={classes.blockSectionResult}>
        <div className={classes.partContest}>
          {resultData.sections &&
            resultData.sections.map(section => {
              return (
                <div className={classes.detailResult} key={section.id}>
                  <div className={classes.wrapBlockTestSection}>
                    <span className={classes.contest}>
                      {section.translations[currentLang]
                        ? section.translations[currentLang].name
                        : section.translations.vi.name}
                    </span>
                  </div>
                  <div className={classes.wrapBlockSection}>
                    {section.details.map(detail => {
                      return (
                        <div key={detail.id} className={classes.blockSectionContest}>
                          <span className={classes.detailContestSection}>
                            <span className={classes.firstContest}>{lang.questions}</span>
                            <span className={classes.lastContest}>
                              {`: ${detail.question}`} / {detail.total_question}
                            </span>
                          </span>
                          <span className={classes.detailContestSection}>
                            <span className={classes.firstContest}>{lang.score}</span>
                            <span className={classes.lastContest}>
                              {Number.isInteger(detail.score)
                                ? `: ${detail.score} `
                                : `: ${
                                    detail.score
                                      .toFixed(2)
                                      .slice(detail.score.toFixed(2).length - 1) === '0'
                                      ? Number(
                                          detail.score
                                            .toFixed(2)
                                            .slice(0, detail.score.toFixed(2).length - 1)
                                        )
                                      : detail.score.toFixed(2)
                                  }`}
                              / {` ${detail.total_score}`}
                            </span>
                          </span>
                          <span className={classes.detailContestSection}>
                            <span className={classes.firstContest}>{lang.failScore}</span>
                            <span className={classes.lastContest}>{`: ${detail.fail_score}`}</span>
                          </span>
                          <span className={classes.detailContestSection}>
                            <span className={classes.firstContest}>{lang.resultContest}</span>
                            <span className={classes.lastContest}>
                              {detail.status ? `: ${lang.passResult}` : `: ${lang.failResult}`}
                            </span>
                          </span>
                        </div>
                      );
                    })}
                  </div>
                </div>
              );
            })}
        </div>

        <div className={classes.wrapTotalResultExam}>
          <span className={classes.titleResultTotalContest}>{lang.summary} </span>
          <div className={classes.blockSummaryContest}>
            <div className={classes.wrapResultContest}>
              <span className={classes.resultTotalContest}>{lang.passScore}</span>
              <span className={classes.resultTotalContestLast}>{`: ${resultData.pass_score}`}</span>
            </div>
            <div className={classes.wrapResultContest}>
              <span className={classes.resultTotalContest}>{`${lang.score}`}</span>
              <span className={classes.resultTotalContestLast}>
                {Number.isInteger(resultData.score)
                  ? `: ${resultData.score} `
                  : `: ${
                      resultData.score.toFixed(2).slice(resultData.score.toFixed(2).length - 1) ===
                      '0'
                        ? Number(
                            resultData.score
                              .toFixed(2)
                              .slice(0, resultData.score.toFixed(2).length - 1)
                          )
                        : resultData.score.toFixed(2)
                    } `}
                / {resultData.total_score}
              </span>
            </div>
            <div className={classes.wrapResultContest}>
              <span className={classes.resultTotalContest}>{lang.titleResult}</span>
              <span className={classes.resultTotalContestLast}>
                :{resultData.result === 'FAILED' ? ` ${lang.failResult}` : ` ${lang.passResult}`}
              </span>
            </div>
          </div>
        </div>
      </div>
    );
  };
  return (
    <>
      {isPrimaryExam ? (
        renderInformation()
      ) : (
        <ModalContent
          onClickDefault={isComplete ? () => movePage() : null}
          title={lang.resultDetail}
          btnLabelModal="OK"
        >
          {renderInformation()}
        </ModalContent>
      )}
    </>
  );
};

export default compose(
  connect(),
  injectSheet(styles),
  translator
)(withRouter(ModalResultDetailExam));
