import React from 'react';
import { put, takeEvery, select } from 'redux-saga/effects';
import { authAPIs } from 'services';
// import { localization } from 'utils';
import { ModalCheckLogin } from 'components';
import { modalActions } from 'containers/ModalManagement/duck';
import { localization } from 'utils';
import * as types from './types';

const currentLangSelector = state => state.app.currentLang;

function* submitForgotPassword(action) {
  const { payload } = action;
  const currentLang = yield select(currentLangSelector);
  try {
    const lang = localization(currentLang);
    const generalLang = lang.general;
    yield put({ type: types.FORGOT_PASSWORD_FETCHING });
    const rawData = yield authAPIs.forgotPassword(payload);
    let children = null;
    const headerTitle = generalLang.forgotPassword;
    if (rawData.status === 422 || rawData.status === 404) {
      children = (
        <ModalCheckLogin
          checkEmailResetLink={generalLang.emailNotExist}
          headerTitle={headerTitle}
        />
      );
      if (rawData.data.errors && rawData.data.errors.email[0] === 'validation.required') {
        children = <ModalCheckLogin checkEmailNotEnter headerTitle={headerTitle} />;
      } else if (rawData.data.errors && rawData.data.errors.email[0] === 'validation.email') {
        children = <ModalCheckLogin checkEmail headerTitle={headerTitle} />;
      }
      yield put({ type: types.FORGOT_PASSWORD_FAILURE });
    } else {
      children = (
        <ModalCheckLogin
          headerTitle={headerTitle}
          checkEmailResetLink={generalLang.emailResetLink}
        />
      );
      yield put({
        type: types.FORGOT_PASSWORD_SUCCESS,
        payload
      });
    }
    yield put(modalActions.showModal({ children }));
    // if (rawData.status === 200) window.location.href = '/login';
  } catch (e) {
    yield put(modalActions.showModal({ title: 'ERROR', message: 'Something went wrong' }));
    yield put({ type: types.FORGOT_PASSWORD_FAILURE });
  }
}

export const forgotPasswordSaga = takeEvery(types.FORGOT_PASSWORD_SUBMIT, submitForgotPassword);

export default this;
