/*
 * @Author: loc.do@bstarsolutions.com
 * @Date: 2019-05-10 10:49:01
 * @Last Modified by: loc.do@bstarsolutions.com
 * @Last Modified time: 2019-08-19 09:44:10
 */

import axios from 'axios';
import { constants } from 'utils';

const Enterprise = {
  submitRegisterEnterprise: (typeForm, name, email, phone, courseId, numOfEmpoyee) =>
    axios
      .post(`${constants.API}/contact`, {
        type: typeForm,
        name,
        email,
        phone,
        course_id: courseId,
        num_of_employee: numOfEmpoyee
      })
      .then(res => res)
      .catch(e =>
        e.response && (e.response.status === 401 || e.response.status === 422) && e.response.data
          ? e.response.data
          : new Error(e)
      ),
  getListCourseEnterprise: () =>
    axios.get(`${constants.API}/landing-page/course?customer_type_id=3`)
};

export default Enterprise;
