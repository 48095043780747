/*
 * @Author: loc.do@bstarsolutions.com
 * @Date: 2019-06-04 11:02:23
 * @Last Modified by: loc.do@bstarsolutions.com
 * @Last Modified time: 2019-07-09 10:25:03
 */

import React, { useRef, useEffect, useState } from 'react';
import injectSheet from 'react-jss';
import c from 'classnames';

import { images } from 'appCore';

import styles from './styles';

function SoundController(props) {
  const { classes, src, className, disableMic, enableMic, resultDisableVolume } = props;

  const refAudio = useRef(null);

  const [canPlay, setCanPlay] = useState(true);

  const play = () => {
    refAudio.current.play();
    setCanPlay(false);
    if (disableMic) disableMic();
  };

  const onPause = () => {
    setCanPlay(true);
    if (enableMic) enableMic();
  };

  const setEnablePlay = () => setCanPlay(true);

  useEffect(() => {
    refAudio.current.addEventListener('pause', setEnablePlay);
    return () => {
      refAudio.current.removeEventListener('pause', setEnablePlay);
    };
  }, []);

  return (
    <>
      <audio
        ref={refAudio}
        onPause={onPause}
        src={src || 'https://www.computerhope.com/jargon/m/example.mp3'}
      >
        <source type="audio/ogg" />
      </audio>
      {!canPlay || !resultDisableVolume ? (
        canPlay ? (
          <img
            className={c(className, classes.icon, { [classes.disable]: !canPlay })}
            onClick={play}
            src={images.volume}
            alt="Volume"
          />
        ) : (
          <img
            className={c(className, classes.icon)}
            onClick={play}
            src={images.volumeActive}
            alt="Volume"
          />
        )
      ) : (
        <img
          className={c(className, classes.disableVolume)}
          src={images.volumeDisable}
          alt="Volume"
        />
      )}
    </>
  );
}

export default injectSheet(styles)(SoundController);
