/*
 * @Author: Do Loc - doxuanloc2016@gmail.com
 * @Date: 2019-06-22 19:24:46
 * @Last Modified by: loc.do@bstarsolutions.com
 * @Last Modified time: 2019-08-09 14:26:18
 */

import React from 'react';
import injectSheet from 'react-jss';
import { translator } from 'utils';
import { ModalContent } from 'components';

import styles from './styles';

const ModalUpdateInformation = props => {
  const {
    strings,
    success,
    fail,
    failImage,
    passwordFail,
    passwordSameFail,
    failEmptyName
  } = props;

  const lang = strings.modalUpdateInformation;
  return (
    <ModalContent isButton={false} title={lang.title}>
      {success && <span>{lang.success}</span>}
      {fail && <span>{lang.fail}</span>}
      {failImage && <span>{lang.failImage}</span>}
      {passwordFail && <span>{lang.passwordFail}</span>}
      {passwordSameFail && <span>{lang.passwordSameFail}</span>}
      {failEmptyName && <span>{lang.failEmptyName}</span>}
    </ModalContent>
  );
};

ModalUpdateInformation.defaultProps = {
  success: false,
  fail: false,
  failImage: false,
  passwordFail: false,
  passwordSameFail: false,
  failEmptyName: false
};

export default translator(injectSheet(styles)(ModalUpdateInformation));
