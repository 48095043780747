/*
 * @Author: loc.do@bstarsolutions.com
 * @Date: 2019-08-02 16:16:37
 * @Last Modified by: loc.do@bstarsolutions.com
 * @Last Modified time: 2019-08-26 10:46:37
 */
import { put, takeLatest } from 'redux-saga/effects';
import React from 'react';
import { ModalContact } from 'components';
import { HomeAPI } from 'services';
import { modalActions } from 'containers/ModalManagement/duck';
import * as types from './types';

function* getListSlideSaga(action) {
  const { slugPage } = action.payload;
  try {
    yield put({ type: types.GET_LIST_SLIDE_FETCHING });
    const rawData = yield HomeAPI.getListSlide(slugPage);

    yield put({
      type: types.GET_LIST_SLIDE_SUCCESS,
      payload: {
        status: false,
        listSlide: rawData.data.data
      }
    });
  } catch (e) {
    yield put({
      type: types.GET_LIST_SLIDE_FAIL,
      payload: {
        listSlide: []
      }
    });
  }
}

function* getListGallerySaga(action) {
  const { slugPage } = action.payload;
  try {
    yield put({ type: types.GET_LIST_GALLERY_FETCHING });
    const rawData = yield HomeAPI.getListGallery(slugPage);
    yield put({
      type: types.GET_LIST_GALLERY_SUCCESS,
      payload: {
        status: false,
        listGallery: rawData.data.data
      }
    });
  } catch (e) {
    yield put({
      type: types.GET_LIST_GALLERY_FAIL,
      payload: {
        listGallery: []
      }
    });
  }
}

function* getListLecturersSaga() {
  try {
    yield put({ type: types.GET_LIST_LECTURERS_FETCHING });
    const rawData = yield HomeAPI.getLecturers();
    yield put({
      type: types.GET_LIST_LECTURERS_SUCCESS,
      payload: {
        status: false,
        listLecturers: rawData.data.data
      }
    });
  } catch (e) {
    yield put({
      type: types.GET_LIST_LECTURERS_FAIL,
      payload: {
        listLecturers: []
      }
    });
  }
}

function* getListCourseInHomeSaga() {
  try {
    yield put({ type: types.GET_LIST_COURSE_IN_HOME_FETCHING });
    const rawData = yield HomeAPI.getListCourseInHome();
    yield put({
      type: types.GET_LIST_COURSE_IN_HOME_SUCCESS,
      payload: {
        status: false,
        listCourseInHome: rawData.data.data
      }
    });
  } catch (e) {
    yield put({
      type: types.GET_LIST_COURSE_IN_HOME_FAIL
    });
  }
}

function* registerBuyCourseInHomeSaga(action) {
  const { typeForm, name, phone, email, courseId, content } = action.payload;
  try {
    yield put({ type: types.REGISTER_BUY_COURSE_IN_HOME_FETCHING });
    const rawData = yield HomeAPI.submitRegisterCourseHome(
      typeForm,
      name,
      phone,
      email,
      courseId,
      content
    );

    if (rawData.data && rawData.data.code === 200) {
      const children = <ModalContact registerSuccess />;
      yield put(modalActions.showModal({ children }));
    }
    yield put({
      type: types.REGISTER_BUY_COURSE_IN_HOME_SUCCESS,
      payload: {
        status: false,
        resultBuyCourseInHome: rawData.data.data
      }
    });
  } catch (e) {
    yield put({
      type: types.REGISTER_BUY_COURSE_IN_HOME_FAIL
    });
  }
}

function* getListAllDataLandingPageSaga(action) {
  const { pageId } = action.payload;
  try {
    yield put({ type: types.GET_LIST_ALL_DATA_LANDING_PAGE_FETCHING });
    const rawData = yield HomeAPI.getListAllDataLandingPage(pageId);

    yield put({
      type: types.GET_LIST_ALL_DATA_LANDING_PAGE_SUCCESS,
      payload: {
        status: false,
        listAllDataLandingPage: rawData.data.data
      }
    });
  } catch (e) {
    yield put({
      type: types.GET_LIST_ALL_DATA_LANDING_PAGE_FAIL
    });
  }
}

export const getListSlideInSaga = takeLatest(types.GET_LIST_SLIDE_INIT, getListSlideSaga);
export const getListGalleryInSaga = takeLatest(types.GET_LIST_GALLERY_INIT, getListGallerySaga);
export const getListLecturersInSaga = takeLatest(
  types.GET_LIST_LECTURERS_INIT,
  getListLecturersSaga
);
export const getListCourseInHomeInSaga = takeLatest(
  types.GET_LIST_COURSE_IN_HOME_INIT,
  getListCourseInHomeSaga
);

export const registerBuyCourseInHomeInSaga = takeLatest(
  types.REGISTER_BUY_COURSE_IN_HOME_INIT,
  registerBuyCourseInHomeSaga
);

export const getListAllDataLandingPageInSaga = takeLatest(
  types.GET_LIST_ALL_DATA_LANDING_PAGE_INIT,
  getListAllDataLandingPageSaga
);

export default this;
