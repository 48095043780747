import React from 'react';
import injectSheet from 'react-jss';
import styles from './styles';

const FirstInAllComponent = ({ classes, ok }) => (
  <div className={classes.firstInAll}>
    This is just a first component! ...
    {ok && <span>loaded data</span>}
  </div>
);

export default injectSheet(styles)(FirstInAllComponent);
