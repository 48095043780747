/*
 * @Author: loc.do@bstarsolutions.com
 * @Date: 2019-05-27 15:20:11
 * @Last Modified by: Do Loc - doxuanloc2016@gmail.com
 * @Last Modified time: 2019-10-17 22:17:40
 */
import React from 'react';
import { put, select, takeLatest } from 'redux-saga/effects';
import { PracticeAPIs } from 'services';
import { modalActions } from 'containers/ModalManagement/duck';
import { ModalResult } from 'components';
import * as types from './types';

const lessonState = state => state.lessonBySkill.lesson;
const isPrimarySelector = state => state.courses.isPrimary;
const isComboSelector = state => state.courses.isCombo;
const lessonInCourseId = state => state.lessonInCourse.lessonInCourseId;

function* postSubmitPractice(action) {
  const isPrimary = yield select(isPrimarySelector);
  const isCombo = yield select(isComboSelector);
  const courseChildBasicId = yield select(lessonInCourseId);
  const {
    payload: { lessionId, score, totalQuestion, courseId, hasPractice, courseName }
  } = action;

  try {
    yield put({
      type: types.SUBMIT_PRACTICE_FETCHING,
      payload: {
        statusSubmit: false
      }
    });

    const rawData = yield yield PracticeAPIs.submitPractices(
      lessionId,
      score,
      totalQuestion,
      courseId
    );

    const onClose = yield put(modalActions.hideModal());

    if (hasPractice) {
      const children = (
        <ModalResult
          onClose={onClose}
          lessionId={lessionId}
          result={rawData}
          total={totalQuestion}
          score={score}
          isPrimary={isPrimary}
          courseId={Number(courseChildBasicId)}
          hasPractice={hasPractice}
          courseName={courseName}
          typeCourse={courseName}
          isCombo={isCombo}
        />
      );

      yield put(modalActions.showModal({ children }));
    } else {
      const children = (
        <ModalResult
          onClose={onClose}
          lessionId={lessionId}
          result={rawData}
          total={totalQuestion}
          score={score}
          isPrimary={isPrimary}
          courseId={courseId}
          hasPractice={hasPractice}
        />
      );

      yield put(modalActions.showModal({ children }));
    }

    yield put({
      type: types.SUBMIT_PRACTICE_SUCCESS,
      payload: {
        status: rawData.data.success,
        result: rawData.data.data,
        statusSubmit: true
      }
    });
  } catch (e) {
    yield put({ type: types.SUBMIT_PRACTICE_FAIL });
  }
}

function* changeActiveAnswer(action) {
  const lessonSelect = yield select(lessonState);
  const {
    payload: { id, type, lessonCurrentId }
  } = action;

  const newLessonSelect = lessonSelect.map(data => ({
    ...data,
    answer: data.answer.map(ans => ({
      ...ans,
      userAnwser: type === 2 && ans.id === id ? !ans.userAnwser : ans.userAnwser,
      userAnwser1: type === 1 && lessonCurrentId === data.id ? ans.id === id : ans.userAnwser1
    })),
    child_questions: data.child_questions.map(ansChild => ({
      ...ansChild,
      answer: ansChild.answer.map(ans => ({
        ...ans,
        userAnwser: ansChild.type === 2 && ans.id === id ? !ans.userAnwser : ans.userAnwser,
        userAnwser1:
          ansChild.type === 1 && lessonCurrentId === ansChild.id ? ans.id === id : ans.userAnwser1
      }))
    }))
  }));

  const checkRightAnswer = newLessonSelect.map(answerL => ({
    ...answerL,
    answer: answerL.answer
      .filter(a => a.is_correct)
      .map(b => {
        return b.id;
      }),
    choice: answerL.answer
      .filter(a => a.userAnwser)
      .map(b => {
        return b.id;
      }),
    choice1: answerL.answer
      .filter(a => a.userAnwser1)
      .map(b => {
        return b.id;
      }),
    child_questions: answerL.child_questions.map(ansChild => ({
      ...ansChild,
      answer: ansChild.answer
        .filter(a => a.is_correct)
        .map(b => {
          return b.id;
        }),
      choice: ansChild.answer
        .filter(a => a.userAnwser)
        .map(b => {
          return b.id;
        }),
      choice1: ansChild.answer
        .filter(a => a.userAnwser1)
        .map(b => {
          return b.id;
        })
    }))
  }));

  const onScore = arrayScore => {
    let storeScore = 0;

    const listScore = arrayScore.map(c => {
      const isRight = key =>
        key.answer.toString() === key.choice.toString() ||
        (key.answer.toString() === key.choice1.toString() && key.child_questions.length === 0);
      if (c.child_questions.length === 0) return isRight(c) ? 1 : 0;
      if (c.answer.length === 0 || c.category !== 4 || c.category !== 5 || c.category !== 6) {
        storeScore = c.child_questions
          .map(y => {
            return isRight(y) ? 1 : 0;
          })
          .reduce((s, p) => s + p, 0);
      }
      return storeScore;
    });
    return listScore;
  };

  const totalScore = onScore(checkRightAnswer).reduce((a, b) => a + b);

  yield put({
    type: types.REMAKE_DATA_LESSON,
    payload: {
      status: true,
      isChoice: true,
      lesson: newLessonSelect,
      totalScore
    }
  });
}

export const onChangeActiveAnswer = takeLatest(types.CHANGE_ACTIVE_INIT, changeActiveAnswer);
export const onSubmitPractice = takeLatest(types.SUBMIT_PRACTICE_INIT, postSubmitPractice);
export default this;
