/*
 * @Author: Do Loc - doxuanloc2016@gmail.com
 * @Date: 2019-06-22 19:24:46
 * @Last Modified by: loc.do@bstarsolutions.com
 * @Last Modified time: 2019-07-05 13:37:43
 */

import React from 'react';
import injectSheet from 'react-jss';
import { translator } from 'utils';
import { ModalContent } from 'components';

import styles from './styles';

const ModalCheckLogin = props => {
  const {
    strings,
    checkFailUserExist,
    checkEmailNotEnter,
    checkEmail,
    checkPasswordNotEnter,
    headerTitle,
    checkEmailResetLink,
    checkUserExits
  } = props;

  const lang = strings.modalCheckLogin;
  return (
    <ModalContent isButton={false} title={headerTitle || lang.title}>
      {checkFailUserExist && <span>{lang.checkFailUserExist}</span>}
      {checkEmailNotEnter && <span>{lang.checkEmailNotEnter}</span>}
      {checkEmail && <span>{lang.checkEmail}</span>}
      {checkPasswordNotEnter && <span>{lang.checkPasswordNotEnter}</span>}
      {checkEmailResetLink && <span>{checkEmailResetLink}</span>}
      {checkUserExits && <span>{lang.checkUserExits}</span>}
    </ModalContent>
  );
};

ModalCheckLogin.defaultProps = {
  checkFailUserExist: false,
  checkEmailNotEnter: false,
  checkEmail: false,
  checkPasswordNotEnter: false,
  checkUserExits: false
};

export default translator(injectSheet(styles)(ModalCheckLogin));
