import { theme } from 'appCore';
import { scaleStyle } from 'utils';

export default {
  breadcrumb: {
    color: theme.colors.black,
    width: `100%`,
    marginTop: 10,
    marginBottom: 10
  },
  rightArrow: {
    margin: '0 14px'
  },
  link: {
    ...scaleStyle({
      lineHeight: '80px',
      fontSize: 18
    }),
    textDecoration: 'none',
    color: theme.colors.black
  },
  active: {
    color: theme.colors.primary
  },
  [`@media screen and (max-width: ${theme.screens.maxWidth900}px)`]: {
    link: {
      lineHeight: `14px`
    }
  }
};
