/*
 * @Author: loc.do@bstarsolutions.com
 * @Date: 2019-05-10 11:04:10
 * @Last Modified by: Do Loc - doxuanloc2016@gmail.com
 * @Last Modified time: 2019-10-17 22:16:06
 */

import { createStructuredSelector } from 'reselect';

const lesson = state => state.lessonBySkill.lesson;
const breadcrumb = state => state.app.breadcrumb;
const currentLang = state => state.app.currentLang;
const leftMenu = state => state.app.leftMenu;
const isPrimary = state => state.courses.isPrimary;
const isCombo = state => state.courses.isCombo;
const courseChild = state => state.courseChild.courseChild;
const courseDetail = state => state.courseChild.courseDetail;
const subtitles = state => state.lessonBySkill.subtitle;
const isChoice = state => state.lessonBySkill.isChoice;
const score = state => state.lessonBySkill.totalScore;
const totalQuestion = state =>
  state.lessonBySkill.metaLesson && state.lessonBySkill.metaLesson.total_question;
const result = state => state.practice;
const isFetching = state => state.lessonBySkill.isFetching;
const kanji = state => state.lessonBySkill.kanji;
const listLessonInCourse = state => state.lessonInCourse;

export default createStructuredSelector({
  breadcrumb,
  currentLang,
  lesson,
  leftMenu,
  isPrimary,
  courseChild,
  courseDetail,
  subtitles,
  isChoice,
  score,
  totalQuestion,
  result,
  isFetching,
  kanji,
  isCombo,
  listLessonInCourse
});
