/*
 * @Author: loc.do@bstarsolutions.com
 * @Date: 2019-08-08 16:54:49
 * @Last Modified by: loc.do@bstarsolutions.com
 * @Last Modified time: 2019-08-08 17:27:27
 */
import { theme } from 'appCore';

export default {
  container: {
    display: 'flex',
    flexDirection: 'column',
    width: '100%'
  },
  wrapMessage: {
    width: `100%`,
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center'
  },
  messsage: {
    objectFit: 'contain',
    fontSize: 20,
    fontWeight: 'bold',
    lineHeight: `27px`,
    textAlign: 'center',
    color: theme.colors.primary,
    marginTop: 20,
    paddingBottom: 20,
    borderBottom: `2px solid ${theme.colors.primary}`
  },
  subMessage: {
    maxWidth: 335,
    fontSize: 14,
    lineHeight: `19px`,
    textAlign: 'center',
    marginTop: 24,
    marginBottom: 30
  },
  imgDesktop: {
    width: 250,
    height: 190,
    objectFit: 'contain',
    marginBottom: 20
  },
  wrapImgDesktop: {
    display: 'flex',
    flexDirection: 'column',
    width: `100%`,
    justifyContent: 'center',
    alignItems: 'center',
    marginBottom: 20
  },
  useDesktop: {
    maxWidth: 280,
    fontSize: 14,
    lineHeight: `19px`,
    textAlign: 'center'
  },
  googlePlay: {
    width: 150,
    marginRight: 10
  },
  appleStore: {
    width: 150,
    marginLeft: 10
  },
  buttonStore: {
    marginBottom: 20,
    display: 'flex',
    width: '100%',
    justifyContent: 'center',
    alignItems: 'center'
  },
  wrapSubMessage: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    width: `100%`
  }
};
