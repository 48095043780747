/*
 * @Author: thach.nguyen@bstarsolutions.com
 * @Date: 2019-06-06 15:00:21
 * @Last Modified by: thach.nguyen@bstarsolutions.com
 * @Last Modified time: 2019-06-06 15:00:21
 */
export const EXAM_INIT = 'EXAM_INIT';
export const EXAM_FETCHING = 'EXAM_FETCHING';
export const EXAM_SUCCESS = 'EXAM_SUCCESS';
export const EXAM_FAIL = 'EXAM_FAIL';
export const EXAM_PACKAGE_COMBO = 'EXAM_PACKAGE_COMBO';
export const EXAM_PACKAGE_PRIMARY = 'EXAM_PACKAGE_PRIMARY';

export const LEVEL_INIT = 'LEVEL_INIT';
export const LEVEL_FETCHING = 'LEVEL_FETCHING';
export const LEVEL_SUCCESS = 'LEVEL_SUCCESS';
export const LEVEL_FAIL = 'LEVEL_FAIL';

export const SELECT_VALUE_EXAM = 'SELECT_VALUE_EXAM';
export const SELECT_VALUE_EXAM_FAIL = 'SELECT_VALUE_EXAM_FAIL';
