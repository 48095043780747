/*
 * @Author: loc.do@bstarsolutions.com
 * @Date: 2019-08-02 16:05:31
 * @Last Modified by: loc.do@bstarsolutions.com
 * @Last Modified time: 2019-08-20 15:41:52
 */

export const GET_LIST_SLIDE_INIT = 'GET_LIST_SLIDE_INIT';
export const GET_LIST_SLIDE_FETCHING = 'GET_LIST_SLIDE_FETCHING';
export const GET_LIST_SLIDE_SUCCESS = 'GET_LIST_SLIDE_SUCCESS';
export const GET_LIST_SLIDE_FAIL = 'GET_LIST_SLIDE_FAIL';

export const GET_LIST_GALLERY_INIT = 'GET_LIST_GALLERY_INIT';
export const GET_LIST_GALLERY_FETCHING = 'GET_LIST_GALLERY_FETCHING';
export const GET_LIST_GALLERY_SUCCESS = 'GET_LIST_GALLERY_SUCCESS';
export const GET_LIST_GALLERY_FAIL = 'GET_LIST_GALLERY_FAIL';

export const GET_LIST_LECTURERS_INIT = 'GET_LIST_LECTURERS_INIT';
export const GET_LIST_LECTURERS_FETCHING = 'GET_LIST_LECTURERS_FETCHING';
export const GET_LIST_LECTURERS_SUCCESS = 'GET_LIST_LECTURERS_SUCCESS';
export const GET_LIST_LECTURERS_FAIL = 'GET_LIST_LECTURERS_FAIL';

export const REGISTER_BUY_COURSE_IN_HOME_INIT = 'REGISTER_BUY_COURSE_IN_HOME_INIT';
export const REGISTER_BUY_COURSE_IN_HOME_FETCHING = 'REGISTER_BUY_COURSE_IN_HOME_FETCHING';
export const REGISTER_BUY_COURSE_IN_HOME_SUCCESS = 'REGISTER_BUY_COURSE_IN_HOME_SUCCESS';
export const REGISTER_BUY_COURSE_IN_HOME_FAIL = 'REGISTER_BUY_COURSE_IN_HOME_FAIL';

export const GET_LIST_COURSE_IN_HOME_INIT = 'GET_LIST_COURSE_IN_HOME_INIT';
export const GET_LIST_COURSE_IN_HOME_FETCHING = 'GET_LIST_COURSE_IN_HOME_FETCHING';
export const GET_LIST_COURSE_IN_HOME_SUCCESS = 'GET_LIST_COURSE_IN_HOME_SUCCESS';
export const GET_LIST_COURSE_IN_HOME_FAIL = 'GET_LIST_COURSE_IN_HOME_FAIL';

export const GET_LIST_ALL_DATA_LANDING_PAGE_INIT = 'GET_LIST_ALL_DATA_LANDING_PAGE_INIT';
export const GET_LIST_ALL_DATA_LANDING_PAGE_FETCHING = 'GET_LIST_ALL_DATA_LANDING_PAGE_FETCHING';
export const GET_LIST_ALL_DATA_LANDING_PAGE_SUCCESS = 'GET_LIST_ALL_DATA_LANDING_PAGE_SUCCESS';
export const GET_LIST_ALL_DATA_LANDING_PAGE_FAIL = 'GET_LIST_ALL_DATA_LANDING_PAGE_FAIL';
