/*
 * @Author: loc.do@bstarsolutions.com
 * @Date: 2019-05-14 14:35:14
 * @Last Modified by: loc.do@bstarsolutions.com
 * @Last Modified time: 2019-05-15 11:25:57
 */

import * as types from './types';

export const fetchCourseChild = id => ({
  type: types.COURSE_CHILD_INIT,
  id
});

export const fetchCourseDetail = id => ({
  type: types.COURSE_DETAIL_INIT,
  id
});

export default this;
