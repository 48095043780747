/*
 * @Author: loc.do@bstarsolutions.com
 * @Date: 2019-08-09 11:31:48
 * @Last Modified by: loc.do@bstarsolutions.com
 * @Last Modified time: 2019-08-09 11:51:17
 */

import { theme } from 'appCore';

export default {
  message: {
    maxWidth: 500
  },
  [`@media screen and (max-width: ${theme.screens.maxWidth800}px)`]: {
    message: {
      maxWidth: 240
    }
  },
  url: {
    backgroundColor: theme.colors.secondary,
    marginBottom: 10,
    marginTop: 10,
    color: theme.colors.black,
    padding: 10,
    borderRadius: 40,
    fontSize: 22
  }
};
