/* eslint-disable */
import { isEmpty } from 'lodash';
export default (data, currentLanguage, key) => {
  let value = '' 
  if (!isEmpty(data) &&  data.translations && data.translations[currentLanguage] && data.translations[currentLanguage][key]) {
     value = data.translations[currentLanguage][key]
  } else {
     value = data.translations && data.translations.vi && data.translations.vi[key] || ''
  }
  return value
  
};
