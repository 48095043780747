export const LOGIN_SUBMIT = 'LOGIN_SUBMIT';
export const LOGIN_FETCHING = 'LOGIN_FETCHING';
export const LOGIN_SUCCESS = 'LOGIN_SUCCESS';
export const LOGIN_FAILURE = 'LOGIN_FAILURE';

export const LOGIN_ERROR_EMPTY = 'LOGIN_ERROR_EMPTY';

export const AUTO_LOGIN_SUBMIT = 'AUTO_LOGIN_SUBMIT';
export const AUTO_LOGIN_FETCHING = 'AUTO_LOGIN_FETCHING';
export const AUTO_LOGIN_SUCCESS = 'AUTO_LOGIN_SUCCESS';
export const AUTO_LOGIN_FAILURE = 'AUTO_LOGIN_FAILURE';

export const LOGOUT = 'LOGOUT';

export const LOGIN_SOCIAL_SUBMIT = 'LOGIN_SOCIAL_SUBMIT';
export const LOGIN_SOCIAL_FETCHING = 'LOGIN_SOCIAL_FETCHING';
export const LOGIN_SOCIAL_SUCCESS = 'LOGIN_SOCIAL_SUCCESS';
export const LOGIN_SOCIAL_FAILURE = 'LOGIN_SOCIAL_FAILURE';
