export const GET_INFOMATION_USER_INIT = 'GET_INFOMATION_USER_INIT';
export const GET_INFOMATION_USER_FETCHING = 'GET_INFOMATION_USER_FETCHING';
export const GET_INFOMATION_USER_SUCCESS = 'GET_INFOMATION_USER_SUCCESS';
export const GET_INFOMATION_USER_FAIL = 'GET_INFOMATION_USER_FAIL';

export const UPDATE_INFOMATION_USER_INIT = 'UPDATE_INFOMATION_USER_INIT';
export const UPDATE_INFOMATION_USER_FETCHING = 'UPDATE_INFOMATION_USER_FETCHING';
export const UPDATE_INFOMATION_USER_SUCCESS = 'UPDATE_INFOMATION_USER_SUCCESS';
export const UPDATE_INFOMATION_USER_FAIL = 'UPDATE_INFOMATION_USER_FAIL';

export const GET_AVATAR_DEFAULT_INIT = 'GET_AVATAR_DEFAULT_INIT';
export const GET_AVATAR_DEFAULT_FETCHING = 'GET_AVATAR_DEFAULT_FETCHING';
export const GET_AVATAR_DEFAULT_SUCCESS = 'GET_AVATAR_DEFAULT_SUCCESS';
export const GET_AVATAR_DEFAULT_FAIL = 'GET_AVATAR_DEFAULT_FAIL';

export const GET_MY_EXAM_INIT = 'GET_MY_EXAM_INIT';
export const GET_MY_EXAM_FETCHING = 'GET_MY_EXAM_FETCHING';
export const GET_MY_EXAM_SUCCESS = 'GET_MY_EXAM_SUCCESS';
export const GET_MY_EXAM_FAIL = 'GET_MY_EXAM_FAIL';

export const GET_MY_EXAM_DETAIL_INIT = 'GET_MY_EXAM_DETAIL_INIT';
export const GET_MY_EXAM_DETAIL_FETCHING = 'GET_MY_EXAM_DETAIL_FETCHING';
export const GET_MY_EXAM_DETAIL_SUCCESS = 'GET_MY_EXAM_DETAIL_SUCCESS';
export const GET_MY_EXAM_DETAIL_FAIL = 'GET_MY_EXAM_DETAIL_FAIL';

export const SHOW_DETAIL_MY_EXAM = 'SHOW_DETAIL_MY_EXAM';
export const HIDE_DETAIL_MY_EXAM = 'HIDE_DETAIL_MY_EXAM';

export const SHOW_DETAIL_MY_EXAM_SECTION = 'SHOW_DETAIL_MY_EXAM_SECTION';
export const HIDE_DETAIL_MY_EXAM_SECTION = 'HIDE_DETAIL_MY_EXAM_SECTION';

export const GET_MY_COURSE_INIT = 'GET_MY_COURSE_INIT';
export const GET_MY_COURSE_FETCHING = 'GET_MY_COURSE_FETCHING';
export const GET_MY_COURSE_SUCCESS = 'GET_MY_COURSE_SUCCESS';
export const GET_MY_COURSE_FAIL = 'GET_MY_COURSE_FAIL';

export const SHOW_DETAIL_MY_COURSE_DETAIL = 'SHOW_DETAIL_MY_COURSE_DETAIL';
export const HIDE_DETAIL_MY_COURSE_DETAIL = 'HIDE_DETAIL_MY_COURSE_DETAIL';

export const GET_MY_COURSE_DETAIL_INIT = 'GET_MY_COURSE_DETAIL_INIT';
export const GET_MY_COURSE_DETAIL_FETCHING = 'GET_MY_COURSE_DETAIL_FETCHING';
export const GET_MY_COURSE_DETAIL_SUCCESS = 'GET_MY_COURSE_DETAIL_SUCCESS';
export const GET_MY_COURSE_DETAIL_FAIL = 'GET_MY_COURSE_DETAIL_FAIL';

export const GET_MY_COIN_INIT = 'GET_MY_COIN_INIT';
export const GET_MY_COIN_FETCHING = 'GET_MY_COIN_FETCHING';
export const GET_MY_COIN_SUCCESS = 'GET_MY_COIN_SUCCESS';
export const GET_MY_COIN_FAIL = 'GET_MY_COIN_FAIL';

export const UPDATE_NEW_PASSWORD_INIT = 'UPDATE_NEW_PASSWORD_INIT';
export const UPDATE_NEW_PASSWORD_FETCHING = 'UPDATE_NEW_PASSWORD_FETCHING';
export const UPDATE_NEW_PASSWORD_SUCCESS = 'UPDATE_NEW_PASSWORD_SUCCESS';
export const UPDATE_NEW_PASSWORD_FAIL = 'UPDATE_NEW_PASSWORD_FAIL';

export const SAVE_MY_COURSE_ID = 'SAVE_MY_COURSE_ID';

export const SAVE_AVATAR_DEFAULT = 'SAVE_AVATAR_DEFAULT';
export const REMOVE_AVATAR_DEFAULT = 'REMOVE_AVATAR_DEFAULT';

export const SAVE_POSITION_AVATAR_DEFAULT = 'SAVE_POSITION_AVATAR_DEFAULT';
export const REMOVE_POSITION_AVATAR_DEFAULT = 'REMOVE_POSITION_AVATAR_DEFAULT';

export const GET_MEMTHOD_CREDIT_INIT = 'GET_MEMTHOD_CREDIT_INIT';
export const GET_MY_METHOD_CREDIT_FETCHING = 'GET_MY_METHOD_CREDIT_FETCHING';
export const GET_MY_METHOD_CREDIT_SUCCESS = 'GET_MY_METHOD_CREDIT_SUCCESS';
export const GET_MY_METHOD_CREDIT_FAIL = 'GET_MY_METHOD_CREDIT_FAIL';
