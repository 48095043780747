/*
 * @Author: loc.do@bstarsolutions.com
 * @Date: 2019-05-14 14:33:58
 * @Last Modified by: loc.do@bstarsolutions.com
 * @Last Modified time: 2019-05-15 11:28:23
 */

export const COURSE_CHILD_INIT = 'COURSE_CHILD_INIT';
export const COURSE_CHILD_FETCHING = 'COURSE_CHILD_FETCHING';
export const COURSE_CHILD_SUCCESS = 'COURSE_CHILD_SUCCESS';
export const COURSE_CHILD_FAIL = 'COURSE_CHILD_FAIL';

export const COURSE_DETAIL_INIT = 'COURSE_DETAIL_INIT';
export const COURSE_DETAIL_FETCHING = 'COURSE_DETAIL_FETCHING';
export const COURSE_DETAIL_SUCCESS = 'COURSE_DETAIL_SUCCESS';
export const COURSE_DETAIL_FAIL = 'COURSE_DETAIL_FAIL';
