import React from 'react';
import { put, takeLatest, select, takeMaybe } from 'redux-saga/effects';
import { ExerciseAPI } from 'services';
import { modalActions } from 'containers/ModalManagement/duck';
import { ModalResult } from 'components';
import * as types from './types';

const userToken = state => (state.login.user ? state.login.user.access_token : '');
const practiceExercise = state => state.exercise.practiceExercise;
const exerciseSelect = state => state.exercise;
const isPrimarySelector = state => state.courses.isPrimary;
const exercisePracticeSelect = state => state.exercise.exercise;
const isBtnTotalSelect = state => state.exercise.isTotal;
const isPrimaryExamSelect = state => state.exams.isPrimary;
const currentLangSelector = state => state.app.currentLang;
function* showPagePracticeExercise() {
  yield put({
    type: types.SHOW_PAGE_PRACTICE_EXERCISE
  });
}

function* hidePagePracticeExercise() {
  yield put({
    type: types.HIDE_PAGE_PRACTICE_EXERCISE
  });
}

function* saveIdExerciseInSaga(action) {
  const { idExercise } = action.payload;
  yield put({
    type: types.SAVE_ID_EXERCISE,
    payload: {
      idExercise
    }
  });
}

function* getExerciseAPI(action) {
  const token = yield select(userToken);

  try {
    yield put({ type: types.EXERCISE_FETCHING });
    let rawData;
    if (token) {
      rawData = yield ExerciseAPI.getExercise(action.examId);
    } else {
      rawData = yield ExerciseAPI.getExerciseNotAuthen(action.examId);
    }
    const dataExercise = {
      ...rawData.data.data,
      test_section: rawData.data.data.test_section.map(section => ({
        ...section,
        tookTheExam: false
      }))
    };

    yield put({
      type: types.EXERCISE_SUCCESS,
      payload: {
        status: rawData.data.success,
        exercise: dataExercise
      }
    });
  } catch (e) {
    yield put({ type: types.EXERCISE_FAIL });
  }
}

function* submitNewTestPracticeInSaga(action) {
  const { exerciseId } = action.payload;
  try {
    yield put({ type: types.SUBMIT_NEW_TEST_PRACTICE_FETCHING });
    const rawData = yield ExerciseAPI.submitNewTestPracticeAPI(exerciseId);

    yield put({
      type: types.SUBMIT_NEW_TEST_PRACTICE_SUCCESS,
      payload: {
        status: rawData.data.success,
        resultDataSubmitNewTest: rawData.data.data
      }
    });
  } catch (e) {
    yield put({ type: types.SUBMIT_NEW_TEST_PRACTICE_FAIL });
  }
}

function* postSubmitPracticeExercise(action) {
  const isPrimary = yield select(isPrimarySelector);
  const exercise = yield select(exerciseSelect);
  const exercisePractice = yield select(exercisePracticeSelect);
  const currentLang = yield select(currentLangSelector);
  const isPrimaryExam = yield select(isPrimaryExamSelect);
  const checkClickBtnTotal = yield select(isBtnTotalSelect);
  const {
    payload: { sectionId, dataSubmit, hasPractice }
  } = action;
  try {
    yield put({
      type: types.SUBMIT_PRACTICE_EXERCISE_FETCHING,
      payload: {
        statusSubmit: false
      }
    });

    const rawData = yield yield ExerciseAPI.submitPracticeExercise(sectionId, dataSubmit);

    const onClose = yield put(modalActions.hideAll());

    const onCloseBtn = yield put(modalActions.hideBtnClose());
    const dataExercise = {
      ...exercisePractice,
      test_section: exercisePractice.test_section.map(section => ({
        ...section,
        tookTheExam:
          section.id === Number(dataSubmit[0].test_section_id)
            ? section.tookTheExam === false
              ? !section.tookTheExam
              : true
            : section.tookTheExam
      }))
    };

    const children = (
      <ModalResult
        hasPractice={hasPractice}
        isPrimaryExam={isPrimaryExam}
        currentLang={currentLang}
        isExerciseResult={(exercise.test_section = true)}
        onClose={onClose}
        onCloseBtn={onCloseBtn}
        lessionId={sectionId}
        result={rawData}
        total={
          (!rawData.data.data.is_completed && !checkClickBtnTotal) ||
          (rawData.data.data.is_completed && !checkClickBtnTotal)
            ? dataSubmit[0].total_score
            : rawData.data.data.total_score
        }
        score={
          (!rawData.data.data.is_completed && !checkClickBtnTotal) ||
          (rawData.data.data.is_completed && !checkClickBtnTotal)
            ? dataSubmit[0].score
            : rawData.data.data.score
        }
        isPrimary={isPrimary}
        sectionId={dataExercise.id}
      />
    );

    yield put(modalActions.showModal({ children }));

    yield put({
      type: types.SUBMIT_PRACTICE_EXERCISE_SUCCESS,
      payload: {
        status: rawData.data.success,
        resultExercise: rawData.data.data,
        statusSubmit: true,
        exercise: dataExercise
      }
    });
  } catch (e) {
    yield put({ type: types.SUBMIT_PRACTICE_EXERCISE_FAIL });
  }
}

function* getPracticeExerciseAPI(action) {
  try {
    yield put({ type: types.PRACTICE_EXERCISE_FETCHING });
    const rawData = yield ExerciseAPI.getPreacticeExercise(action.skillId);
    yield put({
      type: types.PRACTICE_EXERCISE_SUCCESS,
      payload: {
        statusExercise: rawData.data.success,
        practiceExercise: rawData.data.data,
        totalQuestion: rawData.data.meta.total_question,
        totalScoreApi: rawData.data.meta.total_score
      }
    });
  } catch (e) {
    yield put({ type: types.PRACTICE_EXERCISE_FAIL });
  }
}

function* changeActiveAnswerPractice(action) {
  const listPractice = yield select(practiceExercise);
  const {
    payload: { id, type, lessonCurrentId }
  } = action;

  const newLessonSelect = listPractice.map(data => ({
    ...data,
    answer: data.answer.map(ans => ({
      ...ans,
      userAnwser: type === 2 && ans.id === id ? !ans.userAnwser : ans.userAnwser,
      userAnwser1: type === 1 && lessonCurrentId === data.id ? ans.id === id : ans.userAnwser1
    })),
    child_questions: data.child_questions.map(ansChild => ({
      ...ansChild,
      answer: ansChild.answer.map(ans => ({
        ...ans,
        userAnwser: ansChild.type === 2 && ans.id === id ? !ans.userAnwser : ans.userAnwser,
        userAnwser1:
          ansChild.type === 1 && lessonCurrentId === ansChild.id ? ans.id === id : ans.userAnwser1
      }))
    }))
  }));

  const checkRightAnswer = newLessonSelect.map(answerL => ({
    ...answerL,
    answer: answerL.answer
      .filter(a => a.is_correct)
      .map(b => {
        return b.id;
      }),
    choice: answerL.answer
      .filter(a => a.userAnwser)
      .map(b => {
        return b.id;
      }),
    choice1: answerL.answer
      .filter(a => a.userAnwser1)
      .map(b => {
        return b.id;
      }),
    child_questions: answerL.child_questions.map(ansChild => ({
      ...ansChild,
      answer: ansChild.answer
        .filter(a => a.is_correct)
        .map(b => {
          return b.id;
        }),
      choice: ansChild.answer
        .filter(a => a.userAnwser)
        .map(b => {
          return b.id;
        }),
      choice1: ansChild.answer
        .filter(a => a.userAnwser1)
        .map(b => {
          return b.id;
        })
    }))
  }));

  const onScore = arrayScore => {
    let storeScore = 0;

    const listScore = arrayScore.map(c => {
      const isRight = key =>
        key.answer.toString() === key.choice.toString() ||
        (key.answer.toString() === key.choice1.toString() && key.child_questions.length === 0);
      if (c.child_questions.length === 0) return isRight(c) ? c.score : 0;
      if (c.answer.length === 0 || c.category !== 4 || c.category !== 5 || c.category !== 6) {
        storeScore = c.child_questions
          .map(y => {
            return isRight(y) ? y.score : 0;
          })
          .reduce((s, p) => s + p, 0);
      }
      return storeScore;
    });
    return listScore;
  };

  const dataUserChoice = [];

  const dataUserClick = [];

  const answerUserChoise = [];

  const idUserChoise = [];

  checkRightAnswer.map(c => {
    if (c.child_questions.length === 0)
      return (
        (answerUserChoise.push(c.id) && c.choice.length !== 0 && idUserChoise.push(c.choice)) ||
        (c.choice1.length !== 0 && idUserChoise.push(c.choice1))
      );

    if (c.answer.length === 0 || c.category !== 4 || c.category !== 5 || c.category !== 6) {
      c.child_questions.map(y => {
        return (
          (answerUserChoise.push(y.id) && y.choice.length !== 0 && idUserChoise.push(y.choice)) ||
          (y.choice1.length !== 0 && idUserChoise.push(y.choice1))
        );
      });
    }

    return checkRightAnswer;
  });

  checkRightAnswer.map(c => {
    const isRight = key =>
      key.answer.toString() === key.choice.toString() ||
      (key.answer.toString() === key.choice1.toString() && key.child_questions.length === 0);
    if (c.child_questions.length === 0)
      return (
        isRight(c) &&
        dataUserChoice.push(c.id) &&
        dataUserClick.push(
          JSON.stringify(c.choice) === JSON.stringify(c.answer) ||
            JSON.stringify(c.choice1) === JSON.stringify(c.answer)
        )
      );
    if (c.answer.length === 0 || c.category !== 4 || c.category !== 5 || c.category !== 6) {
      c.child_questions
        .map(y => {
          return (
            isRight(y) &&
            dataUserChoice.push(y.id) &&
            dataUserClick.push(
              JSON.stringify(y.choice) === JSON.stringify(y.answer) ||
                JSON.stringify(y.choice1) === JSON.stringify(y.answer)
            )
          );
        })
        .reduce((s, p) => s + p, 0);
    }
    return checkRightAnswer;
  });

  const questions = {};

  const answers = {};

  for (let i = 0; i < dataUserClick.length; i += 1) {
    const choice = dataUserChoice[i];
    questions[choice] = dataUserClick[i];
  }

  for (let i = 0; i < idUserChoise.length; i += 1) {
    const choice = answerUserChoise[i];
    answers[choice] = idUserChoise[i];
  }

  const totalScore = onScore(checkRightAnswer).reduce((a, b) => a + b);

  yield put({
    type: types.CHANGE_ACTIVE_ANSWER_EXERCISE_SUCCESS,
    payload: {
      statusExercise: true,
      practiceExercise: newLessonSelect,
      totalScore,
      questions,
      answers
    }
  });
}

function* isButtonTotalInSaga(action) {
  const { isTotal } = action.payload;

  yield put({
    type: types.IS_BUTTON_TOTAL,
    payload: {
      isTotal
    }
  });
}

export const getExerciseSaga = takeLatest(types.EXERCISE_INIT, getExerciseAPI);
export const getPracticeExerciseSaga = takeLatest(
  types.PRACTICE_EXERCISE_INIT,
  getPracticeExerciseAPI
);
export const changeActiveAnswerPracticesSaga = takeLatest(
  types.CHANGE_ACTIVE_EXERCISE_INIT,
  changeActiveAnswerPractice
);
export const postSubmitPracticeExerciseSaga = takeLatest(
  types.SUBMIT_PRACTIC_EXERCISE_INIT,
  postSubmitPracticeExercise
);
export const submitNewTestPracticeSaga = takeLatest(
  types.SUBMIT_NEW_TEST_PRACTICE_INIT,
  submitNewTestPracticeInSaga
);
export const showPagePracticeExerciseSaga = takeMaybe(showPagePracticeExercise);
export const hidePagePracticeExerciseSaga = takeMaybe(hidePagePracticeExercise);
export const saveIdExerciseSaga = takeMaybe(saveIdExerciseInSaga);
export const isButtonTotalInSagaReducer = takeMaybe(isButtonTotalInSaga);
export default this;
