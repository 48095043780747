import * as types from './types';

const INITIAL_STATE = {
  status: false,
  articles: {},
  category: {},
  slider: '',
  isFetching: false,
  acticleType: 1,
  pagination: {},
  details: null,
  resultApplyToJob: {},
  isShowApplyPageData: false,
  resultRegisterContact: []
};

export default (state = INITIAL_STATE, action) => {
  switch (action.type) {
    case types.ARTICLE_SUCCESS:
      return {
        ...state,
        status: action.payload.status,
        articles: action.payload.articles,
        category: action.payload.category,
        slider: action.payload.slider,
        isFetching: false,
        acticleType: action.payload.acticleType,
        pagination: action.payload.pagination
      };

    case types.ARTICLE_FETCHING:
      return {
        ...state,
        isFetching: true
      };

    case types.ARTICLE_FAILURE:
      return {
        ...state,
        articles: [],
        isFetching: false
      };

    case types.ARTICLE_DETAILS_SUCCESS:
      return {
        ...state,
        status: action.payload.status,
        details: action.payload.details,
        isShowApplyPageData: false,
        isFetching: false
      };

    case types.ARTICLE_DETAILS_FETCHING:
      return {
        ...state,
        isFetching: true
      };

    case types.ARTICLE_DETAILS_FAILURE:
      return {
        ...state,
        isFetching: false
      };
    case types.APPLY_JOB_FETCHING: {
      return {
        ...state,
        isFetching: true
      };
    }
    case types.APPLY_JOB_SUCCESS: {
      return {
        ...state,
        isFetching: true,
        resultApplyToJob: action.payload.resultApplyToJob
      };
    }
    case types.APPLY_JOB_FAIL: {
      return {
        ...state,
        isFetching: true,
        resultApplyToJob: {}
      };
    }
    case types.IS_SHOW_APPLY_PAGE: {
      return {
        ...state,
        isShowApplyPageData: true
      };
    }
    case types.IS_HIDE_APPLY_PAGE: {
      return {
        ...state,
        isShowApplyPageData: false
      };
    }
    case types.POST_SEND_FORM_CONTACT_FETCHING:
      return {
        ...state,
        isFetching: true
      };

    case types.POST_SEND_FORM_CONTACT_FAIL:
      return {
        ...state,
        isFetching: false
      };
    case types.POST_SEND_FORM_CONTACT_SUCCESS: {
      return {
        ...state,
        isFetching: false,
        resultRegisterContact: action.payload.resultRegisterContact
      };
    }
    default:
      return state;
  }
};
