/*
 * @Author: loc.do@bstarsolutions.com
 * @Date: 2019-06-26 15:30:34
 * @Last Modified by: loc.do@bstarsolutions.com
 * @Last Modified time: 2019-08-08 16:17:56
 */

import React, { useEffect } from 'react';
import injectSheet from 'react-jss';
import { translator, constants } from 'utils';
// import c from 'classnames';
import { Button, Block } from 'components';
import styles from './styles';

const YourBlock = props => {
  const {
    classes,
    titleExam,
    score,
    result,
    date,
    onClickDetail,
    onClickMove,
    strings,
    isShowDetailMyExam,
    isShowDetailMyExamSection,
    index,
    totalScore,
    questionLists
  } = props;

  const lang = strings.yourBlock;

  function createMarkup(dataHtml) {
    return { __html: dataHtml };
  }

  useEffect(() => {
    window.scrollTo({ top: 0, left: 0, behavior: 'smooth' });
  }, []);

  return (
    <Block className={classes.container}>
      <span className={classes.titleExam}>
        {titleExam.toUpperCase()}
        {Number.isInteger(index) && `${lang.time} ${index + 1}`}{' '}
      </span>
      <div className={classes.containerInfoExam}>
        <div className={classes.rightInforExam}>
          <div className={classes.boxExam}>
            <span className={classes.name}>{lang.scorePractice}</span>
            <span className={classes.result}>
              : {Number.isInteger(score) ? score : score.toFixed(2)} / {totalScore}
            </span>
          </div>
          <div className={classes.boxExam}>
            <span className={classes.name}>{lang.learningResult}</span>
            <span className={classes.result}>
              : {result === 'NONE' && lang.nope}
              {result === 'FAILED' && lang.fail}
              {result === 'PASSED' && lang.pass}
            </span>
          </div>
          {isShowDetailMyExamSection !== true && (
            <div className={classes.boxExam}>
              <span className={classes.name}>{lang.date}</span>
              <span className={classes.result}>: {date === null ? lang.nope : date}</span>
            </div>
          )}
        </div>
        <div className={classes.boxBtn}>
          {isShowDetailMyExamSection !== false && (
            <>
              {isShowDetailMyExam === false && (
                <>
                  <Button
                    onClick={onClickDetail}
                    className={classes.btn}
                    label={lang.btnDetail}
                    typeButton="fourth"
                  />
                  <Button onClick={onClickMove} label={lang.btnExam} />
                </>
              )}
              {isShowDetailMyExam === true && (
                <>
                  <Button onClick={onClickDetail} className={classes.btn} label={lang.btnDetail} />
                </>
              )}
            </>
          )}
        </div>
      </div>
      <div className={classes.wrapListAnswer}>
        {questionLists && questionLists.length !== 0 && (
          <span className={classes.nameMyExam}>{lang.resultListQuestion}</span>
        )}
        {questionLists &&
          questionLists.map((questionList, i) => {
            return (
              <div key={questionList.id} className={classes.wrapQuestionsMyExam}>
                <div className={classes.wrapTitleTopMyExam}>
                  <span className={classes.orderListQuestion}>{i + 1}</span>
                  <span
                    className={classes.nameAnswerMyExam}
                    dangerouslySetInnerHTML={createMarkup(questionList.name)}
                  ></span>
                </div>

                <div>
                  {' '}
                  {questionList.answers.map((answer, j) => {
                    return (
                      <div key={answer.id}>
                        {`${constants.ALPHA.charAt(j)}: `}
                        <span
                          className={
                            Number(questionList.chosen.filter(e => e === answer.id)) &&
                            answer.is_correct === true
                              ? classes.answerRight
                              : Number(questionList.chosen.filter(e => e === answer.id)) &&
                                answer.is_correct === false
                              ? classes.answerWrong
                              : classes.answerNotWrongAndRight
                          }
                        >
                          {answer.answer}
                        </span>
                      </div>
                    );
                  })}
                </div>
                <span>
                  {`${lang.resultHistoryMyExam}: `}
                  {questionList.status ? lang.resultHistoryRight : lang.resultHistoryWrong}
                </span>
              </div>
            );
          })}
      </div>
    </Block>
  );
};

YourBlock.defaultProps = {
  titleExam: 'Title',
  score: 0,
  result: 'NONE',
  date: '18-06-2019',
  totalScore: '100'
};

export default translator(injectSheet(styles)(YourBlock));
