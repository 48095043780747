export default {
  general: {
    alert: 'Cảnh báo',
    error: 'Lỗi',
    ok: 'Ok',
    noToken: 'Bạn chưa đăng nhập',
    networkError: 'Không có internet',
    relogin: 'Đăng nhập lại',
    login: 'Đăng nhập',
    loginStudent: 'Đăng nhập học viên',
    signup: 'Đăng ký',
    signupStudent: 'Đăng ký học viên',
    activeStudent: 'Kích hoạt học viên',
    forgotPassword: 'Quên mật khẩu',
    confirm: 'Xác nhận',
    emailNotExist: 'Không tìm thấy địa chỉ email',
    emailResetLink: 'Chúng tôi đã gửi liên kết đặt lại mật khẩu đến email bạn!'
  },
  header: {
    language: 'Ngôn ngữ',
    btnNameSignUp: 'Đăng nhập / Đăng ký',
    btnNameEnterprise: 'Dành cho doanh nghiệp',
    btnNameLanguage: 'Ngôn ngữ',
    logout: 'Đăng xuất',
    profile: 'Trang cá nhân',
    adminClass: 'Quản lí lớp học',
    message: 'Thông báo',
    inforModal1: ' Phần này dành cho bạn đã mua lớp học doanh nghiệp',
    inforModal2: `Nếu bạn chưa có thì qua trang cửa hàng nhé`,
    btnMoveShop: `Lớp học ở Cửa hàng`
  },
  footer: {
    copyright: `CTY TNHH MTV VIỆT LA BO | GPĐKKD số 0309716416 do Sở KHĐT TP.HCM cấp ngày 08/01/2010. <br>
      © 2017 - Bản quyền Vietlabo. Trụ sở chính: 231/1 Ba Đình, Phường 8, Quận 8, TP. Hồ Chí Minh.`,
    downloadNow: 'Tải ngay ứng dụng',
    appStore: 'App Store',
    googlePlay: 'Google Play',
    hotline: 'Hotline',
    address: 'Địa chỉ',
    email: 'Email',
    home: 'Trang chủ',
    contact: 'Liên hệ',
    faq: 'Câu hỏi',
    paymentGuide: 'Hướng dẫn thanh toán',
    userManual: 'Hướng dẫn sử dụng',
    about: 'Về chúng tôi',
    enterprise: 'Doanh nghiệp',
    job: 'Việc làm',
    privacyPolicy: 'Chính sách bảo mật',
    studentPolicy: 'Chính sách học viên'
  },
  slidingBanner: {
    title: 'Giải pháp học tiếng nhật cho người bận rộn',
    subTitle: 'Học tiếng nhật trực tuyến - mọi lúc - mọi nơi - mọi chỗ trên mọi thiết bị ',
    appStore: 'App Store',
    googlePlay: 'Google Play',
    nowStudy: 'Học ngay thôi',
    titleBottom: `Tải ngay ứng dụng trên GOOGLE PLAY hoặc APP STORE để học ngay trên điện thoại,
     hoặc học trực tiếp trên máy tính của bạn`
  },
  login: {
    title: 'Đăng nhập',
    active: 'Đăng kí miễn phí',
    activeAccount: 'Đăng ký học viên',
    loginNowButton: 'Đăng nhập ngay',
    forgetPassword: 'Bạn quên mật khẩu',
    wantToRegister: 'Bạn chưa có tài khoản Rainichi. Hãy ',
    wantToLogin: 'Bạn đã có tài khoản Rainichi. Hãy ',
    signUpNow: 'Đăng ký ngay!',
    loginNow: 'Đăng nhập ngay!',
    loginNowWIth: 'Đăng nhập ngay với',
    yourEmail: 'Email của bạn',
    password: 'Mật khẩu',
    activeNow: 'Kích hoạt ngay',
    name: 'Họ & Tên',
    phone: 'Số điện thoại',
    repassword: 'Mật khẩu nhập lại',
    activationToken: 'Mã kích hoạt',
    sendViaMail: 'Gửi thông báo qua mail',
    passcodeInMail: 'Mã kích hoạt đã được gửi vào email của bạn',
    password_wrong_format: 'Mật khẩu phải chứa ít nhất một số và phải có ít nhất 8 ký tự.',
    name_wrong_format: 'Chưa nhập tên người dùng',
    phone_wrong_format: 'Chưa nhập phone người dùng',
    invalidCode: 'Mã kích hoạt sai.'
  },
  coursePage: {
    day: 'ngày',
    course: 'Khóa học'
  },
  introCourse: {
    btnTrial: 'Học Thử',
    btnBuy: 'Mua ngay',
    btnStudy: 'Học ngay'
  },
  vocabularySlide: {
    theSentence: 'Câu',
    chineseVietnameseWord: 'Hán Việt',
    meaning: 'Nghĩa',
    example: 'Ví dụ'
  },
  vocabularyDetail: {
    origin: 'Nguyên gốc',
    resultRecord: 'Kết quả giọng đọc',
    resultFail: 'Không đạt',
    resultPass: 'Đạt',
    speak: 'Nói lại nhé!'
  },
  practice: {
    btnSubmit: 'Nộp bài',
    btnTranslate: 'Dịch',
    btnTranslateOrigin: 'Hiển thị văn bản gốc',
    btnHintShow: 'Cách đọc',
    btnHintHide: 'Gốc',
    checkPreventPage: `Kết quả làm bài sẽ không được lưu lại. Bạn có chắc chắn muốn dừng bài thi? `,
    notData: 'Không có dữ liệu',
    btnGoBack: 'Trở về',
    message: 'Thông báo',
    disconnect: 'Internet đã bị ngắt kết nối',
    submitExam: 'Bạn muốn nộp bài thi?',
    no: 'Không nộp bài',
    goToPageListSectionExam: 'Về danh sách bài thi',
    descriptionExamNow: ` Bạn đã sẵn sàng thi chưa.
    Hãy nhấn nút Bắt đầu phần thi để vào thi.`,
    btnClose: 'Đóng',
    titleExam: 'Phần thi',
    btnStartContest: 'Bắt đầu phần thi',
    timeContest: 'Thời gian thi sẽ là:',
    time: 'phút',
    notPractice: `Phần Hội Thoại không có Luyện tập.
    Bạn vui lòng bấm Mở bài học tiếp theo để qua bài học tiếp theo.`,
    notPracticeCommon: `Phần này không có Luyện tập.
    Bạn vui lòng bấm Mở bài học tiếp theo để qua bài học tiếp theo.`,
    goodLuckPractice: `Chúc bạn học vui vẻ!`,
    btnNextLesson: 'Mở bài học tiếp theo',
    notTime: 'Không có tính giờ'
  },
  submitPractice: {
    titleResult: 'Kết quả',
    contentResult: 'Số điểm của bạn',
    passResult: 'Đậu',
    failResult: 'Rớt',
    failResultExam: 'OK',
    failScore: 'Điểm liệt',
    score: 'Điểm',
    questions: 'Tổng câu hỏi',
    resultContest: 'Kết quả',
    resultDetail: 'Kết quả chi tiết',
    seeResults: 'Xem chi tiết',
    summary: 'Tổng kết',
    passScore: 'Điểm chuẩn',
    nextPractice: `Bài học tiếp theo đã được mở. 
    Chúc bạn học vui vẻ!`,
    titleNotPractice: 'Thông báo',
    victory: 'Chúc mừng bạn đã đạt được'
  },
  listen: {
    empty: 'Không có dữ liệu',
    showTip: 'Mở gợi ý',
    hideTip: 'Ẩn gợi ý',
    btnPrev: 'Lùi',
    btnNext: 'Tiếp',
    sentence: 'Câu'
  },
  examPage: {
    day: 'ngày',
    exam: 'Đề thi'
  },
  exam: {
    exam: `Đề thi`,
    description: `Excepteur sint occaecat cupidatat non proident, sunt in culpa qui officia deserunt
     mollit anim id est laborum. Đề thi`,
    title: 'THÔNG BÁO',
    descriptionLevel: 'Bạn vui lòng chọn trình độ phù hợp',
    submitOK: 'Chọn',
    chooseLevel: 'Chọn trình độ'
  },
  examChild: {
    examNotFound: 'Không tìm thấy dữ liệu đề thi',
    descriptionExam: 'Vui lòng chọn hình thức thi bên dưới. Xin cảm ơn !',
    hasCountTime: 'Có tính giờ',
    noneCountTime: 'Không có tính giờ',
    free: 'Miễn phí',
    title: 'THÔNG BÁO',
    titleBuyNow: 'Mua ngay',
    titleGetExercise: 'Thi ngay',
    titleGetExerciseFree: 'Bắt đầu thi',
    youTookTheExam: 'Bạn đã thi phần',
    checkPreventPage: `Kết quả làm bài sẽ không được lưu lại. Bạn có chắc chắn muốn dừng bài thi? `,
    btnAccept: 'Nộp bài',
    btnNotAccept: 'Thi tiếp',
    descriptionToTalSubmit: `Bạn chưa thi hết các phần,
     bạn muốn nộp bài hay thi tiếp?`,
    duration: 'Thời gian tổng',
    btnToTalSubmit: 'Nộp bài thi tổng',
    atExam: 'Danh sách',
    time: 'phút'
  },
  store: {
    name: 'Cửa hàng',
    buyCoin: 'Mua xu',
    buyCourse: 'Khóa học',
    buyExam: 'Đề thi',
    buyClass: 'Lớp học'
  },
  blockCoin: {
    btnBuy: 'Mua ngay',
    coin: 'Xu'
  },
  blockBuyCourse: {
    valueDefaultCourse: 'Chọn khóa học',
    valueDefaultLevel: 'Chọn trình độ',
    btnBuy: 'Mua ngay',
    title: 'Mua khóa học',
    confirmBuySuccess: 'Bạn đã mua thành công khóa học',
    confirmBuyFail: 'Bạn mua thất bại khóa học',
    confirmNotCoin: `Bạn không đủ Xu để mua khóa học.
    Vui lòng nạp xu để mua khoá học `,
    btnConfirm: 'Đồng ý',
    confirm: 'Bạn đã mua khóa học này rồi. Thời gian còn lại là',
    confirmMore: 'Bạn có muốn mua tiếp?',
    day: 'ngày',
    confirmSure: 'Bạn muốn mua khóa học này?',
    onTime: 'Trọn đời',
    coin: 'Xu',
    successClass: `Tạo lớp học & thêm khóa học thành công.
    Vui lòng liên hệ với quản trị viên và đăng nhập vào trang quản trị`,
    titleClass: 'Lớp học',
    managementClass: 'Quản lí lớp học',
    requestBuyClass: 'Yêu cầu',
    btnStartStudy: 'Bắt đầu học',
    btnStartExam: 'Bắt đầu thi',
    stepBuy: 'Tiếp tục mua',
    btnBuyCoin: 'Nạp xu',
    durationForever: `Bạn đã mua khóa học này trọn đời.
    Bạn không cần mua lại khóa học. Cám ơn`
  },
  blockBuyExam: {
    valueDefaultPackageExam: 'Chọn gói đề thi',
    valueDefaultLevel: 'Chọn trình độ',
    valueDefaultExam: 'Chọn kỳ thi',
    btnBuy: 'Mua ngay',
    coin: 'Xu',
    confirm: 'Bạn muốn mua đề thi này?',
    btnConfirm: 'Đồng ý',
    title: 'Mua đề thi',
    confirmBought: 'Đề thi này đã được mua',
    confirmBuySuccess: 'Bạn đã mua thành công đề thi',
    confirmBuyFail: 'Bạn mua thất bại',
    confirmNotCoin: `Số xu trong tài khoản của bạn không đủ.
    Vui lòng nạp xu để mua khoá học `,
    btnBought: 'Đã mua',
    free: 'Miễn phí',
    createdClass: `Bạn có lớp học.
     Vui lòng vào trang quản trị mua thêm khóa học
    `
  },
  blockPayment: {
    title: 'Chọn hình thức thanh toán',
    transfer: 'Chuyển khoản',
    online: 'Thanh toán Online',
    center: 'Thanh toán Tại Trung tâm Rainichi',
    btnConfirm: 'Tạo đơn hàng',
    infoTransfer1: `Đơn hàng của bạn đã được chuyển đến hệ thống của Rainichi.`,
    infoTransfer2: ` Qúy khách vui lòng thanh toán vào các tài khoản sau của Rainichi.`,
    infoTransfer3: `Để được hướng dẫn thêm vui lòng gọi vào số: `,
    infoTransfer4: `Đơn hàng của bạn đã được ghi nhận, vui lòng đến văn phòng tại địa chỉ:`,
    infoTransfer6: `Để được hướng dẫn thêm vui lòng gọi vào số: `,
    infoBank1: `VCB - Ngân Hàng VietComBank`,
    infoBank2: `Tên tài khoản: Nguyễn Duy Cường`,
    infoBank3: `Số tài khoản: 0181003435738`,
    infoBank4: `Chi nhánh: Phòng giao dịch Quận 4`,
    infoBank5: `Thành phố: Hồ Chí Minh`,
    message: 'Thông báo',
    infoOffline: `Bạn vui lòng đến trung tâm Rainichi tại: `
  },
  alphabet: {
    listen: 'Phát âm'
  },
  infoPractice: {
    theSentence: 'Câu'
  },
  countTime: {
    title: 'Bạn đang thi',
    withTime: 'Có tính giờ',
    freeTime: 'Không tính giờ',
    btnSubmit: 'Nộp bài',
    message: 'Thông báo',
    disconnect: 'Internet đã bị ngắt kết nối'
  },
  profile: {
    titleLevel: 'Trình độ hiện tại',
    btnBuyCoin: 'Mua thêm xu',
    remainingCoin: 'Số xu còn lại',
    myProfile: 'Trang cá nhân',
    myExams: 'Đề thi của bạn',
    myCourse: 'Khóa học của bạn',
    support: 'Yêu cầu hỗ trợ',
    credit: 'Quản lí xu',
    setting: 'Cài đặt',
    methodsCredit: 'Phương thức đạt xu'
  },
  modalUpdateInformation: {
    title: 'Cập nhật thông tin',
    success: 'Cập nhật thông tin thành công',
    fail: 'Cập nhật thông tin thất bại',
    failImage: 'Hình ảnh không hỗ  trợ định dạng này hoặc email đã tồn tại',
    passwordFail: 'Mật khẩu không đúng',
    passwordSameFail: 'Mật khẩu cũ và mật khẩu mới phải khác nhau',
    failEmptyName: 'Tên không được để trống'
  },
  updateInformation: {
    title: 'Cập nhật thông tin',
    btnChangeInformation: 'Thay đổi thông tin',
    titleAvatar: 'Avatar đại diện',
    btnUploadAvatar: 'Chọn ảnh đại diện',
    or: 'Hay',
    chooseAvatarDefault: 'Chọn ảnh đại diện có sẵn',
    btnUpdate: 'Cập nhật',
    checkNaN: 'Dữ liệu phải là số ',
    checkValidatePhone: 'Số điện thoại phải đúng 10 số',
    checkIsEmpty: 'Dữ liệu không được để trống',
    message: 'Thông báo',
    checkEmail: 'Email không đúng cú pháp',
    checkName: 'Tên không được để trống',
    checkEmailEmpty: 'Email không được để trống',
    addressIsEmpty: 'Bạn chưa nhập địa chỉ liên hệ',
    contentIsEmpty: 'Hãy gửi 1 lời nhắn tới cho Rainichi nhé',
    phoneIsZero: `Bạn không nên nhập quá nhiều số 0`,
    passwordTheSame: 'Mật khẩu và mật khẩu nhập lại không khớp',
    passwordNotIsCorrect: `Mật khẩu phải chứa ít nhất một số và phải có ít nhất 8 ký tự.`,
    checkPasswordNew: `Bạn chưa điền mật khẩu mới`,
    courseNotSelect: 'Bạn chưa chọn khoá học',
    studentNull: 'Bạn chưa nhập số lượng học viên'
  },
  modalCheckLogin: {
    title: 'Đăng nhập',
    checkUserExits: 'Email đã được đăng kí',
    checkFailUserExist: 'Email và mật khẩu không đúng',
    checkEmailNotEnter: 'Bạn chưa nhập email',
    checkEmail: 'Bạn chưa nhập đúng cú pháp email',
    checkPasswordNotEnter: 'Bạn chưa nhập mật khẩu'
  },
  myExams: {
    title: 'Đề thi của bạn',
    titleExam: 'Đề thi',
    examResult: 'Kết quả',
    examSectionResult: 'Kết quả thi lần',
    notData: 'Bạn chưa có đề thi'
  },
  yourBlock: {
    scorePractice: 'Điểm luyện tập',
    learningResult: 'Kết quả học tập',
    date: 'Ngày thi',
    btnExam: 'Bắt đầu thi',
    btnDetail: 'Chi tiết',
    btnStudyNow: 'Học tiếp',
    nope: 'Chưa thi',
    fail: 'Không đạt',
    pass: 'Đạt',
    progress: 'Tiến độ chung',
    statusIncomplete: 'Chưa hoàn thành',
    statusComplete: 'Hoàn thành',
    statusStudying: 'Đang học',
    statusNotStudy: 'Chưa học',
    day: 'ngày',
    expirationDate: 'Ngày hết hạn',
    status: 'Trạng thái',
    forever: 'Trọn đời',
    score: 'Điểm',
    time: 'Lần thi',
    resultListQuestion: 'Lịch sử lần thi',
    resultHistoryMyExam: 'Đáp án',
    resultHistoryRight: 'Đúng',
    resultHistoryWrong: 'Sai'
  },
  myCourses: {
    learningResult: 'Kết quả học tập',
    student: 'Học viên',
    course: 'Khóa học',
    notData: 'Bạn chưa có khóa học'
  },
  myCoin: {
    title: 'Lịch sử giao dịch',
    coinCurrent: 'Số xu hiện tại',
    buyCoin: 'Mua xu',
    quantity: 'Số xu',
    dateBuy: 'Ngày mua',
    notTransactions: 'Hiện không có giao dịch nào',
    changeCoin: 'Đổi xu',
    dateChange: 'Ngày đổi',
    rewardCoin: 'Kiếm xu',
    receivedDate: 'Ngày nhận'
  },
  methodCredit: {
    title: 'Phương thức đạt xu',
    quantity: 'Số xu',
    method: 'Phương thức'
  },
  lessonInCourse: {
    notData: 'Không có dữ liệu',
    message: 'Thông báo',
    buy: 'Bạn phải hoàn thành bài học trước mới có thể học tiếp bài học này. Xin cảm ơn.',
    bought: 'Bạn cần mua khoá học này để học tiếp phần này. Xin cảm ơn.',
    btnBuy: 'Mua ngay'
  },
  settings: {
    passwordNotSame: 'Mật khẩu xác nhận không giống với mật khẩu mới',
    checkPassword: `Mật khẩu phải chứa ít nhất một số và phải có ít nhất
    8 kí tự`,
    passwordNotEnter: 'Bạn chưa điền mật khẩu cũ',
    showAnnoument: 'Hiển thị thông báo',
    remindStudy: 'Lời nhắc học tập',
    title: 'Thay đổi mật khẩu',
    placeholderPasswordOld: 'Mật khẩu cũ',
    placeholderPasswordNew: 'Mật khẩu mới',
    confirmPassword: 'Xác nhận mật khẩu mới',
    btnAccept: 'Đồng ý'
  },
  article: {
    news: `Tin Tức`,
    notData: 'Không có dữ liệu'
  },
  articleDetail: {
    btnApply: 'Ứng tuyển ngay',
    btnApplyed: 'Đã ứng tuyển',
    btnShareFacebook: 'Chia sẻ Facebook',
    btnShareGooglePlust: 'Chia sẻ Google+',
    btnShareTwitter: 'Chia sẻ Twitter',
    articlePrevNotData: 'Không có bài trước',
    articleNextNotData: 'Không có bài tiếp theo',
    expireDate: 'Ngày hết hạn'
  },
  modalContent: {
    resultDescriptionJob: 'Bạn đã nộp đơn thành công',
    title: 'Việc làm',
    checkCV: 'Bạn chưa thêm CV',
    checkEmail: 'Bạn chưa thêm Email',
    emailValid: 'Email không đúng cú pháp',
    checkName: 'Bạn chưa nhập tên',
    checkDescription: 'Mô tả không được để trống',
    checkCVIsApplied: 'Bạn đã nộp CV này rồi',
    checkCVMaxFile: 'Kích thước file không vượt quá 1MB',
    fileEmpty: 'Vui lòng điền thông tin vào file CV'
  },
  faq: {
    frequentlyAskedQuestions: 'CÂU HỎI THƯỜNG GẶP'
  },
  slideCenter: {
    registerStudy: 'Đăng ký học ngay'
  },
  contact: {
    fullName: 'Họ & Tên',
    enterFullName: 'Nhập họ & tên',
    phone: 'Số điện thoại',
    enterPhone: 'Nhập số điện thoại',
    email: 'Email',
    enterEmail: 'Nhập email',
    address: 'Địa chỉ liên hệ',
    enterAddress: 'Nhập địa chỉ liên hệ',
    message: 'Lời nhắn của bạn đến Trung Tâm Rainichi',
    enterMessage: 'Hãy gửi 1 lời nhắn tin Rainichi',
    btnSend: 'Gửi'
  },
  applyJob: {
    btnComeBack: 'Trở về',
    applyToJob: 'Ứng tuyển',
    nameCandidate: 'Tên của bạn',
    enterNameCandidate: 'Nhập tên của bạn',
    emailCandidate: 'Email của bạn',
    enterEmailCandidate: 'Nhập email của bạn',
    uploadMyCV: 'Upload CV của bạn',
    uploadFile: 'Chọn file',
    notFile: 'Không có file nào được chọn',
    descriptionStrongMySkill: 'Mô tả về những điểm mạnh của bạn',
    enterStrongMySkill: 'Nhập điểm mạnh của bạn',
    btnSendCV: 'Gửi CV'
  },
  pageAnnoumentMobile: {
    message: 'Thông báo',
    subMessage: `Vui lòng dùng 1 trong 2 phương thức sau để sử dụng cho phần này`,
    useDesktop: `Dùng phiên bản hiển thị trên máy tính`,
    useMobile: `Download ứng dụng từ Apple Store hay Google để sử dụng`
  },
  modalContact: {
    title: 'Thông báo',
    emptyName: 'Bạn chưa nhập họ và tên',
    emptyEmail: 'Bạn chưa nhập email',
    emptyPhone: 'Bạn chưa nhập số điện thoại',
    emptyCourse: 'Bạn chưa lựa chọn khóa học',
    emptyNumOfEmployee: 'Bạn chưa lựa chọn số học viên',
    registerSuccess: `Bạn đã đăng kí thành công. Chúng tôi sẽ liên hệ với bạn sau.
    Cảm ơn bạn.`,
    emptyAddress: `Bạn chưa nhập địa chỉ`,
    emptyContent: `Hãy gửi 1 lời nhắn cho Rainichi`,
    sendSuccess: `Cảm ơn bạn đã liên hệ với chúng tôi.`,
    phoneIsZero: `Bạn không nên nhập quá nhiều số 0`,
    phoneNotValid: 'Số điện thoại của bạn không đúng',
    emailNotValid: 'Email không đúng cú pháp',
    nameCourse: 'Mua Khóa học',
    enterName: 'Nhập Họ và tên',
    enterPhone: 'Nhập số điện thoại',
    enterEmail: 'Nhập email',
    enterContent: 'Lời nhắn dành cho Rainichi',
    btnRegister: 'Đăng ký',
    btnRegisterNow: 'Đăng ký học ngay',
    userExist: 'Bạn đã có lớp học . Bạn có muốn mua tiếp lớp học không?',
    userJoined: 'Học viên đã được vào lớp. Chức năng này chỉ dành cho học viên chưa có lớp',
    notAccept: 'Không đồng ý',
    accept: 'Đồng ý'
  },
  buyClass: {
    buyClassNotExist: 'Mua lớp theo yêu cầu',
    enterNumberOfEmployee: 'Nhập số lượng học viên',
    enterCourse: 'Chọn khóa học',
    btnRequest: 'Yêu cầu'
  },
  strengthForEnterprise: {
    titlePrimary: 'Điểm mạnh khi doanh nghiệp sử dụng ứng dụng',
    titleBlack: 'Tính năng quản lí lớp học',
    btnMovePageAdmin: 'Trang quản lý khóa học'
  },
  enterprise: {
    btnBuyClass: 'Mua lớp học'
  },
  joinCenter: {
    day: 'ngày',
    hour: 'giờ',
    minutes: 'phút',
    seconds: 'giây',
    selectCourse: 'Chọn khóa học',
    allItemAreSelected: 'Đã chọn hết khóa học',
    selectAll: 'Chọn hết khóa học',
    search: 'Tìm kiếm',
    expired: 'Hết hạn',
    quickUp: 'NHANH TAY LÊN',
    getGoodDeals: 'Bạn Muốn Nhận Được Ưu Đãi Tốt'
  },
  allCoursePageCenter: {
    btnRegisterNow: 'Đăng ký ngay'
  },
  commingSoon: {
    btnRegister: 'Đăng ký'
  },
  training: {
    btnJoinTeam: `Gia nhập team "Rainichi" ngay hôm nay`
  },
  joinEnterprise: {
    descriptionIntro: `Cùng Rainichi khám phá siêu năng lực bản thân !!!!!`,
    titlePrimaryIntro: `TEAM RAINICHI`,
    titleBlackIntro: `GIA NHẬP`,
    registerCourseEnterprise: `ĐĂNG KÍ LỚP HỌC CHO DOANH NGHIỆP`,
    youWantSale: `Bạn Muốn Nhận Được Ưu Đãi Tốt`,
    selectNumberStudent: `Chọn số học viên`,
    registerNow: `ĐĂNG KÝ NGAY`
  },
  articleJob: {
    workPlace: `Nơi làm việc`,
    expire: `Hết hạn`,
    salary: `Mức lương`,
    position: `Chức vụ`
  },
  seoTitle: {
    event: `SỰ KIỆN`,
    jobs: `VIỆC LÀM`,
    faq: `FAQ`,
    center: `TRUNG TÂM`,
    store: `CỬA HÀNG`,
    exam: `ĐỀ THI`,
    course: `KHOÁ HỌC`,
    enterprise: `DOANH NGHIỆP`,
    profile: `TRANG CÁ NHÂN`,
    login: `ĐĂNG NHẬP`,
    home: `RAINICHI`
  }
};
