import { createStructuredSelector } from 'reselect';

const isLoggedIn = state => state.login.status;
const activeId = state => state.header.active;
const languages = state => state.app.languages || [];
const currentLang = state => state.app.currentLang;
const myInformation = state => state.profile.myInformation;
const avatarProfile = state =>
  state.login.user && state.login.user.profile && state.login.user.profile.avatar;
const namerProfile = state =>
  state.login.user && state.login.user.profile && state.login.user.profile.name;
const idProfile = state =>
  state.login.user && state.login.user.profile && state.login.user.profile.id;
const menuHeader = state => state.app.listMenu.header;
const isShowMenu = state => state.header.isShowMenu;
const listConfiguration = state => state.app.listConfiguration;

export default createStructuredSelector({
  isLoggedIn,
  activeId,
  languages,
  currentLang,
  myInformation,
  avatarProfile,
  namerProfile,
  idProfile,
  menuHeader,
  isShowMenu,
  listConfiguration
});
