import { createStructuredSelector } from 'reselect';

const leftMenu = state => state.app.leftMenu;
const breadcrumb = state => state.app.breadcrumb;
const currentLang = state => state.app.currentLang;
const myInformation = state => state.profile.myInformation;
const isFetching = state => state.profile.isFetching;
const avatarDefault = state => state.profile.avatarDefault;
const myExams = state => state.profile.myExams;
const isShowDetailMyExam = state => state.profile.isShowDetailMyExam;
const isShowDetailMyExamSection = state => state.profile.isShowDetailMyExamSection;
const myExamDetail = state => state.profile.myExamDetail;
const myCourses = state => state.profile.myCourses;
const isShowMyCourseDetail = state => state.profile.isShowMyCourseDetail;
const myCourseDetail = state => state.profile.myCourseDetail;
const myCoins = state => state.profile.myCoins;
const courseId = state => state.profile.courseId;
const courseName = state => state.profile.courseName;
const status = state => state.profile.status;
const myAvatarDefault = state => state.profile.myAvatarDefault;
const myPositionAvatarDefault = state => state.profile.myPositionAvatarDefault;
const courseDetail = state => state.courseChild.courseDetail;
const isShowMenu = state => state.header.isShowMenu;
const medthodCreditArr = state => state.profile.medthodCreditArr;
export default createStructuredSelector({
  leftMenu,
  breadcrumb,
  currentLang,
  myInformation,
  isFetching,
  avatarDefault,
  myExams,
  isShowDetailMyExam,
  isShowDetailMyExamSection,
  myExamDetail,
  myCourses,
  isShowMyCourseDetail,
  myCourseDetail,
  myCoins,
  courseId,
  courseName,
  status,
  myAvatarDefault,
  myPositionAvatarDefault,
  courseDetail,
  isShowMenu,
  medthodCreditArr
});
