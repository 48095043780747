/*
 * @Author: loc.do@bstarsolutions.com
 * @Date: 2019-07-01 15:02:06
 * @Last Modified by: loc.do@bstarsolutions.com
 * @Last Modified time: 2019-07-08 17:46:15
 */
import { scaleStyle } from 'utils';
import { theme } from 'appCore';

export default {
  switch: {
    position: 'relative',
    display: 'inline-block',
    ...scaleStyle({
      width: 80,
      height: 34
    }),
    '& > input': {
      opacity: 0,
      width: 0,
      height: 0
    }
  },
  slider: {
    position: 'absolute',
    cursor: 'pointer',
    top: 0,
    left: 0,
    right: 0,
    bottom: 0,
    backgroundColor: theme.colors.white,
    WebkitTransition: '.4s',
    transition: '.4s',
    border: `1px solid ${theme.colors.black}`,
    '&:before': {
      position: 'absolute',
      content: '""',
      backgroundColor: theme.colors.black,
      WebkitTransition: '.4s',
      transition: '.4s',
      ...scaleStyle({
        height: 25,
        width: 25,
        left: 4,
        bottom: 4
      })
    }
  },
  input: {
    '&:checked': {
      '&+span': {
        backgroundColor: theme.colors.primary,
        border: `1px solid ${theme.colors.primary}`,
        '&:before': {
          WebkitTransform: 'translateX(26px)',
          MsTransform: 'translateX(26px)',
          transform: 'translateX(26px)',
          backgroundColor: theme.colors.white
        }
      }
    },
    '&:focus': {
      '&+slider': {
        boxShadow: `0 0 1px ${theme.colors.primary}`
      }
    }
  },
  round: {
    '&:before': {
      borderRadius: '50%'
    },
    ...scaleStyle({
      borderRadius: 34
    })
  }
};
