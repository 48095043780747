export const AUTO_LOGIN_SUBMIT = 'AUTO_LOGIN_SUBMIT';
export const AUTO_LOGIN_FETCHING = 'AUTO_LOGIN_FETCHING';
export const AUTO_LOGIN_SUCCESS = 'AUTO_LOGIN_SUCCESS';
export const AUTO_LOGIN_FAILURE = 'AUTO_LOGIN_FAILURE';

export const SUPPORT_LANGUAGE_GET = 'SUPPORT_LANGUAGE_GET';
export const SUPPORT_LANGUAGE_FETCHING = 'SUPPORT_LANGUAGE_FETCHING';
export const SUPPORT_LANGUAGE_SUCCESS = 'SUPPORT_LANGUAGE_SUCCESS';
export const SUPPORT_LANGUAGE_FAILURE = 'SUPPORT_LANGUAGE_FAILURE';

export const LANGUAGE_CHANGE = 'LANGUAGE_CHANGE';

export const BREADCRUMB_CHANGE = 'BREADCRUMB_CHANGE';
export const TABS_CHANGE = 'TABS_CHANGE';
export const LEFTMENU_CHANGE = 'LEFTMENU_CHANGE';

export const GET_LIST_MENU_INIT = 'GET_LIST_MENU_INIT';
export const GET_LIST_MENU_FETCHING = 'GET_LIST_MENU_FETCHING';
export const GET_LIST_MENU_SUCCESS = 'GET_LIST_MENU_SUCCESS';
export const GET_LIST_MENU_FAIL = 'GET_LIST_MENU_FAIL';

export const GET_LIST_CONFIGURATION_INIT = 'GET_LIST_CONFIGURATION_INIT';
export const GET_LIST_CONFIGURATION_FETCHING = 'GET_LIST_CONFIGURATION_FETCHING';
export const GET_LIST_CONFIGURATION_SUCCESS = 'GET_LIST_CONFIGURATION_SUCCESS';
export const GET_LIST_CONFIGURATION_FAIL = 'GET_LIST_CONFIGURATION_FAIL';
