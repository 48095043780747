/*
 * @Author: loc.do@bstarsolutions.com
 * @Date: 2019-05-10 10:38:40
 * @Last Modified by: loc.do@bstarsolutions.com
 * @Last Modified time: 2019-05-17 17:18:29
 */

import * as types from './types';

export const fetchCourse = () => ({
  type: types.COURSE_INIT
});

export const isCourseCombo = value => ({
  type: types.COURSE_COMBO,
  payload: value
});

export const isPrimary = value => ({
  type: types.COURSE_PACKAGE_PRIMARY,
  payload: value
});

export default this;
