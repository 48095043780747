/*
 * @Author: loc.do@bstarsolutions.com
 * @Date: 2019-06-07 15:35:35
 * @Last Modified by: loc.do@bstarsolutions.com
 * @Last Modified time: 2019-10-02 11:52:08
 */

import * as types from './types';

export const fetchListCoin = () => ({
  type: types.COIN_INIT
});

export const fetchListCourseShop = type => ({
  type: types.COURSE_SHOP_INIT,
  payload: {
    type
  }
});

export const fetchListSkillShop = () => ({
  type: types.SKILL_SHOP_INIT
});

export const fetchListSelectCourseShop = (id, courseType, levels, typeUser) => ({
  type: types.SELECT_COURSE_SHOP_INIT,
  payload: {
    id,
    courseType,
    levels,
    typeUser
  }
});

export const fetchListSelectExamShop = (arrayLevel, type, courseType) => ({
  type: types.EXAM_SHOP_INIT,
  payload: {
    type,
    courseType,
    arrayLevel
  }
});

export const filterCourseInShop = (id, courseId, typeCourse) => ({
  type: types.FILTER_COURSE_FROM_COURSE_TO_SHOP_INIT,
  payload: {
    id,
    courseId,
    typeCourse
  }
});

export const fetchPayment = (
  vnpBankCode,
  amount,
  orderInfo,
  courseId,
  isEnterprise,
  duration,
  numOfEmployee
) => ({
  type: types.PAYMENT_INIT,
  payload: {
    vnpBankCode,
    amount,
    orderInfo,
    courseId,
    isEnterprise,
    duration,
    numOfEmployee
  }
});

export const showPaymentPage = courseEnterprise => ({
  type: types.SHOW_PAYMENT_PAGE,
  payload: {
    courseEnterprise
  }
});

export const hidePaymentPage = () => ({
  type: types.HIDE_PAYMENT_PAGE
});

export const payTransferCoinOffline = (
  paymentType,
  amount,
  orderInfo,
  courseId,
  isEnterprise,
  duration,
  numOfEmployee
) => ({
  type: types.PAYMENT_BUY_OFFLINE_INIT,
  payload: {
    paymentType,
    amount,
    orderInfo,
    courseId,
    isEnterprise,
    duration,
    numOfEmployee
  }
});

export const filterExamInShop = examId => ({
  type: types.FILTER_EXAM_IN_COURSE_INIT,
  payload: {
    examId
  }
});

export const buyCourseInShop = (courseId, credit, reward, duration) => ({
  type: types.BUY_COURSE_IN_SHOP_INIT,
  payload: {
    courseId,
    credit,
    reward,
    duration
  }
});

export const buyExamInShop = (examId, credit, reward, duration) => ({
  type: types.BUY_EXAM_IN_SHOP_INIT,
  payload: {
    examId,
    credit,
    reward,
    duration
  }
});

export const buyClassInShopExist = (typeForm, courseId, numOfEmployee, email, phone, name) => ({
  type: types.BUY_CLASS_NOT_EXIST_INIT,
  payload: {
    typeForm,
    courseId,
    numOfEmployee,
    email,
    phone,
    name
  }
});

export const checkBoughtClass = courseEnterprise => ({
  type: types.CHECK_BOUGHT_CLASS_INIT,
  payload: {
    courseEnterprise
  }
});

export const fetchPaymentBuyCourse = (vnpBankCode, amount, orderInfo, courseId) => ({
  type: types.BUY_COURSE_VNPAY_INIT,
  payload: {
    vnpBankCode,
    amount,
    orderInfo,
    courseId
  }
});

export default this;
