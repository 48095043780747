import { createStructuredSelector } from 'reselect';

const isOpen = state => state.modals.isOpen;
const modalProps = state => state.modals.modalProps;
const isShowBtnClose = state => state.modals.isShowBtnClose;

export default createStructuredSelector({
  isOpen,
  modalProps,
  isShowBtnClose
});
