/*
 * @Author: thach.nguyen@bstarsolutions.com
 * @Date: 2019-06-14 09:00:21
 * @Last Modified by: loc.do@bstarsolutions.com
 * @Last Modified time: 2019-07-30 17:57:26
 */

export const EXERCISE_INIT = 'EXERCISE_INIT';
export const EXERCISE_FETCHING = 'EXERCISE_FETCHING';
export const EXERCISE_SUCCESS = 'EXERCISE_SUCCESS';
export const EXERCISE_FAIL = 'EXERCISE_FAIL';

export const PRACTICE_EXERCISE_INIT = 'PRACTICE_EXERCISE_INIT';
export const PRACTICE_EXERCISE_FETCHING = 'PRACTICE_EXERCISE_FETCHING';
export const PRACTICE_EXERCISE_SUCCESS = 'PRACTICE_EXERCISE_SUCCESS';
export const PRACTICE_EXERCISE_FAIL = 'PRACTICE_EXERCISE_FAIL';

export const SUBMIT_PRACTIC_EXERCISE_INIT = 'SUBMIT_PRACTIC_EXERCISE_INIT';
export const SUBMIT_PRACTICE_EXERCISE_FETCHING = 'SUBMIT_PRACTICE_EXERCISE_FETCHING';
export const SUBMIT_PRACTICE_EXERCISE_SUCCESS = 'SUBMIT_PRACTICE_EXERCISE_SUCCESS';
export const SUBMIT_PRACTICE_EXERCISE_FAIL = 'SUBMIT_PRACTICE_EXERCISE_FAIL';

export const CHANGE_ACTIVE_EXERCISE_INIT = 'CHANGE_ACTIVE_EXERCISE_INIT';
export const CHANGE_ACTIVE_ANSWER_EXERCISE_SUCCESS = 'CHANGE_ACTIVE_ANSWER_EXERCISE_SUCCESS';
export const CHANGE_ACTIVE_EXERCISE_ANSWER_EXERCISE_FAIL =
  'CHANGE_ACTIVE_EXERCISE_ANSWER_EXERCISE_FAIL';

export const REMAKE_DATA_PRACTICE_INIT = 'REMAKE_DATA_PRACTICE_INIT';
export const REMAKE_DATA_PRACTICE = 'REMAKE_DATA_PRACTICE';

export const SHOW_PAGE_PRACTICE_EXERCISE = 'SHOW_PAGE_PRACTICE_EXERCISE';
export const HIDE_PAGE_PRACTICE_EXERCISE = 'HIDE_PAGE_PRACTICE_EXERCISE';

export const SAVE_ID_EXERCISE = 'SAVE_ID_EXERCISE';

export const SUBMIT_NEW_TEST_PRACTICE_INIT = 'SUBMIT_NEW_TEST_PRACTICE_INIT';
export const SUBMIT_NEW_TEST_PRACTICE_FETCHING = 'SUBMIT_NEW_TEST_PRACTICE_FETCHING';
export const SUBMIT_NEW_TEST_PRACTICE_SUCCESS = 'SUBMIT_NEW_TEST_PRACTICE_SUCCESS';
export const SUBMIT_NEW_TEST_PRACTICE_FAIL = 'SUBMIT_NEW_TEST_PRACTICE_FAIL';

export const REMOVE_RESULT_EXERCISE = 'REMOVE_RESULT_EXERCISE';

export const HAS_TIME_EXAM = 'HAS_TIME_EXAM';

export const HAS_NOT_TIME_EXAM = 'HAS_NOT_TIME_EXAM';

export const SET_ID_SECTION_INIT = 'SET_ID_SECTION_INIT';
export const SET_ID_SECTION_FETCHING = 'SET_ID_SECTION_FETCHING';
export const SET_ID_SECTION_SUCCESS = 'SET_ID_SECTION_SUCCESS';
export const SET_ID_SECTION_FAIL = 'SET_ID_SECTION_FAIL';

export const CHANGE_IS_FETCHING_FALSE = 'CHANGE_IS_FETCHING_FALSE';

export const CHANGE_IS_FETCHING_TRUE = 'CHANGE_IS_FETCHING_TRUE';

export const IS_BUTTON_TOTAL = 'IS_BUTTON_TOTAL';
