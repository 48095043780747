import { theme } from 'appCore';
import { scaleStyle } from 'utils';

export default {
  wrapper: {
    margin: 'auto',
    position: 'relative',
    ...scaleStyle({
      minHeight: 347,
      minWidth: 500
    })
  },
  close: {
    position: 'absolute',
    cursor: 'pointer',
    transition: 'all 1s',
    '&:hover': {
      transform: 'rotate(180deg)'
    },
    ...scaleStyle({
      right: 40,
      top: 20,
      width: 30,
      height: 30
    })
  },
  content: {
    display: 'flex',
    flexDirection: 'column',
    top: 0,
    left: 0,
    right: 0,
    bottom: 0,
    width: `100%`,
    transition: 'all 1s',
    ...scaleStyle({
      minHeight: 275,
      padding: 20
    })
  },
  title: {
    color: theme.colors.primary,
    fontWeight: 'bold',
    textAlign: 'center',
    textTransform: 'uppercase',
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    ...scaleStyle({
      fontSize: 30
    })
  },
  divider: {
    height: 5,
    background: theme.colors.primary,
    ...scaleStyle({
      marginTop: 23,
      width: 100
    })
  },
  children: {
    alignItems: 'center',
    flex: 1,
    display: 'flex',
    justifyContent: 'center',
    textAlign: 'center',
    width: `100%`,
    ...scaleStyle({
      fontSize: 20,
      marginTop: 35
    })
  },
  [`@media screen and (max-width: ${theme.screens.maxWidth800}px)`]: {
    children: {
      maxWidth: 250,
      display: 'flex',
      alignSelf: 'center'
    }
  }
};
